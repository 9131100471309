import React, { useEffect, useState } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Avatar, Divider, IconButton } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { history } from 'redux/store/configureStore';
import { getMyProfile, logout } from 'redux/actions/UserActions/user.action';
import Popup from '../Popup';
import ResetPassword from 'screen/ResetPassword';
import { useForm } from '../useForm';
import { initialFieldValues } from 'screen/ResetPassword/utils';
import { useStyles } from './utils';
import CustomizedSnackbars from 'components/Snackbar';
import { getCookieData } from 'utils/functionsCustom';
import _ from 'lodash';
import SidebarSlider from 'components/SidebarSlider';
export default function GenericDropdown(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [addOrEditDrawer, setAddOrEditDrawer] = useState(false);
  const toggleDrawer = (setState, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setState(open);
  };
  const { values, setValues } = useForm(initialFieldValues, true);

  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [user, setUser] = useState({});
  const userLoginInfo = useSelector((state) => state.userLogin);
  const { profile, loading: loadProfile } = useSelector(
    (state) => state.myProfile
  );

  const { isAuthenticated } = userLoginInfo;

  const { email, refreshToken } = getCookieData();

  useEffect(() => {
    isAuthenticated && dispatch(getMyProfile());
  }, [dispatch, isAuthenticated]);
  useEffect(() => {
    if (!loadProfile && !_.isEmpty(profile)) {
      setUser({ ...profile });
    }
  }, [setUser, profile, loadProfile]);
  // const openInPopup = (item) => {
  //   setOpenPopup(true);
  // };

  return (
    <div>
      <IconButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        {isAuthenticated && !_.isEmpty(user) && (
          <>
            <Avatar
              style={{ height: '30px', width: '30px' }}
              alt={`${user?.first_name} ${user?.last_name}`}
              src={
                !loadProfile && props?.userimg
                  ? `${props?.userimg}`
                  : '/static/images/avatar/1.jpg'
              }
              variant="circular"
            />
          </>
        )}
      </IconButton>
      <Menu
        {...props}
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <MenuItem className={classes.menuWidth}>
          {user && (
            <div
              style={{
                display: 'flex',
                color: '#222',
                flexDirection: 'row',
                gridColumnGap: 10,
                justifyContent: 'space-between',
                alignItems: 'flex-end'
              }}
            >
              <Avatar
                style={{
                  height: '30px',
                  width: '30px',
                  justifyContent: 'top',
                  fontSize: 20,
                  marginBottom: 8
                }}
                alt={`${user.first_name} ${user?.last_name}`}
                // alt={`${user.first_name} ${user.last_name}`}

                src={
                  !loadProfile && props?.userimg
                    ? `${props?.userimg}`
                    : '/static/images/avatar/1.jpg'
                }
                variant="circular"
              />
              <span
                style={{
                  display: 'flex',
                  color: '#222',
                  flexDirection: 'column',
                  gridColumnGap: 5,
                  justifyContent: 'space-between'
                }}
              >
                <p
                  style={{
                    margin: 0,
                    flexGrow: 1
                  }}
                >
                  {`${user?.first_name} ${user?.last_name}`}
                  {/* {`${user.first_name} ${user.last_name}`} */}
                </p>
                <p
                  style={{
                    margin: 0,
                    flexGrow: 1,
                    fontSize: 12
                  }}
                >
                  ({`${user.role}`})
                </p>
              </span>
            </div>
          )}
        </MenuItem>
        <Divider className={classes.divider} />
        <MenuItem
          onClick={() => {
            history.push('/my-profile');
            dispatch(getMyProfile());
            handleClose();
          }}
          className={classes.menuWidth}
        >
          Profile
        </MenuItem>
        <MenuItem
          onClick={() => {
            // history.push('/reset-password');

            setAddOrEditDrawer(true);

            // dispatch(getSingleUser(user.id));
            handleClose();
          }}
          className={classes.menuWidth}
        >
          Change Password
        </MenuItem>
        <MenuItem
          className={classes.menuWidth}
          onClick={() => dispatch(logout({ email, refreshToken }))}
        >
          Logout
        </MenuItem>
      </Menu>
      {/* <Popup
        title="Change Password"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        setValues={setValues}
        values={values}
        maxWidth="sm"
      >
        <ResetPassword openPopup={openPopup} setOpenPopup={setOpenPopup} />
      </Popup> */}
      <SidebarSlider
        title={`Change Password`}
        toggleDrawer={toggleDrawer}
        openSlider={addOrEditDrawer}
        setOpenSlider={setAddOrEditDrawer}
        width="40vw"
      >
        {/* <AddOrEditCustomers setOpenSlider={setAddOrEditDrawer} /> */}
        <ResetPassword setOpenSlider={setAddOrEditDrawer} />
      </SidebarSlider>
      <CustomizedSnackbars />
    </div>
  );
}
