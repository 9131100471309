import { call, put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import { GET_LOW_STOCK_REQUEST } from 'redux/constants/InventoryConstants/inventory.dashboard.constants';
import {
  populateLowStockItems,
  populateLowStockItemsFailed
} from 'redux/actions/InventoryActions/inventory.dashboard.action';
import { setSnackbar } from 'redux/actions/UIComponentActions/snackbar.action';
import api from 'utils/api';

const baseURLProd = 'http://216.10.244.34:10005/rp/api/v1';

export function* getAllLowStockItems() {
  yield takeLatest(GET_LOW_STOCK_REQUEST, fetchProducts);
}
/* const response = yield call(getTweets, buttonStatus);

 */
function* fetchProducts(payload) {
  const {
    page: currentPage,
    size,
    orderBy: sortField,
    order: sortDir,
    searchValue: query,
    outletId
  } = payload;
  try {
    const { data } = yield api.get('/lowstock-variants', {
      params: {
        currentPage: currentPage + 1 || 1,
        size: size || 8,
        sortField: sortField ?? 'po_number',
        sortDir: sortDir || 'asc',
        query: query || '',
        outletId: outletId || ''
      }
    });
    /* getAwaiting-Delivery?currentPage=1&size=3&sortField=po_number&sortDir=asc */
    yield put(populateLowStockItems(data));
  } catch (error) {
    yield put(populateLowStockItemsFailed(error));
    if (error?.response?.status !== 404) {
      yield put(setSnackbar(true, 'error', error.message));
    }
  }
}

/* 
function* fetchProducts(payload) {
  const {
    page: currentPage,
    size,
    orderBy: sortField,
    order: sortDir,
    searchValue: query,
    oultetId
  } = payload;
  try {
    const { data } = yield api.get('/getAwaiting-Delivery', {
      params: {
        currentPage: currentPage + 1 || 1,
        size: size || 8,
        sortField: sortField ?? 'po_number',
        sortDir: sortDir || 'asc',
        query: query || '',
        outletId: oultetId || ''
      }
    });
  
    yield put(populateAwaitingDelivery(data));
  } catch (error) {
    yield put(populateAwaitingDeliveryFailed(error));
    if (error?.response?.status !== 404) {
      yield put(setSnackbar(true, 'error', error.message));
    }
  }
}
*/
