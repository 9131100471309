import {
  GET_ALL_PURCHASES_SUCCESS,
  GET_ALL_PURCHASES_FAILED,
  REMOVE_PURCHASES_SUCCESS,
  ADD_PURCHASES_SUCCESS,
  ADD_PURCHASES_FAILED,
  UPDATE_PURCHASES_SUCCESS,
  GET_SINGLE_PURCHASES_FAILED,
  GET_SINGLE_PURCHASES_SUCCESS,
  UPDATE_PURCHASES_FAILED,
  GET_ALL_PURCHASES_REQUEST,
  REMOVE_PURCHASES_REQUEST
} from 'redux/constants/InventoryConstants/inventory.purchases.constants';

const InventoryPurchaseReducer = (
  state = {
    loading: false,
    purchases: [],
    error: false,
    purchase: {},
    page: 1,
    first: true,
    last: true
  },
  action
) => {
  const { purchases } = state;
  let newPurchases;
  switch (action.type) {
    case GET_ALL_PURCHASES_REQUEST:
      return {
        ...state,
        purchases: [],
        loading: true
      };
    case GET_ALL_PURCHASES_SUCCESS:
      return {
        ...state,
        purchases: [...action.payload.content],
        page: action.payload.page,
        pages: action.payload.totalElements,
        first: action.payload.first,
        last: action.payload.last,
        size: action.payload.size,
        searchValue: action.payload.searchValue,
        loading: false
      };
    case GET_ALL_PURCHASES_FAILED:
      return {
        ...state,
        error: action.payload,
        purchases: [...state.purchases],
        loading: false
      };
    case GET_SINGLE_PURCHASES_SUCCESS:
      return {
        ...state,
        purchase: action.payload,
        loading: false
      };
    case GET_SINGLE_PURCHASES_FAILED:
      return {
        ...state,
        error: action.payload,
        purchase: {},
        loading: false
      };
    case ADD_PURCHASES_SUCCESS:
      newPurchases = {
        ...state,
        purchases: [...purchases, action.payload],
        loading: false,
        error: false
      };
      return newPurchases;
    case ADD_PURCHASES_FAILED:
      return {
        error: action.payload,
        purchases: [...state.purchases]
      };
    case REMOVE_PURCHASES_REQUEST:
      return {
        ...state,
        loading: true,
        error: false
      };
    case REMOVE_PURCHASES_SUCCESS:
      newPurchases =
        purchases &&
        state.purchases.filter(
          (purchase) => purchase?.id !== action?.payload?.id
        );
      return {
        // ...state,
        loading: false,
        purchases: newPurchases,
        error: false
      };

    case UPDATE_PURCHASES_SUCCESS:
      const {
        id,
        outlet,
        cannot_be_modified,
        po_number,
        po_date,
        due_date,
        supplier,
        ref_number,
        status,
        status_name,
        tax_inclusive,
        discount_is_as_percentage,
        discount_value,
        total_discount,
        total_tax,
        total_shipping_cost,
        shipping_tax_id,
        shipping_tax_name,
        shipping_tax_rate,
        shipping_tax_amount,
        rounding_amount,
        sub_total,
        net_amount,
        note,
        created_date_time,
        last_modified_time,
        purchase_order_line_items,
        shipping_cost_ex_tax
      } = action.payload;

      newPurchases = [...state.purchases];
      const existingProduct = state.purchases.find(
        (purchase) => purchase.id === id
      );

      if (existingProduct) {
        existingProduct.outlet = outlet;

        existingProduct.cannot_be_modified = cannot_be_modified;
        existingProduct.po_number = po_number;
        existingProduct.po_date = po_date;
        existingProduct.due_date = due_date;
        existingProduct.supplier = supplier;

        existingProduct.ref_number = ref_number;
        existingProduct.status = status;
        existingProduct.status_name = status_name;
        existingProduct.tax_inclusive = tax_inclusive;
        existingProduct.discount_is_as_percentage = discount_is_as_percentage;
        existingProduct.discount_value = discount_value;
        existingProduct.total_discount = total_discount;
        existingProduct.total_tax = total_tax;
        existingProduct.shipping_cost_ex_tax = shipping_cost_ex_tax;
        existingProduct.total_shipping_cost = total_shipping_cost;
        existingProduct.shipping_tax_id = shipping_tax_id;
        existingProduct.shipping_tax_name = shipping_tax_name;
        existingProduct.shipping_tax_rate = shipping_tax_rate;
        existingProduct.shipping_tax_amount = shipping_tax_amount;
        existingProduct.rounding_amount = rounding_amount;
        existingProduct.sub_total = sub_total;
        existingProduct.net_amount = net_amount;
        existingProduct.note = note;
        existingProduct.created_date_time = created_date_time;
        existingProduct.last_modified_time = last_modified_time;
        existingProduct.purchase_order_line_items = purchase_order_line_items;
      }

      return { purchases: newPurchases };
    case UPDATE_PURCHASES_FAILED: {
      return {
        error: action.payload,
        purchases: [...state.purchases]
      };
    }

    default:
      return state;
  }
};
export default InventoryPurchaseReducer;
