import {
  call,
  put,
  take,
  takeLatest,
  takeEvery,
  delay
} from 'redux-saga/effects';

import {
  ADD_PAYMENT_TYPES_REQUEST,
  ADD_PAYMENT_TYPES_SUCCESS,
  GET_ALL_PAYMENT_TYPES_REQUEST,
  GET_SINGLE_PAYMENT_TYPES_REQUEST,
  REMOVE_PAYMENT_TYPES_FAILED,
  REMOVE_PAYMENT_TYPES_REQUEST,
  REMOVE_PAYMENT_TYPES_SUCCESS,
  UPDATE_PAYMENT_TYPES_REQUEST,
  UPDATE_PAYMENT_TYPES_SUCCESS
} from 'redux/constants/StoreSetupConstants/payment.types.constants';
import {
  addPaymentTypeFailed,
  populatePaymentTypes,
  populatePaymentTypesFailed,
  populateSinglePaymentType,
  populateSinglePaymentTypeFailed
} from 'redux/actions/StoreSetupActions/payment.types.action';
import { setSnackbar } from 'redux/actions/UIComponentActions/snackbar.action';
import api from 'utils/api';

// /*----------------------------------
//        GET SINGLE cash register
// ==================================*/
export function* onFetchProductStart() {
  yield takeLatest(GET_SINGLE_PAYMENT_TYPES_REQUEST, fetchProduct);
}
function* fetchProduct({ payload }) {
  try {
    if (payload) {
      const { data } = yield api.get(`/get-payTypes/${payload}`);

      yield put(populateSinglePaymentType(data));
    }
  } catch (err) {
    yield put(populateSinglePaymentTypeFailed(err.message));
  }
}

/*----------------------------------
    SYSTEM USER >> GET ALL PAYMENT_TYPE          
==================================*/

// export function* getAllPaymentTypes() {
//   yield take(GET_ALL_PAYMENT_TYPES_REQUEST);
//   try {
//     const { data } = yield call(api, `/getAll-PayTypes`);
//     // const { data } = yield call(api, `http://localhost:3000/taxRules`);
//     yield put(populatePaymentTypes(data));
//   } catch (error) {
//     yield put(populatePaymentTypesFailed(error));
//     if (error.response.status !== 404)
//       yield put(setSnackbar(true, 'error', 'Something Went Wrong !!'));
//   }
// }

export function* getAllPaymentTypes() {
  yield takeLatest(GET_ALL_PAYMENT_TYPES_REQUEST, fetchProducts);
}
function* fetchProducts(payload) {
  const {
    page: currentPage,
    size,
    orderBy: sortField,
    order: sortDir,
    searchValue: query
  } = payload;
  try {
    const { data } = yield api.get(`/getAll-PayTypes`, {
      params: {
        currentPage: currentPage + 1 || 1,
        size: size || 8,
        sortField: sortField || 'payment_name',
        sortDir: sortDir || 'asc',
        query: query || ''
      }
    });
    console.log(data);
    yield delay(400);
    yield put(populatePaymentTypes(data));
  } catch (error) {
    console.log(error);
    yield put(populatePaymentTypesFailed(error));
    if (error.response.status !== 404) {
      yield put(setSnackbar(true, 'error', error.message));
    }
  }
}
/*  */

// export function* getAllProducts() {
//   yield takeEvery(GET_ALL_PAYMENT_TYPES_REQUEST, fetchProducts);
// }
// function* fetchProducts() {
//   try {
//     const { data } = yield call(api, `/getAll-outlets`);
//     console.log(data);
//     yield put(populatePaymentTypes(data));
//   } catch (err) {
//     yield put(populatePaymentTypesFailed(err.message));
//   }
// }

// export function* getPartWatcher() {
//   yield call(takeLatest, GET_ALL_PAYMENT_TYPES_REQUEST, fetchProducts);
// }

// export function* partData() {
//   const watcher = yield fork(getPartWatcher);

//   yield take(LOCATION_CHANGE);
//   yield cancel(watcher);
// }
/*----------------------------------
    SYSTEM USER >> ADD USER          
==================================*/
export function* addSystemUserSaga() {
  yield takeLatest(ADD_PAYMENT_TYPES_REQUEST, systemUser);
}

function* systemUser({ payload }) {
  try {
    const result = yield call(sysUserAsync, payload);
    yield put({ type: ADD_PAYMENT_TYPES_SUCCESS, payload: result });
    yield put({ type: GET_ALL_PAYMENT_TYPES_REQUEST });
    yield call(getAllPaymentTypes);
    yield put(setSnackbar(true, 'success', 'Payment Type Added Successfully'));
  } catch (error) {
    yield put(addPaymentTypeFailed(error));
    if (error.response.status !== 404)
      yield put(setSnackbar(true, 'error', 'Something Went Wrong !!'));
  }
}

async function sysUserAsync(payload) {
  const {
    payment_id,
    payment_name,
    payment_type_name,
    is_payment_type_avail_at_all_reg,
    canbe_configered,
    configuration_details,
    // default,
    active,
    register_payment_options,
    // inputValue_register_payment_options,
    configered,
    customerAccount,
    pos_rid
  } = payload;
  const { data } = await api.post(`/save-payType`, {
    payment_id,
    payment_name,
    payment_type_name,
    is_payment_type_avail_at_all_reg,
    canbe_configered,
    configuration_details,
    default: payload.default,
    active,
    register_payment_options,
    // inputValue_register_payment_options,
    configered,
    customerAccount,
    pos_rid
  });
  return data;
}

/*----------------------------------
       SYSTEM USER >> UPDATE USER        
==================================*/

export function* updateSystemUserSaga() {
  yield takeEvery(UPDATE_PAYMENT_TYPES_REQUEST, updateSystemUser);
}

function* updateSystemUser({ payload }) {
  try {
    const result = yield call(updateAsyncPaymentTypes, payload);
    yield put({ type: UPDATE_PAYMENT_TYPES_SUCCESS, payload: result });
    yield put({ type: GET_ALL_PAYMENT_TYPES_REQUEST });
    yield call(getAllPaymentTypes);
    yield put(
      setSnackbar(true, 'success', 'Payment Type Updated Successfully')
    );
  } catch (error) {
    if (error.response.status !== 404)
      yield put(setSnackbar(true, 'error', 'Something Went Wrong !!'));
  }
}

async function updateAsyncPaymentTypes(payload) {
  const { payment_id, entry } = payload;
  const {
    payment_name,
    payment_type_name,
    is_payment_type_avail_at_all_reg,
    canbe_configered,
    configuration_details,
    // default,
    active,
    register_payment_options,
    // inputValue_register_payment_options,
    configered,
    customerAccount,
    pos_rid
  } = entry;
  const { data } = await api.put(`/update-payType/${payment_id}`, {
    payment_id,
    payment_name,
    payment_type_name,
    is_payment_type_avail_at_all_reg,
    canbe_configered,
    configuration_details,
    default: payload.default,
    active,
    register_payment_options,
    // inputValue_register_payment_options,
    configered,
    customerAccount,
    pos_rid
  });
  return data;
}

/*-----------------------------------------
    TAX RATE >> DELETE SINGLE TAX RATE       
=========================================*/

export function* deleteSystemUserSaga() {
  while (true) {
    try {
      const { payload } = yield take(REMOVE_PAYMENT_TYPES_REQUEST);
      yield call(deleteSingleSystemUsers, payload.payment_id);
      yield put({
        type: REMOVE_PAYMENT_TYPES_SUCCESS,
        payload: { payment_id: payload.payment_id }
      });
      yield put({ type: GET_ALL_PAYMENT_TYPES_REQUEST });
      yield call(getAllPaymentTypes);
      yield put(setSnackbar(true, 'error', 'Payment Type Delete Successfully'));
    } catch (error) {
      yield put({ type: REMOVE_PAYMENT_TYPES_FAILED, payload: error });
      yield put(setSnackbar(true, 'error', 'Something Went Wrong'));
    }
  }
}

async function deleteSingleSystemUsers(payment_id) {
  await api.delete(`/delete-payType/${payment_id}`);
}
