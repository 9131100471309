import {
  GET_AWAITING_DELIVERY_REQUEST,
  GET_AWAITING_DELIVERY_SUCCESS,
  GET_AWAITING_DELIVERY_FAILED
} from 'redux/constants/InventoryConstants/inventory.dashboard.constants';

const InventoryAwaitingReducer = (
  state = {
    loading: false,
    awaiting_products: [],
    error: false,
    page: 1,
    first: true,
    last: true,
    outletId: ''
  },
  action
) => {
  const { awaiting_products } = state;

  switch (action.type) {
    case GET_AWAITING_DELIVERY_REQUEST:
      return {
        ...state,
        awaiting_products: [],
        loading: true
      };
    case GET_AWAITING_DELIVERY_SUCCESS:
      return {
        ...state,
        awaiting_products: [...action.payload.content],
        page: action.payload.page,
        pages: action.payload.totalElements,
        first: action.payload.first,
        last: action.payload.last,
        size: action.payload.size,
        searchValue: action.payload.searchValue,
        loading: false,
        outletId: action.payload.outletId
      };
    case GET_AWAITING_DELIVERY_FAILED:
      return {
        ...state,
        error: action.payload,
        awaiting_products: [...awaiting_products],
        loading: false
      };

    default:
      return state;
  }
};
export default InventoryAwaitingReducer;
