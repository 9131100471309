import {
  GET_SINGLE_USER_SUCCESS,
  GET_SINGLE_USER_FAILED,
  UPDATE_USER_SUCCESS,
  GET_ALL_USERS_FAILED,
  GET_ALL_USERS_SUCCESS,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILED,
  USER_LOGOUT,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAILED,
  GET_ALL_SYSTEM_USERS_SUCCESS,
  GET_ALL_SYSTEM_USERS_FAILED,
  ADD_SYSTEM_USERS_SUCCESS,
  ADD_SYSTEM_USERS_FAILED,
  UPDATE_SYSTEM_USER_SUCCESS,
  UPDATE_SYSTEM_USER_FAILED,
  REMOVE_MULTIPLE_SYSTEM_USER_SUCCESS,
  REMOVE_SYSTEM_USER_SUCCESS,
  CHANGE_USER_PASS_SUCCESS,
  CHANGE_USER_PASS_FAILED,
  UPDATE_USER_FAILED,
  GET_ALL_USERS_REQUEST,
  GET_ALL_SYSTEM_USERS_REQUEST,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_FAILED,
  VALIDATE_TOKEN_SUCCESS,
  VALIDATE_TOKEN_FAILED,
  GET_MY_PROFILE_SUCCESS,
  GET_MY_PROFILE_FAILED,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILED,
  GET_SINGLE_SYSTEM_USER_REQUEST,
  GET_SINGLE_SYSTEM_USER_SUCCESS,
  GET_SINGLE_SYSTEM_USER_FAILED,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILED,
  UPDATE_PROFILE_REQUEST,
  RESET_FORGOT_PASSWORD_SUCCESS,
  RESET_FORGOT_PASSWORD_FAILED,
  LOGIN_USER_REQUEST
} from 'redux/constants/UserConstants/user.constants';
/*----------------------------------
          POS USER REDUCER          
==================================*/
export const posUserReducer = (
  state = { loading: true, error: false, users: [], user: {} },
  action
) => {
  const { users } = state;
  let newUser;
  switch (action.type) {
    case GET_ALL_USERS_REQUEST:
      return {
        ...state,
        users: [],
        loading: true
      };
    case GET_ALL_USERS_SUCCESS:
      return {
        ...state,
        users: [action.payload],
        loading: false
      };
    case GET_ALL_USERS_FAILED:
      return {
        ...state,
        error: action.payload,
        users: [...state.users],
        loading: false
      };
    case GET_SINGLE_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
        loading: false
      };
    case GET_SINGLE_USER_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case UPDATE_USER_SUCCESS:
      const {
        pos_rid,
        first_name,
        last_name,
        email,
        phone,
        country,
        city,
        // password,
        accept_terms,
        created_date_time,
        last_modified_time,
        role,
        store_setup,
        store_feature_setup,
        store_general_rules,
        store_inven_notif,
        store_web_addresses
        // tempPassword
      } = action.payload;
      // const { first_name, last_name, country, city, phone, role } = entry;
      newUser = [...users];

      const existingUser = state.users.find((user) => user.pos_rid === pos_rid);
      if (existingUser) {
        existingUser.first_name = first_name;
        existingUser.last_name = last_name;
        existingUser.country = country;
        existingUser.city = city;
        existingUser.created_date_time = created_date_time;
        existingUser.last_modified_time = last_modified_time;
        existingUser.phone = phone;
        existingUser.accept_terms = accept_terms;
        existingUser.email = email;
        existingUser.role = role;
        existingUser.store_setup = store_setup;
        existingUser.store_feature_setup = store_feature_setup;
        existingUser.store_general_rules = store_general_rules;
        existingUser.store_inven_notif = store_inven_notif;
        existingUser.store_web_addresses = store_web_addresses;
        existingUser.store_web_addresses = store_web_addresses;
      }

      return { users: newUser };
    case UPDATE_USER_FAILED:
      return {
        ...state,
        users: [...state.users],
        error: action.payload,
        loading: false
      };
    case CHANGE_USER_PASS_SUCCESS:
      const {
        email: emailForPass,
        password,
        confirmationToken
      } = action.payload;
      newUser = [...users];

      const existingUserPassword = state.users.find(
        (user) => user.email === emailForPass
      );
      if (existingUserPassword) {
        existingUser.email = emailForPass;
        existingUser.password = password;
        existingUser.confirmationToken = confirmationToken;
      }
      return { users: newUser };
    case CHANGE_USER_PASS_FAILED:
      return {
        ...state,
        users: [...state.users],
        error: action.payload,
        loading: false
      };
    default:
      return state;
  }
};

/*----------------------------------
          LOGIN REDUCER         
==================================*/
export const userLoginReducer = (
  state = {
    loading: true,
    error: false,
    userInfo: {},
    isAuthenticated: false,
    loadingButton: false
  },
  action
) => {
  switch (action.type) {
    case LOGIN_USER_REQUEST:
      return {
        ...state,
        isAuthenticated: false,
        loadingButton: true
      };
    case LOGIN_USER_SUCCESS:
      return {
        loading: false,
        userInfo: action.payload,
        error: false,
        isAuthenticated: true,
        loadingButton: false
      };
    case LOGIN_USER_FAILED:
      return { loading: false, error: action.payload, isAuthenticated: false };
    case USER_LOGOUT:
      return {
        userInfo: {},
        isAuthenticated: false
      };
    case REFRESH_TOKEN_SUCCESS:
      return {
        loading: false,
        userInfo: action.payload,
        error: false,
        isAuthenticated: true
      };
    case REFRESH_TOKEN_FAILED:
      return { loading: false, error: action.payload, isAuthenticated: false };
    case VALIDATE_TOKEN_SUCCESS:
      return {
        ...state,
        loading: false,
        isAuthenticated: true
      };
    case VALIDATE_TOKEN_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
        isAuthenticated: false
      };

    default:
      return state;
  }
};

/*----------------------------------
          FORGOT PASSWORD REDUCER         
==================================*/
export const forgotPasswordReducer = (
  state = { loading: true, error: false, success: false },
  action
) => {
  switch (action.type) {
    case FORGOT_PASSWORD_SUCCESS:
      return {
        loading: false,
        success: true
      };
    case FORGOT_PASSWORD_FAILED:
      return { loading: false, error: action.payload, success: false };
    case RESET_FORGOT_PASSWORD_SUCCESS:
      return {
        loading: false,
        success: true
      };
    case RESET_FORGOT_PASSWORD_FAILED:
      return { loading: false, error: action.payload, success: false };
    default:
      return state;
  }
};
/*----------------------------------
          RESET FORGOT PASSWORD REDUCER         
==================================*/

/*----------------------------------
      REGISTRATION REDUCER         
==================================*/

export const userRegisterReducer = (state = { loading: true }, action) => {
  switch (action.type) {
    case REGISTER_USER_SUCCESS:
      return {
        loading: false,
        userInfo: action.payload,
        error: false
      };
    case REGISTER_USER_FAILED:
      return {
        // ...state,
        error: action.payload,
        loading: false
      };
    default:
      return state;
  }
};

/*----------------------------------
         SYSTEM USER REDUCER        
==================================*/

export const systemUserReducer = (
  state = {
    loading: true,
    error: false,
    users: [],
    user: {},
    page: 1,
    first: true,
    last: true,
    loadingSingle: false
  },
  action
) => {
  const { users } = state;
  let newUser;
  switch (action.type) {
    case GET_ALL_SYSTEM_USERS_REQUEST:
      return {
        ...state,
        users: [],
        loading: true
      };
    case GET_ALL_SYSTEM_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        users: [...action.payload.content],
        page: action.payload.page,
        pages: action.payload.totalElements,
        first: action.payload.first,
        last: action.payload.last,
        size: action.payload.size,
        searchValue: action.payload.searchValue
      };
    case GET_ALL_SYSTEM_USERS_FAILED:
      return {
        ...state,
        error: action.payload,
        users: [...state.users],
        loading: false
      };
    case ADD_SYSTEM_USERS_SUCCESS:
      newUser = {
        ...state,
        users: [...users, action.payload]
      };

      return newUser;
    case ADD_SYSTEM_USERS_FAILED:
      return { users: [...state.users], error: action.payload };
    case UPDATE_SYSTEM_USER_SUCCESS:
      const {
        sys_rid,
        first_name,
        last_name,
        user_name,
        user_pin,
        timezone_language,
        random_password,
        timezone,
        choose_language,
        phone_number,
        display_name,
        email,
        user_role,
        password,
        confirm_password,
        user_outlets,
        active
      } = action.payload;
      newUser = [...users];
      const existingUser = state.users.find((user) => user.sys_rid === sys_rid);
      if (existingUser) {
        existingUser.sys_rid = sys_rid;
        existingUser.first_name = first_name;
        existingUser.last_name = last_name;
        existingUser.display_name = display_name;
        existingUser.phone_number = phone_number;
        existingUser.email = email;
        existingUser.random_password = random_password;
        existingUser.password = password;
        existingUser.confirm_password = confirm_password;
        existingUser.user_name = user_name;
        existingUser.user_pin = user_pin;
        existingUser.timezone_language = timezone_language;
        existingUser.timezone = timezone;
        existingUser.choose_language = choose_language;
        existingUser.user_outlets = user_outlets;
        existingUser.user_role = user_role;
        existingUser.active = active;
      }
      return { users: newUser };
    case UPDATE_SYSTEM_USER_FAILED:
      return { users: [...state.users], error: action.payload };
    case REMOVE_SYSTEM_USER_SUCCESS:
      newUser = users.filter((user) => user.sys_rid !== action.payload.sys_rid);
      return { loading: false, users: newUser, error: false };
    case REMOVE_MULTIPLE_SYSTEM_USER_SUCCESS:
      newUser = users.filter(
        (user) => !action.payload.sys_rid.includes(user.sys_rid)
      );
      return { loading: false, users: newUser, error: false };
    case GET_SINGLE_SYSTEM_USER_REQUEST:
      return {
        ...state,
        user: {},
        loadingSingle: true
      };
    case GET_SINGLE_SYSTEM_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
        loadingSingle: false
      };
    case GET_SINGLE_SYSTEM_USER_FAILED:
      return {
        ...state,
        error: action.payload,
        loadingSingle: false
      };
    default:
      return state;
  }
};

/*----------------------------------
          My Profile          
==================================*/
export const myProfileReducer = (
  state = { loading: true, error: false, profile: {}, loadingButton: false },
  action
) => {
  let newUser;
  const { profile } = state;
  switch (action.type) {
    case GET_MY_PROFILE_SUCCESS:
      return {
        ...state,
        profile: action.payload,
        loading: false
      };
    case GET_MY_PROFILE_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false
      };

    case USER_LOGOUT:
      return { ...state, profile: {} };
    case UPDATE_PROFILE_REQUEST:
      return {
        ...state,
        loading: false,
        loadingButton: true
      };
    case UPDATE_PROFILE_SUCCESS:
      const {
        pos_rid,
        first_name,
        last_name,
        email,
        phone,
        country,
        city,
        image_id,
        image_url
      } = action.payload;

      return {
        profile: {
          pos_rid,
          first_name,
          last_name,
          email,
          phone,
          country,
          city,
          image_id,
          image_url
        },
        loading: false,
        loadingButton: false
      };
    case UPDATE_PROFILE_FAILED:
      return {
        ...state,
        profile: { ...state.profile },
        error: action.payload,
        loading: false
      };

    default:
      return state;
  }
};
