import React from 'react';
import Controls from './controls/Controls';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
  Typography
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    padding: theme.spacing(2),
    position: 'absolute',
    top: theme.spacing(5)
  },
  dialogTitle: {
    paddingRight: '0px',
    display: 'flex'
  },
  dialogContent: {
    backgroundColor: '#EFF3FA'
  }
}));

const Popup = (props) => {
  const classes = useStyles();
  const {
    values,
    setValues,
    title,
    children,
    openPopup,
    setOpenPopup,
    removeValue,
    maxWidth,
    ...other
  } = props;

  return (
    <Dialog
      open={openPopup}
      maxWidth={maxWidth ? maxWidth : 'md'}
      classes={{ paper: classes.dialogWrapper }}
      {...other}
    >
      <DialogTitle>
        <div className={classes.dialogTitle}>
          <Typography variant="h6" component="div" style={{ flexGrow: 1 }}>
            {title}
          </Typography>
          <Controls.ActionButton
            color="secondary"
            onClick={() => {
              setOpenPopup(false);
              removeValue && setValues({ removeValue: null });
            }}
          >
            <CloseIcon />
          </Controls.ActionButton>
        </div>
      </DialogTitle>
      <DialogContent className={classes.dialogContent} dividers>
        {children}
      </DialogContent>
    </Dialog>
  );
};

export default Popup;
