import {
  GET_PRODUCT_TYPES_REQUEST,
  GET_PRODUCT_TYPES_SUCCESS,
  GET_PRODUCT_TYPES_FAILED,
  REMOVE_MULTIPLE_PRODUCT_TYPES_REQUEST,
  REMOVE_PRODUCT_TYPES_REQUEST,
  ADD_PRODUCT_TYPES_REQUEST,
  UPDATE_PRODUCT_TYPES_REQUEST,
  ADD_PRODUCT_TYPES_FAILED,
  GET_SINGLE_PRODUCT_TYPES_REQUEST,
  GET_SINGLE_PRODUCT_TYPES_FAILED,
  GET_SINGLE_PRODUCT_TYPES_SUCCESS,
  ADD_PRODUCT_TYPES_SUCCESS
} from 'redux/constants/ProductConstants/product.types.constants';

export const addProductType = (payload) => {
  return { type: ADD_PRODUCT_TYPES_REQUEST, payload };
};
export const addProductTypeSuccess = (payload) => {
  return { type: ADD_PRODUCT_TYPES_SUCCESS, payload };
};
export const addProductTypeError = (error) => {
  return { type: ADD_PRODUCT_TYPES_FAILED, payload: error };
};
export const removeProductType = (id) => {
  return { type: REMOVE_PRODUCT_TYPES_REQUEST, payload: { id } };
};
export const removeMultipleProductType = (id) => {
  return { type: REMOVE_MULTIPLE_PRODUCT_TYPES_REQUEST, payload: { id } };
};

export const updateProductType = (id, entry) => {
  console.log(id, entry, 'action');
  return { type: UPDATE_PRODUCT_TYPES_REQUEST, payload: { id, entry } };
};

export const getAllProductTypes = (searchValue, page, size, order, orderBy) => {
  return {
    type: GET_PRODUCT_TYPES_REQUEST,
    searchValue,
    page,
    size,
    order,
    orderBy
  };
};
export const populateProductTypes = (entries) => {
  return { type: GET_PRODUCT_TYPES_SUCCESS, payload: entries };
};
export const populateProductTypesError = (error) => {
  return { type: GET_PRODUCT_TYPES_FAILED, payload: error };
};

/*----------------------------------
          GET SINGLE PRODUCT TYPE     
==================================*/

export const getSingleProductType = (id) => {
  return { type: GET_SINGLE_PRODUCT_TYPES_REQUEST, payload: id };
};
export const populateSingleProductType = (type) => {
  return { type: GET_SINGLE_PRODUCT_TYPES_SUCCESS, payload: type };
};
export const populateSingleProductTypeFailed = (error) => {
  return { type: GET_SINGLE_PRODUCT_TYPES_FAILED, payload: error };
};
