import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React from 'react';
const MultiChipControl = (props) => {
  React.useEffect(() => {
    // console.warn = () => {};
  });
  const {
    options,
    handleKeyDown,
    value,
    onChange,
    getOptionLabel,
    placeholder,
    label,
    multiple,
    filterOptions,
    getOptionSelected,
    ...rest
  } = props;
  return (
    <>
      <Autocomplete
        multiple={multiple}
        // id="tags-outlined"
        options={options}
        getOptionLabel={getOptionLabel}
        value={value}
        size="small"
        onChange={onChange}
        filterOptions={filterOptions}
        getOptionSelected={getOptionSelected}
        limitTags={3}
        filterSelectedOptions
        {...rest}
        renderInput={(params) => {
          params.inputProps.onKeyDown = handleKeyDown;
          return (
            <TextField
              {...params}
              variant="outlined"
              label={label}
              placeholder={placeholder}
              margin="normal"
              fullWidth
            />
          );
        }}
      />
    </>
  );
};
export default MultiChipControl;
