import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Redirect, Route } from 'react-router-dom';
import {
  sendRefreshToken,
  validateToken
} from 'redux/actions/UserActions/user.action';
import { getCookieData } from 'utils/functionsCustom';

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated } = useSelector((state) => state.userLogin);

  const dispatch = useDispatch();

  useEffect(() => {
    const cookieData = getCookieData();
    const { email, authenticationToken, refreshToken } = cookieData;
    if (!isAuthenticated) {
      if (authenticationToken === undefined) {
        dispatch(sendRefreshToken(refreshToken, email));
      }
      if (authenticationToken !== null && authenticationToken !== undefined) {
        dispatch(validateToken());
      }
    }
  }, [dispatch, isAuthenticated]);

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated && (
          <>
            <Component {...props} />
          </>
        )
      }
    />
  );
};
