import { CountryCityData } from 'collections/countryData';
import id from 'date-fns/esm/locale/id/index.js';
import cookie from 'react-cookies';
export const onKeyPressFormSubmit = (e) => {
  if (e.key === 'Enter') {
    e.preventDefault();
    return false;
  }
};

export const mergeArrayObjects = (arr1, arr2) => {
  let start = 0;
  let merge = [];

  while (start < arr1.length) {
    if (arr1[start].id === arr2[start].id) {
      //pushing the merged objects into array
      merge.push({ ...arr1[start], ...arr2[start] });
    }
    //incrementing start value
    start = start + 1;
  }
  return merge;
};

// export const mergeArrayObjectsForPurchase = (arr1, arr2) => {
//   let start = 0;
//   let merge = [];

//   while (start < arr1.length) {
//     if (arr1[start]?.barcode === arr2[start]?.barcodeSku) {
//       //pushing the merged objects into array
//       merge.push({ ...arr1[start], ...arr2[start] });
//     }
//     //incrementing start value
//     start = start + 1;
//   }
//   return merge;
// };
export const mergeArrayObjectsForPurchase = (arr1, arr2) => {
  return arr1.map((t1) => ({
    ...t1,
    ...arr2.find((t2) => t2.barcodeSku === t1.barcode)
  }));
};
// export const mergeArrayObjectsForPurchase = (arr1, arr2) => {
//   return arr1.map((t1) => {
//     // const alreadyAvailable = {
//     //   ...arr2.find((t2) => t2.barcodeSku === t1.barcode)
//     // };
//     // alreadyAvailable.barcode && console.log('id', alreadyAvailable.id);
//     const al = arr2;
//     if (al.id) {
//       console.log
//       return { ...t1 };
//     } else {
//       return {
//         ...t1,
//         ...arr2.find((t2) => t2.barcodeSku === t1.barcode)
//       };
//     }
//   });
// };

export const numberWithCommas = (number) => {
  return number?.toLocaleString('en-US', { minimumFractionDigits: 2 });
};

export const compactNumber = (number, style, symbol) => {
  return new Intl.NumberFormat('en-US', {
    style,
    currency: symbol,
    notation: 'compact',
    compactDisplay: 'short'
  }).format(number);
};

export const getCookieData = () => {
  const email = cookie.load('email');
  const refreshToken = cookie.load('refreshToken');
  const authenticationToken = cookie.load('authenticationToken');
  const userOption = cookie.load('userOption');
  // const userInfo = cookie.load('userInfo');
  // return userInfo;

  return {
    email,
    refreshToken,
    authenticationToken,
    userOption
  };
};

export function reverseString(str) {
  return str.split('').reverse().join('');
}

// eslint-disable-next-line no-extend-native
Number.prototype.betweenTwoNumbersCustom = function (a, b, inclusive) {
  var min = Math.min.apply(Math, [a, b]),
    max = Math.max.apply(Math, [a, b]);
  return inclusive ? this >= min && this <= max : this > min && this < max;
};
export const checkNumber = (evt) => {
  const ASCIICode = evt.which ? evt.which : evt.keyCode;
  if (ASCIICode.betweenTwoNumbersCustom(96, 105, true)) {
    return true;
  }
  if (ASCIICode.betweenTwoNumbersCustom(37, 40, true)) {
    return true;
  }
  if (ASCIICode === 110) {
    return true;
  }
  if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57)) {
    evt.preventDefault();
    return false;
  }
  if (evt.shiftKey) {
    evt.preventDefault();
    return false;
  }
  if (evt.which === 13) {
    evt.preventDefault();
    return false;
  }
  return true;
};

export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      // let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
      // if (encoded.length % 4 > 0) {
      //   encoded += '='.repeat(4 - (encoded.length % 4));
      // }
      resolve(reader.result);
    };
    reader.onerror = (error) => reject(error);
  });
};

export const countryOptions = CountryCityData.flatMap(
  (country) => country.countryName
);

export function formatAMPM(date) {
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  hours = hours < 10 ? '0' + hours : hours;
  var strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
}
