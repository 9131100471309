import {
  OPEN_DRAWER,
  CLOSE_DRAWER
} from 'redux/constants/UIComponentsConstants/drawer.constants';

export const openDrawer = (modalParams) => {
  return {
    type: OPEN_DRAWER,
    ...modalParams
  };
};

export const closeDrawer = (modalParams) => {
  return {
    type: CLOSE_DRAWER,
    ...modalParams
  };
};
