import React from 'react';
import {
  makeStyles,
  Drawer,
  Grid,
  Typography,
  ClickAwayListener,
  useTheme
} from '@material-ui/core';
import Controls from '../controls/Controls';
import { Close as CloseIcon } from '@material-ui/icons';
import { useWindowSize } from 'hooks/useWindowSize';

const useStyles = makeStyles((theme) => ({
  mainContent: {
    maxWidth: (props) =>
      props && props.windowWidth > 768 ? props.drawerWidth : '100vw',
    minWidth: (props) =>
      props && props.windowWidth > 768 ? props.drawerWidth : '100vw',
    padding: 16
  },
  drawerTitle: {
    paddingRight: '0px',
    display: 'flex',
    backgroundColor: '#FFFFFF'
  },
  paper: {
    backgroundColor: '#EFF3FB',
    zIndex: 99999999
  },
  titleBar: {
    backgroundColor: '#fff',
    padding: '5px 5px 5px 16px'
  },
  drawerTitleTypography: {
    flexGrow: 1,
    lineHeight: 3,
    fontSize: '1.2rem',
    fontWeight: 500,
    textTransform: 'capitalize',
    marginLeft: theme.spacing(2)
  },
  actioButton: {
    zIndex: theme.zIndex.drawer - 1
  }
}));

const SidebarSlider = (props) => {
  let {
    children,
    title,
    toggleDrawer,
    openSlider,
    setOpenSlider,
    btnText,
    btnFunction,
    values,
    onClose,
    width,
    ...other
  } = props;

  const { width: windowWidth } = useWindowSize();
  const classes = useStyles({
    drawerWidth: width,
    windowWidth: windowWidth
  });

  // const handleOnClose = () => {
  //   return setOpenSlider(true);
  // };
  return (
    // <ClickAwayListener onClickAway={handleClickAway}>
    <Drawer
      classes={{ paper: classes.paper }}
      anchor="right"
      open={openSlider}
      // onClose={onClose}
      disableBackdropClick={true}
    >
      <div className={classes.titleBar}>
        <div className={classes.drawerTitle}>
          <Controls.ActionButton
            style={{
              position: 'relative',
              left: windowWidth > 768 ? `-5px` : `5px`,
              top: '5px',
              width: 40,
              height: 40
            }}
            color="secondary"
            onClick={() => {
              setOpenSlider(false);
              // removeValue && setValues({ removeValue: null });
            }}
          >
            <CloseIcon />
          </Controls.ActionButton>
          <Typography
            variant="h6"
            component="div"
            className={classes.drawerTitleTypography}
          >
            {title}
          </Typography>
          {btnText && (
            <Controls.Button
              onClick={btnFunction}
              text={btnText}
              style={{
                height: 40
              }}
            />
          )}
        </div>
      </div>
      <Grid container className={classes.mainContent}>
        <Grid item xs={12}>
          {children}
        </Grid>
      </Grid>
    </Drawer>
    // </ClickAwayListener>
  );
};

export default SidebarSlider;
