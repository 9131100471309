import {
  UPLOAD_REQUEST,
  UPLOAD_SUCCESS
} from 'redux/constants/FileUpload/file.upload.constants';

/*----------------------------------
          My Profile          
==================================*/
export const fileUploadReducer = (
  state = { loading: false, error: false },
  action
) => {
  switch (action.type) {
    case UPLOAD_SUCCESS:
      return {
        ...state,
        progress: action.payload,
        loading: false
      };

    default:
      return state;
  }
};
