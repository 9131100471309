import { LOCATION_CHANGE } from 'connected-react-router';

import { SET_SNACKBAR } from 'redux/constants/UIComponentsConstants/snackbar.constants';

const initialState = {
  snackbarOpen: false,
  snackbarType: 'success',
  snackbarMessage: '',
  loading: true
};
const snackbarReducer = (state = initialState, action) => {
  const { snackbarOpen, snackbarMessage, snackbarType } = action;
  switch (action.type) {
    case SET_SNACKBAR:
      return {
        ...state,
        snackbarOpen,
        snackbarType,
        snackbarMessage
      };
    case LOCATION_CHANGE:
      return {
        ...state,
        snackbarOpen: false
      };

    default:
      return state;
  }
};

export default snackbarReducer;
