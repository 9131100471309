import {
  GET_PRODUCT_SEASONS_SUCCESS,
  GET_PRODUCT_SEASONS_FAILED,
  REMOVE_MULTIPLE_PRODUCT_SEASONS_SUCCESS,
  REMOVE_PRODUCT_SEASONS_SUCCESS,
  ADD_PRODUCT_SEASONS_SUCCESS,
  ADD_PRODUCT_SEASONS_FAILED,
  UPDATE_PRODUCT_SEASONS_SUCCESS,
  UPDATE_PRODUCT_SEASONS_FAILED,
  GET_PRODUCT_SEASONS_REQUEST,
  GET_SINGLE_PRODUCT_SEASON_SUCCESS,
  GET_SINGLE_PRODUCT_SEASON_FAILED,
  GET_SINGLE_PRODUCT_SEASON_REQUEST,
  REMOVE_MULTIPLE_PRODUCT_SEASONS_FAILED,
  REMOVE_PRODUCT_SEASONS_FAILED
} from 'redux/constants/ProductConstants/product.seasons.constants';

const initialStateValues = {
  seasons: [],
  season: {},
  loading: true,
  error: false,
  page: 1,
  first: true,
  last: true
};

const productSeasonsReducer = (state = initialStateValues, action) => {
  const { seasons } = state;
  let newSeasons;
  switch (action.type) {
    case GET_PRODUCT_SEASONS_REQUEST:
      return {
        ...state,
        loading: true,
        seasons: []
      };
    case GET_PRODUCT_SEASONS_SUCCESS:
      return {
        loading: false,
        error: false,
        seasons: [...action.payload.content],
        page: action.payload.page,
        pages: action.payload.totalElements,
        first: action.payload.first,
        last: action.payload.last,
        size: action.payload.size,
        searchValue: action.payload.searchValue
      };
    case GET_PRODUCT_SEASONS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
        seasons: [...state.seasons]
      };
    case ADD_PRODUCT_SEASONS_SUCCESS:
      newSeasons = {
        ...state,
        seasons: [...seasons, action.payload],
        loading: true
      };
      return newSeasons;
    case ADD_PRODUCT_SEASONS_FAILED:
      return { seasons: [...state.seasons], error: action.payload };
    case GET_SINGLE_PRODUCT_SEASON_REQUEST:
      return {
        ...state,
        season: {},
        loading: false
      };
    case GET_SINGLE_PRODUCT_SEASON_SUCCESS:
      return {
        ...state,
        season: action.payload,
        loading: false
      };
    case GET_SINGLE_PRODUCT_SEASON_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case REMOVE_PRODUCT_SEASONS_SUCCESS:
      newSeasons = seasons.filter((season) => season.id !== action.payload.id);
      return { loading: false, seasons: newSeasons, error: false };
    case REMOVE_PRODUCT_SEASONS_FAILED:
      return {
        loading: false,
        seasons: [...state.seasons],
        error: action.payload
      };
    case REMOVE_MULTIPLE_PRODUCT_SEASONS_SUCCESS:
      newSeasons = seasons.filter(
        (season) => !action.payload.id.includes(season.id)
      );
      return { loading: false, seasons: newSeasons, error: false };
    case REMOVE_MULTIPLE_PRODUCT_SEASONS_FAILED:
      return {
        loading: false,
        seasons: [...state.seasons],
        error: action.payload
      };
    case UPDATE_PRODUCT_SEASONS_SUCCESS:
      const { id, season_name, active } = action.payload;
      newSeasons = [...seasons];
      const existingUser = state.seasons.find((season) => season.id === id);
      if (existingUser) {
        existingUser.season_name = season_name;
        existingUser.active = active;
      }
      return { seasons: newSeasons, loading: true };
    case UPDATE_PRODUCT_SEASONS_FAILED:
      return {
        seasons: [...state.seasons],
        error: action.payload,
        loading: false
      };
    default:
      return state;
  }
};
export default productSeasonsReducer;
