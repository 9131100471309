import {
  userRegisterReducer,
  userLoginReducer,
  posUserReducer,
  systemUserReducer,
  myProfileReducer,
  forgotPasswordReducer
} from './UserReducers';
import {
  productBrandsReducer,
  productSeasonsReducer,
  productTagsReducer,
  productTypesReducer,
  productsReducer
} from './ProductReducers';

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import snackbarReducer from './UIComponentReducers/snackbar.reducer';
import {
  CashRegistersReducer,
  OutletsRegistersReducer,
  taxRulesReducer,
  taxGroupsReducer,
  EmailTemplatesReducer,
  PaymentTypesReducer,
  GeneralStoreSetupReducer
} from './StoreSetupReducers';
import {
  InventorySupplierReducer,
  InventoryPurchaseReducer,
  InventoryPurchaseOrderReducer,
  MyInventoryReducer,
  InventoryAwaitingReducer,
  InventoryDraftPOReducer,
  InventoryLowStockReducer,
  InventoryDashboardReducer
} from './InventoryReducers';
import { customersReducer } from './CustomersReducers';
import sliderDrawerReducer from './UIComponentReducers/drawer.reducer';
import { customerGroupsReducer } from './CustomersReducers/customer.groups.reducer';
import { fileUploadReducer } from './UIComponentReducers/file.upload';
import routerLocations from './UIComponentReducers/location.reducer';
import loadingButtonReducer from './UIComponentReducers/loading.button.reducer';

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    productBrands: productBrandsReducer,
    productSeasons: productSeasonsReducer,
    productTags: productTagsReducer,
    productTypes: productTypesReducer,
    products: productsReducer,
    userRegister: userRegisterReducer,
    userLogin: userLoginReducer,
    posUser: posUserReducer,
    myProfile: myProfileReducer,
    systemUser: systemUserReducer,
    taxRule: taxRulesReducer,
    taxGroups: taxGroupsReducer,
    OutletsRegisters: OutletsRegistersReducer,
    CashRegisters: CashRegistersReducer,
    EmailTemplates: EmailTemplatesReducer,
    PaymentTypes: PaymentTypesReducer,
    inventorySuppliers: InventorySupplierReducer,
    inventoryPurchases: InventoryPurchaseReducer,
    inventoryPurchaseOrder: InventoryPurchaseOrderReducer,
    myInventory: MyInventoryReducer,
    inventoryDashboard: InventoryDashboardReducer,
    inventoryAwaiting: InventoryAwaitingReducer,
    inventoryDraftPO: InventoryDraftPOReducer,
    inventoryLowStock: InventoryLowStockReducer,
    customersPos: customersReducer,
    customersGroupPos: customerGroupsReducer,
    snackbar: snackbarReducer,
    sliderDrawer: sliderDrawerReducer,
    generalStoreSetup: GeneralStoreSetupReducer,
    fileUpload: fileUploadReducer,
    routerLocations: routerLocations,
    forgotPassword: forgotPasswordReducer,
    loadingButton: loadingButtonReducer
  });
/*   InventoryAwaitingReducer,
  InventoryDraftPOReducer,
  InventoryLowStockReducer */
export default rootReducer;
