import {
  GET_GENERAL_STORE_SETUP_REQUEST,
  GET_GENERAL_STORE_SETUP_SUCCESS,
  GET_GENERAL_STORE_SETUP_FAILED
} from 'redux/constants/StoreSetupConstants/general.store.setup.constants';

export const getGeneralStoreSetup = () => {
  return { type: GET_GENERAL_STORE_SETUP_REQUEST };
};
export const populateGeneralStoreSetup = (user) => {
  return { type: GET_GENERAL_STORE_SETUP_SUCCESS, payload: user };
};
export const populateGeneralStoreSetupFailed = (error) => {
  return { type: GET_GENERAL_STORE_SETUP_FAILED, payload: error };
};
