/* eslint-disable no-use-before-define */
import React from 'react';
import PropTypes from 'prop-types';

import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 500,
    '& > * + *': {
      marginTop: theme.spacing(3)
    }
  }
}));

const ChipMultiControlled = (props) => {
  const {
    multiple,
    id,
    onChange,
    label,
    variant,
    placeholder,
    getOptionLabel,
    getOptionSelected,
    options,
    value
  } = props;
  const classes = useStyles();
  return (
    <div>
      <Autocomplete
        size="small"
        disableClearable
        multiple={multiple}
        id={id}
        getOptionSelected={getOptionSelected}
        options={options}
        getOptionLabel={getOptionLabel}
        value={value}
        onChange={onChange}
        renderInput={(params) => (
          <TextField
            {...params}
            variant={variant || 'outlined'}
            label={label}
            placeholder={placeholder}
          />
        )}
      />
    </div>
  );
};

ChipMultiControlled.propTypes = {};

export default ChipMultiControlled;

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
/*  
Single
<Autocomplete
        multiple
        id="tags-standard2"
        options={top100Films}
        getOptionLabel={(option) => option.title}
        value={value2 ?? ''}
        onChange={(e, newVal) => {
          const idToResult = [newVal.slice(-1).pop()].map((item) => {
            return item.year;
          });
          setId(idToResult);
          return setValue2([newVal.slice(-1).pop()]);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label="Single value"
            placeholder="Favorites"
          />
        )}
      /> */
/* 
Multiple
 <Autocomplete
        multiple
        id="tags-standard"
        options={top100Films}
        getOptionLabel={(option) => option.title}
        value={value ?? ''}
        onChange={(e, newVal) => {
          const idToResult = newVal.map((item) => {
            return item.year;
          });
          setIds(idToResult);
          return setValue(newVal);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            label="Multiple values"
            placeholder="Favorites"
          />
        )}
      /> */
