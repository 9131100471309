const {
  LOADING_REQUEST,
  LOADING_SUCCESS
} = require('redux/constants/UIComponentsConstants/loading.button.constants');

const loadingButtonReducer = (state = { loadingButton: null }, action) => {
  switch (action.type) {
    case LOADING_REQUEST:
      return { loadingButton: true };
    case LOADING_SUCCESS:
      return { loadingButton: false };

    default:
      return state;
  }
};
export default loadingButtonReducer;
