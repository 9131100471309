import { call, put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import { GET_INVENTORY_DASHBOARD_REQUEST } from 'redux/constants/InventoryConstants/inventory.dashboard.constants';
import {
  populateInventoryDashboard,
  populateInventoryDashboardFailed
} from 'redux/actions/InventoryActions/inventory.dashboard.action';
import { setSnackbar } from 'redux/actions/UIComponentActions/snackbar.action';
import api from 'utils/api';

export function* getAllInventoryDashboard() {
  yield takeLatest(GET_INVENTORY_DASHBOARD_REQUEST, fetchProducts);
}
/* const response = yield call(getTweets, buttonStatus);

 */
function* fetchProducts({ payload }) {
  try {
    const { data } = yield call(api, `/dashboard`, {
      params: { outletId: payload || '' }
    });

    /* getAwaiting-Delivery?currentPage=1&size=3&sortField=po_number&sortDir=asc */
    yield put(populateInventoryDashboard(data));
  } catch (error) {
    yield put(populateInventoryDashboardFailed(error));
    if (error?.response?.status !== 404) {
      yield put(setSnackbar(true, 'error', error.message));
    }
  }
}

/* export function* onFetchProductStart() {
  yield takeLatest(GET_SINGLE_PURCHASE_ORDER_REQUEST, fetchProduct);
}
function* fetchProduct({ payload }) {
  try {
    if (payload) {
      const { data } = yield axios.get(
        `${baseURLProd}/get-purchase/${payload}`
      );
      yield put(populateSinglePurchaseOrder(data));
    }
  } catch (err) {
    yield put(populateSinglePurchaseOrderFailed(err.message));
  }
} */
