/*----------------------------------
    CASH REGISTERS CONSTANTS         
==================================*/

export const GET_ALL_CASH_REGISTERS_REQUEST = 'GET_ALL_CASH_REGISTERS_REQUEST';
export const GET_ALL_CASH_REGISTERS_SUCCESS = 'GET_ALL_CASH_REGISTERS_SUCCESS';
export const GET_ALL_CASH_REGISTERS_FAILED = 'GET_ALL_CASH_REGISTERS_FAILED';

export const REMOVE_CASH_REGISTERS_REQUEST = 'REMOVE_CASH_REGISTERS_REQUEST';
export const REMOVE_CASH_REGISTERS_SUCCESS = 'REMOVE_CASH_REGISTERS_SUCCESS';
export const REMOVE_CASH_REGISTERS_FAILED = 'REMOVE_CASH_REGISTERS_FAILED';

export const ADD_CASH_REGISTERS_REQUEST = 'ADD_CASH_REGISTERS_REQUEST';
export const ADD_CASH_REGISTERS_SUCCESS = 'ADD_CASH_REGISTERS_SUCCESS';
export const ADD_CASH_REGISTERS_FAILED = 'ADD_CASH_REGISTERS_FAILED';

export const UPDATE_CASH_REGISTERS_REQUEST = 'UPDATE_CASH_REGISTERS_REQUEST';
export const UPDATE_CASH_REGISTERS_SUCCESS = 'UPDATE_CASH_REGISTERS_SUCCESS';
export const UPDATE_CASH_REGISTERS_FAILED = 'UPDATE_CASH_REGISTERS_FAILED';

export const GET_SINGLE_CASH_REGISTERS_REQUEST =
  'GET_SINGLE_CASH_REGISTERS_REQUEST';
export const GET_SINGLE_CASH_REGISTERS_SUCCESS =
  'GET_SINGLE_CASH_REGISTERS_SUCCESS';
export const GET_SINGLE_CASH_REGISTERS_FAILED =
  'GET_SINGLE_CASH_REGISTERS_FAILED';
