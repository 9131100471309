import {
  GET_ALL_SUPPLIERS_SUCCESS,
  GET_ALL_SUPPLIERS_FAILED,
  REMOVE_SUPPLIERS_SUCCESS,
  ADD_SUPPLIERS_SUCCESS,
  ADD_SUPPLIERS_FAILED,
  UPDATE_SUPPLIERS_SUCCESS,
  GET_SINGLE_SUPPLIERS_FAILED,
  GET_SINGLE_SUPPLIERS_SUCCESS,
  UPDATE_SUPPLIERS_FAILED,
  GET_ALL_SUPPLIERS_REQUEST
} from 'redux/constants/InventoryConstants/inventory.suppliers.constants';

const InventorySupplierReducer = (
  state = {
    suppliers: [],
    supplier: {},
    loading: true,
    error: false,
    page: 1,
    first: true,
    last: true
  },
  action
) => {
  const { suppliers } = state;
  let newSuppliers;
  switch (action.type) {
    case GET_ALL_SUPPLIERS_REQUEST:
      return {
        ...state,
        suppliers: [],
        loading: true
      };
    case GET_ALL_SUPPLIERS_SUCCESS:
      return {
        loading: false,
        error: false,
        suppliers: [...action.payload.content],
        page: action.payload.page,
        pages: action.payload.totalElements,
        first: action.payload.first,
        last: action.payload.last,
        size: action.payload.size,
        searchValue: action.payload.searchValue
      };
    case GET_ALL_SUPPLIERS_FAILED:
      return {
        ...state,
        error: action.payload,
        suppliers: [...state.suppliers],
        loading: false
      };
    case GET_SINGLE_SUPPLIERS_SUCCESS:
      return {
        ...state,
        supplier: action.payload,
        loading: false
      };
    case GET_SINGLE_SUPPLIERS_FAILED:
      return {
        ...state,
        error: action.payload,
        supplier: {},
        loading: false
      };
    case ADD_SUPPLIERS_SUCCESS:
      newSuppliers = {
        ...state,
        suppliers: [...suppliers, action.payload]
      };
      return newSuppliers;
    case ADD_SUPPLIERS_FAILED:
      return {
        error: action.payload,
        suppliers: [...state.suppliers]
      };
    case REMOVE_SUPPLIERS_SUCCESS:
      newSuppliers = suppliers.filter(
        (supplier) => supplier.supplier_id !== action.payload.supplier_id
      );
      return { loading: false, suppliers: newSuppliers, error: false };

    case UPDATE_SUPPLIERS_SUCCESS:
      const {
        supplier_id,
        supplier_name,
        supplier_code,
        // supplier_name,
        supplier_phone,
        supplier_website,
        note,
        default_markup,
        fax,
        first_name,
        last_name,
        primary_mobile,
        email,
        invoices_dueDay,
        invoices_dueType,
        active,
        default_tax_id,
        mail_addr_id,
        phys_addr_id,
        created_date_time,
        last_modified_time,
        mailing_address,
        physical_address,
        same_as_mailing,
        pos_rid
      } = action.payload;

      newSuppliers = [...state.suppliers];
      const existingProduct = state.suppliers.find(
        (supplier) => supplier.supplier_id === supplier_id
      );

      if (existingProduct) {
        existingProduct.supplier_name = supplier_name;
        existingProduct.supplier_code = supplier_code;
        existingProduct.supplier_name = supplier_name;
        existingProduct.supplier_phone = supplier_phone;
        existingProduct.supplier_website = supplier_website;
        existingProduct.note = note;
        existingProduct.default_markup = default_markup;
        existingProduct.fax = fax;
        existingProduct.first_name = first_name;
        existingProduct.last_name = last_name;
        existingProduct.primary_mobile = primary_mobile;
        existingProduct.email = email;
        existingProduct.invoices_dueDay = invoices_dueDay;
        existingProduct.invoices_dueType = invoices_dueType;
        existingProduct.active = active;
        existingProduct.default_tax_id = default_tax_id;
        existingProduct.mail_addr_id = mail_addr_id;
        existingProduct.phys_addr_id = phys_addr_id;
        existingProduct.last_modified_time = last_modified_time;
        existingProduct.created_date_time = created_date_time;
        existingProduct.mailing_address = mailing_address;
        existingProduct.physical_address = physical_address;
        existingProduct.same_as_mailing = same_as_mailing;
        existingProduct.pos_rid = pos_rid;
      }

      return { suppliers: newSuppliers };
    case UPDATE_SUPPLIERS_FAILED: {
      return {
        error: action.payload,
        suppliers: [...state.suppliers]
      };
    }

    default:
      return state;
  }
};
export default InventorySupplierReducer;
