import {
  GET_SINGLE_USER_REQUEST,
  GET_SINGLE_USER_SUCCESS,
  GET_SINGLE_USER_FAILED,
  GET_MY_PROFILE_REQUEST,
  GET_MY_PROFILE_SUCCESS,
  GET_MY_PROFILE_FAILED,
  UPDATE_USER_REQUEST,
  GET_ALL_USERS_REQUEST,
  GET_ALL_USERS_SUCCESS,
  LOGIN_USER_REQUEST,
  USER_LOGOUT,
  REGISTER_USER_REQUEST,
  REGISTER_USER_FAILED,
  GET_ALL_SYSTEM_USERS_REQUEST,
  GET_ALL_SYSTEM_USERS_SUCCESS,
  ADD_SYSTEM_USERS_REQUEST,
  ADD_SYSTEM_USERS_FAILED,
  UPDATE_SYSTEM_USER_REQUEST,
  REMOVE_SYSTEM_USER_REQUEST,
  REMOVE_MULTIPLE_SYSTEM_USER_REQUEST,
  CHANGE_USER_PASS_REQUEST,
  CHANGE_USER_PASS_FAILED,
  GET_ALL_SYSTEM_USERS_FAILED,
  GET_ALL_USERS_FAILED,
  REFRESH_TOKEN_REQUEST,
  VALIDATE_TOKEN_REQUEST,
  UPDATE_PROFILE_REQUEST,
  GET_SINGLE_SYSTEM_USER_REQUEST,
  GET_SINGLE_SYSTEM_USER_SUCCESS,
  GET_SINGLE_SYSTEM_USER_FAILED,
  FORGOT_PASSWORD_REQUEST,
  RESET_FORGOT_PASSWORD_REQUEST
} from 'redux/constants/UserConstants/user.constants';

/*----------------------------------
          GET ALL POS USERS         
==================================*/

export const getAllUsers = () => {
  return { type: GET_ALL_USERS_REQUEST };
};
export const populateUsers = (entries) => {
  return { type: GET_ALL_USERS_SUCCESS, payload: entries };
};
export const populateUsersFailed = (error) => {
  return { type: GET_ALL_USERS_FAILED, payload: error };
};
/*----------------------------------
          GET SINGLE POS USER         
==================================*/

export const getSingleUser = (pos_rid) => {
  return { type: GET_SINGLE_USER_REQUEST, payload: pos_rid };
};
export const populateSingleUser = (user) => {
  return { type: GET_SINGLE_USER_SUCCESS, payload: user };
};
export const populateSingleUserFailed = (error) => {
  return { type: GET_SINGLE_USER_FAILED, payload: error };
};
/*----------------------------------
          UPDATE  GENERAL STORE       
==================================*/

export const updateStore = (pos_rid, entry) => {
  return {
    type: UPDATE_USER_REQUEST,
    payload: { pos_rid, entry }
  };
};

/*----------------------------------
          UPDATE  PROFILE      
==================================*/

export const updateProfile = (pos_rid, entry) => {
  return {
    type: UPDATE_PROFILE_REQUEST,
    payload: { pos_rid, entry }
  };
};
/*----------------------------------
          LOGIN USER         
==================================*/

export const login = (email, password) => {
  return {
    type: LOGIN_USER_REQUEST,
    payload: { email, password }
  };
};

/*----------------------------------
          FORGOT PASSWORD         
==================================*/

export const forgotPassword = (email) => {
  return {
    type: FORGOT_PASSWORD_REQUEST,
    payload: { email }
  };
};
/*----------------------------------
          RESET FORGOT PASSWORD         
==================================*/

export const resetForgotPassword = (password, token) => {
  return {
    type: RESET_FORGOT_PASSWORD_REQUEST,
    payload: { password, token }
  };
};
/*----------------------------------
         REFRESH TOKEN
 ==================================*/
export const sendRefreshToken = (refreshToken, email) => {
  console.log('Sending refresh token from action');
  return {
    type: REFRESH_TOKEN_REQUEST,
    payload: { refreshToken, email }
  };
};

/*----------------------------------
         VALIDATE TOKEN
 ==================================*/
export const validateToken = () => {
  console.log('Sending  token from action TO VALIDATE');
  return {
    type: VALIDATE_TOKEN_REQUEST
  };
};
/*----------------------------------
          LOGOUT USER         
==================================*/

export const logout = (payload) => {
  console.log(payload);
  return { type: USER_LOGOUT, payload };
};
/*----------------------------------
         REGISTER USER          
==================================*/

export const registerUser = (payload) => {
  return { type: REGISTER_USER_REQUEST, payload };
};
export const registerUserFailed = (error) => {
  return { type: REGISTER_USER_FAILED, payload: error };
};

/*----------------------------------
        CHANGE USER PASSWORD         
==================================*/

export const changeUserPass = (entry) => {
  return { type: CHANGE_USER_PASS_REQUEST, payload: entry };
};
export const changeUserPassFailed = (error) => {
  return { type: CHANGE_USER_PASS_FAILED, payload: error };
};

/*----------------------------------
        MY PROFILE        
==================================*/

export const getMyProfile = () => {
  return { type: GET_MY_PROFILE_REQUEST };
};
export const populateProfile = (user) => {
  return { type: GET_MY_PROFILE_SUCCESS, payload: user };
};
export const populateProfileFailed = (error) => {
  return { type: GET_MY_PROFILE_FAILED, payload: error };
};
/*----------------------------------
    SYSTEM USER > GET ALL USERS       
==================================*/

export const getAllSystemUsers = (searchValue, page, size, order, orderBy) => {
  return {
    type: GET_ALL_SYSTEM_USERS_REQUEST,
    searchValue,
    page,
    size,
    order,
    orderBy
  };
};
export const populateSystemUsers = (entries) => {
  return { type: GET_ALL_SYSTEM_USERS_SUCCESS, payload: entries };
};
export const populateSystemUsersFailed = (error) => {
  return { type: GET_ALL_SYSTEM_USERS_FAILED, payload: error };
};

/*----------------------------------
    SYSTEM USER > ADD USERS       
==================================*/

export const addSystemUser = (payload) => {
  return { type: ADD_SYSTEM_USERS_REQUEST, payload };
};
export const addSystemUserFailed = (error) => {
  return { type: ADD_SYSTEM_USERS_FAILED, payload: error };
};

/*----------------------------------
    SYSTEM USER > UPDATE USERS       
==================================*/

export const updateSystemUser = (sys_rid, entry) => {
  return { type: UPDATE_SYSTEM_USER_REQUEST, payload: { sys_rid, entry } };
};
/*----------------------------------
    SYSTEM USER > REMOVE USERS       
==================================*/

export const removeSystemUser = (sys_rid) => {
  return { type: REMOVE_SYSTEM_USER_REQUEST, payload: { sys_rid } };
};
export const removeMultipleSystemUser = (sys_rid) => {
  return { type: REMOVE_MULTIPLE_SYSTEM_USER_REQUEST, payload: { sys_rid } };
};

/*----------------------------------
          GET SINGLE SYSTEM USER      
==================================*/

export const getSingleSystemUser = (sys_rid) => {
  return { type: GET_SINGLE_SYSTEM_USER_REQUEST, payload: sys_rid };
};
export const populateSingleSystemUser = (user) => {
  return { type: GET_SINGLE_SYSTEM_USER_SUCCESS, payload: user };
};
export const populateSingleSystemUserFailed = (error) => {
  return { type: GET_SINGLE_SYSTEM_USER_FAILED, payload: error };
};
