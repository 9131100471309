import { makeStyles } from '@material-ui/core';
import React, { useState } from 'react';

const useForm = (initialFieldValues, validateOnChange = false, validate) => {
  const [values, setValues] = useState(initialFieldValues);
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value
    });
    if (validateOnChange) validate({ [name]: value });
  };

  const resetForm = () => {
    setValues(initialFieldValues);
    setErrors({});
  };

  return { values, setValues, errors, setErrors, handleInputChange, resetForm };
};
const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiFormControl-root': {
      width: '100%',
      margin: theme.spacing(1)
    }
  }
}));
const Form = (props) => {
  const { children, ...other } = props;
  const classes = useStyles();

  return (
    <div>
      <form className={classes.root} noValidate autoComplete="off" {...other}>
        {children}
      </form>
    </div>
  );
};

export { useForm, Form };
