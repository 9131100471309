import {
  GET_SINGLE_CUSTOMER_GROUP_REQUEST,
  GET_SINGLE_CUSTOMER_GROUP_SUCCESS,
  GET_SINGLE_CUSTOMER_GROUP_FAILED,
  UPDATE_CUSTOMER_GROUP_REQUEST,
  GET_ALL_CUSTOMER_GROUPS_REQUEST,
  GET_ALL_CUSTOMER_GROUPS_SUCCESS,
  GET_ALL_CUSTOMER_GROUPS_FAILED,
  ADD_CUSTOMER_GROUPS_REQUEST,
  ADD_CUSTOMER_GROUPS_FAILED,
  REMOVE_CUSTOMER_GROUP_REQUEST,
  REMOVE_MULTIPLE_CUSTOMER_GROUP_REQUEST
} from 'redux/constants/CustomersConstants/customer.groups.constants';

/*----------------------------------
          GET SINGLE POS CUSTOMER_GROUP         
==================================*/

export const getSingleCustomerGroup = (id) => {
  return { type: GET_SINGLE_CUSTOMER_GROUP_REQUEST, payload: id };
};
export const populateSingleCustomerGroup = (customer_group) => {
  console.log(customer_group);
  return { type: GET_SINGLE_CUSTOMER_GROUP_SUCCESS, payload: customer_group };
};
export const populateSingleCustomerGroupFailed = (error) => {
  return { type: GET_SINGLE_CUSTOMER_GROUP_FAILED, payload: error };
};
/*----------------------------------
          UPDATE POS CUSTOMER_GROUP         
==================================*/

export const updateCustomerGroup = (id, entry) => {
  console.log(id, entry, 'reducer');
  return {
    type: UPDATE_CUSTOMER_GROUP_REQUEST,
    payload: { id, entry }
  };
};

/*----------------------------------
   CUSTOMER_GROUP > GET ALL CUSTOMER_GROUPS       
==================================*/

export const getAllCustomerGroups = (
  searchValue,
  page,
  size,
  order,
  orderBy
) => {
  return {
    type: GET_ALL_CUSTOMER_GROUPS_REQUEST,
    searchValue,
    page,
    size,
    order,
    orderBy
  };
};
export const populateCustomerGroups = (entries) => {
  console.log(entries, 'action');
  return { type: GET_ALL_CUSTOMER_GROUPS_SUCCESS, payload: entries };
};
export const populateCustomerGroupsFailed = (error) => {
  return { type: GET_ALL_CUSTOMER_GROUPS_FAILED, payload: error };
};

/*----------------------------------
   CUSTOMER_GROUP > ADD CUSTOMER_GROUPS       
==================================*/

export const addCustomerGroup = (payload) => {
  return { type: ADD_CUSTOMER_GROUPS_REQUEST, payload };
};
export const addCustomerGroupFailed = (error) => {
  return { type: ADD_CUSTOMER_GROUPS_FAILED, payload: error };
};

/*----------------------------------
   CUSTOMER_GROUP > REMOVE CUSTOMER_GROUPS       
==================================*/

export const removeCustomerGroup = (id) => {
  return { type: REMOVE_CUSTOMER_GROUP_REQUEST, payload: { id } };
};
export const removeMultipleCustomerGroup = (id) => {
  return {
    type: REMOVE_MULTIPLE_CUSTOMER_GROUP_REQUEST,
    payload: { id }
  };
};
