import {
  GET_MY_INVENTORY_REQUEST,
  GET_MY_INVENTORY_SUCCESS,
  GET_MY_INVENTORY_FAILED
} from 'redux/constants/InventoryConstants/inventory.my.constants';

/*------------------------
        MY INVENTORY     
=========================*/

export const getAllInventoryItems = (
  searchValue,
  page,
  size,
  order,
  orderBy,
  outletId
) => {
  return {
    type: GET_MY_INVENTORY_REQUEST,
    searchValue,
    page,
    size,
    order,
    orderBy,
    outletId
  };
};
export const populateInventoryItems = (entries) => {
  return { type: GET_MY_INVENTORY_SUCCESS, payload: entries };
};
export const populateInventoryItemsFailed = (error) => {
  return { type: GET_MY_INVENTORY_FAILED, payload: error };
};
