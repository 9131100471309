import { Grid } from '@material-ui/core';
import Controls from 'components/controls/Controls';
import { Form, useForm } from 'components/useForm';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { changeUserPass } from 'redux/actions/UserActions/user.action';
import { initialFieldValues } from './utils';

const ResetPassword = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userLogin.userInfo);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ('password' in fieldValues)
      temp.password = fieldValues.password
        ? ''
        : 'Please Enter Current Password';
    if ('confirmationToken' in fieldValues)
      temp.confirmationToken = !fieldValues.confirmationToken
        ? 'Please Enter New Password'
        : /^.{6,10}$/.test(fieldValues.confirmationToken)
        ? ''
        : 'Please enter 6 to 10 characters long password';
    if ('confirm_password' in fieldValues) {
      if (values.confirmationToken !== '')
        temp.confirm_password =
          fieldValues.confirm_password !== values.confirmationToken
            ? 'Password does not match'
            : '';
    }
    setErrors({ ...temp });
    if (fieldValues === values)
      return Object.values(temp).every((x) => x === '');
  };
  const {
    values,
    // eslint-disable-next-line no-unused-vars
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm
  } = useForm(initialFieldValues, true, validate);
  const resetPass = (passValues, resetForm) => {
    const { email, password, confirmationToken } = passValues;
    dispatch(changeUserPass({ email, password, confirmationToken }, resetForm));
    resetForm();
    props.setOpenPopup(false);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      const newVal = { ...values, email: user.email };
      resetPass(newVal, resetForm);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Grid container>
        <Grid item xs={12} sm={12}>
          <Controls.Input
            required
            name="password"
            label="Current Password"
            type="password"
            value={values.password}
            onChange={handleInputChange}
            error={errors.password}
            autoComplete="on"
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Controls.Input
            required
            name="confirmationToken"
            label="New Password"
            value={values.confirmationToken}
            type="password"
            onChange={handleInputChange}
            error={errors.confirmationToken}
            autoComplete="on"
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Controls.Input
            required
            autoComplete="on"
            name="confirm_password"
            label="Confirm Password"
            value={values.confirm_password}
            type="password"
            onChange={handleInputChange}
            error={errors.confirm_password}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <div>
            <Controls.Button type="submit" text="Save" />
            <Controls.Button text="Reset" color="default" onClick={resetForm} />
          </div>
        </Grid>
      </Grid>
    </Form>
  );
};

export default ResetPassword;
