import { call, put, takeLatest, takeEvery, delay } from 'redux-saga/effects';
import {
  GET_PRODUCTS_REQUEST,
  REMOVE_MULTIPLE_PRODUCTS_REQUEST,
  REMOVE_MULTIPLE_PRODUCTS_SUCCESS,
  REMOVE_PRODUCTS_REQUEST,
  REMOVE_PRODUCTS_SUCCESS,
  ADD_PRODUCTS_REQUEST,
  ADD_PRODUCTS_SUCCESS,
  UPDATE_PRODUCTS_REQUEST,
  UPDATE_PRODUCTS_SUCCESS,
  GET_SINGLE_PRODUCTS_REQUEST
} from 'redux/constants/ProductConstants/products.constants';
import {
  populateProducts,
  populateProductsFailed,
  addProductFailed,
  populateSingleProduct,
  populateSingleProductFailed
} from 'redux/actions/ProductActions/products.actions';
import { setSnackbar } from 'redux/actions/UIComponentActions/snackbar.action';
import api from 'utils/api';
import { sendRefreshToken } from 'redux/actions/UserActions/user.action';
import { getCookieData } from 'utils/functionsCustom';
import { push } from 'connected-react-router';
import { fileUploadDone } from 'redux/actions/UIComponentActions/file.upload';
import { store } from 'index';

/*----------------------------------
      GET ALL PRODUCTS
==================================*/
// export function* getAllProducts() {
//   yield take(GET_PRODUCTS_REQUEST);
//   try {
//     // const { data } = yield call(axios, url);
//     const { data } = yield call(axios, `${baseURLProd}/getAll-product`);
//     yield put(populateProducts(data));
//   } catch (error) {
//     yield put(populateProductsFailed(error.errorMessage));
//   }
// }

export function* getAllProducts() {
  yield takeEvery(GET_PRODUCTS_REQUEST, fetchProducts);
}
function* fetchProducts(payload) {
  const {
    page: currentPage,
    size,
    orderBy: sortField,
    order: sortDir,
    searchValue: query
  } = payload;
  try {
    const { data } = yield api.get('/getAll-product', {
      params: {
        currentPage: currentPage + 1 || 1,
        size: size || 8,
        sortField: sortField ?? 'name',
        sortDir: sortDir || 'asc',
        query: query || ''
      }
    });
    yield delay(400);
    yield put(populateProducts(data));
  } catch (err) {
    if (err.status !== 404) {
      yield put(populateProductsFailed(err.message));
      yield put(setSnackbar(true, 'error', err.message));

      if (err.response.config.headers.Authorization === undefined) {
        const { refreshToken, email } = getCookieData();
        yield put(sendRefreshToken(refreshToken, email));
        window.location.reload();
      }
    }
    yield put(populateProductsFailed(err.message));
  }
}

/*----------------------------------
         DELETE SINGLE ENTRY
==================================*/
// export function* deleteEntrySaga() {
//   while (true) {
//     const { payload } = yield take(REMOVE_PRODUCTS_REQUEST);
//     yield call(deleteEntries, payload.id);
//     yield put({
//       type: REMOVE_PRODUCTS_SUCCESS,
//       payload: { id: payload.id }
//     });
//     yield put(push('/products'));
//     yield delay(400);
//     yield put({ type: GET_PRODUCTS_REQUEST });
//     yield call(getAllProducts);
//     // yield put(setSnackbar(true, 'error', 'Product Deleted successfully'));
//   }
// }
// async function deleteEntries(id) {
//   await api.delete(`/delete-product/${id}`);
// }
/* Delete Buyers address */
function* deleteProduct(action) {
  const { id } = action.payload;
  try {
    yield call(deleteProductDB, id);
    yield put({
      type: REMOVE_PRODUCTS_SUCCESS,
      payload: { id: id }
    });
    yield put(push('/products'));
    // yield delay(400);
    yield put(setSnackbar(true, 'error', 'Product Deleted successfully'));
    yield put({ type: GET_PRODUCTS_REQUEST });
    yield call(getAllProducts);
  } catch (error) {
    // yield deleteProductErrorAction();
    console.log(error);
  }
}
const deleteProductDB = async (id) => {
  return await api.delete(`/delete-product/${id}`);
};
// watcher saga
export function* deleteProductSaga() {
  yield takeEvery(REMOVE_PRODUCTS_REQUEST, deleteProduct);
}
/*----------------------------------
         DELETE MULTIPLE ENTRY
==================================*/
// export function* deleteMultipleEntrySaga() {
//   while (true) {
//     const { payload } = yield take(REMOVE_MULTIPLE_PRODUCTS_REQUEST);
//     yield call(deleteMultipleEntries, payload.id);
//     yield put({
//       type: REMOVE_MULTIPLE_PRODUCTS_SUCCESS,
//       payload: { id: payload.id }
//     });
//     yield put(push('/products'));
//     yield put(setSnackbar(true, 'error', 'Product Deleted successfully'));
//     yield put({ type: GET_PRODUCTS_REQUEST });
//     yield call(getAllProducts);
//   }
// }
// async function deleteMultipleEntries(ids) {
//   await Promise.all(
//     ids.map((id) => api.delete(`/delete-product/${id}`))
//   );
// }
function* deleteProductMultiple(action) {
  const { id } = action.payload;
  console.log(action.payload, 'mult');
  try {
    yield call(deleteProductDBMultiple, id);
    yield put({
      type: REMOVE_MULTIPLE_PRODUCTS_SUCCESS,
      payload: { id: id }
    });
    yield put(push('/products'));
    yield delay(400);
    yield put({ type: GET_PRODUCTS_REQUEST });
    yield call(getAllProducts);
    yield put(setSnackbar(true, 'error', 'Product Deleted successfully'));
  } catch (error) {
    // yield deleteProductErrorAction();
    console.log(error);
  }
}
const deleteProductDBMultiple = async (ids) => {
  await Promise.all(ids.map((id) => api.delete(`/delete-product/${id}`)));
};
// watcher saga
export function* deleteProductSagaMultiple() {
  yield takeEvery(REMOVE_MULTIPLE_PRODUCTS_REQUEST, deleteProductMultiple);
}
/*----------------------------------
         ADD PRODUCT
==================================*/
export function* addEntrySaga() {
  yield takeLatest(ADD_PRODUCTS_REQUEST, addEntryToDb);
}
function* addEntryToDb({ payload }) {
  try {
    const result = yield call(addEntry, payload);
    yield delay(400);
    yield put({ type: ADD_PRODUCTS_SUCCESS, payload: result });
    yield put(push('/products'));
    yield put(setSnackbar(true, 'success', 'Product added successfully'));
    yield put({ type: GET_PRODUCTS_REQUEST });
    yield call(getAllProducts);
  } catch (error) {
    yield put(addProductFailed(error.message));
  }
}

async function addEntry(payload) {
  const {
    name,
    productType,
    unit,
    hsn_code,
    active,
    sku,
    barcode,
    custom_field,
    description,
    sales_channels,
    product_types_ids,
    product_suppliers_ids,
    product_brand_id,
    product_tags_ids,
    product_season_id,
    loyaltyPoints,
    optional_extra_products,
    units_of_measure,
    additional_units_of_measure,
    part_composite_enabled,
    serial_number_enabled,
    supplier_code,
    track_inventory,
    continue_selling_no_inventory,
    product_outlets,
    created_date_time,
    last_modified_time,
    component_products,
    id,
    rack_no,
    file,
    image_url,
    image_id,
    all_same_price
  } = payload;
  const formData = new FormData();
  const { data } = await api.post(`/save-product`, {
    productType,
    name,
    sku,
    barcode,
    supplier_code,
    custom_field,
    serial_number_enabled,
    part_composite_enabled,
    description,
    created_date_time,
    last_modified_time,
    track_inventory,
    continue_selling_no_inventory,
    product_outlets,
    units_of_measure,
    additional_units_of_measure,
    sales_channels,
    product_types_ids,
    product_suppliers_ids,
    product_brand_id,
    product_tags_ids,
    product_season_id,
    loyaltyPoints,
    optional_extra_products,
    active,
    component_products,
    unit,
    hsn_code,
    rack_no,
    image_url,
    image_id,
    all_same_price
  });
  formData.append('id', data.id);
  formData.append('name', 'product');
  formData.append('file', file);
  if (file) {
    await api.put('/image-upload', formData, {
      headers: {
        'content-type': 'multipart/form-data'
      },
      onUploadProgress: (progressEvent) => {
        const progress = (progressEvent.loaded / progressEvent.total) * 100;
        store.dispatch(fileUploadDone(progress));
      }
    });
  }
  return data;
}
/*----------------------------------
       UPDATE PRODUCT
==================================*/
export function* updateEntrySaga() {
  yield takeEvery(UPDATE_PRODUCTS_REQUEST, updateEntryToDb);
}
function* updateEntryToDb({ payload }) {
  try {
    const result = yield call(updateEntry, payload);
    console.log(result, 'saga');
    yield delay(400);
    yield put({ type: UPDATE_PRODUCTS_SUCCESS, payload: result });
    yield put(push('/products'));
    yield put({ type: GET_PRODUCTS_REQUEST });
    yield call(getAllProducts);
    yield call(setSnackbar(true, 'success', 'Product updated Successfully'));
  } catch (error) {}
}
async function updateEntry(payload) {
  const { id, entry } = payload;
  const {
    name,
    productType,
    active,
    sku,
    barcode,
    custom_field,
    description,
    sales_channels,
    product_types_ids,
    product_suppliers_ids,
    product_brand_id,
    product_tags_ids,
    product_season_id,
    loyaltyPoints,
    optional_extra_products,
    units_of_measure,
    additional_units_of_measure,
    part_composite_enabled,
    serial_number_enabled,
    supplier_code,
    track_inventory,
    continue_selling_no_inventory,
    product_outlets,
    created_date_time,
    last_modified_time,
    component_products,
    unit,
    hsn_code,
    rack_no,
    file,
    image_url,
    image_id,
    all_same_price
  } = entry;
  console.log(product_outlets, 'product_outlets saga');
  const { data } = await api.put(`/update-product/${id}`, {
    id,
    name,
    productType,
    active,
    sku,
    barcode,
    custom_field,
    description,
    sales_channels,
    product_types_ids,
    product_suppliers_ids,
    product_brand_id,
    product_tags_ids,
    product_season_id,
    loyaltyPoints,
    optional_extra_products,
    units_of_measure,
    additional_units_of_measure,
    part_composite_enabled,
    serial_number_enabled,
    supplier_code,
    track_inventory,
    continue_selling_no_inventory,
    product_outlets,
    created_date_time,
    last_modified_time,
    component_products,
    unit,
    hsn_code,
    rack_no,
    image_url,
    image_id,
    all_same_price
  });
  const formData = new FormData();
  formData.append('id', id);
  formData.append('name', 'product');
  formData.append('file', file);
  if (file) {
    await api.put('/image-upload', formData, {
      headers: {
        'content-type': 'multipart/form-data'
      },
      onUploadProgress: (progressEvent) => {
        const progress = (progressEvent.loaded / progressEvent.total) * 100;
        store.dispatch(fileUploadDone(progress));
      }
    });
  }
  console.log(data, 'data saga');
  return data;
}
/*----------------------------------
       GET SINGLE PRODUCT
==================================*/

export function* onFetchProductStart() {
  yield takeLatest(GET_SINGLE_PRODUCTS_REQUEST, fetchProduct);
}
function* fetchProduct({ payload }) {
  try {
    // const { data } = yield axios.get(`${url}/${payload}`);
    const { data } = yield api.get(`/get-product/${payload}`);
    console.log(data);
    yield put(populateSingleProduct(data));
  } catch (err) {
    yield put(populateSingleProductFailed(err.message));
  }
}
