/*----------------------------------
    INVENTORY PURCHASE_ORDER CONSTANTS         
==================================*/

export const GET_ALL_PURCHASE_ORDER_REQUEST = 'GET_ALL_PURCHASE_ORDER_REQUEST';
export const GET_ALL_PURCHASE_ORDER_SUCCESS = 'GET_ALL_PURCHASE_ORDER_SUCCESS';
export const GET_ALL_PURCHASE_ORDER_FAILED = 'GET_ALL_PURCHASE_ORDER_FAILED';

export const REMOVE_PURCHASE_ORDER_REQUEST = 'REMOVE_PURCHASE_ORDER_REQUEST';
export const REMOVE_PURCHASE_ORDER_SUCCESS = 'REMOVE_PURCHASE_ORDER_SUCCESS';
export const REMOVE_PURCHASE_ORDER_FAILED = 'REMOVE_PURCHASE_ORDER_FAILED';

export const ADD_PURCHASE_ORDER_REQUEST = 'ADD_PURCHASE_ORDER_REQUEST';
export const ADD_PURCHASE_ORDER_SUCCESS = 'ADD_PURCHASE_ORDER_SUCCESS';
export const ADD_PURCHASE_ORDER_FAILED = 'ADD_PURCHASE_ORDER_FAILED';

export const RECEIVE_PURCHASE_ORDER_REQUEST = 'RECEIVE_PURCHASE_ORDER_REQUEST';
export const RECEIVE_PURCHASE_ORDER_SUCCESS = 'RECEIVE_PURCHASE_ORDER_SUCCESS';
export const RECEIVE_PURCHASE_ORDER_FAILED = 'RECEIVE_PURCHASE_ORDER_FAILED';

export const RECEIVE_ALL_PURCHASE_ORDER_REQUEST =
  'RECEIVE_ALL_PURCHASE_ORDER_REQUEST';
export const RECEIVE_ALL_PURCHASE_ORDER_SUCCESS =
  'RECEIVE_ALL_PURCHASE_ORDER_SUCCESS';
export const RECEIVE_ALL_PURCHASE_ORDER_FAILED =
  'RECEIVE_ALL_PURCHASE_ORDER_FAILED';

export const GET_SINGLE_PURCHASE_ORDER_REQUEST =
  'GET_SINGLE_PURCHASE_ORDER_REQUEST';
export const GET_SINGLE_PURCHASE_ORDER_SUCCESS =
  'GET_SINGLE_PURCHASE_ORDER_SUCCESS';
export const GET_SINGLE_PURCHASE_ORDER_FAILED =
  'GET_SINGLE_PURCHASE_ORDER_FAILED';
