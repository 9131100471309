import {
  call,
  put,
  take,
  delay,
  takeLatest,
  takeEvery
} from 'redux-saga/effects';

import cookie from 'react-cookies';
import {
  ADD_SYSTEM_USERS_REQUEST,
  ADD_SYSTEM_USERS_SUCCESS,
  CHANGE_USER_PASS_REQUEST,
  CHANGE_USER_PASS_SUCCESS,
  FORGOT_PASSWORD_FAILED,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  GET_ALL_SYSTEM_USERS_REQUEST,
  GET_ALL_USERS_REQUEST,
  GET_MY_PROFILE_REQUEST,
  GET_SINGLE_SYSTEM_USER_REQUEST,
  GET_SINGLE_USER_REQUEST,
  LOGIN_USER_FAILED,
  LOGIN_USER_REQUEST,
  LOGIN_USER_SUCCESS,
  REFRESH_TOKEN_FAILED,
  REFRESH_TOKEN_REQUEST,
  REFRESH_TOKEN_SUCCESS,
  REGISTER_USER_REQUEST,
  REGISTER_USER_SUCCESS,
  REMOVE_MULTIPLE_SYSTEM_USER_REQUEST,
  REMOVE_MULTIPLE_SYSTEM_USER_SUCCESS,
  REMOVE_SYSTEM_USER_REQUEST,
  REMOVE_SYSTEM_USER_SUCCESS,
  RESET_FORGOT_PASSWORD_FAILED,
  RESET_FORGOT_PASSWORD_REQUEST,
  RESET_FORGOT_PASSWORD_SUCCESS,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_SYSTEM_USER_REQUEST,
  UPDATE_SYSTEM_USER_SUCCESS,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  USER_LOGOUT,
  VALIDATE_TOKEN_FAILED,
  VALIDATE_TOKEN_REQUEST,
  VALIDATE_TOKEN_SUCCESS
} from 'redux/constants/UserConstants/user.constants';
import {
  addSystemUserFailed,
  getMyProfile,
  login,
  populateProfile,
  populateProfileFailed,
  populateSingleSystemUser,
  populateSingleSystemUserFailed,
  populateSingleUser,
  populateSingleUserFailed,
  populateSystemUsers,
  populateSystemUsersFailed,
  populateUsers,
  populateUsersFailed,
  registerUserFailed
} from 'redux/actions/UserActions/user.action';
import { setSnackbar } from 'redux/actions/UIComponentActions/snackbar.action';
import { push } from 'connected-react-router';
import api from 'utils/api';
import { history } from 'redux/store/configureStore';
import { store } from 'index';
import { fileUploadDone } from 'redux/actions/UIComponentActions/file.upload';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

/*----------------------------------
      GET ALL USERS
==================================*/
// export function* getAllUsers() {
//   yield take(GET_ALL_USERS_REQUEST);

//   try {
//     // const { data } = yield call(api, url);
//     const { data } = yield call(api, `/getAll-posUser`);
//     yield put(populateUsers(data));
//     // yield call(cancelSource.cancel);
//     // console.log(cancelSource.cancel, 'cancel');
//   } catch (error) {
//     // yield put(populate(error.errorMessage));
//   }
// }

// export function* getAllUsers() {
//   yield takeLatest(GET_ALL_USERS_REQUEST, fetchProducts);
// }
// function* fetchProducts() {
//   try {
//     const response = yield call(api, `/getAll-posUser `);

//     const data = yield response.data;
//     const result = yield data.json();
//     console.log(result, 'saga');
//     yield put(populateUsers(data));
//   } catch (error) {
//     yield put(populateUsersFailed(error));
//     if (error.response.status !== 404) {
//       yield put(setSnackbar(true, 'error', error.message));
//     }
//   }
// }
export function* getAllUsersgetAllUsers() {
  yield takeEvery(GET_ALL_USERS_REQUEST, fetchProducts);
}
function* fetchProducts() {
  try {
    const { data } = yield api.get(`/getAll-posUser`);
    yield put(populateUsers(data));
  } catch (err) {
    if (err.status !== 404) {
      yield put(populateUsersFailed(err.message));
      yield put(setSnackbar(true, 'error', err.message));
    }
    yield put(populateUsersFailed(err.message));
  }
}

/*----------------------------------
       GET SINGLE USER
==================================*/
export function* onFetchProductStart() {
  yield takeLatest(GET_SINGLE_USER_REQUEST, fetchProduct);
}
function* fetchProduct({ payload }) {
  try {
    if (payload) {
      const { data } = yield api.get(`/get-posUser/${payload}`);
      yield put(populateSingleUser(data));
    }
  } catch (err) {
    yield put(populateSingleUserFailed(err.message));
  }
}
/*----------------------------------
       GET SINGLE SYSTEM USER
==================================*/
export function* onFetchSystemUser() {
  yield takeLatest(GET_SINGLE_SYSTEM_USER_REQUEST, fetchSysUser);
}
function* fetchSysUser({ payload }) {
  try {
    if (payload) {
      const { data } = yield api.get(`/get-systemUser/${payload}`);
      yield delay(300);
      yield put(populateSingleSystemUser(data));
    }
  } catch (err) {
    yield put(populateSingleSystemUserFailed(err.message));
  }
}
/*----------------------------------
       MY PROFILE
==================================*/
export function* onFetchMyProfile() {
  yield takeLatest(GET_MY_PROFILE_REQUEST, fetchProfile);
}
function* fetchProfile() {
  try {
    const { data } = yield api.get(`/my-profile`);
    yield put(populateProfile(data));
  } catch (err) {
    yield put(populateProfileFailed(err.message));
  }
}

/*----------------------------------
       UPDATE store
==================================*/
export function* updateEntrySaga() {
  yield takeEvery(UPDATE_USER_REQUEST, updateEntryToDb);
}
function* updateEntryToDb({ payload }) {
  try {
    // console.log();
    const result = yield call(updateEntry, payload);

    yield put({ type: UPDATE_USER_SUCCESS, payload: result });

    yield put(getMyProfile());
    yield put(setSnackbar(true, 'success', 'Updated Successfully'));
    // yield put(push('/my-profile'));
    // localStorage.setItem('userInfo', JSON.stringify(result));
    // cookie.save('userOption', {
    //   first_name: result.first_name,
    //   last_name: result.last_name,
    //   email: result.email
    // });
    // setTimeout(() => {
    //   window.location.reload();
    // }, 2000);
  } catch (error) {
    yield put(setSnackbar(true, 'error', 'Something Went Wrong'));
  }
}
async function updateEntry(payload) {
  const { pos_rid, entry } = payload;
  const formData = new FormData();
  const {
    first_name,
    last_name,
    country,
    city,
    phone,
    email,
    role,
    // accept_terms,
    created_date_time,
    last_modified_time,
    store_setup,
    store_feature_setup,
    store_general_rules,
    store_inven_notif,
    store_web_addresses,
    file,
    name,
    image_url,
    logo_url
  } = entry;

  const { data } = await api.put(`/update-storeSetUp`, {
    pos_rid,
    first_name,
    last_name,
    country,
    city,
    email,
    // accept_terms,
    created_date_time,
    last_modified_time,
    phone,
    role,
    store_setup,
    store_feature_setup,
    store_general_rules,
    store_inven_notif,
    store_web_addresses,
    image_url,
    logo_url
  });
  formData.append('id', data.pos_rid);
  formData.append('name', name);

  formData.append('file', file);

  if (file) {
    await api.put('/image-upload', formData, {
      headers: {
        'content-type': 'multipart/form-data'
      },
      onUploadProgress: (progressEvent) => {
        const progress = (progressEvent.loaded / progressEvent.total) * 100;
        store.dispatch(fileUploadDone(progress));
      }
    });
  }
  return data;
}
/*----------------------------------
       UPDATE profile
==================================*/
export function* updateProfileSaga() {
  yield takeEvery(UPDATE_PROFILE_REQUEST, updateProfileEntryToDb);
}
function* updateProfileEntryToDb({ payload }) {
  try {
    // console.log();
    const result = yield call(updateProfile, payload);
    yield delay(700);
    yield put({ type: UPDATE_PROFILE_SUCCESS, payload: result });
    yield put(getMyProfile());
    yield put(setSnackbar(true, 'success', 'Updated Successfully'));
  } catch (error) {
    yield put(setSnackbar(true, 'error', 'Something Went Wrong'));
  }
}
async function updateProfile(payload) {
  const { pos_rid, entry } = payload;
  const formData = new FormData();
  const {
    first_name,
    last_name,
    country,
    city,
    phone,
    email,
    file,
    name,
    image_id,
    image_url
  } = entry;

  const { data } = await api.put(`/update-posUser`, {
    pos_rid,
    first_name,
    last_name,
    email,
    phone,
    country,
    city,
    image_id,
    image_url
  });
  formData.append('id', data.pos_rid);
  formData.append('name', name);
  formData.append('file', file);
  console.log('profile', file);
  if (file) {
    await api.put('/image-upload', formData, {
      headers: {
        'content-type': 'multipart/form-data'
      },
      onUploadProgress: (progressEvent) => {
        const progress = (progressEvent.loaded / progressEvent.total) * 100;
        store.dispatch(fileUploadDone(progress));
      }
    });
  }
  return data;
}
/*----------------------------------
         REGISTER USER
==================================*/
export function* registerPosUserSaga() {
  yield takeLatest(REGISTER_USER_REQUEST, registerPosUser);
}
function* registerPosUser({ payload }) {
  try {
    const result = yield call(registerPos, payload);

    yield put({ type: REGISTER_USER_SUCCESS, payload: result });
    yield put(push('/login'));
    yield put(
      setSnackbar(
        true,
        'success',
        `Activation link sent to your registered email account . 
        Please verify your email address to continue`
      )
    );
  } catch (error) {
    yield put(registerUserFailed(error));
    yield put(setSnackbar(true, 'error', error?.response?.data?.errorMessage));
  }
}

async function registerPos(payload) {
  const {
    pos_rid,
    first_name,
    last_name,
    email,
    phone,
    country,
    city,
    password,
    accept_terms,
    created_date_time,
    last_modified_time,
    role,
    store_setup,
    store_feature_setup,
    store_general_rules,
    store_inven_notif,
    store_web_addresses,
    tempPassword,
    image_url,
    image_id,
    logo_url
  } = payload;
  const { data } = await api.post(`/register-posUser`, {
    pos_rid,
    first_name,
    last_name,
    email,
    phone,
    country,
    city,
    password,
    accept_terms,
    created_date_time,
    last_modified_time,
    role,
    store_setup,
    store_feature_setup,
    store_general_rules,
    store_inven_notif,
    store_web_addresses,
    tempPassword,
    image_url,
    image_id,
    logo_url
  });
  return data;
}
/*----------------------------------
        FORGOT PASSOWRD
==================================*/

function* forgotPasswordPos({ payload }) {
  try {
    const result = yield call(forgotPassword, payload);
    yield put({
      type: FORGOT_PASSWORD_SUCCESS,
      payload: result
    });
    yield put(push('/login'));
    yield put(setSnackbar(true, 'success', result));
  } catch (error) {
    yield put({ type: FORGOT_PASSWORD_FAILED, payload: error });
    yield put(setSnackbar(true, 'error', 'Email does not exist'));
  }
}
export function* forgotPasswordPosSaga() {
  yield takeLatest(FORGOT_PASSWORD_REQUEST, forgotPasswordPos);
}

async function forgotPassword(payload) {
  const { email } = payload;
  const { data } = await api.post(`/send-ForgotLink`, {
    email
  });

  return data;
}
/*----------------------------------
        FORGOT PASSOWRD
==================================*/

function* resetForgotPasswordPos({ payload }) {
  try {
    const result = yield call(resetForgotPassword, payload);
    yield put({
      type: RESET_FORGOT_PASSWORD_SUCCESS,
      payload: result
    });
    yield put(push('/login'));
    yield put(setSnackbar(true, 'success', result));
  } catch (error) {
    yield put({ type: RESET_FORGOT_PASSWORD_FAILED, payload: error });
    yield put(
      setSnackbar(
        true,
        'error',
        error?.response?.data?.errorMessage || 'Something went wrong'
      )
    );
  }
}
export function* resetForgotPasswordPosSaga() {
  yield takeLatest(RESET_FORGOT_PASSWORD_REQUEST, resetForgotPasswordPos);
}

async function resetForgotPassword(payload) {
  const { password, token } = payload;
  const { data } = await api.post(
    `/forgot-Password?token=${token}&password=${password}`
  );

  return data;
}
/*----------------------------------
         LOGIN USER
==================================*/

function* loginPosUser({ payload }) {
  try {
    const result = yield call(loginPos, payload);
    yield delay(400);
    yield put({
      type: LOGIN_USER_SUCCESS,
      payload: result
    });
    yield put(push('/dashboard'));
  } catch (error) {
    yield delay(400);
    yield put({ type: LOGIN_USER_FAILED, payload: error });
    yield put(setSnackbar(true, 'error', 'Invalid Email or Password'));
    console.log(error);
  }
}
export function* loginPosUserSaga() {
  yield takeLatest(LOGIN_USER_REQUEST, loginPosUser);
}

async function loginPos(payload) {
  const { email, password } = payload;
  const { data } = await api.post(`/login-posUser`, {
    email,
    password
  });
  cookie.save('refreshToken', data.refreshToken);
  cookie.save('email', data.email);
  cookie.save('userOption', data.userOption);
  cookie.save('authenticationToken', data.authenticationToken, {
    maxAge: data.expiresIn || 28800
  });
  return data;
}

/*----------------------------------
        REFRESH TOKEN
==================================*/

function* refreshTokenPosUser({ payload }) {
  try {
    const result = yield call(refreshTokenPos, payload);
    yield put({
      type: REFRESH_TOKEN_SUCCESS,
      payload: result
    });

    // yield put(push('/dashboard'));
    yield put(push(history.location.pathname));
  } catch (error) {
    yield put({ type: REFRESH_TOKEN_FAILED, payload: error });
    yield put(push('/login'));
  }
}
export function* refreshTokenPosUserSaga() {
  yield takeLatest(REFRESH_TOKEN_REQUEST, refreshTokenPosUser);
}

async function refreshTokenPos(payload) {
  const { refreshToken, email } = payload;
  const { data } = await api.post(`/refresh/token`, {
    refreshToken,
    email
  });

  // localStorage.setItem('userInfo', JSON.stringify(data));
  cookie.remove('userInfo');
  cookie.save('refreshToken', data.refreshToken);
  cookie.save('userOption', data.userOption);
  cookie.save('authenticationToken', data.authenticationToken, {
    maxAge: data.expiresIn || 28800
  });

  return data;
}
/*----------------------------------
        VALIDATE TOKEN
==================================*/

function* validateTokenPosUser() {
  try {
    const result = yield call(validateTokenPos);
    yield put({
      type: VALIDATE_TOKEN_SUCCESS,
      payload: result
    });

    yield put(push(`${history.location.pathname}`));
  } catch (error) {
    yield put({ type: VALIDATE_TOKEN_FAILED, payload: error });
    yield put(push('/login'));
    cookie.remove('authenticationToken');
  }
}
export function* validateTokenPosUserSaga() {
  yield takeLatest(VALIDATE_TOKEN_REQUEST, validateTokenPosUser);
}

async function validateTokenPos() {
  const { data } = await api.post(`/check-login`);
  return data;
}

/*----------------------------------
          LOGOUT          
==================================*/
function* logout({ payload }) {
  console.log(payload, 'saga');
  try {
    yield call(logoutPos, payload);
    yield delay(500);
    yield put(push('/login'));
    yield put(setSnackbar(true, 'success', 'Logged out Successfully'));
  } catch (error) {
    console.log(error);
  }
}
export function* logoutPosUser() {
  yield takeLatest(USER_LOGOUT, logout);
}

async function logoutPos(payload) {
  const { email, refreshToken } = payload;
  cookie.remove('refreshToken');
  cookie.remove('authenticationToken');
  cookie.remove('email');
  cookie.remove('userOption');
  await api.post(`/logout`, {
    email,
    refreshToken
  });

  // localStorage.setItem('userInfo', JSON.stringify(data));
}

/*----------------------------------
         CHANGE PASSWORD        
==================================*/

export function* updateUserPassSaga() {
  yield takeEvery(CHANGE_USER_PASS_REQUEST, updatePosUserPass);
}

function* updatePosUserPass({ payload }) {
  try {
    const result = yield call(updateAsyncUserPassword, payload);
    yield put({ type: CHANGE_USER_PASS_SUCCESS, payload: result });
    yield put(setSnackbar(true, 'success', 'Password Updated Successfully'));
    // localStorage.setItem('userInfo', JSON.stringify(result));
  } catch (error) {
    yield put(setSnackbar(true, 'error', 'Something Went Wrong !!'));
  }
}

async function updateAsyncUserPassword(payload) {
  const { email, password, confirmationToken } = payload;
  const { data } = await api.post(`/change-posUserPassword`, {
    email,
    password,
    confirmationToken
  });
  return data;
}

/*----------------------------------
    SYSTEM USER >> GET ALL USERS          
==================================*/
/*   const {
    page: currentPage,
    size,
    orderBy: sortField,
    order: sortDir,
    searchValue: query
  } = payload;
  try {
    const { data } = yield api.get('/getAll-types', {
      params: {
        currentPage: currentPage + 1 || 1,
        size: size || 8,
        sortField: sortField || 'type_name',
        sortDir: sortDir || 'asc',
        query: query || ''
      }
    }); */
// export function* getAllSysUsers() {
//   yield take(GET_ALL_SYSTEM_USERS_REQUEST);
//   try {
//     // const { data } = yield call(api, url);
//     const { data } = yield call(api, `/getAll-systemUser`);
//     yield put(populateSystemUsers(data));
//   } catch (error) {
//     yield put(populateSystemUsersFailed(error));
//     yield put(setSnackbar(true, 'error', 'Something Went Wrong !!'));
//   }
// }
export function* getAllSysUsers() {
  yield takeLatest(GET_ALL_SYSTEM_USERS_REQUEST, fetchProductSys);
}
function* fetchProductSys(payload) {
  const {
    page: currentPage,
    size,
    orderBy: sortField,
    order: sortDir,
    searchValue: query
  } = payload;
  try {
    const { data } = yield api.get('/getAll-systemUser', {
      params: {
        currentPage: currentPage + 1 || 1,
        size: size || 8,
        sortField: sortField || 'user_name',
        sortDir: sortDir || 'asc',
        query: query || ''
      }
    });
    yield delay(400);
    yield put(populateSystemUsers(data));
  } catch (err) {
    yield put(populateSystemUsersFailed(err));
    if (err.response.status !== 404) {
      yield put(setSnackbar(true, 'error', err.message));
    }
  }
}
/*----------------------------------
    SYSTEM USER >> ADD USER          
==================================*/
export function* addSystemUserSaga() {
  yield takeLatest(ADD_SYSTEM_USERS_REQUEST, systemUser);
}

function* systemUser({ payload }) {
  try {
    const result = yield call(sysUserAsync, payload);
    yield put({ type: ADD_SYSTEM_USERS_SUCCESS, payload: result });
    yield put(setSnackbar(true, 'success', 'User Added Successfully'));
    yield put({ type: GET_ALL_SYSTEM_USERS_REQUEST });
    yield call(getAllSysUsers);
  } catch (error) {
    yield put(addSystemUserFailed(error));
    yield put(setSnackbar(true, 'error', 'Something Went Wrong !!'));
  }
}

async function sysUserAsync({
  sys_rid,
  first_name,
  last_name,
  user_name,
  user_pin,
  timezone_language,
  random_password,
  timezone,
  choose_language,
  phone_number,
  display_name,
  email,
  user_role,
  password,
  confirm_password,
  user_outlets,
  active,
  image_url,
  image_id,
  file,
  name
}) {
  const formData = new FormData();
  const { data } = await api.post(`/save-systemUser`, {
    sys_rid,
    first_name,
    last_name,
    user_name,
    user_pin,
    timezone_language,
    random_password,
    timezone,
    choose_language,
    phone_number,
    display_name,
    email,
    user_role,
    password,
    confirm_password,
    user_outlets,
    active,
    image_url,
    image_id
  });
  formData.append('id', data.sys_rid);
  formData.append('name', name);
  formData.append('file', file);

  if (file) {
    await api.put('/image-upload', formData, {
      headers: {
        'content-type': 'multipart/form-data'
      },
      onUploadProgress: (progressEvent) => {
        const progress = (progressEvent.loaded / progressEvent.total) * 100;
        store.dispatch(fileUploadDone(progress));
      }
    });
  }
  return data;
}

/*----------------------------------
       SYSTEM USER >> UPDATE USER        
==================================*/

export function* updateSystemUserSaga() {
  yield takeEvery(UPDATE_SYSTEM_USER_REQUEST, updateSystemUser);
}

function* updateSystemUser({ payload }) {
  try {
    const result = yield call(updateAsyncSystemUser, payload);

    yield put({ type: UPDATE_SYSTEM_USER_SUCCESS, payload: result });
    // yield call(getAllProductBrands);
    // yield put({ type: GET_PRODUCT_BRANDS_REQUEST });
    yield put(setSnackbar(true, 'success', 'User Updated Successfully'));

    // yield call(cancelSource.cancel);
  } catch (error) {
    yield put(setSnackbar(true, 'error', 'Something Went Wrong !!'));
  }
}

async function updateAsyncSystemUser(payload) {
  const { sys_rid, entry } = payload;
  const {
    first_name,
    last_name,
    user_name,
    user_pin,
    timezone_language,
    random_password,
    timezone,
    choose_language,
    phone_number,
    display_name,
    email,
    user_role,
    password,
    confirm_password,
    user_outlets,
    active,
    image_url,
    image_id,
    file,
    name
  } = entry;
  const formData = new FormData();
  const { data } = await api.put(`/update-systemUser/${sys_rid}`, {
    sys_rid,
    first_name,
    last_name,
    user_name,
    user_pin,
    timezone_language,
    random_password,
    timezone,
    choose_language,
    phone_number,
    display_name,
    email,
    user_role,
    password,
    confirm_password,
    user_outlets,
    active,
    image_url,
    image_id
  });
  formData.append('id', data.sys_rid);
  formData.append('name', name);
  formData.append('file', file);

  if (file) {
    await api.put('/image-upload', formData, {
      headers: {
        'content-type': 'multipart/form-data'
      },
      onUploadProgress: (progressEvent) => {
        const progress = (progressEvent.loaded / progressEvent.total) * 100;
        store.dispatch(fileUploadDone(progress));
      }
    });
  }
  return data;
}

/*-------------------------------------
    SYSTEM USER >> DELETE SINGLE USER       
=====================================*/

export function* deleteSystemUserSaga() {
  while (true) {
    const { payload } = yield take(REMOVE_SYSTEM_USER_REQUEST);
    yield call(deleteSingleSystemUsers, payload.sys_rid);
    yield put({
      type: REMOVE_SYSTEM_USER_SUCCESS,
      payload: { sys_rid: payload.sys_rid }
    });
  }
}

async function deleteSingleSystemUsers(sys_rid) {
  await api.delete(`/delete-systemUser/${sys_rid}`);
}
/*---------------------------------------
  SYSTEM USER >> DELETE MULTIPLE USER       
========================================*/

export function* deleteMultipleSystemUserSaga() {
  while (true) {
    const { payload } = yield take(REMOVE_MULTIPLE_SYSTEM_USER_REQUEST);
    yield call(deleteMultipleSystemUsers, payload.sys_rid);
    yield put({
      type: REMOVE_MULTIPLE_SYSTEM_USER_SUCCESS,
      payload: { sys_rid: payload.sys_rid }
    });
  }
}

async function deleteMultipleSystemUsers(sys_rids) {
  await api.all(
    sys_rids.map((sys_rid) => api.delete(`/delete-systemUser/${sys_rid}`))
  );
}
