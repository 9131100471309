import {
  GET_SINGLE_EMAIL_TEMPLATES_REQUEST,
  GET_SINGLE_EMAIL_TEMPLATES_SUCCESS,
  GET_SINGLE_EMAIL_TEMPLATES_FAILED,
  GET_ALL_EMAIL_TEMPLATES_REQUEST,
  GET_ALL_EMAIL_TEMPLATES_SUCCESS,
  GET_ALL_EMAIL_TEMPLATES_FAILED,
  UPDATE_EMAIL_TEMPLATES_REQUEST
} from 'redux/constants/StoreSetupConstants/email.templates.constants';
/*----------------------------------
    EMAIL_TEMPLATE > GET SINGLE EMAIL_TEMPLATE         
==================================*/

export const getSingleEmailTemplate = (id) => {
  return { type: GET_SINGLE_EMAIL_TEMPLATES_REQUEST, payload: id };
};
export const populateSingleEmailTemplate = (tax_rate) => {
  return { type: GET_SINGLE_EMAIL_TEMPLATES_SUCCESS, payload: tax_rate };
};
export const populateSingleEmailTemplateFailed = (error) => {
  return { type: GET_SINGLE_EMAIL_TEMPLATES_FAILED, payload: error };
};

/*------------------------------------------
     EMAIL_TEMPLATE > GET ALL EMAIL_TEMPLATES       
  ==========================================*/

export const getAllEmailTemplates = (
  searchValue,
  page,
  size,
  order,
  orderBy
) => {
  return {
    type: GET_ALL_EMAIL_TEMPLATES_REQUEST,
    searchValue,
    page,
    size,
    order,
    orderBy
  };
};
export const populateEmailTemplates = (entries) => {
  return { type: GET_ALL_EMAIL_TEMPLATES_SUCCESS, payload: entries };
};
export const populateEmailTemplatesFailed = (error) => {
  return { type: GET_ALL_EMAIL_TEMPLATES_FAILED, payload: error };
};

/*------------------------------------------
     EMAIL_TEMPLATE > UPDATE EMAIL_TEMPLATES       
  ==========================================*/

export const updateEmailTemplate = (id, entry) => {
  return {
    type: UPDATE_EMAIL_TEMPLATES_REQUEST,
    payload: { id, entry }
  };
};
