import {
  REMOVE_MULTIPLE_PRODUCT_TAGS_FAILED,
  REMOVE_PRODUCT_TAGS_FAILED
} from 'redux/constants/ProductConstants/product.tags.constants';
import {
  GET_PRODUCT_TYPES_SUCCESS,
  GET_PRODUCT_TYPES_FAILED,
  REMOVE_MULTIPLE_PRODUCT_TYPES_SUCCESS,
  REMOVE_PRODUCT_TYPES_SUCCESS,
  ADD_PRODUCT_TYPES_SUCCESS,
  ADD_PRODUCT_TYPES_FAILED,
  UPDATE_PRODUCT_TYPES_SUCCESS,
  UPDATE_PRODUCT_TYPES_FAILED,
  GET_PRODUCT_TYPES_REQUEST,
  GET_SINGLE_PRODUCT_TYPES_SUCCESS,
  GET_SINGLE_PRODUCT_TYPES_FAILED,
  GET_SINGLE_PRODUCT_TYPES_REQUEST,
  ADD_PRODUCT_TYPES_REQUEST,
  UPDATE_PRODUCT_TYPES_REQUEST
} from 'redux/constants/ProductConstants/product.types.constants';

const initialStateValues = {
  types: [],
  type: {},
  loading: true,
  error: false,
  page: 1,
  first: true,
  last: true,
  loadingSingle: false,
  loadingButton: null
};

const productTypesReducer = (state = initialStateValues, action) => {
  const { types } = state;
  let newTypes;
  switch (action.type) {
    case GET_PRODUCT_TYPES_REQUEST:
      return {
        ...state,
        loading: true,
        types: []
      };
    case GET_PRODUCT_TYPES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        types: [...action.payload.content],
        page: action.payload.page,
        pages: action.payload.totalElements,
        first: action.payload.first,
        last: action.payload.last,
        size: action.payload.size,
        searchValue: action.payload.searchValue
      };
    case GET_PRODUCT_TYPES_FAILED:
      return {
        loading: false,
        error: action.payload,
        types: [...state.types]
      };
    case GET_SINGLE_PRODUCT_TYPES_REQUEST:
      return {
        ...state,
        type: {},
        loadingSingle: true,
        loadingButton: true
      };
    case GET_SINGLE_PRODUCT_TYPES_SUCCESS:
      return {
        ...state,
        type: action.payload,
        loadingSingle: false,
        loadingButton: false
      };
    case GET_SINGLE_PRODUCT_TYPES_FAILED:
      return {
        ...state,
        error: action.payload,
        cash_register: {},
        loading: false
      };
    case ADD_PRODUCT_TYPES_REQUEST:
      newTypes = {
        ...state,
        types: [...types],
        // loading: true,
        loadingButton: true,
        loading: true
      };
      return newTypes;
    case ADD_PRODUCT_TYPES_SUCCESS:
      console.log(action.payload, 'type red');
      newTypes = {
        ...state,
        types: [...types, action.payload],
        // loading: true,
        loadingButton: false,
        loading: true
      };

      return newTypes;
    case ADD_PRODUCT_TYPES_FAILED:
      return { types: [...state.types], error: action.payload, loading: false };
    case REMOVE_PRODUCT_TYPES_SUCCESS:
      newTypes = types.filter((type) => type.id !== action.payload.id);
      return { loading: false, types: newTypes, error: false };
    case REMOVE_PRODUCT_TAGS_FAILED:
      return {
        loading: false,
        types: [...state.types],
        error: action.payload
      };
    case REMOVE_MULTIPLE_PRODUCT_TYPES_SUCCESS:
      newTypes = types.filter((type) => !action.payload.id.includes(type.id));
      return { loading: false, types: newTypes, error: false };
    case REMOVE_MULTIPLE_PRODUCT_TAGS_FAILED:
      return {
        loading: false,
        types: [...state.types],
        error: action.payload
      };
    case UPDATE_PRODUCT_TYPES_REQUEST:
      return {
        ...state,
        loadingButton: true,
        loading: true
      };
    case UPDATE_PRODUCT_TYPES_SUCCESS:
      const {
        id,
        type_name,
        display_order,
        parent_typeId,

        image,
        created_date_time,
        last_modified_time,
        active
      } = action.payload;
      newTypes = [...types];

      const existingUser = state.types.find((type) => type.id === id);
      if (existingUser) {
        existingUser.type_name = type_name;

        existingUser.display_order = display_order;
        existingUser.parent_typeId = parent_typeId;

        existingUser.active = active;
        existingUser.image = image;
        existingUser.created_date_time = created_date_time;
        existingUser.last_modified_time = last_modified_time;
      }

      return { ...state, types: newTypes, loadingButton: false, loading: true };
    case UPDATE_PRODUCT_TYPES_FAILED:
      return {
        types: [...state.types],
        error: action.payload,
        loading: false
      };

    default:
      return state;
  }
};
export default productTypesReducer;
