import React from 'react';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as MuiSelect
} from '@material-ui/core';

const Select = (props) => {
  const {
    error = null,
    name,
    label,
    value,
    onChange,
    options,
    required,
    size,
    noneValue,
    noneText,
    shrink,
    ...others
  } = props;
  return (
    <FormControl
      size={size}
      variant="outlined"
      required={required ? true : false}
      {...(error && { error: true })}
    >
      <InputLabel shrink={shrink}>{label}</InputLabel>
      <MuiSelect
        label={label}
        name={name}
        value={value}
        onChange={onChange}
        {...others}
        displayEmpty
      >
        {/* {noneValue && (
          <MenuItem value="" disabled>
            None
          </MenuItem>
        )} */}
        {noneValue && (
          <MenuItem
            selected={noneText.selected === true ?? noneText.default === true}
            value={noneText.id}
            required
          >
            {noneText.title}
          </MenuItem>
        )}
        {options.map((item, index) => (
          <MenuItem
            selected={item.selected === true ?? item.default === true}
            key={index}
            value={item.id ?? item.tax_id}
            required
          >
            {item.title ?? `${item.tax_name}`}
          </MenuItem>
        ))}
      </MuiSelect>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export default Select;
