export const GET_PRODUCTS_REQUEST = 'GET_PRODUCTS_REQUEST';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_FAILED = 'GET_PRODUCTS_FAILED';
export const REMOVE_PRODUCTS_REQUEST = 'REMOVE_PRODUCTS_REQUEST';
export const REMOVE_PRODUCTS_SUCCESS = 'REMOVE_PRODUCTS_SUCCESS';
export const REMOVE_PRODUCTS_FAILED = 'REMOVE_PRODUCTS_FAILED';
export const REMOVE_MULTIPLE_PRODUCTS_REQUEST =
  'REMOVE_MULTIPLE_PRODUCTS_REQUEST';
export const REMOVE_MULTIPLE_PRODUCTS_SUCCESS =
  'REMOVE_MULTIPLE_PRODUCTS_SUCCESS';
export const REMOVE_MULTIPLE_PRODUCTS_FAILED =
  'REMOVE_MULTIPLE_PRODUCTS_FAILED';
export const ADD_PRODUCTS_REQUEST = 'ADD_PRODUCTS_REQUEST';
export const ADD_PRODUCTS_SUCCESS = 'ADD_PRODUCTS_SUCCESS';
export const ADD_PRODUCTS_FAILED = 'ADD_PRODUCTS_FAILED';
export const UPDATE_PRODUCTS_REQUEST = 'UPDATE_PRODUCTS_REQUEST';
export const UPDATE_PRODUCTS_SUCCESS = 'UPDATE_PRODUCTS_SUCCESS';
export const UPDATE_PRODUCTS_FAILED = 'UPDATE_PRODUCTS_FAILED';
export const GET_SINGLE_PRODUCTS_REQUEST = 'GET_SINGLE_PRODUCTS_REQUEST';
export const GET_SINGLE_PRODUCTS_SUCCESS = 'GET_SINGLE_PRODUCTS_SUCCESS';
export const GET_SINGLE_PRODUCTS_FAILED = 'GET_SINGLE_PRODUCTS_FAILED';
export const RESET_SINGLE_PRODUCT_REQUEST = 'RESET_SINGLE_PRODUCT_REQUEST';
export const RESET_SINGLE_PRODUCT_SUCCESS = 'RESET_SINGLE_PRODUCT_SUCCESS';
