import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const MuiAutocomplete = ({
  options,
  value,
  onChange,
  id,
  getOptionLabel,
  label
}) => {
  return (
    <Autocomplete
      options={options}
      getOptionLabel={getOptionLabel}
      id={id}
      value={value}
      onChange={onChange}
      size="small"
      renderInput={(params) => (
        <TextField
          variant="outlined"
          {...params}
          label={label}
          margin="normal"
        />
      )}
    />
  );
};
export default MuiAutocomplete;
