export const GET_PRODUCT_SEASONS_REQUEST = 'GET_PRODUCT_SEASONS_REQUEST';
export const GET_PRODUCT_SEASONS_SUCCESS = 'GET_PRODUCT_SEASONS_SUCCESS';
export const GET_PRODUCT_SEASONS_FAILED = 'GET_PRODUCT_SEASONS_FAILED';
export const GET_SINGLE_PRODUCT_SEASON_REQUEST =
  'GET_SINGLE_PRODUCT_SEASON_REQUEST';
export const GET_SINGLE_PRODUCT_SEASON_SUCCESS =
  'GET_SINGLE_PRODUCT_SEASON_SUCCESS';
export const GET_SINGLE_PRODUCT_SEASON_FAILED =
  'GET_SINGLE_PRODUCT_SEASON_FAILED';
export const REMOVE_PRODUCT_SEASONS_REQUEST = 'REMOVE_PRODUCT_SEASONS_REQUEST';
export const REMOVE_PRODUCT_SEASONS_SUCCESS = 'REMOVE_PRODUCT_SEASONS_SUCCESS';
export const REMOVE_PRODUCT_SEASONS_FAILED = 'REMOVE_PRODUCT_SEASONS_FAILED';
export const REMOVE_MULTIPLE_PRODUCT_SEASONS_REQUEST =
  'REMOVE_MULTIPLE_PRODUCT_SEASONS_REQUEST';
export const REMOVE_MULTIPLE_PRODUCT_SEASONS_SUCCESS =
  'REMOVE_MULTIPLE_PRODUCT_SEASONS_SUCCESS';
export const REMOVE_MULTIPLE_PRODUCT_SEASONS_FAILED =
  'REMOVE_MULTIPLE_PRODUCT_SEASONS_FAILED';
export const ADD_PRODUCT_SEASONS_REQUEST = 'ADD_PRODUCT_SEASONS_REQUEST';
export const ADD_PRODUCT_SEASONS_SUCCESS = 'ADD_PRODUCT_SEASONS_SUCCESS';
export const ADD_PRODUCT_SEASONS_FAILED = 'ADD_PRODUCT_SEASONS_FAILED';
export const UPDATE_PRODUCT_SEASONS_REQUEST = 'UPDATE_PRODUCT_SEASONS_REQUEST';
export const UPDATE_PRODUCT_SEASONS_SUCCESS = 'UPDATE_PRODUCT_SEASONS_SUCCESS';
export const UPDATE_PRODUCT_SEASONS_FAILED = 'UPDATE_PRODUCT_SEASONS_FAILED';
