import { call, put, take, takeLatest, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import {
  ADD_PURCHASE_ORDER_REQUEST,
  ADD_PURCHASE_ORDER_SUCCESS,
  GET_ALL_PURCHASE_ORDER_REQUEST,
  GET_SINGLE_PURCHASE_ORDER_REQUEST,
  REMOVE_PURCHASE_ORDER_FAILED,
  REMOVE_PURCHASE_ORDER_REQUEST,
  REMOVE_PURCHASE_ORDER_SUCCESS,
  RECEIVE_PURCHASE_ORDER_REQUEST,
  RECEIVE_PURCHASE_ORDER_SUCCESS,
  RECEIVE_ALL_PURCHASE_ORDER_REQUEST,
  RECEIVE_ALL_PURCHASE_ORDER_SUCCESS
} from 'redux/constants/InventoryConstants/inventory.receive.purchorders.constants';
import {
  addPurchaseOrderFailed,
  populatePurchaseOrders,
  populatePurchaseOrdersFailed,
  populateSinglePurchaseOrder,
  populateSinglePurchaseOrderFailed
} from 'redux/actions/InventoryActions/inventory.receive.purchorders';
import { setSnackbar } from 'redux/actions/UIComponentActions/snackbar.action';
import { push } from 'connected-react-router';
import { getSinglePurchase } from 'redux/actions/InventoryActions/inventory.purchase.action';
import { getAllPurchaseOrders } from 'redux/actions/InventoryActions/inventory.receive.purchorders';

const baseURLProd = 'http://216.10.244.34:10005/rp/api/v1';

// /*----------------------------------
//        GET SINGLE USER
// ==================================*/
export function* onFetchProductStart() {
  yield takeLatest(GET_SINGLE_PURCHASE_ORDER_REQUEST, fetchProduct);
}
function* fetchProduct({ payload }) {
  try {
    if (payload) {
      const { data } = yield axios.get(
        `${baseURLProd}/get-purchase/${payload}`
      );
      yield put(populateSinglePurchaseOrder(data));
    }
  } catch (err) {
    yield put(populateSinglePurchaseOrderFailed(err.message));
  }
}

/*----------------------------------
    SYSTEM USER >> GET ALL PURCHASE          
==================================*/

// export function* getAllPurchaseOrders() {
//   yield take(GET_ALL_PURCHASE_ORDER_REQUEST);
//   try {
//     const { data } = yield call(
//       axios,
//       `${baseURLProd}/getAll-purchaseOrderLineItems`
//     );
//     // const { data } = yield call(axios, `http://localhost:3000/taxRules`);
//     yield put(populatePurchaseOrders(data));
//   } catch (error) {
//     yield put(populatePurchaseOrdersFailed(error));
//     if (error.response.status !== 404)
//       yield put(setSnackbar(true, 'error', error.message));
//   }
// }
export function* getAllPurchaseOrderss() {
  yield takeLatest(GET_ALL_PURCHASE_ORDER_REQUEST, fetchProducts);
}
function* fetchProducts() {
  try {
    const { data } = yield call(
      axios,
      `${baseURLProd}/getAll-purchaseOrderLineItems`
    );

    yield put(populatePurchaseOrders(data));
  } catch (error) {
    yield put(populatePurchaseOrdersFailed(error));
    if (error.response.status !== 404) {
      yield put(setSnackbar(true, 'error', error.message));
    }
  }
}
/*----------------------------------
    SYSTEM USER >> ADD PURCHASE          
==================================*/
export function* addSystemUserSaga() {
  yield takeLatest(ADD_PURCHASE_ORDER_REQUEST, systemUser);
}

function* systemUser({ payload }) {
  try {
    const result = yield call(sysUserAsync, payload);
    console.log(result, 'result');
    yield put({ type: ADD_PURCHASE_ORDER_SUCCESS, payload: result });
    yield put(push('/inventory/purchases'));
    yield put(setSnackbar(true, 'success', 'Order Added Successfully'));
  } catch (error) {
    yield put(addPurchaseOrderFailed(error));
    if (error.response.status !== 404)
      yield put(setSnackbar(true, 'error', 'Something Went Wrong !!'));
  }
}

async function sysUserAsync(payload) {
  const {
    purchase_id,
    outlet_id,
    outlet_name,
    po_number,
    po_date,
    due_date,
    supplier_id,
    supplier_name,
    ref_number,
    status,
    status_name,
    tax_inclusive,
    discount_is_as_percentage,
    discount_value,
    total_discount,
    total_tax,
    total_shipping_cost,
    shipping_tax_id,
    shipping_tax_rate,
    shipping_tax_amount,
    rounding_amount,
    sub_total,
    net_amount,
    note,
    created_date_time,
    last_modified_time,
    purchase_order_line_items,
    pos_rid,
    cannot_be_modified
  } = payload;

  const { data } = await axios.post(`${baseURLProd}/save-purchase`, {
    purchase_id,
    outlet_id,
    outlet_name,
    po_number,
    po_date,
    due_date,
    supplier_id,
    supplier_name,
    ref_number,
    status,
    status_name,
    tax_inclusive,
    discount_is_as_percentage,
    discount_value,
    total_discount,
    total_tax,
    total_shipping_cost,
    shipping_tax_id,
    shipping_tax_rate,
    shipping_tax_amount,
    rounding_amount,
    sub_total,
    net_amount,
    note,
    created_date_time,
    last_modified_time,
    purchase_order_line_items,
    pos_rid,
    cannot_be_modified
  });
  return data;
}

/*----------------------------------
       RECEIVE SINGLE PO        
==================================*/

export function* receiveSinglePOSaga() {
  yield takeEvery(RECEIVE_PURCHASE_ORDER_REQUEST, receiveSinglePO);
}

function* receiveSinglePO({ payload }) {
  try {
    const result = yield call(receiveSinglePOAsync, payload);

    yield put({ type: RECEIVE_PURCHASE_ORDER_SUCCESS, payload: result });
    console.log(result.purchase_id, 'result sagaMiddleware');
    yield put(getSinglePurchase(result.purchase_id));
    yield put(getAllPurchaseOrders());
    yield put(
      setSnackbar(true, 'success', 'PurchaseOrder Updated Successfully')
    );
  } catch (error) {
    if (error.response.status !== 404)
      yield put(setSnackbar(true, 'error', 'Something Went Wrong !!'));
  }
}
async function receiveSinglePOAsync(payload) {
  const { purch_orderlineitem_id, entry } = payload;
  const { updated_receivedQty } = entry;
  const { data } = await axios.put(
    `${baseURLProd}/receive-PurchaseOrderLineItem?id=${purch_orderlineitem_id}`,
    {
      updated_receivedQty
    }
  );

  return data;
}
/*----------------------------------
       RECEIVE ALL PO        
==================================*/

export function* receiveAllPOSaga() {
  yield takeEvery(RECEIVE_ALL_PURCHASE_ORDER_REQUEST, receiveAllPO);
}

function* receiveAllPO({ payload }) {
  try {
    const result = yield call(receiveAllPOAsync, payload);
    console.log(result, 'result user update');
    yield put({ type: RECEIVE_ALL_PURCHASE_ORDER_SUCCESS, payload: result });
    console.log(result, 'result sagaMiddleware');
    yield put(
      setSnackbar(true, 'success', 'PurchaseOrder Updated Successfully')
    );
    yield put(getSinglePurchase(payload.purchase_id));
    yield put(getAllPurchaseOrders());
  } catch (error) {
    if (error.response.status !== 404)
      yield put(setSnackbar(true, 'error', 'Something Went Wrong !!'));
  }
}
async function receiveAllPOAsync(payload) {
  const { purchase_id } = payload;
  const { data } = await axios.put(
    `${baseURLProd}/receiveAll-PurchaseOrderLineItem?purchase_id=${purchase_id}`
  );
  return data;
}

/*-----------------------------------------
    TAX RATE >> DELETE SINGLE TAX RATE       
=========================================*/

export function* deleteSystemUserSaga() {
  while (true) {
    try {
      const { payload } = yield take(REMOVE_PURCHASE_ORDER_REQUEST);
      yield call(deleteSingleSystemUsers, payload.purch_orderlineitem_id);
      yield put({
        type: REMOVE_PURCHASE_ORDER_SUCCESS,
        payload: { purch_orderlineitem_id: payload.purch_orderlineitem_id }
      });
      yield put(
        setSnackbar(true, 'error', 'Purchase Order Delete Successfully')
      );
    } catch (error) {
      yield put({ type: REMOVE_PURCHASE_ORDER_FAILED, payload: error });
      yield put(setSnackbar(true, 'error', 'Something Went Wrong'));
    }
  }
}

async function deleteSingleSystemUsers(purch_orderlineitem_id) {
  await axios.delete(
    `${baseURLProd}/delete-purchaseOrderLineItem/${purch_orderlineitem_id}`
  );
}
