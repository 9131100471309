import {
  call,
  put,
  delay,
  take,
  takeLatest,
  takeEvery
} from 'redux-saga/effects';
import {
  GET_PRODUCT_BRANDS_REQUEST,
  REMOVE_MULTIPLE_PRODUCT_BRANDS_REQUEST,
  REMOVE_MULTIPLE_PRODUCT_BRANDS_SUCCESS,
  REMOVE_PRODUCT_BRANDS_REQUEST,
  REMOVE_PRODUCT_BRANDS_SUCCESS,
  ADD_PRODUCT_BRANDS_REQUEST,
  ADD_PRODUCT_BRANDS_SUCCESS,
  UPDATE_PRODUCT_BRANDS_REQUEST,
  UPDATE_PRODUCT_BRANDS_SUCCESS,
  UPDATE_PRODUCT_BRANDS_FAILED,
  REMOVE_PRODUCT_BRANDS_FAILED,
  REMOVE_MULTIPLE_PRODUCT_BRANDS_FAILED
} from 'redux/constants/ProductConstants/product.brands.constants';

import {
  populateProductBrands,
  populateProductBrandsFailed,
  addProductBrandFailed
} from 'redux/actions/ProductActions/product.brands.actions';
import { setSnackbar } from 'redux/actions/UIComponentActions/snackbar.action';
import { closeDrawer } from 'redux/actions/UIComponentActions/drawer.actions';
import api from 'utils/api';

/*----------------------------------
      GET ALL PRODUCT BRANDS        
==================================*/
// export function* getAllProductBrands() {
//   yield take(GET_PRODUCT_BRANDS_REQUEST);
//   try {
//     const result = yield call(api, `/getAll-brands`);

//     // console.log(data);

//     yield put(populateProductBrands(result.data));
//     // throw new Error('error');
//   } catch (error) {
//     error.response.data.status = 500;
//     error.response.status = 500;

//     yield put(populateProductBrandsFailed(error));
//     console.log(error.response, 'error');
//   }
// }

export function* getAllProductBrands() {
  yield takeLatest(GET_PRODUCT_BRANDS_REQUEST, fetchProducts);
}
function* fetchProducts(payload) {
  const {
    page: currentPage,
    size,
    orderBy: sortField,
    order: sortDir,
    searchValue: query
  } = payload;
  try {
    const { data } = yield api.get('/getAll-brands', {
      params: {
        currentPage: currentPage + 1 || 1,
        size: size || 8,
        sortField: sortField || 'brand_name',
        sortDir: sortDir || 'asc',
        query: query || ''
      }
    });
    yield delay(400);
    yield put(populateProductBrands(data));
  } catch (err) {
    yield put(populateProductBrandsFailed(err));
    if (err.response.status !== 404) {
      yield put(setSnackbar(true, 'error', err.message));
    }
  }
}
/* 

*/

/*----------------------------------
         DELETE SINGLE ENTRY       
==================================*/
// export function* deleteEntrySaga() {
//   while (true) {
//     const { payload } = yield take(REMOVE_PRODUCT_BRANDS_REQUEST);
//     yield call(deleteEntries, payload.id);
//     yield put({
//       type: REMOVE_PRODUCT_BRANDS_SUCCESS,
//       payload: { id: payload.id }
//     });
//     yield put({ type: GET_PRODUCT_BRANDS_REQUEST });
//     yield call(getAllProductBrands);
//   }
// }

// async function deleteEntries(id) {
//   await api.delete(`/delete-brands/${id}`);
// }
function* deleteProduct(action) {
  const { id } = action.payload;
  try {
    yield call(deleteProductDB, id);
    yield put({
      type: REMOVE_PRODUCT_BRANDS_SUCCESS,
      payload: { id: id }
    });
    yield put(closeDrawer({ modalProps: { open: false } }));
    yield put(setSnackbar(true, 'error', 'Product Brand Deleted successfully'));
    yield put({ type: GET_PRODUCT_BRANDS_REQUEST });
    yield call(getAllProductBrands);
  } catch (error) {
    yield put({
      type: REMOVE_PRODUCT_BRANDS_FAILED,
      payload: error?.response?.data?.errorMessage
    });
    yield put(setSnackbar(true, 'error', error?.response?.data?.errorMessage));
    yield put({ type: GET_PRODUCT_BRANDS_REQUEST });
    yield call(getAllProductBrands);
  }
}
const deleteProductDB = async (id) => {
  return await api.delete(`/delete-brands/${id}`);
};
// watcher saga
export function* deleteProductSaga() {
  yield takeEvery(REMOVE_PRODUCT_BRANDS_REQUEST, deleteProduct);
}
/*----------------------------------
         DELETE MULTIPLE ENTRY       
==================================*/
// export function* deleteMultipleEntrySaga() {
//   while (true) {
//     const { payload } = yield take(REMOVE_MULTIPLE_PRODUCT_BRANDS_REQUEST);
//     yield call(deleteMultipleEntries, payload.id);
//     yield put({
//       type: REMOVE_MULTIPLE_PRODUCT_BRANDS_SUCCESS,
//       payload: { id: payload.id }
//     });
//     yield put({ type: GET_PRODUCT_BRANDS_REQUEST });
//     yield call(getAllProductBrands);
//   }
// }

// async function deleteMultipleEntries(ids) {
//   await Promise.all(ids.map((id) => api.delete(`/delete-brands/${id}`)));
// }
function* deleteProductBrandMultiple(action) {
  const ids = action?.paylaod?.id;
  try {
    yield call(deleteProductBrandMultiDB, action?.payload?.id);
    yield put({
      type: REMOVE_MULTIPLE_PRODUCT_BRANDS_SUCCESS,
      payload: { id: action?.payload?.id }
    });
    yield put(closeDrawer({ modalProps: { open: false } }));
    yield put(
      setSnackbar(true, 'error', 'Product Brands Deleted successfully')
    );
    yield put({ type: REMOVE_MULTIPLE_PRODUCT_BRANDS_REQUEST });
    yield call(getAllProductBrands);
  } catch (error) {
    yield put({
      type: REMOVE_MULTIPLE_PRODUCT_BRANDS_FAILED,
      payload: 'Some Product Brands were not deleted'
    });
    yield put(
      setSnackbar(
        true,
        'error',
        `Some Product Brands were not deleted with ids :
      ${ids}
      `
      )
    );
    yield put({ type: GET_PRODUCT_BRANDS_REQUEST });
    yield call(getAllProductBrands);
  }
}
const deleteProductBrandMultiDB = async (ids) => {
  return await Promise.all(ids.map((id) => api.delete(`/delete-brands/${id}`)));
};
// watcher saga
export function* deleteProductBrandSaga() {
  yield takeEvery(
    REMOVE_MULTIPLE_PRODUCT_BRANDS_REQUEST,
    deleteProductBrandMultiple
  );
}
/*----------------------------------
         ADD PRODUCT BRAND       
==================================*/
export function* addEntrySaga() {
  yield takeLatest(ADD_PRODUCT_BRANDS_REQUEST, addEntryToDb);
}

function* addEntryToDb({ payload }) {
  try {
    const res = yield call(addEntry, payload);
    yield put({ type: ADD_PRODUCT_BRANDS_SUCCESS, payload: res });
    yield put(closeDrawer({ modalProps: { open: false } }));
    yield put(setSnackbar(true, 'success', 'Product Brand added Successfully'));
    yield put({ type: GET_PRODUCT_BRANDS_REQUEST });
    yield call(getAllProductBrands);
  } catch (error) {
    yield put(setSnackbar(true, 'error', 'Something Went Wrong'));
    yield put(addProductBrandFailed(error));
  }
}

async function addEntry({
  id,
  brand_name,
  created_date_time,
  last_modified_time,
  active
}) {
  const { data } = await api.post(`/save-brands`, {
    id,
    brand_name,
    created_date_time,
    last_modified_time,
    active
  });
  return data;
}

/*----------------------------------
       UPDATE PRODUCT BRAND        
==================================*/

export function* updateEntrySaga() {
  yield takeEvery(UPDATE_PRODUCT_BRANDS_REQUEST, updateEntryToDb);
}

function* updateEntryToDb({ payload }) {
  try {
    const result = yield call(updateEntry, payload);
    yield put({ type: UPDATE_PRODUCT_BRANDS_SUCCESS, payload: result });
    yield put(
      setSnackbar(true, 'success', 'Product Brand updated Successfully')
    );
    yield put({ type: GET_PRODUCT_BRANDS_REQUEST });
    yield call(getAllProductBrands);
  } catch (error) {
    yield put({ type: UPDATE_PRODUCT_BRANDS_FAILED, payload: error });
    yield put(setSnackbar(true, 'error', 'Something Went Wrong !!!!'));
  }
}

async function updateEntry(payload) {
  const { id, entry } = payload;
  const {
    brand_name,

    created_date_time,
    last_modified_time,
    active
  } = entry;
  const { data } = await api.put(`/update-brands/${id}`, {
    id,
    brand_name,

    created_date_time,
    last_modified_time,
    active
  });
  return data;
}
