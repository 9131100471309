import {
  GET_ALL_OUTLETS_SUCCESS,
  GET_ALL_OUTLETS_FAILED,
  REMOVE_OUTLETS_SUCCESS,
  ADD_OUTLETS_SUCCESS,
  ADD_OUTLETS_FAILED,
  UPDATE_OUTLETS_SUCCESS,
  GET_SINGLE_OUTLETS_FAILED,
  GET_SINGLE_OUTLETS_SUCCESS,
  UPDATE_OUTLETS_FAILED,
  GET_ALL_OUTLETS_REQUEST,
  GET_SINGLE_OUTLETS_REQUEST
} from 'redux/constants/StoreSetupConstants/outlets.registers.constants';
const OutletsRegistersReducer = (
  state = {
    outlets: [],
    outlet: {},
    loading: true,
    error: false,
    page: 1,
    first: true,
    last: true
  },
  action
) => {
  let { outlets } = state;
  let newOutlets;

  switch (action.type) {
    case GET_ALL_OUTLETS_REQUEST:
      return {
        ...state,
        outlets: [],
        loading: true
      };
    case GET_ALL_OUTLETS_SUCCESS:
      return {
        ...state,
        loading: false,
        outlets: [...action.payload.content],
        page: action.payload.page,
        pages: action.payload.totalElements,
        first: action.payload.first,
        last: action.payload.last,
        size: action.payload.size,
        searchValue: action.payload.searchValue
      };
    case GET_ALL_OUTLETS_FAILED:
      return {
        ...state,
        error: action.payload,
        outlets: [...state.outlets],
        loading: false
      };
    case GET_SINGLE_OUTLETS_REQUEST:
      return {
        ...state,
        outlet: {},
        loading: true
      };
    case GET_SINGLE_OUTLETS_SUCCESS:
      return {
        ...state,
        outlet: action.payload,
        loading: false
      };
    case GET_SINGLE_OUTLETS_FAILED:
      return {
        ...state,
        error: action.payload,
        outlet: {},
        loading: false
      };
    case ADD_OUTLETS_SUCCESS:
      newOutlets = {
        ...state,
        outlets: [...outlets, action.payload],
        loading: true
      };
      console.log(action.payload, 'reducer success');
      return newOutlets;
    case ADD_OUTLETS_FAILED:
      console.log(action.payload, 'reducer faild');

      return {
        error: action.payload,
        outlets: [...state.outlets],
        loading: false
      };
    case REMOVE_OUTLETS_SUCCESS:
      newOutlets = outlets.filter(
        (outlet) => outlet.outlet_id !== action.payload.outlet_id
      );
      return { loading: false, outlets: newOutlets, error: false };

    case UPDATE_OUTLETS_SUCCESS:
      const {
        outlet_id,
        outlet_name,
        tax_id,
        email,
        phone,
        purch_orderno,
        purch_prefix,
        purch_suffix,
        inven_transferno,
        inven_prefix,
        inven_suffix,
        inven_countno,
        inven_count_prefix,
        inven_count_suffix,
        cash_register,
        outlet_hours,
        outlet_address,
        active,
        pos_rid
      } = action.payload;

      newOutlets = [...outlets];
      const existingOutlet = state.outlets.find(
        (outlet) => outlet.outlet_id === outlet_id
      );
      if (existingOutlet) {
        existingOutlet.outlet_name = outlet_name;
        existingOutlet.tax_id = tax_id;
        existingOutlet.email = email;
        existingOutlet.phone = phone;
        existingOutlet.purch_orderno = purch_orderno;
        existingOutlet.purch_prefix = purch_prefix;
        existingOutlet.purch_suffix = purch_suffix;
        existingOutlet.inven_transferno = inven_transferno;
        existingOutlet.inven_prefix = inven_prefix;
        existingOutlet.inven_suffix = inven_suffix;
        existingOutlet.inven_countno = inven_countno;
        existingOutlet.inven_count_prefix = inven_count_prefix;
        existingOutlet.inven_count_suffix = inven_count_suffix;
        existingOutlet.cash_register = cash_register;
        existingOutlet.outlet_hours = outlet_hours;
        existingOutlet.outlet_address = outlet_address;
        existingOutlet.active = active;
        existingOutlet.pos_rid = pos_rid;
      }
      console.log(newOutlets, 'red');
      return { outlets: newOutlets };
    case UPDATE_OUTLETS_FAILED: {
      return { error: action.payload, outlets: [...state.outlets] };
    }

    default:
      return state;
  }
};
export default OutletsRegistersReducer;
