import {
  GET_ALL_PAYMENT_TYPES_SUCCESS,
  GET_ALL_PAYMENT_TYPES_FAILED,
  REMOVE_PAYMENT_TYPES_SUCCESS,
  ADD_PAYMENT_TYPES_SUCCESS,
  ADD_PAYMENT_TYPES_FAILED,
  UPDATE_PAYMENT_TYPES_SUCCESS,
  GET_SINGLE_PAYMENT_TYPES_FAILED,
  GET_SINGLE_PAYMENT_TYPES_SUCCESS,
  UPDATE_PAYMENT_TYPES_FAILED,
  GET_ALL_PAYMENT_TYPES_REQUEST,
  GET_SINGLE_PAYMENT_TYPES_REQUEST,
  ADD_PAYMENT_TYPES_REQUEST
} from 'redux/constants/StoreSetupConstants/payment.types.constants';
const PaymentTypesReducer = (
  state = {
    payment_types: [],
    payment_type: {},
    loading: true,
    error: false,
    page: 1,
    first: true,
    last: true
  },
  action
) => {
  let { payment_types } = state;
  let newPaymentTypes;

  switch (action.type) {
    case GET_ALL_PAYMENT_TYPES_REQUEST:
      return {
        ...state,
        payment_types: [],
        loading: true
      };
    case GET_ALL_PAYMENT_TYPES_SUCCESS:
      return {
        ...state,
        payment_types: [...action.payload.content],
        loading: false,
        error: false,
        page: action.payload.page,
        pages: action.payload.totalElements,
        first: action.payload.first,
        last: action.payload.last,
        size: action.payload.size,
        searchValue: action.payload.searchValue
      };
    case GET_ALL_PAYMENT_TYPES_FAILED:
      return {
        ...state,
        error: action.payload,
        payment_types: [...state.payment_types],
        loading: false
      };
    case GET_SINGLE_PAYMENT_TYPES_REQUEST:
      return {
        ...state,
        payment_type: {},
        loading: true
      };

    case GET_SINGLE_PAYMENT_TYPES_SUCCESS:
      return {
        ...state,
        payment_type: action.payload,
        loading: false
      };
    case GET_SINGLE_PAYMENT_TYPES_FAILED:
      return {
        ...state,
        error: action.payload,
        payment_type: {},
        loading: false
      };
    case ADD_PAYMENT_TYPES_REQUEST:
      return {
        ...state,
        payment_types: [...payment_types],
        loading: true
      };
    case ADD_PAYMENT_TYPES_SUCCESS:
      newPaymentTypes = {
        ...state,
        loading: true,
        payment_types: [...payment_types, action.payload]
      };
      return newPaymentTypes;
    case ADD_PAYMENT_TYPES_FAILED:
      return {
        error: action.payload,
        payment_types: [...state.payment_types],
        loading: false
      };
    case REMOVE_PAYMENT_TYPES_SUCCESS:
      newPaymentTypes = payment_types.filter(
        (payment_type) => payment_type.payment_id !== action.payload.payment_id
      );
      return { loading: false, payment_types: newPaymentTypes, error: false };

    case UPDATE_PAYMENT_TYPES_SUCCESS:
      const {
        payment_id,
        payment_name,
        payment_type_name,
        is_payment_type_avail_at_all_reg,
        canbe_configered,
        configuration_details,
        // default,
        active,
        register_payment_options,
        configered,
        customerAccount,
        pos_rid
      } = action.payload;

      newPaymentTypes = [...payment_types];
      const existingPaymentType = state.payment_types.find(
        (payment_type) => payment_type.payment_id === payment_id
      );
      if (existingPaymentType) {
        existingPaymentType.payment_name = payment_name;
        existingPaymentType.payment_type_name = payment_type_name;
        existingPaymentType.is_payment_type_avail_at_all_reg =
          is_payment_type_avail_at_all_reg;
        existingPaymentType.default = action.payload.default;
        existingPaymentType.active = active;
        existingPaymentType.register_payment_options = register_payment_options;
        existingPaymentType.canbe_configered = canbe_configered;
        existingPaymentType.configuration_details = configuration_details;
        existingPaymentType.configered = configered;
        existingPaymentType.customerAccount = customerAccount;
        existingPaymentType.pos_rid = pos_rid;
      }
      return { payment_types: newPaymentTypes };
    case UPDATE_PAYMENT_TYPES_FAILED: {
      return { error: action.payload, payment_types: [...state.payment_types] };
    }

    default:
      return state;
  }
};
export default PaymentTypesReducer;
