import {
  GET_PRODUCT_TAGS_REQUEST,
  GET_PRODUCT_TAGS_SUCCESS,
  GET_PRODUCT_TAGS_FAILED,
  REMOVE_MULTIPLE_PRODUCT_TAGS_REQUEST,
  REMOVE_PRODUCT_TAGS_REQUEST,
  ADD_PRODUCT_TAGS_REQUEST,
  UPDATE_PRODUCT_TAGS_REQUEST,
  ADD_PRODUCT_TAGS_FAILED
} from 'redux/constants/ProductConstants/product.tags.constants';

export const addProductTag = (payload) => {
  return { type: ADD_PRODUCT_TAGS_REQUEST, payload };
};
export const addProductTagError = (error) => {
  return { type: ADD_PRODUCT_TAGS_FAILED, payload: error };
};
export const removeProductTag = (id) => {
  return { type: REMOVE_PRODUCT_TAGS_REQUEST, payload: { id } };
};
export const removeMultipleProductTag = (id) => {
  return { type: REMOVE_MULTIPLE_PRODUCT_TAGS_REQUEST, payload: { id } };
};

export const updateProductTag = (id, entry) => {
  return { type: UPDATE_PRODUCT_TAGS_REQUEST, payload: { id, entry } };
};

export const getAllProductTags = (searchValue, page, size, order, orderBy) => {
  return {
    type: GET_PRODUCT_TAGS_REQUEST,
    searchValue,
    page,
    size,
    order,
    orderBy
  };
};

export const populateProductTags = (entries) => {
  return { type: GET_PRODUCT_TAGS_SUCCESS, payload: entries };
};
export const populateProductTagsError = (error) => {
  return { type: GET_PRODUCT_TAGS_FAILED, payload: error };
};
