import {
  GET_SINGLE_PAYMENT_TYPES_REQUEST,
  GET_SINGLE_PAYMENT_TYPES_SUCCESS,
  GET_SINGLE_PAYMENT_TYPES_FAILED,
  GET_ALL_PAYMENT_TYPES_REQUEST,
  GET_ALL_PAYMENT_TYPES_SUCCESS,
  ADD_PAYMENT_TYPES_REQUEST,
  ADD_PAYMENT_TYPES_FAILED,
  REMOVE_PAYMENT_TYPES_REQUEST,
  GET_ALL_PAYMENT_TYPES_FAILED,
  UPDATE_PAYMENT_TYPES_REQUEST
} from 'redux/constants/StoreSetupConstants/payment.types.constants';

/*----------------------------------
    PAYMENT_TYPE > GET SINGLE PAYMENT_TYPE         
==================================*/

export const getSinglePaymentType = (payment_id) => {
  return { type: GET_SINGLE_PAYMENT_TYPES_REQUEST, payload: payment_id };
};
export const populateSinglePaymentType = (tax_rate) => {
  return { type: GET_SINGLE_PAYMENT_TYPES_SUCCESS, payload: tax_rate };
};
export const populateSinglePaymentTypeFailed = (error) => {
  return { type: GET_SINGLE_PAYMENT_TYPES_FAILED, payload: error };
};

/*----------------------------------
   PAYMENT_TYPE > GET ALL PAYMENT_TYPES       
==================================*/

export const getAllPaymentTypes = (searchValue, page, size, order, orderBy) => {
  return {
    type: GET_ALL_PAYMENT_TYPES_REQUEST,
    searchValue,
    page,
    size,
    order,
    orderBy
  };
};
export const populatePaymentTypes = (entries) => {
  console.log('entries', entries);
  return { type: GET_ALL_PAYMENT_TYPES_SUCCESS, payload: entries };
};
export const populatePaymentTypesFailed = (error) => {
  console.log(error.message);
  return { type: GET_ALL_PAYMENT_TYPES_FAILED, payload: error };
};

/*----------------------------------
   PAYMENT_TYPE > ADD PAYMENT_TYPES       
==================================*/

export const addPaymentType = (payload) => {
  return { type: ADD_PAYMENT_TYPES_REQUEST, payload };
};
export const addPaymentTypeFailed = (error) => {
  return { type: ADD_PAYMENT_TYPES_FAILED, payload: error };
};

/*----------------------------------
   PAYMENT_TYPE > UPDATE PAYMENT_TYPES       
==================================*/

export const updatePaymentType = (payment_id, entry) => {
  return { type: UPDATE_PAYMENT_TYPES_REQUEST, payload: { payment_id, entry } };
};
/*----------------------------------
   PAYMENT_TYPE > REMOVE PAYMENT_TYPES       
==================================*/

export const removePaymentType = (payment_id) => {
  return { type: REMOVE_PAYMENT_TYPES_REQUEST, payload: { payment_id } };
};
