import { makeStyles } from '@material-ui/core';
// import { sideMenuDrawer } from "colorScheme";
export const width = window.innerWidth;
export const breakpoint = 778;
export const useStyles = makeStyles((theme) => ({
  root: {
    '&$selected': {
      // backgroundColor: `${sideMenuDrawer.selectedColor.backgroundColor}`,
      backgroundColor: '#126391 !important'
    }
  },

  selectedIcon: {
    '& span, & svg': {
      fontSize: 22,
      // color: `${sideMenuDrawer.iconSelectedColor.color}`,
      color: '#EB3732 !important'
    }
  },
  item: {},
  selected: {},
  nested: {
    paddingLeft: theme.spacing(4)
  },

  nestedIcons: {
    color: '#FFFFFF !important'
  },
  active: {
    // backgroundColor: sideMenuDrawer.active.backgroundColor,
    backgroundColor: '#167BB6 !important',
    fontWeight: '600'
  },
  single: {
    // backgroundColor: `${sideMenuDrawer.active.single.backgroundColor}`,
    backgroundColor: '#126391 !important'
  },
  primary: {
    fontSize: 13
  },
  primaryMain: {
    fontSize: 14
  },
  smallIcon: {
    '& span, & svg': {
      fontSize: 18,
      // color: sideMenuDrawer.iconColor,
      color: '#FFFFFF !important'
    }
  }
}));
