import {
  GET_SINGLE_PURCHASES_REQUEST,
  GET_SINGLE_PURCHASES_SUCCESS,
  GET_SINGLE_PURCHASES_FAILED,
  GET_ALL_PURCHASES_REQUEST,
  GET_ALL_PURCHASES_SUCCESS,
  GET_ALL_PURCHASES_FAILED,
  ADD_PURCHASES_REQUEST,
  ADD_PURCHASES_FAILED,
  UPDATE_PURCHASES_REQUEST,
  REMOVE_PURCHASES_REQUEST,
  PURCHASE_RESET
} from 'redux/constants/InventoryConstants/inventory.purchases.constants';
/*----------------------------------
    PURCHASE > GET SINGLE PURCHASE         
==================================*/

export const getSinglePurchase = (purchase_id) => {
  return { type: GET_SINGLE_PURCHASES_REQUEST, payload: purchase_id };
};
export const populateSinglePurchase = (purchase) => {
  return { type: GET_SINGLE_PURCHASES_SUCCESS, payload: purchase };
};
export const populateSinglePurchaseFailed = (error) => {
  return { type: GET_SINGLE_PURCHASES_FAILED, payload: error };
};

/*------------------------------------------
     PURCHASE > GET ALL PURCHASES       
  ==========================================*/

export const getAllPurchases = (searchValue, page, size, order, orderBy) => {
  return {
    type: GET_ALL_PURCHASES_REQUEST,
    searchValue,
    page,
    size,
    order,
    orderBy
  };
};
export const populatePurchases = (entries) => {
  return { type: GET_ALL_PURCHASES_SUCCESS, payload: entries };
};
export const populatePurchasesFailed = (error) => {
  return { type: GET_ALL_PURCHASES_FAILED, payload: error };
};

/*-------------------------------------
     PURCHASE > ADD PURCHASES       
  =====================================*/

export const addPurchase = (payload) => {
  return { type: ADD_PURCHASES_REQUEST, payload };
};
export const addPurchaseFailed = (error) => {
  return { type: ADD_PURCHASES_FAILED, payload: error };
};

/*------------------------------------------
     PURCHASE > UPDATE PURCHASES       
  ==========================================*/

export const updatePurchase = (id, entry) => {
  return {
    type: UPDATE_PURCHASES_REQUEST,
    payload: { id, entry }
  };
};
/*----------------------------------------
     PURCHASE > REMOVE PURCHASES       
  =========================================*/

export const removePurchase = (id) => {
  return { type: REMOVE_PURCHASES_REQUEST, payload: { id } };
};
