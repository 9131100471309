import {
  GET_PRODUCT_BRANDS_REQUEST,
  GET_PRODUCT_BRANDS_SUCCESS,
  GET_PRODUCT_BRANDS_FAILED,
  REMOVE_MULTIPLE_PRODUCT_BRANDS_REQUEST,
  REMOVE_PRODUCT_BRANDS_REQUEST,
  ADD_PRODUCT_BRANDS_REQUEST,
  UPDATE_PRODUCT_BRANDS_REQUEST,
  ADD_PRODUCT_BRANDS_FAILED
} from 'redux/constants/ProductConstants/product.brands.constants';

export const addProductBrand = (payload) => {
  return { type: ADD_PRODUCT_BRANDS_REQUEST, payload };
};
export const addProductBrandFailed = (error) => {
  return { type: ADD_PRODUCT_BRANDS_FAILED, payload: error };
};
export const removeProductBrand = (id) => {
  return { type: REMOVE_PRODUCT_BRANDS_REQUEST, payload: { id } };
};
export const removeMultipleProductBrand = (id) => {
  return { type: REMOVE_MULTIPLE_PRODUCT_BRANDS_REQUEST, payload: { id } };
};

export const updateProductBrand = (id, entry) => {
  return { type: UPDATE_PRODUCT_BRANDS_REQUEST, payload: { id, entry } };
};

export const getAllProductBrands = (
  searchValue,
  page,
  size,
  order,
  orderBy
) => {
  return {
    type: GET_PRODUCT_BRANDS_REQUEST,
    searchValue,
    page,
    size,
    order,
    orderBy
  };
};
export const populateProductBrands = (entries) => {
  return { type: GET_PRODUCT_BRANDS_SUCCESS, payload: entries };
};
export const populateProductBrandsFailed = (error) => {
  return { type: GET_PRODUCT_BRANDS_FAILED, payload: error };
};
