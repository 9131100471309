import {
  LOADING_REQUEST,
  LOADING_SUCCESS
} from 'redux/constants/UIComponentsConstants/loading.button.constants';

export const loadingButtonRequest = (payload) => {
  return { type: LOADING_REQUEST, payload };
};
export const loadingButtonDone = (payload) => {
  return { type: LOADING_SUCCESS, payload };
};
