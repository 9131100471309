import { LOCATION_CHANGE } from 'connected-react-router';
import {
  GET_SINGLE_CUSTOMER_SUCCESS,
  GET_SINGLE_CUSTOMER_FAILED,
  GET_ALL_CUSTOMERS_REQUEST,
  GET_ALL_CUSTOMERS_SUCCESS,
  GET_ALL_CUSTOMERS_FAILED,
  ADD_CUSTOMERS_SUCCESS,
  ADD_CUSTOMERS_FAILED,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_FAILED,
  REMOVE_MULTIPLE_CUSTOMER_SUCCESS,
  REMOVE_CUSTOMER_SUCCESS,
  GET_SINGLE_CUSTOMER_REQUEST,
  RESET_CUSTOMER,
  SET_CUSTOMER,
  RESET_CUSTOMER_SEARCH,
  HIDE_POPUP
} from 'redux/constants/CustomersConstants/customers.constants';
/*----------------------------------
          CUSTOMER REDUCER          
==================================*/
// export const customersReducer = (
//   state = { loading: true, error: false, customers: [], customer: {} },
//   action
// ) => {
//   const { customers } = state;
//   let newUser;
//   switch (action.type) {
//     case GET_ALL_CUSTOMERS_REQUEST:
//       return {
//         ...state,
//         customers: [],
//         loading: true
//       };
//     case GET_ALL_CUSTOMERS_SUCCESS:
//       return {
//         ...state,
//         customers: [action.payload],
//         loading: false
//       };
//     case GET_ALL_CUSTOMERS_FAILED:
//       return {
//         ...state,
//         error: action.payload,
//         customers: [...state.customers],
//         loading: false
//       };
//     case GET_SINGLE_CUSTOMER_SUCCESS:
//       return {
//         ...state,
//         customer: action.payload,
//         loading: false
//       };
//     case GET_SINGLE_CUSTOMER_FAILED:
//       return {
//         ...state,
//         error: action.payload,
//         loading: false
//       };
//     case UPDATE_CUSTOMER_SUCCESS:
//       const {
//         id,
//         first_name,
//         last_name,
//         email,
//         phone,
//         country,
//         city,
//         // password,
//         accept_terms,
//         created_date_time,
//         last_modified_time,
//         role,
//         store_setup,
//         store_feature_setup,
//         store_general_rules,
//         store_inven_notif,
//         store_web_addresses
//         // tempPassword
//       } = action.payload;
//       // const { first_name, last_name, country, city, phone, role } = entry;
//       newUser = [...customers];
//       const existingUser = state.customers.find(
//         (customer) => customer.id === id
//       );
//       if (existingUser) {
//         existingUser.first_name = first_name;
//         existingUser.last_name = last_name;
//         existingUser.country = country;
//         existingUser.city = city;
//         existingUser.created_date_time = created_date_time;
//         existingUser.last_modified_time = last_modified_time;
//         existingUser.phone = phone;
//         existingUser.accept_terms = accept_terms;
//         existingUser.email = email;
//         existingUser.role = role;
//         existingUser.store_setup = store_setup;
//         existingUser.store_feature_setup = store_feature_setup;
//         existingUser.store_general_rules = store_general_rules;
//         existingUser.store_inven_notif = store_inven_notif;
//         existingUser.store_web_addresses = store_web_addresses;
//         existingUser.store_web_addresses = store_web_addresses;
//       }

//       return { customers: newUser };
//     case UPDATE_CUSTOMER_FAILED:
//       return {
//         ...state,
//         customers: [...state.customers],
//         error: action.payload,
//         loading: false
//       };
//     default:
//       return state;
//   }
// };

/*----------------------------------
          CUSTOMER REDUCER          
==================================*/

export const customersReducer = (
  state = {
    loading: true,
    error: false,
    customers: [],
    customer: {},
    page: 1,
    first: true,
    last: true,
    customerFromSearch: null
  },
  action
) => {
  const { customers } = state;
  let newUser;
  switch (action.type) {
    case GET_ALL_CUSTOMERS_REQUEST:
      return {
        ...state,
        customers: [],
        loading: true
      };
    case GET_ALL_CUSTOMERS_SUCCESS:
      return {
        ...state,
        customers: [...action.payload.content],
        page: action.payload.page,
        pages: action.payload.totalElements,
        first: action.payload.first,
        last: action.payload.last,
        size: action.payload.size,
        searchValue: action.payload.searchValue,
        loading: false
      };
    case GET_ALL_CUSTOMERS_FAILED:
      return {
        ...state,
        error: action.payload,
        customers: [...state.customers],
        loading: false
      };
    case RESET_CUSTOMER:
      return {
        ...state,
        customer: {}
      };
    case GET_SINGLE_CUSTOMER_REQUEST:
      return {
        ...state,
        customer: {},
        loading: false
      };
    case GET_SINGLE_CUSTOMER_SUCCESS:
      return {
        ...state,
        customer: action.payload,
        loading: false
      };
    case GET_SINGLE_CUSTOMER_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case ADD_CUSTOMERS_SUCCESS:
      newUser = {
        ...state,
        customers: [...customers, action.payload],
        loading: true
      };

      return newUser;
    case ADD_CUSTOMERS_FAILED:
      return { customers: [...state.customers], error: action.payload };
    case UPDATE_CUSTOMER_SUCCESS:
      const {
        id,
        first_name,
        last_name,
        gender,
        birth_date,
        company_name,
        email,
        phone,
        primary_address,
        customer_group,
        customer_from,
        customer_fromName,
        custome_field1,
        custome_field2,
        custome_field3,
        custome_field4,
        customer_code,
        credit_limit,
        howDidYou_hearAboutusId,
        howDidYou_hearAboutus_name,
        note,
        toAllowFor_marketing,
        toAllowFor_taxExempt,
        active,
        created_date_time,
        last_modified_time,
        allow_loyalty,
        customer_outlets,
        credit_balance,
        current_loyalty_balance,
        isDefault,
        isLoyalty_welcome_emailSent,
        isOffline_customer,
        outStanding_balance,
        password,
        profile_image,
        sync_reference,
        total_loyaltySpent
      } = action.payload;
      newUser = [...customers];
      const existingUser = state.customers.find(
        (customer) => customer.id === id
      );
      if (existingUser) {
        existingUser.first_name = first_name;
        existingUser.last_name = last_name;
        existingUser.gender = gender;
        existingUser.birth_date = birth_date;
        existingUser.email = email;
        existingUser.company_name = company_name;
        existingUser.phone = phone;
        existingUser.primary_address = primary_address;
        existingUser.customer_group = customer_group;
        existingUser.customer_from = customer_from;
        existingUser.customer_fromName = customer_fromName;
        existingUser.custome_field1 = custome_field1;
        existingUser.custome_field2 = custome_field2;
        existingUser.custome_field3 = custome_field3;
        existingUser.custome_field4 = custome_field4;
        existingUser.customer_code = customer_code;
        existingUser.credit_limit = credit_limit;
        existingUser.howDidYou_hearAboutusId = howDidYou_hearAboutusId;
        existingUser.howDidYou_hearAboutus_name = howDidYou_hearAboutus_name;
        existingUser.note = note;
        existingUser.toAllowFor_marketing = toAllowFor_marketing;
        existingUser.toAllowFor_taxExempt = toAllowFor_taxExempt;
        existingUser.active = active;
        existingUser.created_date_time = created_date_time;
        existingUser.last_modified_time = last_modified_time;
        existingUser.allow_loyalty = allow_loyalty;
        existingUser.customer_outlets = customer_outlets;
        existingUser.credit_balance = credit_balance;
        existingUser.current_loyalty_balance = current_loyalty_balance;
        existingUser.isDefault = isDefault;
        existingUser.isLoyalty_welcome_emailSent = isLoyalty_welcome_emailSent;
        existingUser.isOffline_customer = isOffline_customer;
        existingUser.outStanding_balance = outStanding_balance;
        existingUser.profile_image = profile_image;
        existingUser.password = password;
        existingUser.sync_reference = sync_reference;
        existingUser.total_loyaltySpent = total_loyaltySpent;
      }
      return { customers: newUser, loading: true };
    case UPDATE_CUSTOMER_FAILED:
      return { customers: [...state.customers], error: action.payload };
    case REMOVE_CUSTOMER_SUCCESS:
      newUser = customers.filter(
        (customer) => customer.id !== action.payload.id
      );
      return { loading: false, customers: newUser, error: false };
    case REMOVE_MULTIPLE_CUSTOMER_SUCCESS:
      newUser = customers.filter(
        (customer) => !action.payload.id.includes(customer.id)
      );
      return { loading: false, customers: newUser, error: false };
    case SET_CUSTOMER:
      return { ...state, customerFromSearch: action.payload };
    case RESET_CUSTOMER_SEARCH:
      return {
        ...state,
        customerFromSearch: null
      };
    case HIDE_POPUP:
      return {
        ...state,
        popup: false
      };
    default:
      return state;
  }
};
