import {
  call,
  put,
  take,
  takeLatest,
  takeEvery,
  delay
} from 'redux-saga/effects';

import {
  ADD_TAX_GROUPS_REQUEST,
  ADD_TAX_GROUPS_SUCCESS,
  ADD_TAX_RATES_REQUEST,
  ADD_TAX_RATES_SUCCESS,
  GET_ALL_TAX_GROUPS_REQUEST,
  GET_ALL_TAX_RATES_REQUEST,
  GET_SINGLE_TAX_GROUP_REQUEST,
  GET_SINGLE_TAX_RATES_REQUEST,
  REMOVE_TAX_GROUPS_REQUEST,
  REMOVE_TAX_GROUPS_SUCCESS,
  REMOVE_TAX_RATES_FAILED,
  REMOVE_TAX_RATES_REQUEST,
  REMOVE_TAX_RATES_SUCCESS,
  UPDATE_TAX_GROUPS_REQUEST,
  UPDATE_TAX_GROUPS_SUCCESS,
  UPDATE_TAX_RATES_REQUEST,
  UPDATE_TAX_RATES_SUCCESS
} from 'redux/constants/StoreSetupConstants/tax.rules.constants';
import {
  addTaxRateFailed,
  populateSingleTaxGroup,
  populateSingleTaxGroupFailed,
  populateSingleTaxRate,
  populateSingleTaxRateFailed,
  populateTaxGroups,
  populateTaxGroupsFailed,
  populateTaxRates,
  populateTaxRatesFailed
} from 'redux/actions/StoreSetupActions/tax.rules.action';
import { setSnackbar } from 'redux/actions/UIComponentActions/snackbar.action';
import api from 'utils/api';

// /*----------------------------------
//        GET SINGLE USER
// ==================================*/
export function* getSingleCustomerGroup() {
  yield takeLatest(GET_SINGLE_TAX_RATES_REQUEST, fetchSingleOutlet);
}

function* fetchSingleOutlet({ payload }) {
  console.log(payload, 'saga');
  try {
    const { data } = yield api.get(`/get-tax/${payload}`);
    yield put(populateSingleTaxRate(data));
  } catch (error) {
    yield put(populateSingleTaxRateFailed(error));
    if (error?.response?.status !== 404) {
      yield put(setSnackbar(true, 'error', error.message));
    }
  }
}
// /*----------------------------------
//        GET SINGLE USER
// ==================================*/
export function* getSingleTaxGroup() {
  yield takeLatest(GET_SINGLE_TAX_GROUP_REQUEST, fetchSingleTaxGroup);
}

function* fetchSingleTaxGroup({ payload }) {
  console.log(payload, 'saga');
  try {
    const { data } = yield api.get(`/get-taxGroup/${payload}`);
    yield put(populateSingleTaxGroup(data));
  } catch (error) {
    yield put(populateSingleTaxGroupFailed(error));
    if (error?.response?.status !== 404) {
      yield put(setSnackbar(true, 'error', error.message));
    }
  }
}

/*----------------------------------
    SYSTEM USER >> GET ALL TAX_RATE          
==================================*/

// export function* getAllTaxRates() {
//   yield take(GET_ALL_TAX_RATES_REQUEST);
//   try {
//     const { data } = yield call(api, `/getAll-taxes`);
//     // const { data } = yield call(api, `http://localhost:3000/taxRules`);
//     yield put(populateTaxRates(data));
//   } catch (error) {
//     yield put(populateTaxRatesFailed(error));
//     if (error.response.status !== 404)
//       yield put(setSnackbar(true, 'error', 'Something Went Wrong !!'));
//   }
// }

export function* getAllTaxRates() {
  yield takeLatest(GET_ALL_TAX_RATES_REQUEST, fetchProducts);
}
function* fetchProducts() {
  try {
    const { data } = yield call(api, `/getAll-tax`);
    yield delay(400);
    yield put(populateTaxRates(data));
  } catch (error) {
    yield put(populateTaxRatesFailed(error));
    if (error.response.status !== 404) {
      yield put(setSnackbar(true, 'error', error.message));
    }
  }
}

/*----------------------------------
    SYSTEM USER >> ADD USER          
==================================*/
export function* addSystemUserSaga() {
  yield takeLatest(ADD_TAX_RATES_REQUEST, systemUser);
}

function* systemUser({ payload }) {
  try {
    const result = yield call(sysUserAsync, payload);
    yield put({ type: ADD_TAX_RATES_SUCCESS, payload: result });
    console.log(result, 'result');
    yield put(setSnackbar(true, 'success', 'Tax Rate Added Successfully'));
  } catch (error) {
    yield put(addTaxRateFailed(error));
    if (error.response.status !== 404)
      yield put(setSnackbar(true, 'error', 'Something Went Wrong !!'));
  }
}

async function sysUserAsync(payload) {
  const { id, tax_name, tax_rate, group, about, sub_taxes } = payload;
  const { data } = await api.post(`/save-tax`, {
    id,
    tax_name,
    tax_rate,
    default: payload.default,
    group,
    about,
    sub_taxes
  });
  return data;
}

/*----------------------------------
       SYSTEM USER >> UPDATE USER        
==================================*/

export function* updateSystemUserSaga() {
  yield takeEvery(UPDATE_TAX_RATES_REQUEST, updateSystemUser);
}

function* updateSystemUser({ payload }) {
  try {
    const result = yield call(updateAsyncTaxRates, payload);
    yield put({ type: UPDATE_TAX_RATES_SUCCESS, payload: result });
    yield put(setSnackbar(true, 'success', 'Tax Rate Updated Successfully'));
  } catch (error) {
    if (error.response.status !== 404)
      yield put(setSnackbar(true, 'error', 'Something Went Wrong !!'));
  }
}

async function updateAsyncTaxRates(payload) {
  const { id, entry } = payload;
  const { tax_name, tax_rate, group, about, sub_taxes, sub_taxIds } = entry;
  const { data } = await api.put(`/update-tax/${id}`, {
    id,
    tax_name,
    tax_rate,
    group,
    about,
    sub_taxes,
    sub_taxIds,
    default: entry.default
  });
  return data;
}

/*-----------------------------------------
    TAX RATE >> DELETE SINGLE TAX RATE       
=========================================*/

// export function* deleteSystemUserSaga() {
//   while (true) {
//     const { payload } = yield take(REMOVE_TAX_RATES_REQUEST);
//     const { id } = payload;
//     yield call(deleteSingleSystemUsers, id);
//     yield put({
//       type: REMOVE_TAX_RATES_SUCCESS,
//       payload: { id }
//     });
//   }
// }

// async function deleteSingleSystemUsers(id) {
//   await api.delete(`/delete-taxes/${id}`);
// }
function* deleteTaxRate(action) {
  const { id } = action.payload;
  try {
    yield call(deleteTaxRateDB, id);
    yield put({
      type: REMOVE_TAX_RATES_SUCCESS,
      payload: { id: id }
    });
    yield put(setSnackbar(true, 'error', 'Tax Rate Deleted successfully'));
    yield put({ type: GET_ALL_TAX_RATES_REQUEST });
    yield call(getAllTaxRates);
  } catch (error) {
    yield put({
      type: REMOVE_TAX_RATES_FAILED,
      payload: error?.response?.data?.errorMessage
    });
    yield put(setSnackbar(true, 'error', error?.response?.data?.errorMessage));
    yield put({ type: GET_ALL_TAX_RATES_REQUEST });
    yield call(getAllTaxRates);
  }
}
const deleteTaxRateDB = async (id) => {
  return await api.delete(`/delete-tax/${id}`);
};
// watcher saga
export function* deleteTaxRateSaga() {
  yield takeEvery(REMOVE_TAX_RATES_REQUEST, deleteTaxRate);
}

/*----------------------------------
          TAX GROUP          
==================================*/

/*----------------------------------
    SYSTEM USER >> GET ALL TAX_RATE          
==================================*/

// export function* getAllTaxGroups() {
//   yield take(GET_ALL_TAX_GROUPS_REQUEST);
//   try {
//     const { data } = yield call(api, `/getAll-taxGroup`);
//     // const { data } = yield call(api, `http://localhost:3000/taxRules`);
//     yield delay(400);
//     yield put(populateTaxGroups(data));
//   } catch (error) {
//     yield put(populateTaxGroupsFailed(error));
//     if (error.response.status !== 404)
//       yield put(setSnackbar(true, 'error', 'Something Went Wrong !!'));
//   }
// }
export function* getAllTaxGroups() {
  yield takeLatest(GET_ALL_TAX_GROUPS_REQUEST, fetchCustomers);
}
function* fetchCustomers() {
  try {
    const { data } = yield api.get('/getAll-taxGroup');
    yield delay(400);
    yield put(populateTaxGroups(data));
  } catch (err) {
    yield put(populateTaxGroupsFailed(err));
    if (err.response.status !== 404) {
      yield put(setSnackbar(true, 'error', err.message));
    }
  }
}
/*----------------------------------
    SYSTEM USER >> ADD USER          
==================================*/
export function* addTaxGroupSaga() {
  yield takeLatest(ADD_TAX_GROUPS_REQUEST, taxGroup);
}

function* taxGroup({ payload }) {
  try {
    const result = yield call(taxGroupAsync, payload);
    yield put({ type: ADD_TAX_GROUPS_SUCCESS, payload: result });
    console.log(result, 'result');
    yield put(setSnackbar(true, 'success', 'Tax Rate Added Successfully'));
    yield put({ type: GET_ALL_TAX_GROUPS_REQUEST });
    yield call(getAllTaxGroups);
  } catch (error) {
    yield put(addTaxRateFailed(error));
    if (error.response.status !== 404)
      yield put(setSnackbar(true, 'error', 'Something Went Wrong !!'));
  }
}

async function taxGroupAsync(payload) {
  const {
    tax_name,
    tax_rate,
    group = true,
    about,
    sub_taxIds,
    systemDefault,
    active
  } = payload;
  const { data } = await api.post(`/save-taxGroup`, {
    tax_name,
    tax_rate,
    default: payload.default,
    group,
    about,
    sub_taxIds,
    systemDefault,
    active
  });
  return data;
}

/*----------------------------------
       SYSTEM USER >> UPDATE USER        
==================================*/

export function* updateTaxGroupSaga() {
  yield takeEvery(UPDATE_TAX_GROUPS_REQUEST, updateTaxGroup);
}

function* updateTaxGroup({ payload }) {
  try {
    const result = yield call(updateAsyncTaxGroups, payload);
    yield put({ type: UPDATE_TAX_GROUPS_SUCCESS, payload: result });

    yield put(setSnackbar(true, 'success', 'Tax Group Updated Successfully'));
    yield put({ type: GET_ALL_TAX_GROUPS_REQUEST });
    yield call(getAllTaxGroups);
    console.log(result, 'result saga group');
  } catch (error) {
    if (error.response.status !== 404)
      yield put(setSnackbar(true, 'error', 'Something Went Wrong !!'));
  }
}

async function updateAsyncTaxGroups(payload) {
  console.log(payload, 'payload saga group');
  const { tax_id, entry } = payload;
  const {
    tax_name,
    tax_rate,
    group = true,
    about,
    systemDefault,
    sub_taxIds,
    active
  } = entry;
  const { data } = await api.put(`/update-taxGroup/${tax_id}`, {
    tax_id,
    tax_name,
    tax_rate,
    group,
    about,
    default: entry.default,
    systemDefault,
    sub_taxIds,
    active
  });

  return data;
}

/*-----------------------------------------
    TAX RATE >> DELETE SINGLE TAX RATE       
=========================================*/

// export function* deleteTaxGroupSaga() {
//   while (true) {
//     const { payload } = yield take(REMOVE_TAX_GROUPS_REQUEST);
//     yield call(deleteSingleTaxGroup, payload.id);
//     yield put({
//       type: REMOVE_TAX_RATES_SUCCESS,
//       payload: { id: payload.id }
//     });
//   }
// }

// async function deleteSingleTaxGroup(id) {
//   await api.delete(`/delete-taxes/${id}`);
// }
function* deleteProduct(action) {
  const { tax_id } = action.payload;
  try {
    yield call(deleteProductDB, tax_id);
    yield put({
      type: REMOVE_TAX_GROUPS_SUCCESS,
      payload: { tax_id: tax_id }
    });
    yield put(setSnackbar(true, 'error', 'Tax Group Deleted successfully'));
    yield put({ type: GET_ALL_TAX_GROUPS_REQUEST });
    yield call(getAllTaxGroups);
  } catch (error) {
    yield put({
      type: REMOVE_TAX_RATES_FAILED,
      payload: error?.response?.data?.errorMessage
    });
    yield put(setSnackbar(true, 'error', error?.response?.data?.errorMessage));
    yield put({ type: GET_ALL_TAX_GROUPS_REQUEST });
    yield call(getAllTaxGroups);
  }
}
const deleteProductDB = async (tax_id) => {
  return await api.delete(`/delete-taxGroup/${tax_id}`);
};
// watcher saga
export function* deleteProductSaga() {
  yield takeEvery(REMOVE_TAX_GROUPS_REQUEST, deleteProduct);
}
