import { makeStyles } from '@material-ui/core';
export const useStyles = makeStyles((theme) => ({
  paperContent: {
    margin: theme.spacing(0),
    padding: theme.spacing(3),
    width: '100%'
  },
  menuWidth: {
    minWidth: 130
  }
}));
