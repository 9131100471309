import {
  GET_ALL_PURCHASE_ORDER_SUCCESS,
  GET_ALL_PURCHASE_ORDER_FAILED,
  GET_SINGLE_PURCHASE_ORDER_SUCCESS,
  GET_SINGLE_PURCHASE_ORDER_FAILED,
  ADD_PURCHASE_ORDER_SUCCESS,
  ADD_PURCHASE_ORDER_FAILED,
  REMOVE_PURCHASE_ORDER_SUCCESS,
  RECEIVE_PURCHASE_ORDER_SUCCESS,
  RECEIVE_PURCHASE_ORDER_FAILED,
  GET_ALL_PURCHASE_ORDER_REQUEST,
  RECEIVE_ALL_PURCHASE_ORDER_SUCCESS,
  RECEIVE_ALL_PURCHASE_ORDER_FAILED
} from 'redux/constants/InventoryConstants/inventory.receive.purchorders.constants';

const InventoryPurchaseReducer = (
  state = {
    loading: true,
    purchase_orders: [],
    error: false,
    purchase_order: {}
  },
  action
) => {
  const { purchase_orders } = state;
  let newPurchases;
  switch (action.type) {
    case GET_ALL_PURCHASE_ORDER_REQUEST:
      return {
        ...state,
        purchase_orders: [],
        loading: true
      };
    case GET_ALL_PURCHASE_ORDER_SUCCESS:
      return {
        ...state,
        purchase_orders: [...action.payload],
        loading: false
      };
    case GET_ALL_PURCHASE_ORDER_FAILED:
      return {
        ...state,
        error: action.payload,
        purchase_orders: [...state.purchase_orders],
        loading: false
      };
    case GET_SINGLE_PURCHASE_ORDER_SUCCESS:
      return {
        ...state,
        purchase_order: action.payload,
        loading: false
      };
    case GET_SINGLE_PURCHASE_ORDER_FAILED:
      return {
        ...state,
        error: action.payload,
        purchase_order: {},
        loading: false
      };
    case ADD_PURCHASE_ORDER_SUCCESS:
      newPurchases = {
        ...state,
        purchase_orders: [...purchase_orders, action.payload]
      };
      return newPurchases;
    case ADD_PURCHASE_ORDER_FAILED:
      return {
        error: action.payload,
        purchase_orders: [...state.purchase_orders]
      };
    case REMOVE_PURCHASE_ORDER_SUCCESS:
      newPurchases = purchase_orders.filter(
        (purchase_order) =>
          purchase_order.purch_orderlineitem_id !==
          action.payload.purch_orderlineitem_id
      );
      return { loading: false, purchase_orders: newPurchases, error: false };

    case RECEIVE_PURCHASE_ORDER_SUCCESS:
      const { purch_orderlineitem_id } = action.payload;

      newPurchases = [...state.purchase_orders];
      const existingProduct = state.purchase_orders.find(
        (purchase_order) =>
          purchase_order.purchase_order_id === purch_orderlineitem_id
      );

      return { purchase_orders: newPurchases };
    case RECEIVE_ALL_PURCHASE_ORDER_SUCCESS:
      const { purchase_id } = action.payload;
      console.log(purchase_id);

      newPurchases = [...state.purchase_orders];
      // const existingProduct2 = state.purchase_orders.find(
      //   (purchase_order) =>
      //     purchase_order.purchase_order_id === purch_orderlineitem_id
      // );

      return { purchase_orders: newPurchases };
    case RECEIVE_ALL_PURCHASE_ORDER_FAILED: {
      return {
        error: action.payload,
        purchase_orders: [...state.purchase_orders]
      };
    }

    default:
      return state;
  }
};
export default InventoryPurchaseReducer;
