import { call, put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import { GET_DRAFT_PO_NOT_YET_SENT_REQUEST } from 'redux/constants/InventoryConstants/inventory.dashboard.constants';
import {
  populateDraftPOs,
  populateDraftPOsFailed
} from 'redux/actions/InventoryActions/inventory.dashboard.action';
import { setSnackbar } from 'redux/actions/UIComponentActions/snackbar.action';

const baseURLProd = 'http://216.10.244.34:10005/rp/api/v1';

export function* getAlldDraftPOs() {
  yield takeLatest(GET_DRAFT_PO_NOT_YET_SENT_REQUEST, fetchProducts);
}
/* const response = yield call(getTweets, buttonStatus);

 */
function* fetchProducts(payload) {
  try {
    const { data } = yield call(
      axios,
      `${baseURLProd}/getDraftPO-PurchaseOrder?currentPage=${
        payload.page + 1
      }&query=${payload.searchValue}&size=${payload.size}&sortField=${
        payload.orderBy
      }&sortDir=${payload.order}`
    );
    /* getAwaiting-Delivery?currentPage=1&size=3&sortField=po_number&sortDir=asc */
    yield put(populateDraftPOs(data));
  } catch (error) {
    yield put(populateDraftPOsFailed(error));
    if (error?.response?.status !== 404) {
      yield put(setSnackbar(true, 'error', error.message));
    }
  }
}
