import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useStyles, width, tabBreakPoint, breakpoint } from './utils';
import logo from 'assets/images/logo.png';
import {
  Drawer,
  AppBar,
  Toolbar,
  CssBaseline,
  Divider,
  IconButton,
  Grid,
  InputBase,
  Badge,
  useTheme
} from '@material-ui/core';
import {
  Menu as MenuIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  NotificationsNone as NotificationsNoneIcon,
  ChatBubbleOutline as ChatBubbleOutlineIcon,
  Search as SearchIcon
} from '@material-ui/icons';
import NestedList from '../NestedList';
import { Link } from 'react-router-dom';
import Image from 'components/Image';
import GenericDropdown from 'components/GenericDropdown';
import { useDispatch, useSelector } from 'react-redux';
import { getMyProfile } from 'redux/actions/UserActions/user.action';
import _ from 'lodash';
export default function Layout(props) {
  const classes = useStyles();
  const theme = useTheme();

  const drawerWidth = 200;
  const [open, setOpen] = useState(width < tabBreakPoint ? false : true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const dispatch = useDispatch();
  const [user, setUser] = useState({});
  const { isAuthenticated } = useSelector((state) => state.userLogin);
  const { profile, loading: loadProfile } = useSelector(
    (state) => state.myProfile
  );

  useEffect(() => {
    isAuthenticated && dispatch(getMyProfile());
  }, [dispatch, isAuthenticated]);
  useEffect(() => {
    if (!loadProfile && !_.isEmpty(profile)) {
      setUser({ ...profile });
    }
  }, [setUser, profile, loadProfile]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={`${clsx(classes.menuButton, open && classes.hide)}`}
          >
            <MenuIcon />
          </IconButton>
          <Grid container alignItems="center">
            <Grid item xs={4}>
              {/* <InputBase
                placeholder="Search topics"
                className={classes.searchInput}
                startAdornment={<SearchIcon fontSize="small" />}
              /> */}
            </Grid>
            <Grid item sm xs={2}></Grid>

            <Grid item xs={6}>
              <div
                style={{
                  float: 'right',
                  display: 'flex',
                  alignContent: 'center'
                }}
              >
                <IconButton>
                  <Badge badgeContent={4} color="secondary">
                    <NotificationsNoneIcon fontSize="small" />
                  </Badge>
                </IconButton>
                <IconButton>
                  <Badge badgeContent={3} color="primary">
                    <ChatBubbleOutlineIcon fontSize="small" />
                  </Badge>
                </IconButton>
                {isAuthenticated && !_.isEmpty(user) && (
                  <>
                    <GenericDropdown
                      first_name={user?.first_name}
                      // first_name={user1 && user1.first_name }
                      last_name={user?.last_name}
                      role={user?.role}
                      userimg={user?.image_url}
                    />
                  </>
                )}
              </div>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant={width < breakpoint ? 'temporary' : 'persistent'}
        // variant="temporary"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <div className={classes.drawerHeader}>
          <Link to="/">
            <Image
              src={logo}
              alt="AAIPAY - pay with faith"
              className={classes.logo}
            />
          </Link>

          <IconButton
            onClick={handleDrawerClose}
            className={classes.iconButton}
          >
            {theme.direction === 'ltr' ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </div>
        <Divider />

        <NestedList setOpen={setOpen} open={open} />
      </Drawer>

      <main
        className={clsx(
          classes.content,
          {
            [classes.contentShift]: open
          },
          classes.main
        )}
      >
        <CssBaseline />
        <div className={classes.drawerHeaderContent} />
        {props.children}
      </main>
    </div>
  );
}
