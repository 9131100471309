export const GET_PRODUCT_BRANDS_REQUEST = 'GET_PRODUCT_BRANDS_REQUEST';
export const GET_PRODUCT_BRANDS_SUCCESS = 'GET_PRODUCT_BRANDS_SUCCESS';
export const GET_PRODUCT_BRANDS_FAILED = 'GET_PRODUCT_BRANDS_FAILED';
export const REMOVE_PRODUCT_BRANDS_REQUEST = 'REMOVE_PRODUCT_BRANDS_REQUEST';
export const REMOVE_PRODUCT_BRANDS_SUCCESS = 'REMOVE_PRODUCT_BRANDS_SUCCESS';
export const REMOVE_PRODUCT_BRANDS_FAILED = 'REMOVE_PRODUCT_BRANDS_FAILED';
export const REMOVE_MULTIPLE_PRODUCT_BRANDS_REQUEST =
  'REMOVE_MULTIPLE_PRODUCT_BRANDS_REQUEST';
export const REMOVE_MULTIPLE_PRODUCT_BRANDS_SUCCESS =
  'REMOVE_MULTIPLE_PRODUCT_BRANDS_SUCCESS';
export const REMOVE_MULTIPLE_PRODUCT_BRANDS_FAILED =
  'REMOVE_MULTIPLE_PRODUCT_BRANDS_FAILED';
export const ADD_PRODUCT_BRANDS_REQUEST = 'ADD_PRODUCT_BRANDS_REQUEST';
export const ADD_PRODUCT_BRANDS_SUCCESS = 'ADD_PRODUCT_BRANDS_SUCCESS';
export const ADD_PRODUCT_BRANDS_FAILED = 'ADD_PRODUCT_BRANDS_FAILED';
export const UPDATE_PRODUCT_BRANDS_REQUEST = 'UPDATE_PRODUCT_BRANDS_REQUEST';
export const UPDATE_PRODUCT_BRANDS_SUCCESS = 'UPDATE_PRODUCT_BRANDS_SUCCESS';
export const UPDATE_PRODUCT_BRANDS_FAILED = 'UPDATE_PRODUCT_BRANDS_FAILED';
