import Input from './Input';
import RadioGroup from './RadioGroup';
import Select from './Select';
import Checkbox from './Checkbox';
import DatePicker from './DatePicker';
import Button from './Button';
import ActionButton from './ActionButton';
import SwitchToggle from './Switch';
import ActiveSwitchToggle from './ActiveSwitchToggle';
import MultiSelectInput from './MultiSelectInput';
import CountrySelect from './CountrySelect';
import CustomAutoComplete from './CustomAutoComplete';
import AutoCompleteSearch from './AutoCompleteSearch';
import AutoCompleteSearchSingle from './AutoCompleteSearchControlled';
import CitySelect from './CitySelect';
import MultiChipControl from './MultiChipControl';
import MultiSwitchToggle from './MultiSwitchToggle';
import AutoCompleteHighlight from './AutoCompleteHighlight';
import AsyncAutoComplete from './AsyncAutoComplete';
import SplitButton from './SplitButton';
import AutoComplete from './AutoComplete';
import ChipMultiControlled from './ChipMultiControlled';

const Controls = {
  Input,
  RadioGroup,
  Select,
  Checkbox,
  DatePicker,
  Button,
  ActionButton,
  SwitchToggle,
  ActiveSwitchToggle,
  MultiSelectInput,
  CountrySelect,
  CitySelect,
  CustomAutoComplete,
  AutoCompleteSearch,
  MultiChipControl,
  MultiSwitchToggle,
  AutoCompleteSearchSingle,
  AsyncAutoComplete,
  AutoCompleteHighlight,
  SplitButton,
  AutoComplete,
  ChipMultiControlled
};

export default Controls;
