import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import createSagaMiddleware from 'redux-saga';
import rootReducer from '../reducers/root.reducer';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { initSagas } from '../sagas';
import { useDispatch, useSelector } from 'react-redux';
import { getCookieData } from 'utils/functionsCustom';
export const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();
const routerMiddlewaree = routerMiddleware(history);
const persistConfig = {
  key: 'root',
  storage,
  whitelist: []
};
const persistedReducer = persistReducer(persistConfig, rootReducer(history));
// const middlewares = [sagaMiddleware, logger];
const middlewares = [sagaMiddleware];
// const { authenticationToken, emailFromCookie, refreshToken } = getCookieData();
// if (authenticationToken === undefined) {
//   .dispatch();
// }
export const userFromCookie = getCookieData() ? getCookieData() : null;

const initialStateValues = {
  userLogin: { userInfo: userFromCookie }
};

const configureStore = () => {
  const store = createStore(
    persistedReducer,
    initialStateValues,
    // autoRehydrate(),
    composeWithDevTools(applyMiddleware(routerMiddlewaree, ...middlewares))
  );
  initSagas(sagaMiddleware);
  // if (module.hot) {
  //   module.hot.accept('../reducers/root.reducer.js', () => {
  //     // This fetch the new state of the above reducers.
  //     const nextRootReducer = require('../reducers/root.reducer.js').default;
  //     store.replaceReducer(persistReducer(persistConfig, nextRootReducer));
  //   });
  // }
  return store;
};
// const mainStore = configureStore();
// export const persistor = persistStore(mainStore);
// export const persistor = persistStore(mainStore);
export default configureStore;
