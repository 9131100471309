import React from 'react';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as MuiSelect
} from '@material-ui/core';

const CitySelect = (props) => {
  const {
    error = null,
    name,
    label,
    value,
    onChange,
    options,
    required,
    noTextLabel,
    country,
    size,
    ...others
  } = props;
  return (
    <FormControl
      size={size || 'small'}
      variant="outlined"
      required={required ? true : false}
      {...(error && { error: true })}
    >
      <InputLabel>{label}</InputLabel>
      <MuiSelect
        label={label}
        name={name}
        value={value}
        onChange={onChange}
        {...others}
      >
        <MenuItem value="" disabled>
          {noTextLabel}
        </MenuItem>
        {country
          ? options
              .find(({ countryName }) => countryName === country)
              .city.map((ct) => (
                <MenuItem value={ct.name} key={ct.shortCode}>
                  {ct.name}
                </MenuItem>
              ))
          : []}
      </MuiSelect>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export default CitySelect;
