import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles, Typography, Drawer } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import Controls from 'components/controls/Controls';
import { useWindowSize } from 'hooks/useWindowSize';
import { closeDrawer } from 'redux/actions/UIComponentActions/drawer.actions';
// Styles

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: (props) =>
      props && props.windowWidth > 1024 ? `${props.drawerWidth}` : '100vw',
    minWidth: (props) =>
      props && props.windowWidth > 1024 ? `${props.drawerWidth}` : '100vw',
    padding: (props) =>
      props && props.windowWidth > 1024
        ? theme.spacing(0, 2, 0, 2)
        : props.title
        ? theme.spacing(0, 2, 0, 2)
        : theme.spacing(0, 2, 0, 3.5)
  },

  drawerTitle: {
    paddingRight: '10px',
    paddingLeft: '10px',
    display: 'flex'
  },
  paper: {
    backgroundColor: '#EFF3FB !important',
    zIndex: `2001 !important`
  },
  titleBar: {
    marginBottom: theme.spacing(2),
    backgroundColor: '#FFFFFF',
    padding: '5px 5px 5px 16px'
  },
  drawerTitleTypography: {
    flexGrow: 1,
    lineHeight: 3,
    fontSize: '1.2rem',
    fontWeight: 500,
    textTransform: 'capitalize'
  },
  actionButton: {
    zIndex: theme.zIndex.drawer - 1,
    position: 'relative',
    right: '10px'
  }
}));

// Styles End
const MaterialDrawerRedux = () => {
  const { width: windowWidth } = useWindowSize();

  const drawerState = useSelector((state) => state.sliderDrawer);
  let { modalType, modalProps } = drawerState;
  const { widthDrawer, open, title, btnText, btnFunction, closeDrawerAction } =
    modalProps;
  const classes = useStyles({
    drawerWidth: widthDrawer,
    windowWidth: windowWidth,
    title
  });

  const [openDrawer, setOpenDrawer] = useState(open);
  useEffect(() => {
    setOpenDrawer(open);
  }, [open, setOpenDrawer]);
  const dispatch = useDispatch();
  const handleDrawerClose = () => {
    setOpenDrawer(false);
    setTimeout(() => {
      dispatch(
        closeDrawer({
          modalProps: { open: false }
        })
      );
    }, 500);
  };

  const SpecificModal = modalType;
  return (
    <div>
      <Drawer
        classes={{ paper: classes.paper }}
        className={classes.root}
        anchor={'right'}
        open={openDrawer}
        onClose={handleDrawerClose}
      >
        {closeDrawerAction ? (
          <div className={classes.titleBar}>
            <div className={classes.drawerTitle}>
              <Controls.ActionButton
                style={{
                  position: windowWidth > 1024 ? `fixed` : `relative`,
                  right:
                    windowWidth > 1024
                      ? `calc(${widthDrawer} + 20px)`
                      : title
                      ? 8
                      : 15,
                  top: windowWidth > 1024 ? 10 : title ? 5 : 0,
                  width: windowWidth > 1024 ? 50 : title ? 40 : 50,
                  height: windowWidth > 1024 ? 50 : title ? 40 : 50,
                  // height: 50,
                  borderRadius: 8,
                  backgroundColor: windowWidth > 768 ? '#fff' : ''
                }}
                color="secondary"
                onClick={() => {
                  setOpenDrawer(false);
                  setTimeout(() => {
                    dispatch(
                      closeDrawer({
                        modalProps: { open: false }
                      })
                    );
                  }, 500);
                }}
              >
                <CloseIcon />
              </Controls.ActionButton>
              <Typography
                variant="h6"
                component="div"
                className={classes.drawerTitleTypography}
              >
                {title}
              </Typography>
              {btnText && (
                <Controls.Button
                  onClick={btnFunction}
                  text={btnText}
                  style={{
                    height: 40
                  }}
                />
              )}
            </div>
          </div>
        ) : null}
        <div className={classes.root}>
          {modalType && <SpecificModal {...drawerState} />}
        </div>
      </Drawer>
    </div>
  );
};
export default MaterialDrawerRedux;
