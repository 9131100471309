/*----------------------------------
    EMAIL TEMPLATE CONSTANTS         
==================================*/

export const GET_ALL_EMAIL_TEMPLATES_REQUEST =
  'GET_ALL_EMAIL_TEMPLATES_REQUEST';
export const GET_ALL_EMAIL_TEMPLATES_SUCCESS =
  'GET_ALL_EMAIL_TEMPLATES_SUCCESS';
export const GET_ALL_EMAIL_TEMPLATES_FAILED = 'GET_ALL_EMAIL_TEMPLATES_FAILED';

export const UPDATE_EMAIL_TEMPLATES_REQUEST = 'UPDATE_EMAIL_TEMPLATES_REQUEST';
export const UPDATE_EMAIL_TEMPLATES_SUCCESS = 'UPDATE_EMAIL_TEMPLATES_SUCCESS';
export const UPDATE_EMAIL_TEMPLATES_FAILED = 'UPDATE_EMAIL_TEMPLATES_FAILED';

export const GET_SINGLE_EMAIL_TEMPLATES_REQUEST =
  'GET_SINGLE_EMAIL_TEMPLATES_REQUEST';
export const GET_SINGLE_EMAIL_TEMPLATES_SUCCESS =
  'GET_SINGLE_EMAIL_TEMPLATES_SUCCESS';
export const GET_SINGLE_EMAIL_TEMPLATES_FAILED =
  'GET_SINGLE_EMAIL_TEMPLATES_FAILED';
