/*----------------------------------
           CUSTOMER GROUP        
==================================*/

export const GET_SINGLE_CUSTOMER_GROUP_REQUEST =
  'GET_SINGLE_CUSTOMER_GROUP_REQUEST';
export const GET_SINGLE_CUSTOMER_GROUP_SUCCESS =
  'GET_SINGLE_CUSTOMER_GROUP_SUCCESS';
export const GET_SINGLE_CUSTOMER_GROUP_FAILED =
  'GET_SINGLE_CUSTOMER_GROUP_FAILED';

export const GET_ALL_CUSTOMER_GROUPS_REQUEST =
  'GET_ALL_CUSTOMER_GROUPS_REQUEST';
export const GET_ALL_CUSTOMER_GROUPS_SUCCESS =
  'GET_ALL_CUSTOMER_GROUPS_SUCCESS';
export const GET_ALL_CUSTOMER_GROUPS_FAILED = 'GET_ALL_CUSTOMER_GROUPS_FAILED';

export const ADD_CUSTOMER_GROUPS_REQUEST = 'ADD_CUSTOMER_GROUPS_REQUEST';
export const ADD_CUSTOMER_GROUPS_SUCCESS = 'ADD_CUSTOMER_GROUPS_SUCCESS';
export const ADD_CUSTOMER_GROUPS_FAILED = 'ADD_CUSTOMER_GROUPS_FAILED';

export const UPDATE_CUSTOMER_GROUP_REQUEST = 'UPDATE_CUSTOMER_GROUP_REQUEST';
export const UPDATE_CUSTOMER_GROUP_SUCCESS = 'UPDATE_CUSTOMER_GROUP_SUCCESS';
export const UPDATE_CUSTOMER_GROUP_FAILED = 'UPDATE_CUSTOMER_GROUP_FAILED';

export const REMOVE_CUSTOMER_GROUP_REQUEST = 'REMOVE_CUSTOMER_GROUP_REQUEST';
export const REMOVE_CUSTOMER_GROUP_SUCCESS = 'REMOVE_CUSTOMER_GROUP_SUCCESS';
export const REMOVE_CUSTOMER_GROUP_FAILED = 'REMOVE_CUSTOMER_GROUP_FAILED';

export const REMOVE_MULTIPLE_CUSTOMER_GROUP_REQUEST =
  'REMOVE_MULTIPLE_CUSTOMER_GROUP_REQUEST';
export const REMOVE_MULTIPLE_CUSTOMER_GROUP_SUCCESS =
  'REMOVE_MULTIPLE_CUSTOMER_GROUP_SUCCESS';
export const REMOVE_MULTIPLE_CUSTOMER_GROUP_FAILED =
  'REMOVE_MULTIPLE_CUSTOMER_GROUP_FAILED';
