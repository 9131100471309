import {
  take,
  call,
  put,
  takeLatest,
  takeEvery,
  delay
} from '@redux-saga/core/effects';

import {
  GET_PRODUCT_SEASONS_REQUEST,
  REMOVE_MULTIPLE_PRODUCT_SEASONS_REQUEST,
  REMOVE_MULTIPLE_PRODUCT_SEASONS_SUCCESS,
  REMOVE_PRODUCT_SEASONS_REQUEST,
  REMOVE_PRODUCT_SEASONS_SUCCESS,
  ADD_PRODUCT_SEASONS_REQUEST,
  ADD_PRODUCT_SEASONS_SUCCESS,
  UPDATE_PRODUCT_SEASONS_REQUEST,
  UPDATE_PRODUCT_SEASONS_SUCCESS,
  GET_SINGLE_PRODUCT_SEASON_REQUEST,
  REMOVE_PRODUCT_SEASONS_FAILED,
  REMOVE_MULTIPLE_PRODUCT_SEASONS_FAILED
} from 'redux/constants/ProductConstants/product.seasons.constants';
import {
  populateProductSeasons,
  populateProductSeasonsError,
  addProductSeasonError,
  populateSingleSeason,
  populateSingleSeasonFailed
} from 'redux/actions/ProductActions/product.seasons.actions';
import { setSnackbar } from 'redux/actions/UIComponentActions/snackbar.action';
import api from 'utils/api';

/*----------------------------------
      GET ALL PRODUCT SEASONS        
==================================*/
// export function* getAllProductSeasons() {
//   yield take(GET_PRODUCT_SEASONS_REQUEST);
//   try {
//     // const { data } = yield call(api, url);
//     const { data } = yield call(api, `/getAll-seasons`);
//     yield put(populateProductSeasons(data));
//   } catch (error) {
//     yield put(populateProductSeasonsError(error));
//   }
// }

/*----------------------------------
       GET SINGLE CUSTOMER
==================================*/
export function* onFetchProductStart() {
  yield takeLatest(GET_SINGLE_PRODUCT_SEASON_REQUEST, fetchProduct);
}
function* fetchProduct({ payload }) {
  try {
    if (payload) {
      const { data } = yield api.get(`/get-seasons/${payload}`);

      yield put(populateSingleSeason(data));
    }
  } catch (err) {
    yield put(populateSingleSeasonFailed(err.message));
  }
}

export function* getAllProductSeasons() {
  yield takeLatest(GET_PRODUCT_SEASONS_REQUEST, fetchProducts);
}
function* fetchProducts(payload) {
  const {
    page: currentPage,
    size,
    orderBy: sortField,
    order: sortDir,
    searchValue: query
  } = payload;
  try {
    const { data } = yield api.get('/getAll-seasons', {
      params: {
        currentPage: currentPage + 1 || 1,
        size: size || 8,
        sortField: sortField || 'season_name',
        sortDir: sortDir || 'asc',
        query: query || ''
      }
    });
    yield delay(400);
    yield put(populateProductSeasons(data));
  } catch (err) {
    yield put(populateProductSeasonsError(err));
    if (err.response.status !== 404) {
      yield put(setSnackbar(true, 'error', err.message));
    }
  }
}
/*----------------------------------
         DELETE SINGLE ENTRY       
==================================*/
// export function* deleteEntrySaga() {
//   while (true) {
//     const { payload } = yield take(REMOVE_PRODUCT_SEASONS_REQUEST);
//     yield call(deleteEntries, payload.id);
//     yield put({
//       type: REMOVE_PRODUCT_SEASONS_SUCCESS,
//       payload: { id: payload.id }
//     });
//     yield put({ type: GET_PRODUCT_SEASONS_REQUEST });
//     yield call(getAllProductSeasons);
//   }
// }

// async function deleteEntries(id) {
//   await api.delete(`/delete-seasons/${id}`);
// }
function* deleteProduct(action) {
  const { id } = action.payload;
  try {
    yield call(deleteProductDB, id);
    yield put({
      type: REMOVE_PRODUCT_SEASONS_SUCCESS,
      payload: { id: id }
    });
    yield put(
      setSnackbar(true, 'error', 'Product Season Deleted successfully')
    );
    yield put({ type: GET_PRODUCT_SEASONS_REQUEST });
    yield call(getAllProductSeasons);
  } catch (error) {
    yield put({
      type: REMOVE_PRODUCT_SEASONS_FAILED,
      payload: error?.response?.data?.errorMessage
    });
    yield put(setSnackbar(true, 'error', error?.response?.data?.errorMessage));
    yield put({ type: GET_PRODUCT_SEASONS_REQUEST });
    yield call(getAllProductSeasons);
  }
}
const deleteProductDB = async (id) => {
  return await api.delete(`/delete-seasons/${id}`);
};
// watcher saga
export function* deleteProductSaga() {
  yield takeEvery(REMOVE_PRODUCT_SEASONS_REQUEST, deleteProduct);
}
/*----------------------------------
         DELETE MULTIPLE ENTRY       
==================================*/
// export function* deleteMultipleEntrySaga() {
//   while (true) {
//     const { payload } = yield take(REMOVE_MULTIPLE_PRODUCT_SEASONS_REQUEST);
//     yield call(deleteMultipleEntries, payload.id);
//     yield put({
//       type: REMOVE_MULTIPLE_PRODUCT_SEASONS_SUCCESS,
//       payload: { id: payload.id }
//     });
//     yield put({ type: GET_PRODUCT_SEASONS_REQUEST });
//     yield call(getAllProductSeasons);
//   }
// }

// async function deleteMultipleEntries(ids) {
//   await Promise.all(ids.map((id) => api.delete(`/delete-seasons/${id}`)));
// }
function* deleteProductSeasonMultiple(action) {
  const { id } = action.payload;
  try {
    yield call(deleteProductSeasonMultiDB, id);
    yield put({
      type: REMOVE_MULTIPLE_PRODUCT_SEASONS_SUCCESS,
      payload: { id: id }
    });
    yield put(
      setSnackbar(true, 'error', 'Product Seasons Deleted successfully')
    );
    yield put({ type: REMOVE_MULTIPLE_PRODUCT_SEASONS_REQUEST });
    yield call(getAllProductSeasons);
  } catch (error) {
    yield put({
      type: REMOVE_MULTIPLE_PRODUCT_SEASONS_FAILED,
      payload: error?.response?.data?.errorMessage
    });
    yield put(setSnackbar(true, 'error', error?.response?.data?.errorMessage));
    yield put({ type: GET_PRODUCT_SEASONS_REQUEST });
    yield call(getAllProductSeasons);
  }
}
const deleteProductSeasonMultiDB = async (ids) => {
  return await Promise.all(
    ids.map((id) => api.delete(`/delete-seasons/${id}`))
  );
};
// watcher saga
export function* deleteProductSeasonSaga() {
  yield takeEvery(
    REMOVE_MULTIPLE_PRODUCT_SEASONS_REQUEST,
    deleteProductSeasonMultiple
  );
}

/*----------------------------------
         ADD PRODUCT SEASON       
==================================*/
export function* addEntrySaga() {
  yield takeLatest(ADD_PRODUCT_SEASONS_REQUEST, addEntryToDb);
}

function* addEntryToDb({ payload }) {
  try {
    const result = yield call(addEntry, payload);
    yield put({ type: ADD_PRODUCT_SEASONS_SUCCESS, payload: result });
    yield put(setSnackbar(true, 'success', 'Season Added successfully'));
    yield put({ type: GET_PRODUCT_SEASONS_REQUEST });
    yield call(getAllProductSeasons);
  } catch (error) {
    yield put(addProductSeasonError(error));
    yield put(setSnackbar(true, 'error', 'Something Went Wrong'));
  }
}

async function addEntry({
  id,
  season_name,
  active,
  created_date_time,
  last_modified_time
}) {
  const { data } = await api.post(`/save-seasons`, {
    id,
    season_name,
    active,
    created_date_time,
    last_modified_time
  });
  return data;
}

/*----------------------------------
       UPDATE PRODUCT SEASON        
==================================*/

export function* updateEntrySaga() {
  yield takeEvery(UPDATE_PRODUCT_SEASONS_REQUEST, updateEntryToDb);
}

function* updateEntryToDb({ payload }) {
  try {
    const result = yield call(updateEntry, payload);
    yield put({ type: UPDATE_PRODUCT_SEASONS_SUCCESS, payload: result });

    // yield delay(1000);
    yield put(setSnackbar(true, 'success', 'Season Updated successfully'));
    yield put({ type: GET_PRODUCT_SEASONS_REQUEST });
    yield call(getAllProductSeasons);
  } catch (error) {
    yield put(setSnackbar(true, 'error', 'Something Went Wrong'));
  }
}

async function updateEntry(payload) {
  const { id, entry } = payload;
  const { season_name, active, created_date_time, last_modified_time } = entry;
  const { data } = await api.put(`/update-seasons/${id}`, {
    id,
    season_name,
    active,
    created_date_time,
    last_modified_time
  });
  return data;
}
