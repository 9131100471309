import {
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_FAILED,
  REMOVE_MULTIPLE_PRODUCTS_SUCCESS,
  REMOVE_PRODUCTS_SUCCESS,
  ADD_PRODUCTS_SUCCESS,
  ADD_PRODUCTS_FAILED,
  UPDATE_PRODUCTS_SUCCESS,
  GET_SINGLE_PRODUCTS_FAILED,
  GET_SINGLE_PRODUCTS_SUCCESS,
  GET_PRODUCTS_REQUEST,
  GET_SINGLE_PRODUCTS_REQUEST,
  REMOVE_PRODUCTS_REQUEST,
  ADD_PRODUCTS_REQUEST,
  UPDATE_PRODUCTS_REQUEST
} from 'redux/constants/ProductConstants/products.constants';

const productsReducer = (
  state = {
    loading: true,
    error: false,
    products: [],
    product: {},
    page: 1,
    first: true,
    last: true,
    loadingButton: false
  },
  action
) => {
  const { products } = state;
  let newProducts;
  switch (action.type) {
    case GET_PRODUCTS_REQUEST:
      return {
        ...state,
        products: [],
        loading: true
      };
    case GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        products: [...action.payload.content],
        page: action.payload.page,
        pages: action.payload.totalElements,
        first: action.payload.first,
        last: action.payload.last,
        size: action.payload.size,
        searchValue: action.payload.searchValue,
        loading: false
      };
    case GET_PRODUCTS_FAILED:
      return {
        ...state,
        error: action.payload,
        products: [...state.products],
        loading: false
      };
    case GET_SINGLE_PRODUCTS_REQUEST:
      return {
        ...state,
        product: {},
        loadingSingle: true
      };
    case GET_SINGLE_PRODUCTS_SUCCESS:
      return {
        ...state,
        product: action.payload,
        loadingSingle: false
      };
    case GET_SINGLE_PRODUCTS_FAILED:
      return {
        ...state,
        error: action.payload,
        product: {},
        loading: false
      };
    case ADD_PRODUCTS_REQUEST:
      newProducts = {
        ...state,
        loadingButton: true
      };
      return newProducts;
    case ADD_PRODUCTS_SUCCESS:
      newProducts = {
        ...state,
        products: [...products, action.payload],
        loadingButton: false
      };
      return newProducts;
    case ADD_PRODUCTS_FAILED:
      return {
        ...state,
        error: action.payload,
        products: [...state.products],
        loadingButton: false
      };
    case REMOVE_PRODUCTS_REQUEST:
      return { ...state, loading: true };
    case REMOVE_PRODUCTS_SUCCESS:
      newProducts = products.filter(
        (product) => product.id !== action.payload.id
      );
      return { loading: true, products: newProducts, error: false };
    case REMOVE_MULTIPLE_PRODUCTS_SUCCESS:
      console.log(action.payload);
      newProducts = products.filter(
        (item) => !action.payload.id.includes(item).id
      );
      console.log(action.payload);
      return { loading: false, products: newProducts, error: false };
    case UPDATE_PRODUCTS_REQUEST:
      return { ...state, loadingButton: true };
    case UPDATE_PRODUCTS_SUCCESS:
      const { id, ...entry } = action.payload;
      console.log(action.payload, 'action.payload');
      const {
        name,
        productType,
        active,
        sku,
        barcode,
        custom_field,
        description,
        sales_channels,
        product_types,
        product_suppliers,
        product_brands,
        product_tags,
        product_seasons,
        loyaltyPoints,
        optional_extra_products,
        units_of_measure,
        additional_units_of_measure,
        part_composite_enabled,
        serial_number_enabled,
        supplier_code,
        track_inventory,
        continue_selling_no_inventory,
        product_outlets,
        created_date_time,
        last_modified_time,
        component_products,
        unit,
        hsn_code,
        rack_no,
        image_url,
        image_id,
        all_same_price
      } = entry;
      newProducts = [...products];
      const existingProduct = state.products.find(
        (product) => product.id === id
      );
      console.log();
      if (existingProduct) {
        existingProduct.name = name;
        existingProduct.active = active;
        existingProduct.sku = sku;
        existingProduct.barcode = barcode;
        existingProduct.custom_field = custom_field;
        existingProduct.description = description;
        existingProduct.sales_channels = sales_channels;
        existingProduct.product_types = product_types;
        existingProduct.product_suppliers = product_suppliers;
        existingProduct.product_brands = product_brands;
        existingProduct.product_tags = product_tags;
        existingProduct.product_seasons = product_seasons;
        existingProduct.loyaltyPoints = loyaltyPoints;
        existingProduct.optional_extra_products = optional_extra_products;
        existingProduct.units_of_measure = units_of_measure;
        existingProduct.additional_units_of_measure =
          additional_units_of_measure;
        existingProduct.part_composite_enabled = part_composite_enabled;
        existingProduct.serial_number_enabled = serial_number_enabled;
        existingProduct.supplier_code = supplier_code;
        existingProduct.track_inventory = track_inventory;
        existingProduct.continue_selling_no_inventory =
          continue_selling_no_inventory;
        existingProduct.product_outlets = product_outlets;
        existingProduct.created_date_time = created_date_time;
        existingProduct.last_modified_time = last_modified_time;
        existingProduct.created_date_time = created_date_time;
        existingProduct.unit = unit;
        existingProduct.hsn_code = hsn_code;
        existingProduct.rack_no = rack_no;
        existingProduct.image_url = image_url;
        existingProduct.image_id = image_id;
        existingProduct.all_same_price = all_same_price;
      }
      return { products: newProducts, loadingButton: false };

    default:
      return state;
  }
};
export default productsReducer;
