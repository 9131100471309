import {
  FormControlLabel,
  Checkbox as MuiCheckbox,
  FormControl
} from '@material-ui/core';
import React from 'react';

const Checkbox = (props) => {
  const {
    name,
    label,
    onChange,
    value = false,
    indeterminate,
    transformsize,
    fontsize,
    ...rest
  } = props;
  const convertToDefaultEventParameter = (name, value) => ({
    target: {
      name,
      value
    }
  });
  return (
    <FormControl style={{ marginBottom: 0, marginTop: 0 }}>
      <FormControlLabel
        control={
          <MuiCheckbox
            indeterminate={indeterminate}
            checked={value}
            onChange={(e) =>
              onChange(convertToDefaultEventParameter(name, e.target.checked))
            }
            name={name}
            color="primary"
          />
        }
        label={<span style={{ fontSize: fontsize }}>{label}</span>}
        {...rest}
      />
    </FormControl>
  );
};

export default Checkbox;
