import {
  take,
  call,
  put,
  takeLatest,
  takeEvery,
  delay
} from '@redux-saga/core/effects';
import {
  GET_PRODUCT_TYPES_REQUEST,
  REMOVE_MULTIPLE_PRODUCT_TYPES_REQUEST,
  REMOVE_MULTIPLE_PRODUCT_TYPES_SUCCESS,
  REMOVE_PRODUCT_TYPES_REQUEST,
  REMOVE_PRODUCT_TYPES_SUCCESS,
  ADD_PRODUCT_TYPES_REQUEST,
  UPDATE_PRODUCT_TYPES_REQUEST,
  UPDATE_PRODUCT_TYPES_SUCCESS,
  UPDATE_PRODUCT_TYPES_FAILED,
  GET_SINGLE_PRODUCT_TYPES_REQUEST,
  REMOVE_PRODUCT_TYPES_FAILED,
  REMOVE_MULTIPLE_PRODUCT_TYPES_FAILED
} from 'redux/constants/ProductConstants/product.types.constants';
import {
  populateProductTypes,
  populateProductTypesError,
  addProductTypeError,
  populateSingleProductTypeFailed,
  populateSingleProductType,
  addProductTypeSuccess
} from 'redux/actions/ProductActions/product.types.actions';
import { setSnackbar } from 'redux/actions/UIComponentActions/snackbar.action';
import api from 'utils/api';

import { loadingButtonDone } from 'redux/actions/UIComponentActions/loading.button.action';
import { closeDrawer } from 'redux/actions/UIComponentActions/drawer.actions';
import { store } from 'index';
import { fileUploadDone } from 'redux/actions/UIComponentActions/file.upload';

export function* getAllProductTypes() {
  yield takeLatest(GET_PRODUCT_TYPES_REQUEST, fetchProducts);
}
function* fetchProducts(payload) {
  const {
    page: currentPage,
    size,
    orderBy: sortField,
    order: sortDir,
    searchValue: query
  } = payload;
  try {
    const { data } = yield api.get('/getAll-types', {
      params: {
        currentPage: currentPage + 1 || 1,
        size: size || 8,
        sortField: sortField || 'type_name',
        sortDir: sortDir || 'asc',
        query: query || ''
      }
    });
    yield delay(400);
    yield put(populateProductTypes(data));
    yield put(loadingButtonDone(null));
  } catch (err) {
    yield put(populateProductTypesError(err));
    if (err.response.status !== 404) {
      yield put(setSnackbar(true, 'error', err.message));
    }
  }
}

/*----------------------------------
         DELETE SINGLE ENTRY       
==================================*/
// export function* deleteEntrySaga() {
//   while (true) {
//     const { payload } = yield take(REMOVE_PRODUCT_TYPES_REQUEST);
//     yield call(deleteEntries, payload.id);
//     yield put({
//       type: REMOVE_PRODUCT_TYPES_SUCCESS,
//       payload: { id: payload.id }
//     });
//   }
// }

// async function deleteEntries(id) {
//   await api.delete(`/delete-types/${id}`);
// }
function* deleteProduct(action) {
  const { id } = action.payload;
  try {
    yield call(deleteProductDB, id);
    yield put({
      type: REMOVE_PRODUCT_TYPES_SUCCESS,
      payload: { id: id }
    });
    yield put(closeDrawer({ modalProps: { open: false } }));
    yield put(setSnackbar(true, 'error', 'Product Type Deleted successfully'));
    yield put({ type: GET_PRODUCT_TYPES_REQUEST });
    yield call(getAllProductTypes);
  } catch (error) {
    yield put({
      type: REMOVE_PRODUCT_TYPES_FAILED,
      payload: error?.response?.data?.errorMessage
    });
    yield put(setSnackbar(true, 'error', error?.response?.data?.errorMessage));
    yield put({ type: GET_PRODUCT_TYPES_REQUEST });
    yield call(getAllProductTypes);
  }
}
const deleteProductDB = async (id) => {
  return await api.delete(`/delete-types/${id}`);
};
// watcher saga
export function* deleteProductSaga() {
  yield takeEvery(REMOVE_PRODUCT_TYPES_REQUEST, deleteProduct);
}
/*----------------------------------
         DELETE MULTIPLE ENTRY       
==================================*/
// export function* deleteMultipleEntrySaga() {
//   while (true) {
//     const { payload } = yield take(REMOVE_MULTIPLE_PRODUCT_TYPES_REQUEST);
//     console.log(payload, 'payload delete');
//     yield call(deleteMultipleEntries, payload.id);
//     yield put({
//       type: REMOVE_MULTIPLE_PRODUCT_TYPES_SUCCESS,
//       payload: { id: payload.id }
//     });
//     // yield call(getAllProductTypes);
//     yield put(
//       setSnackbar(
//         true,
//         'error',
//         `${payload.id.length} Product(s) Type Deleed Successfully`
//       )
//     );
//     // yield call(getAllProductTypes);
//   }
// }

// async function deleteMultipleEntries(ids) {
//   await Promise.all(ids.map((id) => api.delete(`/delete-types/${id}`)));
// }
function* deleteProductTypeMultiple(action) {
  const { id } = action.payload;
  try {
    yield call(deleteProductTypeMultiDB, id);
    yield put({
      type: REMOVE_MULTIPLE_PRODUCT_TYPES_SUCCESS,
      payload: { id: id }
    });
    yield put(setSnackbar(true, 'error', 'Product Types Deleted successfully'));
    yield put({ type: REMOVE_MULTIPLE_PRODUCT_TYPES_REQUEST });
    yield call(getAllProductTypes);
  } catch (error) {
    yield put({
      type: REMOVE_MULTIPLE_PRODUCT_TYPES_FAILED,
      payload: error?.response?.data?.errorMessage
    });
    yield put(setSnackbar(true, 'error', error?.response?.data?.errorMessage));
    yield put({ type: GET_PRODUCT_TYPES_REQUEST });
    yield call(getAllProductTypes);
  }
}
const deleteProductTypeMultiDB = async (ids) => {
  return await Promise.all(ids.map((id) => api.delete(`/delete-types/${id}`)));
};
// watcher saga
export function* deleteProductTypeSaga() {
  yield takeEvery(
    REMOVE_MULTIPLE_PRODUCT_TYPES_REQUEST,
    deleteProductTypeMultiple
  );
}
/*----------------------------------
         ADD PRODUCT TYPE       
==================================*/
export function* addEntrySaga() {
  yield takeLatest(ADD_PRODUCT_TYPES_REQUEST, addEntryToDb);
}

function* addEntryToDb({ payload }) {
  try {
    // const { formData, order, orderBy, page, rowsPerPage } = payload;
    const result = yield call(addEntry, payload);
    // yield put({ type: ADD_PRODUCT_TYPES_SUCCESS, payload: result });
    // console.log(payload, result, 'saga');
    yield put(addProductTypeSuccess(result));

    yield put(closeDrawer({ modalProps: { open: false } }));

    yield put(
      setSnackbar(true, 'success', 'Product Category Added Successfully')
    );
    yield put({ type: GET_PRODUCT_TYPES_REQUEST });
    yield call(getAllProductTypes);
  } catch (error) {
    yield put(addProductTypeError(error));
  }
}

async function addEntry(payload) {
  const formData = new FormData();

  const { data } = await api.post(`/save-types`, payload.formData, {});
  formData.append('id', data.id);
  formData.append('name', payload.name);

  payload.noImages
    ? formData.append('file', payload.file)
    : formData.append('file', '');

  if (payload.noImages) {
    await api.put('/image-upload', formData, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    });
  }
  return data;
}

/*----------------------------------
       UPDATE PRODUCT TYPE        
==================================*/

export function* updateEntrySaga() {
  yield takeEvery(UPDATE_PRODUCT_TYPES_REQUEST, updateEntryToDb);
}

function* updateEntryToDb({ payload }) {
  try {
    const result = yield call(updateEntry, payload);
    yield delay(400);
    yield put({ type: UPDATE_PRODUCT_TYPES_SUCCESS, payload: result });
    yield put(closeDrawer({ modalProps: { open: false } }));
    yield put(setSnackbar(true, 'success', 'Category Updated Successfully'));
    yield put({ type: GET_PRODUCT_TYPES_REQUEST });
    yield call(getAllProductTypes);
  } catch (error) {
    yield put({ type: UPDATE_PRODUCT_TYPES_FAILED, payload: error });
    yield put(setSnackbar(true, 'error', 'Something Went Wrong !!!!'));
    console.log(error);
  }
}

async function updateEntry(payload) {
  const { id, entry } = payload;
  const formData = new FormData();
  console.log(payload);
  const { data } = await api.put(`/update-types/${id}`, entry.formData, {});
  formData.append('id', id);
  formData.append('name', entry.name);

  entry.noImages
    ? formData.append('file', entry.file)
    : formData.append('file', '');

  if (entry.noImages) {
    await api.put('/image-upload', formData, {
      headers: {
        'content-type': 'multipart/form-data'
      },
      onUploadProgress: (progressEvent) => {
        const progress = (progressEvent.loaded / progressEvent.total) * 100;
        store.dispatch(fileUploadDone(progress));
      }
    });
  }

  return data;
}

/*----------------------------------
      GET SINGLE PRODUCT TYPE          
==================================*/
export function* getSingleCustomerGroup() {
  yield takeLatest(GET_SINGLE_PRODUCT_TYPES_REQUEST, fetchSingleProductType);
}

function* fetchSingleProductType({ payload }) {
  try {
    const { data } = yield api.get(`/get-types/${payload}`);
    yield delay(400);
    yield put(populateSingleProductType(data));
  } catch (error) {
    yield put(populateSingleProductTypeFailed(error));
    if (error?.response?.status !== 404) {
      yield put(setSnackbar(true, 'error', error.message));
    }
  }
}
