import {
  GET_LOW_STOCK_REQUEST,
  GET_LOW_STOCK_SUCCESS,
  GET_LOW_STOCK_FAILED
} from 'redux/constants/InventoryConstants/inventory.dashboard.constants';
/* GET_LOW_STOCK_REQUEST */
const InventoryLowStockReducer = (
  state = {
    loading: false,
    low_stocks: [],
    error: false,
    page: 1,
    first: true,
    last: true
  },
  action
) => {
  const { low_stocks } = state;

  switch (action.type) {
    case GET_LOW_STOCK_REQUEST:
      return {
        ...state,
        low_stocks: [],
        loading: true
      };
    case GET_LOW_STOCK_SUCCESS:
      return {
        ...state,
        low_stocks: [...action.payload.content],
        page: action.payload.page,
        pages: action.payload.totalElements,
        first: action.payload.first,
        last: action.payload.last,
        size: action.payload.size,
        searchValue: action.payload.searchValue,
        loading: false
      };
    case GET_LOW_STOCK_FAILED:
      return {
        ...state,
        error: action.payload,
        low_stocks: [...low_stocks],
        loading: false
      };

    default:
      return state;
  }
};
export default InventoryLowStockReducer;
