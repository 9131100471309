import { LOCATION_CHANGE } from 'connected-react-router';

export default function routerLocations(state = { location: [] }, action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      const { location } = state;
      return {
        ...state,
        location:
          location.length < 4
            ? [...location, action.payload]
            : [...location, action.payload].slice(
                Math.max(location.length - 4, 1)
              )
      };
    default:
      return state;
  }
}
