import './index.css';
import ReactDOM from 'react-dom';
import React from 'react';
import App from './app/App';
import { PersistGate } from 'redux-persist/integration/react';
// import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import storeConfig, { history, persistor } from 'redux/store/configureStore';
import { ConnectedRouter as Router } from 'connected-react-router';
import MaterialDrawerRedux from 'components/DrawerRedux/DrawerRedux';
// import SliderDrawerContainer from 'components/SliderDrawerContainer';
// import history from 'utils/history';
// import history from 'connected-react-router';
export const store = storeConfig();

// const history = useHistory();
// store.subscribe(() => {
//   console.log(store.getState(), "store state");
// });
ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      {/* <PersistGate persistor={persistor}> */}
      <App />

      {/* </PersistGate> */}
    </Router>
    {/* <SliderDrawerContainer /> */}
  </Provider>,
  document.getElementById('root')
);
