import { call, put, delay, takeEvery, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import {
  GET_ALL_EMAIL_TEMPLATES_REQUEST,
  GET_SINGLE_EMAIL_TEMPLATES_REQUEST,
  UPDATE_EMAIL_TEMPLATES_REQUEST,
  UPDATE_EMAIL_TEMPLATES_SUCCESS
} from 'redux/constants/StoreSetupConstants/email.templates.constants';
import {
  populateEmailTemplates,
  populateEmailTemplatesFailed,
  populateSingleEmailTemplate,
  populateSingleEmailTemplateFailed
} from 'redux/actions/StoreSetupActions/email.templates.action';
import { setSnackbar } from 'redux/actions/UIComponentActions/snackbar.action';
import api from 'utils/api';
const baseURLProd = 'http://216.10.244.34:10005/rp/api/v1';

// /*----------------------------------
//        GET SINGLE USER
// ==================================*/
export function* onFetchProductStart() {
  yield takeLatest(GET_SINGLE_EMAIL_TEMPLATES_REQUEST, fetchProduct);
}
function* fetchProduct({ payload }) {
  try {
    if (payload) {
      const { data } = yield api.get(`/get-emailTemp/${payload}`);
      yield delay(400);
      yield put(populateSingleEmailTemplate(data));
    }
  } catch (err) {
    yield put(populateSingleEmailTemplateFailed(err.response.data.message));
  }
}

/*----------------------------------
    SYSTEM USER >> GET ALL EMAIL_TEMPLATE          
==================================*/

// export function* getAllEmailTemplates() {
//   yield take(GET_ALL_EMAIL_TEMPLATES_REQUEST);
//   try {
//     const { data } = yield call(axios, `${baseURLProd}/getAll-emailTemps`);
//     yield put(populateEmailTemplates(data));
//   } catch (error) {
//     yield put(populateEmailTemplatesFailed(error));
//     if (error.response.status !== 404)
//       yield put(setSnackbar(true, 'error', 'Something Went Wrong !!'));
//   }
// }

export function* getAllEmailTemplates() {
  yield takeLatest(GET_ALL_EMAIL_TEMPLATES_REQUEST, fetchProducts);
}
// function* fetchProducts() {
//   try {
//     const { data } = yield call(axios, `${baseURLProd}/getAll-emailTemps`);

//     yield put(populateEmailTemplates(data));
//   } catch (error) {
//     yield put(populateEmailTemplatesFailed(error));
//     if (error.response.status !== 404) {
//       yield put(setSnackbar(true, 'error', error.message));
//     }
//   }
// }
function* fetchProducts(payload) {
  const {
    page: currentPage,
    size,
    orderBy: sortField,
    order: sortDir,
    searchValue: query
  } = payload;
  try {
    const { data } = yield api.get(`/getAll-emailTemps`, {
      params: {
        currentPage: currentPage + 1 || 1,
        size: size || 8,
        sortField: sortField || 'temp_email',
        sortDir: sortDir || 'asc',
        query: query || ''
      }
    });
    yield delay(400);
    yield put(populateEmailTemplates(data));
  } catch (error) {
    yield put(populateEmailTemplatesFailed(error));
    if (error.response.status !== 404) {
      yield put(setSnackbar(true, 'error', error.message));
    }
  }
}

/*----------------------------------
       SYSTEM USER >> UPDATE USER        
==================================*/

export function* updateSystemUserSaga() {
  yield takeEvery(UPDATE_EMAIL_TEMPLATES_REQUEST, updateSystemUser);
}

function* updateSystemUser({ payload }) {
  try {
    const result = yield call(updateAsyncEmailTemplates, payload);
    yield put({ type: UPDATE_EMAIL_TEMPLATES_SUCCESS, payload: result });
    yield put({ type: GET_ALL_EMAIL_TEMPLATES_REQUEST });
    yield call(getAllEmailTemplates);
    yield put(
      setSnackbar(true, 'success', 'Email Template Updated Successfully')
    );
  } catch (error) {
    if (error.response.status !== 404)
      yield put(setSnackbar(true, 'error', 'Something Went Wrong !!'));
  }
}

async function updateAsyncEmailTemplates(payload) {
  const { id, entry } = payload;

  const {
    temp_email,
    temp_subject,
    temp_body,
    active,
    loyalty_details_added,
    temp_body_reset
  } = entry;
  const { data } = await api.put(`/update-emailTemp/${id}`, {
    id,
    temp_email,
    temp_subject,
    temp_body,
    active,
    loyalty_details_added,
    temp_body_reset
  });
  return data;
}
