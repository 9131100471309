import {
  GET_SINGLE_CUSTOMER_GROUP_SUCCESS,
  GET_SINGLE_CUSTOMER_GROUP_FAILED,
  GET_ALL_CUSTOMER_GROUPS_REQUEST,
  GET_ALL_CUSTOMER_GROUPS_SUCCESS,
  GET_ALL_CUSTOMER_GROUPS_FAILED,
  ADD_CUSTOMER_GROUPS_SUCCESS,
  ADD_CUSTOMER_GROUPS_FAILED,
  UPDATE_CUSTOMER_GROUP_SUCCESS,
  UPDATE_CUSTOMER_GROUP_FAILED,
  REMOVE_MULTIPLE_CUSTOMER_GROUP_SUCCESS,
  REMOVE_CUSTOMER_GROUP_SUCCESS,
  GET_SINGLE_CUSTOMER_GROUP_REQUEST
} from 'redux/constants/CustomersConstants/customer.groups.constants';
/*----------------------------------
          CUSTOMER_GROUP REDUCER          
==================================*/
// export const customer_groupsReducer = (
//   state = { loading: true, error: false, customer_groups: [], customer_group: {} },
//   action
// ) => {
//   const { customer_groups } = state;
//   let newUser;
//   switch (action.type) {
//     case GET_ALL_CUSTOMER_GROUPS_REQUEST:
//       return {
//         ...state,
//         customer_groups: [],
//         loading: true
//       };
//     case GET_ALL_CUSTOMER_GROUPS_SUCCESS:
//       return {
//         ...state,
//         customer_groups: [action.payload],
//         loading: false
//       };
//     case GET_ALL_CUSTOMER_GROUPS_FAILED:
//       return {
//         ...state,
//         error: action.payload,
//         customer_groups: [...state.customer_groups],
//         loading: false
//       };
//     case GET_SINGLE_CUSTOMER_GROUP_SUCCESS:
//       return {
//         ...state,
//         customer_group: action.payload,
//         loading: false
//       };
//     case GET_SINGLE_CUSTOMER_GROUP_FAILED:
//       return {
//         ...state,
//         error: action.payload,
//         loading: false
//       };
//     case UPDATE_CUSTOMER_GROUP_SUCCESS:
//       const {
//         customer_group_id,
//         first_name,
//         last_name,
//         email,
//         phone,
//         country,
//         city,
//         // password,
//         accept_terms,
//         created_date_time,
//         last_modified_time,
//         role,
//         store_setup,
//         store_feature_setup,
//         store_general_rules,
//         store_inven_notif,
//         store_web_addresses
//         // tempPassword
//       } = action.payload;
//       // const { first_name, last_name, country, city, phone, role } = entry;
//       newUser = [...customer_groups];
//       const existingUser = state.customer_groups.find(
//         (customer_group) => customer_group.customer_group_id === customer_group_id
//       );
//       if (existingUser) {
//         existingUser.first_name = first_name;
//         existingUser.last_name = last_name;
//         existingUser.country = country;
//         existingUser.city = city;
//         existingUser.created_date_time = created_date_time;
//         existingUser.last_modified_time = last_modified_time;
//         existingUser.phone = phone;
//         existingUser.accept_terms = accept_terms;
//         existingUser.email = email;
//         existingUser.role = role;
//         existingUser.store_setup = store_setup;
//         existingUser.store_feature_setup = store_feature_setup;
//         existingUser.store_general_rules = store_general_rules;
//         existingUser.store_inven_notif = store_inven_notif;
//         existingUser.store_web_addresses = store_web_addresses;
//         existingUser.store_web_addresses = store_web_addresses;
//       }

//       return { customer_groups: newUser };
//     case UPDATE_CUSTOMER_GROUP_FAILED:
//       return {
//         ...state,
//         customer_groups: [...state.customer_groups],
//         error: action.payload,
//         loading: false
//       };
//     default:
//       return state;
//   }
// };

/*----------------------------------
          CUSTOMER_GROUP REDUCER          
==================================*/

export const customerGroupsReducer = (
  state = {
    loading: true,
    error: false,
    customer_groups: [],
    customer_group: {},
    page: 1,
    first: true,
    last: true
  },
  action
) => {
  const { customer_groups } = state;
  let newUser;
  switch (action.type) {
    case GET_ALL_CUSTOMER_GROUPS_REQUEST:
      return {
        ...state,
        customer_groups: [],
        loading: true
      };
    case GET_ALL_CUSTOMER_GROUPS_SUCCESS:
      return {
        ...state,
        customer_groups: [...action.payload.content],
        page: action.payload.page,
        pages: action.payload.totalElements,
        first: action.payload.first,
        last: action.payload.last,
        size: action.payload.size,
        searchValue: action.payload.searchValue,
        loading: false
      };
    case GET_ALL_CUSTOMER_GROUPS_FAILED:
      return {
        ...state,
        error: action.payload,
        customer_groups: [...state.customer_groups],
        loading: false
      };
    case GET_SINGLE_CUSTOMER_GROUP_REQUEST:
      return {
        ...state,
        customer_group: {},
        loading: false
      };
    case GET_SINGLE_CUSTOMER_GROUP_SUCCESS:
      return {
        ...state,
        customer_group: action.payload,
        loading: false
      };
    case GET_SINGLE_CUSTOMER_GROUP_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case ADD_CUSTOMER_GROUPS_SUCCESS:
      newUser = {
        ...state,
        customer_groups: [...customer_groups, action.payload]
      };
      console.log(newUser, 'reducer');
      return newUser;
    case ADD_CUSTOMER_GROUPS_FAILED:
      console.log(action.payload, 'reducer');
      return {
        customer_groups: [...state.customer_groups],
        error: action.payload
      };
    case UPDATE_CUSTOMER_GROUP_SUCCESS:
      const {
        id,
        name,
        enable_discount,
        customerGroup_discountType,
        discount_percent,
        upload_fileName,
        created_date_time,
        last_modified_time,
        active
      } = action.payload;

      newUser = [...customer_groups];
      const existingUser = state.customer_groups.find(
        (customer_group) => customer_group.id === id
      );
      if (existingUser) {
        existingUser.name = name;
        existingUser.enable_discount = enable_discount;
        existingUser.customerGroup_discountType = customerGroup_discountType;
        existingUser.discount_percent = discount_percent;
        existingUser.upload_fileName = upload_fileName;
        existingUser.created_date_time = created_date_time;
        existingUser.last_modified_time = last_modified_time;
        existingUser.active = active;
      }
      return { customer_groups: newUser };
    case UPDATE_CUSTOMER_GROUP_FAILED:
      return {
        customer_groups: [...state.customer_groups],
        error: action.payload
      };
    case REMOVE_CUSTOMER_GROUP_SUCCESS:
      newUser = customer_groups.filter(
        (customer_group) => customer_group.id !== action.payload.id
      );
      return { loading: false, customer_groups: newUser, error: false };
    case REMOVE_MULTIPLE_CUSTOMER_GROUP_SUCCESS:
      newUser = customer_groups.filter(
        (customer_group) => !action.payload.id.includes(customer_group.id)
      );
      return { loading: false, customer_groups: newUser, error: false };
    default:
      return state;
  }
};
