import { lazy } from 'react';

const Login = lazy(() => import('screen/Login/index'));
const ForgetPassword = lazy(() => import('screen/ForgetPassword/index'));
const ResetForgetPassword = lazy(() =>
  import('screen/ResetForgetPassword/index')
);
const Customers = lazy(() => import('screen/Customers/Customer/index'));
const CustomerGroup = lazy(() =>
  import('screen/Customers/CustomerGroup/index')
);
const Register = lazy(() => import('screen/Register/index'));
const Dashboard = lazy(() => import('screen/Dashboard/index'));
const MyInventory = lazy(() => import('screen/Inventory/MyInventory/index'));
const NewSale = lazy(() => import('screen/POS/NewSale/index'));
const SalesHistory = lazy(() => import('screen/POS/SalesHistory/index'));
const CashRegister = lazy(() => import('screen/POS/CashRegister/index'));
const InventoryDashboard = lazy(() =>
  import('screen/Inventory/InventoryDashboard/index')
);

const OpenPurchaseOrdersAwaitingDelivery = lazy(() =>
  import('screen/Inventory/OpenPurchaseOrdersAwaitingDelivery/index')
);
const DraftPurchaseOrdersSupplier = lazy(() =>
  import('screen/Inventory/DraftPurchaseOrdersSupplier/index')
);

const LowStockVariants = lazy(() =>
  import('screen/Inventory/LowStockVariants/index')
);
const Purchases = lazy(() => import('screen/Inventory/Purchases/index'));
const Transfer = lazy(() => import('screen/Inventory/Transfer/index'));
const CountStockable = lazy(() =>
  import('screen/Inventory/CountStockable/index')
);
const InventorySuppliers = lazy(() =>
  import('screen/Inventory/InventorySuppliers/index')
);
const AddInventoryPurchase = lazy(() =>
  import('screen/Inventory/Purchases/AddInventoryPurchase/index')
);
const ProductBrands = lazy(() => import('screen/Product/ProductBrands/index'));
const Products = lazy(() => import('screen/Product/Products/'));
const AddOrEditProducts = lazy(() =>
  import('screen/Product/Products/AddOrEditProducts/index')
);
const ProductSeasons = lazy(() =>
  import('screen/Product/ProductSeasons/index')
);
const ProductTags = lazy(() => import('screen/Product/ProductTags/index'));
const ProductTypes = lazy(() => import('screen/Product/ProductTypes/index'));
const Users = lazy(() => import('screen/Users/index'));
const Roster = lazy(() => import('screen/Roster/index'));
const Profile = lazy(() => import('screen/Profile/index'));
const ResetPassword = lazy(() => import('screen/ResetPassword/index'));
const General = lazy(() => import('screen/StoreSetup/General/index'));
const OutletsAndRegisters = lazy(() =>
  import('screen/StoreSetup/OutletsAndRegisters/index')
);
const EmailTemplates = lazy(() =>
  import('screen/StoreSetup/EmailTemplates/index')
);
const LoyaltyPoints = lazy(() =>
  import('screen/StoreSetup/LoyaltyPoints/index')
);
const MyAccount = lazy(() => import('screen/StoreSetup/MyAccount/index'));
const TaxRules = lazy(() => import('screen/StoreSetup/TaxRules/index'));
const PaymentTypes = lazy(() => import('screen/StoreSetup/PaymentTypes/index'));
const Integrations = lazy(() => import('screen/StoreSetup/Integrations/index'));
const ReceiptTemplates = lazy(() =>
  import('screen/StoreSetup/ReceiptTemplates/index')
);

export const NAV_LIST = [
  {
    name: 'Login',
    path: '/login',
    component: Login
  },
  {
    name: 'Register',
    path: '/register',
    component: Register
  },
  {
    name: 'ForgetPassword',
    path: '/forget-password',
    component: ForgetPassword
  },
  {
    name: 'ResetForgetPassword',
    path: `/forgot-Password/:token`,
    component: ResetForgetPassword
  }
];
export const PAGE_LIST = [
  {
    name: 'Home',
    path: '/',
    component: Dashboard
  },
  {
    name: 'Dashboard',
    path: '/dashboard',
    component: Dashboard
  },
  {
    name: 'Product',
    path: '/products',
    component: Products
  },
  {
    name: 'AddProducts',
    path: '/products/add',
    component: AddOrEditProducts
  },
  {
    name: 'EditProducts',
    path: '/products/edit/:id',
    component: AddOrEditProducts
  },
  {
    name: 'AddInventoryPurchase',
    path: '/inventory/purchases/add',
    component: AddInventoryPurchase
  },
  {
    name: 'EditInventoryPurchase',
    path: '/inventory/purchases/edit/:id',
    component: AddInventoryPurchase
  },
  {
    name: 'ProductTypes',
    path: '/product-types',
    component: ProductTypes
  },
  {
    name: 'ProductBrands',
    path: '/product-brands',
    component: ProductBrands
  },
  {
    name: 'ProductTags',
    path: '/product-tags',
    component: ProductTags
  },
  {
    name: 'ProductSeasons',
    path: '/product-seasons',
    component: ProductSeasons
  },
  {
    name: 'InventoryDashboard',
    path: '/inventory',
    component: InventoryDashboard
  },
  {
    name: 'OpenPurchaseOrdersAwaitingDelivery',
    path: '/open-purchase-orders-awaiting-delivery',
    component: OpenPurchaseOrdersAwaitingDelivery
  },
  {
    name: 'DraftPurchaseOrdersSupplier',
    path: '/draft-purchase-orders-supplier',
    component: DraftPurchaseOrdersSupplier
  },

  {
    name: 'LowStockVariants',
    path: '/low-stock-variants',
    component: LowStockVariants
  },
  {
    name: 'MyInventory',
    path: '/inventory/my',
    component: MyInventory
  },
  {
    name: 'CountStockable',
    path: '/inventory/count-stockable',
    component: CountStockable
  },
  {
    name: 'Purchases',
    path: '/inventory/purchases',
    component: Purchases
  },
  {
    name: 'Transfer',
    path: '/inventory/transfer',
    component: Transfer
  },
  {
    name: 'InventorySuppliers',
    path: '/inventory/suppliers',
    component: InventorySuppliers
  },
  {
    name: 'Customer',
    path: '/customer',
    component: Customers
  },
  {
    name: 'CustomerGroup',
    path: '/customer-groups',
    component: CustomerGroup
  },
  // {
  //   name: "Reporting",
  //   path: "/reporting",
  //   component: Report,
  // },
  {
    name: 'User',
    path: '/users',
    component: Users
  },
  {
    name: 'Roster',
    path: '/roster',
    component: Roster
  },
  {
    name: 'Profile',
    path: '/my-profile',
    component: Profile
  },
  {
    name: 'ResetPassword',
    path: '/reset-password',
    component: ResetPassword
  },
  {
    name: 'EmailTemplates',
    path: '/email-templates',
    component: EmailTemplates
  },
  {
    name: 'General',
    path: '/general',
    component: General
  },
  {
    name: 'Integrations',
    path: '/integrations',
    component: Integrations
  },
  {
    name: 'LoyaltyPoints',
    path: '/loyalty-points',
    component: LoyaltyPoints
  },
  {
    name: 'MyAccount',
    path: '/my-account',
    component: MyAccount
  },
  {
    name: 'OutletsAndRegisters',
    path: '/outlets-and-registers',
    component: OutletsAndRegisters
  },
  {
    name: 'PaymentTypes',
    path: '/payment-types',
    component: PaymentTypes
  },
  {
    name: 'ReceiptTemplates',
    path: '/receipt-templates',
    component: ReceiptTemplates
  },
  {
    name: 'TaxRules',
    path: '/tax-rules',
    component: TaxRules
  },
  {
    name: 'NewSale',
    path: '/enter-new-sale',
    component: NewSale
  },
  {
    name: 'SalesHistory',
    path: '/sales-history',
    component: SalesHistory
  },
  {
    name: 'CashRegister',
    path: '/cash-register',
    component: CashRegister
  }
];
