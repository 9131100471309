import React from 'react';
import { Switch } from '@material-ui/core';

const MultiSwitchToggle = (props) => {
  const {
    obj, //  object, name, id, idOpen boolean
    objIdx, // index of the obj object
    handleOpenChange // on change function
  } = props;

  return (
    <>
      <Switch
        color="primary"
        checked={obj.open}
        onChange={() => {
          handleOpenChange(objIdx, !obj.open);
        }}
      />
    </>
  );
};

export default MultiSwitchToggle;
