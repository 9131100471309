import React, { useState, useEffect } from 'react';
import {
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';
import { useStyles, width, breakpoint } from './utils';
import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon
} from '@material-ui/icons';
import { NavLink } from 'react-router-dom';
import getItems from 'collections/dashboardSidemenu';

const NestedList = (props) => {
  const { setOpen } = props;
  const [selectedIndex, setSelectedIndex] = useState('');
  const classes = useStyles();
  const items = getItems();
  const handleClick = (index) => {
    if (selectedIndex === index) {
      setSelectedIndex('');
    } else {
      setSelectedIndex(index);
    }
  };

  const handleSidebarClose = () => width < breakpoint && setOpen(false);
  return (
    <div key={items.list.map((_, index) => index)}>
      <List component="nav">
        {items.list.map((item, index) => {
          return (
            <div key={item.id}>
              <List key={item.id}>
                {item.subitems ? (
                  <>
                    {index === selectedIndex ? (
                      <ListItem
                        key={index}
                        button
                        onClick={() => {
                          handleClick(index);
                        }}
                        classes={{
                          root: classes.root,
                          selected: classes.selected
                        }}
                        selected
                      >
                        <ListItemIcon
                          fontSize="small"
                          // classes={{ root: classes.smallIcon }}
                          className={`${classes.selectedIcon}`}
                        >
                          {item.icon}
                        </ListItemIcon>
                        <ListItemText
                          classes={{ primary: classes.primaryMain }}
                          primary={item.primary}
                        />
                        {index === selectedIndex ? (
                          <ExpandLessIcon />
                        ) : (
                          <ExpandMoreIcon />
                        )}
                      </ListItem>
                    ) : (
                      <ListItem
                        key={index}
                        button
                        onClick={() => {
                          handleClick(index);
                        }}
                      >
                        <ListItemIcon className={classes.smallIcon}>
                          {item.icon}
                        </ListItemIcon>
                        <ListItemText
                          classes={{ primary: classes.primaryMain }}
                          primary={item.primary}
                        />
                        {index === selectedIndex ? (
                          <ExpandLessIcon />
                        ) : (
                          <ExpandMoreIcon />
                        )}
                      </ListItem>
                    )}

                    <Collapse
                      in={index === selectedIndex}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List component="div">
                        {item.subitems.map((sub, index) => {
                          return (
                            <List key={sub.id}>
                              <ListItem
                                className={classes.nested}
                                button
                                exact
                                strict
                                component={NavLink}
                                activeClassName={classes.active}
                                to={sub.to}
                                onClick={handleSidebarClose}
                              >
                                <ListItemIcon
                                  className={classes.nestedIcons}
                                  style={{ fontSize: '15px' }}
                                >
                                  {sub.icon}
                                </ListItemIcon>
                                <ListItemText
                                  className="nestedText"
                                  classes={{ primary: classes.primary }}
                                  primary={sub.primary}
                                />
                              </ListItem>
                            </List>
                          );
                        })}
                      </List>
                    </Collapse>
                  </>
                ) : (
                  <>
                    {index === selectedIndex ? (
                      <ListItem
                        button
                        exact
                        // id={}
                        strict
                        component={NavLink}
                        activeClassName={classes.single}
                        to={item.to}
                        key={item.id}
                        classes={{
                          root: classes.root,
                          selected: classes.selected
                        }}
                        selected
                      >
                        <ListItemIcon
                          fontSize="small"
                          component={IconButton}
                          className={classes.selectedIcon}
                        >
                          {item.icon}
                        </ListItemIcon>
                        <ListItemText
                          className={classes.navText}
                          primary={item.primary}
                        />
                      </ListItem>
                    ) : (
                      <ListItem
                        button
                        exact
                        strict
                        component={NavLink}
                        activeClassName={classes.single}
                        to={item.to}
                        key={item.id}
                        onClick={() => {
                          handleSidebarClose();
                          handleClick(index);
                        }}
                        // onClick={
                        //   // () => (width < breakpoint ? () => setOpen(false) : "")
                        //   // alert(item.id
                        //   () => {
                        //     handleClick(index);
                        //   }
                        // }
                      >
                        <ListItemIcon
                          // fontSize="small"
                          // component={IconButton}
                          className={classes.smallIcon}

                          // className={classes.iconsMain}
                        >
                          {item.icon}
                        </ListItemIcon>
                        <ListItemText
                          classes={{ primary: classes.primaryMain }}
                          primary={item.primary}
                        />
                      </ListItem>
                    )}
                  </>
                )}
              </List>
            </div>
          );
        })}
      </List>
    </div>
  );
};

export default NestedList;
