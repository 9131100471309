import React from 'react';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select as MuiSelect
} from '@material-ui/core';

const CountrySelect = (props) => {
  const {
    error = null,
    name,
    label,
    size,
    value,
    onChange,
    options,
    required,
    noTextLabel,
    typeSelect,
    ...others
  } = props;
  return (
    <FormControl
      size={size || 'small'}
      variant="outlined"
      required={required ? true : false}
      {...(error && { error: true })}
    >
      <InputLabel>{label}</InputLabel>
      <MuiSelect
        label={label}
        name={name}
        value={value}
        onChange={onChange}
        {...others}
      >
        <MenuItem value="" disabled>
          {noTextLabel}
        </MenuItem>
        {options.map((item) => (
          <MenuItem
            key={item.countryShortCode}
            value={item.countryName}
            required
          >
            {item.countryName}
          </MenuItem>
        ))}
      </MuiSelect>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export default CountrySelect;
