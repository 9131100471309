import { makeStyles } from '@material-ui/core/';
const drawerWidth = 200;
const width = window.innerWidth;
const breakpoint = 780;
const tabBreakPoint = 1024;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    transition: 'all 2000 ease-in-out'
  },
  logo: {
    marginRight: '13px',
    width: `${110}px`
  },
  searchInput: {
    opacity: '0.6',
    padding: `0px ${theme.spacing(1)}px`,
    fontSize: '0.8rem',
    '&:hover': {
      backgroundColor: '#f2f2f2 !important'
    },
    '& .MuiSvgIcon-root': {
      marginRight: theme.spacing(1)
    }
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    // background: theme.palette.secondary.main,
    backgroundColor: '#F3F6F8 !important',
    // color: theme.palette.secondary.dark,
    marginLeft: `${width < breakpoint ? 0 : `${-drawerWidth}px`} !important`
  },
  appBarShift: {
    width: '100%',
    marginLeft: 0,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px) !important`,
      marginLeft: `${drawerWidth}px !important`
    },
    [theme.breakpoints.down('sm')]: {
      width: `calc(100% - ${drawerWidth}px) !important`,
      marginLeft: 0
    },
    [theme.breakpoints.down('xs')]: {
      width: `100% !important`,
      marginLeft: 0
    },
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: `${theme.palette.primary.dark} !important`
  },
  iconButton: {
    color: '#fff !important'
  },
  hide: {
    display: 'none !important'
  },
  drawer: {
    width: `${drawerWidth}px !important`,
    transition: theme.transitions.create(['height', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  drawerPaper: {
    width: `${drawerWidth}px !important`,
    backgroundColor: '#1A2E54 !important',
    color: '#FFFFFF !important'
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // backgroundColor: sideMenuDrawer.backgroundColor,
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end'
  },
  drawerHeaderContent: {
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end'
  },
  nested: {
    paddingLeft: `${theme.spacing(4)} !important `
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: `${width < breakpoint ? 0 : `${-drawerWidth}px`}`,
    minHeight: '100vh',
    backgroundColor: '#EFF3FA !important'
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px) !important`,
      marginLeft: 0
    }
  },

  main: {
    width: width > tabBreakPoint ? '60%' : '100%'
    // backgroundColor: '#EFF3FB !important'
  }
}));

export { useStyles, width, tabBreakPoint, breakpoint, drawerWidth };
