/*----------------------------------
         INVENTORY DASHBOARD        
==================================*/

export const GET_INVENTORY_DASHBOARD_REQUEST =
  'GET_INVENTORY_DASHBOARD_REQUEST';
export const GET_INVENTORY_DASHBOARD_SUCCESS =
  'GET_INVENTORY_DASHBOARD_SUCCESS';
export const GET_INVENTORY_DASHBOARD_FAILED = 'GET_INVENTORY_DASHBOARD_FAILED';

/*----------------------------------
    INVENTORY AWAITING DELIVERY        
==================================*/

export const GET_AWAITING_DELIVERY_REQUEST = 'GET_AWAITING_DELIVERY_REQUEST';
export const GET_AWAITING_DELIVERY_SUCCESS = 'GET_AWAITING_DELIVERY_SUCCESS';
export const GET_AWAITING_DELIVERY_FAILED = 'GET_AWAITING_DELIVERY_FAILED';
/*----------------------------------
   INVENTORY DRAFT PO NOT YET SENT        
==================================*/

export const GET_DRAFT_PO_NOT_YET_SENT_REQUEST =
  'GET_DRAFT_PO_NOT_YET_SENT_REQUEST';
export const GET_DRAFT_PO_NOT_YET_SENT_SUCCESS =
  'GET_DRAFT_PO_NOT_YET_SENT_SUCCESS';
export const GET_DRAFT_PO_NOT_YET_SENT_FAILED =
  'GET_DRAFT_PO_NOT_YET_SENT_FAILED';
/*----------------------------------
         INVENTORY LOW STOCK        
==================================*/

export const GET_LOW_STOCK_REQUEST = 'GET_LOW_STOCK_REQUEST';
export const GET_LOW_STOCK_SUCCESS = 'GET_LOW_STOCK_SUCCESS';
export const GET_LOW_STOCK_FAILED = 'GET_LOW_STOCK_FAILED';
