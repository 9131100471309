import React from 'react';
import {
  Button as MuiButton,
  CircularProgress,
  makeStyles
} from '@material-ui/core';

const useStyles = (props) =>
  makeStyles((theme) => ({
    root: {
      margin: theme.spacing(1),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingRight: theme.spacing(4),
      paddingLeft: theme.spacing(4),
      // borderRadius: '36% 64% 36% 64% / 100% 0% 100% 0% ',
      minWidth: 120
      // borderRadius: '80% 20% 70% 30% / 36% 57% 43% 64%'
    },
    circularProgress: {
      position: 'absolute',
      left: 20
    },
    label: {
      textTransform: 'uppercase'
    },
    buttonLoading: {
      margin: theme.spacing(1),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingRight:
        props.fullWidthButton === true ? theme.spacing(5) : theme.spacing(4),
      paddingLeft:
        props.fullWidthButton === true ? theme.spacing(5) : theme.spacing(4),
      backgroundColor: 'rgb(0 0 0 / 26%)',
      width: props.fullWidthButton === true ? '100%' : 'auto',
      // borderRadius: '36% 64% 36% 64% / 100% 0% 100% 0% ',
      minWidth: 120,
      '&:disabled': {
        cursor: 'not-allowed',
        pointerEvents: 'all !important'
      }
    }
  }));

const Button = (props) => {
  const {
    text,
    size,
    color,
    variant,
    onClick,
    loadingButton,
    loadingIndicator,
    fullWidthButton,
    ...other
  } = props;
  const classes = useStyles(props)();
  return loadingIndicator ? (
    !loadingButton ? (
      <MuiButton
        color={color || 'primary'}
        variant={variant || 'contained'}
        size={size || 'small'}
        onClick={onClick}
        {...other}
        classes={{ root: classes.root, label: classes.label }}
      >
        {text}
      </MuiButton>
    ) : (
      <MuiButton
        disabled
        color={color || 'primary'}
        size={size || 'small'}
        classes={{ root: classes.buttonLoading, label: classes.label }}
        // style={}
      >
        <CircularProgress className={classes.circularProgress} size={15} />
        {text}
      </MuiButton>
    )
  ) : (
    <MuiButton
      color={color || 'primary'}
      variant={variant || 'contained'}
      size={size || 'small'}
      onClick={onClick}
      {...other}
      classes={{ root: classes.root, label: classes.label }}
    >
      {text}
    </MuiButton>
  );
};

export default Button;
