import {
  call,
  put,
  take,
  takeLatest,
  takeEvery,
  delay
} from 'redux-saga/effects';
import api from 'utils/api';
import {
  ADD_OUTLETS_REQUEST,
  ADD_OUTLETS_SUCCESS,
  GET_ALL_OUTLETS_REQUEST,
  GET_SINGLE_OUTLETS_REQUEST,
  REMOVE_OUTLETS_FAILED,
  REMOVE_OUTLETS_REQUEST,
  REMOVE_OUTLETS_SUCCESS,
  UPDATE_OUTLETS_REQUEST,
  UPDATE_OUTLETS_SUCCESS
} from 'redux/constants/StoreSetupConstants/outlets.registers.constants';
import {
  addOutletFailed,
  populateOutlets,
  populateOutletsFailed,
  populateSingleOutlet,
  populateSingleOutletFailed
} from 'redux/actions/StoreSetupActions/outlets.registers.action';
import { setSnackbar } from 'redux/actions/UIComponentActions/snackbar.action';

// /*----------------------------------
//        GET SINGLE USER
// ==================================*/
// export function* onFetchProductStart() {
//   yield takeLatest(GET_SINGLE_OUTLETS_REQUEST, fetchProduct);
// }
// function* fetchProduct({ payload }) {
//   try {
//     if (payload) {
//       const { data } = yield api.get(`/get-outlet/${payload}`);

//       yield put(populateSingleOutlet(data));
//     }
//   } catch (err) {
//     yield put(populateSingleOutletFailed(err.message));
//   }
// }
export function* getSingleCustomerGroup() {
  yield takeLatest(GET_SINGLE_OUTLETS_REQUEST, fetchSingleOutlet);
}

function* fetchSingleOutlet({ payload }) {
  try {
    const { data } = yield api.get(`/get-outlet/${payload}`);
    yield put(populateSingleOutlet(data));
  } catch (error) {
    yield put(populateSingleOutletFailed(error));
    if (error?.response?.status !== 404) {
      yield put(setSnackbar(true, 'error', error.message));
    }
  }
}

/*----------------------------------
    SYSTEM USER >> GET ALL OUTLET          
==================================*/

// export function* getAllOutlets() {
//   yield take(GET_ALL_OUTLETS_REQUEST);
//   try {
//     const { data } = yield call(api, `/getAll-outlets`);
//     // console.log(data, 'data');
//     // const newReturn = data.map((outlet) => {
//     //   return {
//     //     outlet_id: outlet.outlet_id,
//     //     outlet_name: outlet.outlet_name
//     //   };
//     // });
//     // console.log(newReturn, 'newReturn');
//     // const { data } = yield call(api, `http://localhost:3000/taxRules`);
//     yield put(populateOutlets(data));
//     // yield put(populateOutlets(newReturn));
//   } catch (error) {
//     yield put(populateOutletsFailed(error));
//     if (error.response.status !== 404)
//       yield put(setSnackbar(true, 'error', 'Something Went Wrong !!'));
//   }
// }

export function* getAllOutlets() {
  yield takeLatest(GET_ALL_OUTLETS_REQUEST, fetchProducts);
}
function* fetchProducts(payload) {
  const {
    page: currentPage,
    size,
    orderBy: sortField,
    order: sortDir,
    searchValue: query
  } = payload;
  try {
    const { data } = yield api.get(`/getAll-outlets`, {
      params: {
        currentPage: currentPage + 1 || 1,
        size: size || 8,
        sortField: sortField || 'outlet_name',
        sortDir: sortDir || 'asc',
        query: query || ''
      }
    });
    yield delay(400);
    yield put(populateOutlets(data));
  } catch (error) {
    yield put(populateOutletsFailed(error));
    if (error.response.status !== 404) {
      yield put(setSnackbar(true, 'error', error.message));
    }
  }
}

/*  */

// export function* getAllProducts() {
//   yield takeEvery(GET_ALL_OUTLETS_REQUEST, fetchProducts);
// }
// function* fetchProducts() {
//   try {
//     const { data } = yield call(api, `/getAll-outlets`);
//     console.log(data);
//     yield put(populateOutlets(data));
//   } catch (err) {
//     yield put(populateOutletsFailed(err.message));
//   }
// }

// export function* getPartWatcher() {
//   yield call(takeLatest, GET_ALL_OUTLETS_REQUEST, fetchProducts);
// }

// export function* partData() {
//   const watcher = yield fork(getPartWatcher);

//   yield take(LOCATION_CHANGE);
//   yield cancel(watcher);
// }
/*----------------------------------
    SYSTEM USER >> ADD USER          
==================================*/
export function* addSystemUserSaga() {
  yield takeLatest(ADD_OUTLETS_REQUEST, systemUser);
}

function* systemUser({ payload }) {
  try {
    const result = yield call(sysUserAsync, payload);

    yield put({ type: ADD_OUTLETS_SUCCESS, payload: result });
    console.log(result, 'result');
    yield put(setSnackbar(true, 'success', 'Outlet Added Successfully'));
    yield put({ type: GET_ALL_OUTLETS_REQUEST });
    yield call(getAllOutlets);
  } catch (error) {
    yield put(addOutletFailed(error));
    if (error.response.status !== 404)
      yield put(setSnackbar(true, 'error', 'Something Went Wrong !!'));
  }
}

async function sysUserAsync(payload) {
  const {
    outlet_id,
    outlet_name,
    tax_id,
    tax_rate,
    tax_name,
    email,
    phone,
    purch_orderno,
    purch_prefix,
    purch_suffix,
    inven_prefix,
    inven_suffix,
    inven_countno,
    inven_transferno,
    inven_count_prefix,
    inven_count_suffix,
    outlet_hours,
    outlet_address,
    active
  } = payload;
  /* outlet_id: 0,
  outlet_name: '',
  tax_id: '',
  email: '',
  phone: '',
  purch_orderno: 1,
  purch_prefix: '',
  purch_suffix: '',
  inven_transferno: 1,
  inven_count_prefix: '',
  inven_count_suffix: '',
  count_num: 1,
  count_prefix: '',
  count_suffix: '', */
  console.log(payload, 'payload');
  const { data } = await api.post(`/save-outlet`, {
    outlet_id,
    outlet_name,
    tax_id,
    email,
    tax_rate,
    tax_name,
    phone,
    purch_orderno,
    purch_prefix,
    purch_suffix,
    inven_transferno,
    inven_prefix,
    inven_suffix,
    inven_countno,
    inven_count_prefix,
    inven_count_suffix,
    outlet_hours,
    outlet_address,
    active
  });
  console.log(
    outlet_id,
    outlet_name,
    tax_id,
    tax_rate,
    email,
    phone,
    purch_orderno,
    purch_prefix,
    purch_suffix,
    inven_prefix,
    inven_suffix,
    inven_countno,
    inven_transferno,
    inven_count_prefix,
    inven_count_suffix,
    outlet_hours,
    outlet_address,
    active,
    'saga'
  );
  return data;
}

/*----------------------------------
       SYSTEM USER >> UPDATE USER        
==================================*/

export function* updateSystemUserSaga() {
  yield takeEvery(UPDATE_OUTLETS_REQUEST, updateSystemUser);
}

function* updateSystemUser({ payload }) {
  try {
    const result = yield call(updateAsyncOutlets, payload);

    yield put({ type: UPDATE_OUTLETS_SUCCESS, payload: result });
    yield put({ type: GET_ALL_OUTLETS_REQUEST });
    yield call(getAllOutlets);
    yield put(setSnackbar(true, 'success', 'Outlet Updated Successfully'));
  } catch (error) {
    if (error.response.status !== 404)
      yield put(setSnackbar(true, 'error', 'Something Went Wrong !!'));
  }
}

async function updateAsyncOutlets(payload) {
  const { outlet_id, entry } = payload;
  const {
    outlet_name,
    tax_id,
    tax_name,
    email,
    phone,
    purch_orderno,
    purch_prefix,
    purch_suffix,
    inven_transferno,
    inven_prefix,
    inven_suffix,
    inven_countno,
    inven_count_prefix,
    inven_count_suffix,
    outlet_hours,
    outlet_address,
    tax_rate,
    active
  } = entry;
  const { data } = await api.put(`/update-outlet/${outlet_id}`, {
    outlet_id,
    outlet_name,
    tax_id,
    tax_rate,
    tax_name,
    email,
    phone,
    purch_orderno,
    purch_prefix,
    purch_suffix,
    inven_transferno,
    inven_prefix,
    inven_suffix,
    inven_countno,
    inven_count_prefix,
    inven_count_suffix,
    outlet_hours,
    outlet_address,
    active
  });
  return data;
}

/*-----------------------------------------
    OUTLET >> DELETE SINGLE OUTLET       
=========================================*/

export function* deleteSystemUserSaga() {
  while (true) {
    try {
      const { payload } = yield take(REMOVE_OUTLETS_REQUEST);
      yield call(deleteSingleSystemUsers, payload.outlet_id);
      yield put({
        type: REMOVE_OUTLETS_SUCCESS,
        payload: { outlet_id: payload.outlet_id }
      });
      yield put({ type: GET_ALL_OUTLETS_REQUEST });
      yield call(getAllOutlets);
      yield put(setSnackbar(true, 'error', 'Outlet Delete Successfully'));
    } catch (error) {
      yield put({ type: REMOVE_OUTLETS_FAILED, payload: error });
      yield put(setSnackbar(true, 'error', error.response.data.errorMessage));
    }
  }
}

async function deleteSingleSystemUsers(outlet_id) {
  await api.delete(`/delete-outlet/${outlet_id}`);
}
