import {
  OPEN_DRAWER,
  CLOSE_DRAWER
} from 'redux/constants/UIComponentsConstants/drawer.constants';

export default function drawerReducer(
  state = {
    modalType: null,
    modalProps: {}
  },
  action
) {
  switch (action.type) {
    case OPEN_DRAWER:
      console.log(action);
      return {
        ...state,
        modalType: action.modalType,
        modalProps: action.modalProps
      };

    case CLOSE_DRAWER:
      console.log(
        {
          modalType: null,
          modalProps: action.modalProps
        },
        'reducer'
      );
      return {
        modalType: null,
        modalProps: action.modalProps
      };

    default:
      return state;
  }
}
