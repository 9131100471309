import * as productBrandSaga from './ProductSagas/product.brands.saga';
import * as productSeasonSaga from './ProductSagas/product.seasons.saga';
import * as productTagSaga from './ProductSagas/product.tags.saga';
import * as productTypeSaga from './ProductSagas/product.types.saga';
import * as productsSaga from './ProductSagas/products.saga';
import * as userSaga from './UserSagas/user.saga';
import * as taxRulesSaga from './StoreSetupSagas/tax.rules.saga';
import * as outletsRegistersSaga from './StoreSetupSagas/outlets.registers.saga';
import * as cashRegisterSaga from './StoreSetupSagas/cash.registers.saga';
import * as emailTemplatesSaga from './StoreSetupSagas/email.templates.saga';
import * as paymentTypesSaga from './StoreSetupSagas/payment.types.saga';
import * as inventorySupplierSaga from './InventorySagas/inventory.supplier.saga';
import * as inventoryPurchaseSaga from './InventorySagas/inventory.purchase.saga';
import * as inventoryPurchaseOrderSaga from './InventorySagas/inventory.receive.purchorders.saga';
import * as myInventory from './InventorySagas/inventory.my.saga';
import * as awaitingDeliverySaga from './InventorySagas/inventory.awaiting.delivery.saga';
import * as inventoryDashboardSaga from './InventorySagas/inventory.dashboard.saga';
import * as draftPOSaga from './InventorySagas/inventory.draft.po.saga';
import * as lowStockSaga from './InventorySagas/inventory.low.stock.saga';
import * as customersSaga from './CustomersSagas/customers.saga';
import * as customerGroupsSaga from './CustomersSagas/customer.groups.saga';
import * as StoreSetupSaga from './StoreSetupSagas/general.store.setup.saga';
const sagas = [
  productBrandSaga,
  productSeasonSaga,
  productTagSaga,
  productTypeSaga,
  productsSaga,
  userSaga,
  taxRulesSaga,
  outletsRegistersSaga,
  cashRegisterSaga,
  emailTemplatesSaga,
  paymentTypesSaga,
  inventorySupplierSaga,
  inventoryPurchaseSaga,
  inventoryPurchaseOrderSaga,
  myInventory,
  awaitingDeliverySaga,
  draftPOSaga,
  lowStockSaga,
  customersSaga,
  customerGroupsSaga,
  inventoryDashboardSaga,
  StoreSetupSaga
];
export function initSagas(sagaMiddleware) {
  sagas.map((saga) =>
    Object.values(saga).forEach(sagaMiddleware.run.bind(sagaMiddleware))
  );
}
