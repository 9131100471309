import {
  GET_PRODUCT_BRANDS_SUCCESS,
  GET_PRODUCT_BRANDS_FAILED,
  REMOVE_MULTIPLE_PRODUCT_BRANDS_SUCCESS,
  REMOVE_PRODUCT_BRANDS_SUCCESS,
  ADD_PRODUCT_BRANDS_SUCCESS,
  ADD_PRODUCT_BRANDS_FAILED,
  UPDATE_PRODUCT_BRANDS_SUCCESS,
  UPDATE_PRODUCT_BRANDS_FAILED,
  GET_PRODUCT_BRANDS_REQUEST,
  REMOVE_PRODUCT_BRANDS_FAILED,
  REMOVE_MULTIPLE_PRODUCT_BRANDS_FAILED
} from 'redux/constants/ProductConstants/product.brands.constants';
const initialStateValues = {
  brands: [],
  brand: {},
  loading: true,
  error: false,
  page: 1,
  first: true,
  last: true
};
const productBrandsReducer = (state = initialStateValues, action) => {
  const { brands } = state;
  let newBrands;
  switch (action.type) {
    case GET_PRODUCT_BRANDS_REQUEST:
      return {
        ...state,
        brands: [],
        loading: true
      };
    case GET_PRODUCT_BRANDS_SUCCESS:
      return {
        loading: false,
        error: false,
        brands: [...action.payload.content],
        page: action.payload.page,
        pages: action.payload.totalElements,
        first: action.payload.first,
        last: action.payload.last,
        size: action.payload.size,
        searchValue: action.payload.searchValue
      };
    case GET_PRODUCT_BRANDS_FAILED:
      return {
        ...state,
        error: action.payload,
        brands: [...state.brands],
        loading: false
      };
    case ADD_PRODUCT_BRANDS_SUCCESS:
      newBrands = {
        ...state,
        brands: [...brands, action.payload],
        loading: true
      };

      return newBrands;
    case ADD_PRODUCT_BRANDS_FAILED:
      return { brands: [...state.brands], error: action.payload };
    case REMOVE_PRODUCT_BRANDS_SUCCESS:
      newBrands = brands.filter((brand) => brand.id !== action.payload.id);
      return { loading: false, brands: newBrands, error: false };
    case REMOVE_PRODUCT_BRANDS_FAILED:
      return {
        loading: false,
        brands: [...state.brands],
        error: action.payload
      };
    case REMOVE_MULTIPLE_PRODUCT_BRANDS_SUCCESS:
      newBrands = brands.filter(
        (brand) => !action.payload.id.includes(brand.id)
      );
      return { loading: false, brands: newBrands, error: false };
    case REMOVE_MULTIPLE_PRODUCT_BRANDS_FAILED:
      return {
        loading: false,
        brands: [...state.brands],
        error: action.payload
      };

    case UPDATE_PRODUCT_BRANDS_SUCCESS:
      const { id, brand_name } = action.payload;
      newBrands = [...brands];
      const existingUser = state.brands.find((brand) => brand.id === id);
      if (existingUser) {
        existingUser.brand_name = brand_name;
      }
      return { brands: newBrands };
    case UPDATE_PRODUCT_BRANDS_FAILED:
      return {
        brands: [...state.brands],
        error: action.payload,
        loading: false
      };
    default:
      return state;
  }
};
export default productBrandsReducer;
