import {
  GET_PRODUCT_TAGS_SUCCESS,
  GET_PRODUCT_TAGS_FAILED,
  REMOVE_MULTIPLE_PRODUCT_TAGS_SUCCESS,
  REMOVE_PRODUCT_TAGS_SUCCESS,
  ADD_PRODUCT_TAGS_SUCCESS,
  ADD_PRODUCT_TAGS_FAILED,
  UPDATE_PRODUCT_TAGS_SUCCESS,
  UPDATE_PRODUCT_TAGS_FAILED,
  GET_PRODUCT_TAGS_REQUEST,
  REMOVE_PRODUCT_TAGS_FAILED,
  REMOVE_MULTIPLE_PRODUCT_TAGS_FAILED
} from 'redux/constants/ProductConstants/product.tags.constants';

const initialStateValues = {
  tags: [],
  tag: {},
  loading: true,
  error: false,
  page: 1,
  first: true,
  last: true
};

const productTagsReducer = (state = initialStateValues, action) => {
  const { tags } = state;
  let newTags;
  switch (action.type) {
    case GET_PRODUCT_TAGS_REQUEST:
      return {
        ...state,
        loading: true,
        tags: []
      };
    case GET_PRODUCT_TAGS_SUCCESS:
      return {
        loading: false,
        error: false,
        tags: [...action.payload.content],
        page: action.payload.page,
        pages: action.payload.totalElements,
        first: action.payload.first,
        last: action.payload.last,
        size: action.payload.size,
        searchValue: action.payload.searchValue
      };
    case GET_PRODUCT_TAGS_FAILED:
      return {
        loading: false,
        error: action.payload,
        tags: [...state.tags]
      };
    case ADD_PRODUCT_TAGS_SUCCESS:
      newTags = { ...state, tags: [...tags, action.payload], loading: true };
      return newTags;
    case ADD_PRODUCT_TAGS_FAILED:
      return { tags: [...state.tags], error: action.payload };
    case REMOVE_PRODUCT_TAGS_SUCCESS:
      newTags = tags.filter((tag) => tag.id !== action.payload.id);
      return { loading: false, tags: newTags, error: false };
    case REMOVE_PRODUCT_TAGS_FAILED:
      return {
        loading: false,
        tags: [...state.tags],
        error: action.payload
      };
    case REMOVE_MULTIPLE_PRODUCT_TAGS_SUCCESS:
      newTags = tags.filter((tag) => !action.payload.id.includes(tag.id));
      return { loading: false, tags: newTags, error: false };
    case REMOVE_MULTIPLE_PRODUCT_TAGS_FAILED:
      return {
        loading: false,
        tags: [...state.tags],
        error: action.payload
      };
    case UPDATE_PRODUCT_TAGS_SUCCESS:
      const {
        id,
        tag_name,

        active,
        created_date_time,
        last_modified_time
      } = action.payload;
      newTags = [...tags];
      const existingUser = state.tags.find((tag) => tag.id === id);
      if (existingUser) {
        existingUser.tag_name = tag_name;
        existingUser.active = active;
        existingUser.created_date_time = created_date_time;
        existingUser.last_modified_time = last_modified_time;
      }
      return { tags: newTags };
    case UPDATE_PRODUCT_TAGS_FAILED:
      return {
        tags: [...state.tags],
        error: action.payload,
        loading: false
      };
    default:
      return state;
  }
};
export default productTagsReducer;
