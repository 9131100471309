import './App.css';
import React, { Suspense, useEffect } from 'react';
import { useStyles, theme } from './utils';
import { ThemeProvider } from '@material-ui/core';
import { Route, Switch, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { NAV_LIST, PAGE_LIST } from '../configComponents';
import LinearLoader from 'components/LinearLoader';
import { PrivateRoute } from 'components/PrivateRoute';
import Layout from 'components/Layout';
import NotFound from 'screen/NotFound';
import _ from 'lodash';
import { getMyProfile } from 'redux/actions/UserActions/user.action';
import { getCookieData } from 'utils/functionsCustom';
import { getAllOutlets } from 'redux/actions/StoreSetupActions/outlets.registers.action';
import MaterialDrawerRedux from 'components/DrawerRedux/DrawerRedux';

const App = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  useStyles();
  useEffect(() => {
    if (history.location.pathname === '/') {
      history.push('/dashboard');
    }
  }, [history]);
  const { profile, loading: loadProfile } = useSelector(
    (state) => state.myProfile
  );
  useEffect(() => {
    const cookieData = getCookieData();
    cookieData.authorizationToken && dispatch(getMyProfile());
  }, [dispatch]);
  useEffect(() => {
    if (
      !loadProfile &&
      !_.isEmpty(profile) &&
      profile?.store_setup?.trading_name
    ) {
      document.title = `AAIPAY POS - ${profile?.store_setup?.trading_name}`;
    } else {
      document.title = `AAIPAY POS`;
    }
  }, [profile, loadProfile]);
  useEffect(() => {
    if (!loadProfile && !_.isEmpty(profile)) {
      dispatch(getAllOutlets());
    } else {
    }
  }, [profile, loadProfile, dispatch]);

  return (
    <>
      <ThemeProvider theme={theme}>
        <Suspense fallback={<LinearLoader />}>
          <Switch history={history}>
            {NAV_LIST.map((page) => (
              <Route
                key={page.name}
                path={page.path}
                component={page.component}
                exact
              />
            ))}
            <Layout>
              <Suspense fallback={<LinearLoader />}>
                <Switch>
                  {PAGE_LIST.map((page) => (
                    <PrivateRoute
                      key={page.name}
                      path={page.path}
                      component={page.component}
                      exact
                    />
                  ))}

                  <Route component={NotFound} />
                </Switch>
              </Suspense>
            </Layout>
            )
          </Switch>
        </Suspense>
        <MaterialDrawerRedux />
      </ThemeProvider>
    </>
  );
};

export default App;
