import {
  GET_ALL_EMAIL_TEMPLATES_SUCCESS,
  GET_ALL_EMAIL_TEMPLATES_FAILED,
  UPDATE_EMAIL_TEMPLATES_SUCCESS,
  GET_SINGLE_EMAIL_TEMPLATES_FAILED,
  GET_SINGLE_EMAIL_TEMPLATES_SUCCESS,
  UPDATE_EMAIL_TEMPLATES_FAILED,
  GET_ALL_EMAIL_TEMPLATES_REQUEST,
  GET_SINGLE_EMAIL_TEMPLATES_REQUEST
} from 'redux/constants/StoreSetupConstants/email.templates.constants';

const EmailTemplatesReducer = (
  state = {
    loading: true,
    email_templates: [],
    error: false,
    email_template: {},
    page: 1,
    first: true,
    last: true
  },
  action
) => {
  const { email_templates } = state;
  let newEmailTemplates;
  switch (action.type) {
    case GET_ALL_EMAIL_TEMPLATES_REQUEST:
      return {
        ...state,
        email_templates: [],
        loading: true
      };
    case GET_ALL_EMAIL_TEMPLATES_SUCCESS:
      return {
        ...state,
        email_templates: [...action.payload.content],
        loading: false,
        page: action.payload.page,
        pages: action.payload.totalElements,
        first: action.payload.first,
        last: action.payload.last,
        size: action.payload.size,
        searchValue: action.payload.searchValue
      };
    case GET_ALL_EMAIL_TEMPLATES_FAILED:
      return {
        ...state,
        error: action.payload,
        email_templates: [...email_templates],

        loading: false
      };
    case GET_SINGLE_EMAIL_TEMPLATES_REQUEST:
      return {
        ...state,
        email_template: {},
        loading: true
      };
    case GET_SINGLE_EMAIL_TEMPLATES_SUCCESS:
      return {
        ...state,
        email_template: action.payload,
        loading: false
      };
    case GET_SINGLE_EMAIL_TEMPLATES_FAILED:
      return {
        ...state,
        error: action.payload,
        email_template: {},
        loading: false
      };
    case UPDATE_EMAIL_TEMPLATES_SUCCESS:
      const {
        id,
        temp_email,
        temp_subject,
        donot_send_notif,
        temp_body,
        temp_body_reset,
        active,
        loyalty_details_added,
        pos_rid
      } = action.payload;

      newEmailTemplates = [...email_templates];
      const existingProduct = email_templates.find(
        (email_template) => email_template.id === id
      );

      if (existingProduct) {
        existingProduct.temp_email = temp_email;
        existingProduct.temp_subject = temp_subject;
        existingProduct.temp_body = temp_body;
        existingProduct.active = active;
        existingProduct.loyalty_details_added = loyalty_details_added;
        existingProduct.temp_body_reset = temp_body_reset;
        existingProduct.donot_send_notif = donot_send_notif;
        existingProduct.pos_rid = pos_rid;
      }
      return { email_templates: newEmailTemplates };
    case UPDATE_EMAIL_TEMPLATES_FAILED: {
      return {
        error: action.payload,
        email_templates: [...email_templates]
      };
    }

    default:
      return state;
  }
};
export default EmailTemplatesReducer;
