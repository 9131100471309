import {
  call,
  put,
  take,
  takeLatest,
  takeEvery,
  delay
} from 'redux-saga/effects';
import axios from 'axios';
import {
  ADD_CUSTOMER_GROUPS_REQUEST,
  ADD_CUSTOMER_GROUPS_SUCCESS,
  GET_ALL_CUSTOMER_GROUPS_REQUEST,
  GET_SINGLE_CUSTOMER_GROUP_REQUEST,
  REMOVE_MULTIPLE_CUSTOMER_GROUP_REQUEST,
  REMOVE_MULTIPLE_CUSTOMER_GROUP_SUCCESS,
  REMOVE_CUSTOMER_GROUP_REQUEST,
  REMOVE_CUSTOMER_GROUP_SUCCESS,
  UPDATE_CUSTOMER_GROUP_REQUEST,
  UPDATE_CUSTOMER_GROUP_SUCCESS
} from 'redux/constants/CustomersConstants/customer.groups.constants';
import {
  addCustomerGroupFailed,
  populateSingleCustomerGroup,
  populateSingleCustomerGroupFailed,
  populateCustomerGroups,
  populateCustomerGroupsFailed
} from 'redux/actions/CustomersActions/customer.groups.action';
import { setSnackbar } from 'redux/actions/UIComponentActions/snackbar.action';
import { push } from 'connected-react-router';
import api from 'utils/api';
const baseURLProd = 'http://216.10.244.34:10005/rp/api/v1';
const cancelSource = axios.CancelToken.source();
/* {{URL}}/getAll-CustomerGroups?currentPage=1&size=&sortField=first_name&sortDir=asc&query=usuf */
export function* getAllCustomerGroups() {
  yield takeLatest(GET_ALL_CUSTOMER_GROUPS_REQUEST, fetchCustomerGroups);
}
/* const response = yield call(getTweets, buttonStatus);

 */
function* fetchCustomerGroups(payload) {
  const {
    page: currentPage,
    size,
    orderBy: sortField,
    order: sortDir,
    searchValue: query
  } = payload;
  try {
    const { data } = yield api.get('/getAll-CustomerGroups', {
      params: {
        currentPage: currentPage + 1 || 1,
        size: size || 8,
        sortField: sortField || 'name',
        sortDir: sortDir || 'asc',
        query: query || ''
      }
    });
    /* getAwaiting-Delivery?currentPage=1&size=3&sortField=po_number&sortDir=asc */
    yield delay(400);
    yield put(populateCustomerGroups(data));
  } catch (error) {
    yield put(populateCustomerGroupsFailed(error));
    if (error?.response?.status !== 404) {
      yield put(setSnackbar(true, 'error', error.message));
    }
  }
}

// /*----------------------------------
//        GET SINGLE CUSTOMER_GROUP
// ==================================*/
// export function* onFetchProductStart() {
//   yield takeLatest(GET_SINGLE_CUSTOMER_GROUP_REQUEST, fetchProduct);
// }
// function* fetchProduct({ payload }) {
//   console.log(payload, 'payload');
//   try {
//     if (payload) {
//       const { data } = yield axios.get(
//         `${baseURLProd}/get-CustomerGroup/${payload}`
//       );
//       yield put(populateSingleCustomerGroup(data));
//     }
//   } catch (err) {
//     yield put(populateSingleCustomerGroupFailed(err.message));
//   }
// }
export function* getSingleCustomerGroup() {
  yield takeLatest(
    GET_SINGLE_CUSTOMER_GROUP_REQUEST,
    fetchSingleCustomerGroups
  );
}
/* const response = yield call(getTweets, buttonStatus);

 */
function* fetchSingleCustomerGroups(payload) {
  console.log(payload);
  try {
    const { data } = yield api.get(`/get-CustomerGroup/${payload.payload}`);
    /* getAwaiting-Delivery?currentPage=1&size=3&sortField=po_number&sortDir=asc */
    yield put(populateSingleCustomerGroup(data));
  } catch (error) {
    yield put(populateSingleCustomerGroupFailed(error));
    if (error?.response?.status !== 404) {
      yield put(setSnackbar(true, 'error', error.message));
    }
  }
}

/*----------------------------------
       UPDATE CUSTOMER_GROUP
==================================*/
export function* updateEntrySaga() {
  yield takeEvery(UPDATE_CUSTOMER_GROUP_REQUEST, updateEntryToDb);
}
function* updateEntryToDb({ payload }) {
  console.log(payload, 'saga');
  try {
    // console.log();
    const result = yield call(updateEntry, payload);
    yield put({ type: UPDATE_CUSTOMER_GROUP_SUCCESS, payload: result });

    yield put(setSnackbar(true, 'success', 'Updated Successfully'));
  } catch (error) {
    yield put(setSnackbar(true, 'error', 'Something Went Wrong'));
  }
}
async function updateEntry(payload) {
  const { id, entry } = payload;

  // const {} = entry;

  const { data } = await api.put(`/update-CustomerGroup/${id}`, entry);

  return data;
}

/*----------------------------------
   CUSTOMER_GROUP >> ADD CUSTOMER_GROUP          
==================================*/
export function* addCustomerGroupsSaga() {
  yield takeLatest(ADD_CUSTOMER_GROUPS_REQUEST, systemCustomerGroup);
}

function* systemCustomerGroup({ payload }) {
  try {
    const result = yield call(sysCustomerGroupAsync, payload);
    console.log(result, 'saga');
    yield put({ type: ADD_CUSTOMER_GROUPS_SUCCESS, payload: result });
    yield put(setSnackbar(true, 'success', 'CustomerGroup Added Successfully'));
  } catch (error) {
    yield put(addCustomerGroupFailed(error));
    yield put(setSnackbar(true, 'error', 'Something Went Wrong !!'));
  }
}
/*  id: 1,
  name: '',
  customer_count: 2,
  enable_discount: true,
  customerGroup_discountType: '1',
  discount_percent: 0,
  upload_fileName: 'fsfd',
  created_date_time: new Date(),
  last_modified_time: new Date(),
  id: 0,
  active: false */
async function sysCustomerGroupAsync({
  name,
  enable_discount,
  customerGroup_discountType,
  discount_percent,
  upload_fileName,
  created_date_time,
  last_modified_time,
  active
}) {
  const { data } = await api.post(`/save-CustomerGroup`, {
    name,
    enable_discount,
    customerGroup_discountType,
    discount_percent,
    upload_fileName,
    created_date_time,
    last_modified_time,
    active
  });
  return data;
}

/*----------------------------------
       CUSTOMER_GROUP >> UPDATE CUSTOMER_GROUP        
==================================*/

export function* updateCustomerGroupsSaga() {
  yield takeEvery(UPDATE_CUSTOMER_GROUP_REQUEST, updateCustomerGroups);
}

function* updateCustomerGroups({ payload }) {
  try {
    const result = yield call(updateAsyncCustomerGroups, payload);
    console.log(result);
    yield put({ type: UPDATE_CUSTOMER_GROUP_SUCCESS, payload: result });

    yield put(
      setSnackbar(true, 'success', 'Customer Group Updated Successfully')
    );
    yield put({ type: GET_ALL_CUSTOMER_GROUPS_REQUEST });
    yield call(getAllCustomerGroups);

    // yield call(cancelSource.cancel);
  } catch (error) {
    yield put(setSnackbar(true, 'error', 'Something Went Wrong !!'));
  }
}

async function updateAsyncCustomerGroups(payload) {
  const { id, entry } = payload;
  const {
    name,
    enable_discount,
    customerGroup_discountType,
    discount_percent,
    upload_fileName,
    created_date_time,
    last_modified_time,
    active
  } = entry;
  const { data } = await api.put(`/update-CustomerGroup/${id}`, {
    id,
    name,
    enable_discount,
    customerGroup_discountType,
    discount_percent,
    upload_fileName,
    created_date_time,
    last_modified_time,
    active
  });
  return data;
}

/*-------------------------------------
   CUSTOMER_GROUP >> DELETE SINGLE CUSTOMER_GROUP       
=====================================*/

export function* deleteCustomerGroupsSaga() {
  while (true) {
    const { payload } = yield take(REMOVE_CUSTOMER_GROUP_REQUEST);
    yield call(deleteSingleCustomerGroups, payload.id);
    yield put({
      type: REMOVE_CUSTOMER_GROUP_SUCCESS,
      payload: { id: payload.id }
    });
    yield put(
      setSnackbar(true, 'error', 'Customer Group Deleted Successfully')
    );
    yield put({ type: GET_ALL_CUSTOMER_GROUPS_REQUEST });
    yield call(getAllCustomerGroups);
  }
}

async function deleteSingleCustomerGroups(id) {
  await api.delete(`/delete-CustomerGroup/${id}`);
}
/*---------------------------------------
 CUSTOMER_GROUP >> DELETE MULTIPLE CUSTOMER_GROUP       
========================================*/

export function* deleteMultipleCustomerGroupsSaga() {
  while (true) {
    const { payload } = yield take(REMOVE_MULTIPLE_CUSTOMER_GROUP_REQUEST);
    yield call(deleteMultipleCustomerGroupss, payload.id);
    yield put({
      type: REMOVE_MULTIPLE_CUSTOMER_GROUP_SUCCESS,
      payload: { id: payload.id }
    });
  }
}

async function deleteMultipleCustomerGroupss(ids) {
  await Promise.all(
    ids.map((id) => api.delete(`/delete-systemCustomerGroup/${id}`))
  );
}
