/*----------------------------------
          OUTLETS CONSTANTS         
==================================*/
export const GET_ALL_OUTLETS_REQUEST = 'GET_ALL_OUTLETS_REQUEST';
export const GET_ALL_OUTLETS_SUCCESS = 'GET_ALL_OUTLETS_SUCCESS';
export const GET_ALL_OUTLETS_FAILED = 'GET_ALL_OUTLETS_FAILED';

export const REMOVE_OUTLETS_REQUEST = 'REMOVE_OUTLETS_REQUEST';
export const REMOVE_OUTLETS_SUCCESS = 'REMOVE_OUTLETS_SUCCESS';
export const REMOVE_OUTLETS_FAILED = 'REMOVE_OUTLETS_FAILED';

export const ADD_OUTLETS_REQUEST = 'ADD_OUTLETS_REQUEST';
export const ADD_OUTLETS_SUCCESS = 'ADD_OUTLETS_SUCCESS';
export const ADD_OUTLETS_FAILED = 'ADD_OUTLETS_FAILED';

export const UPDATE_OUTLETS_REQUEST = 'UPDATE_OUTLETS_REQUEST';
export const UPDATE_OUTLETS_SUCCESS = 'UPDATE_OUTLETS_SUCCESS';
export const UPDATE_OUTLETS_FAILED = 'UPDATE_OUTLETS_FAILED';

export const GET_SINGLE_OUTLETS_REQUEST = 'GET_SINGLE_OUTLETS_REQUEST';
export const GET_SINGLE_OUTLETS_SUCCESS = 'GET_SINGLE_OUTLETS_SUCCESS';
export const GET_SINGLE_OUTLETS_FAILED = 'GET_SINGLE_OUTLETS_FAILED';
