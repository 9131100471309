import {
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup as MuiRadioGroup,
  Radio
} from '@material-ui/core';
import React from 'react';

const RadioGroup = (props) => {
  const { name, label, value, onChange, items, row, disabled } = props;
  return (
    <FormControl>
      <FormLabel>{label}</FormLabel>
      <MuiRadioGroup row={row} name={name} value={value} onChange={onChange}>
        {items.map((item) => (
          <FormControlLabel
            key={item.id}
            disabled={disabled}
            value={item.id}
            control={<Radio />}
            label={item.title}
          ></FormControlLabel>
        ))}
      </MuiRadioGroup>
    </FormControl>
  );
};

export default RadioGroup;
