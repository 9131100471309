import React from 'react';
import { Switch } from '@material-ui/core';
const ActiveSwitchToggle = (props) => {
  const {
    mainObject, // mainObject object, name, id, isActive boolean
    mainObjectIndex, // index of the mainObject object
    handleActiveChange, // on change function
    ...others
  } = props;
  return (
    <>
      <Switch
        {...others}
        checked={mainObject.active} // boolean true/false
        color="primary"
        onChange={() => {
          handleActiveChange(mainObjectIndex, !mainObject.active, mainObject);
        }} // "!" <-- lets the state flip, otherwise state flips once
      />
    </>
  );
};

export default ActiveSwitchToggle;
