import React from 'react';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const DatePicker = (props) => {
  const { name, label, value, onChange, disableToolbar, maxDate,...others } = props;
  const convertToDefaultEventParameter = (name, value) => ({
    target: {
      name,
      value
    }
  });
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        disableToolbar={disableToolbar}
        variant="normal"
        inputVariant="outlined"
        size="small"
        label={label}
        format="dd-MMM-yyyy"
        name={name}
        value={value}
        maxDate={maxDate}
        onChange={(date) =>
          onChange(convertToDefaultEventParameter(name, date))
        }
        KeyboardButtonProps={{
          'aria-label': 'change date'
        }}
        {...others}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DatePicker;
