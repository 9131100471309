import React from 'react';
import {
  Grid,
  Button,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList
} from '@material-ui/core';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { ArrowDropDown as ArrowDropDownIcon } from '@material-ui/icons';

export default function SplitButton({
  options,
  handleSave,
  handleOther,
  open,
  setOpen,
  ...rest
}) {
  const anchorRef = React.useRef(null);
  // const [selectedIndex, setSelectedIndex] = React.useState(0);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <Grid container direction="column" alignItems="center">
      <Grid item xs={12}>
        <ButtonGroup
          // style={{ maxWidth: "230px", minWidth: "230px" }}
          {...rest}
          variant="contained"
          color="primary"
          ref={anchorRef}
          aria-label="split button"
        >
          <Button
            style={{ maxWidth: '160px', minWidth: '160px' }}
            onClick={handleSave}
          >
            {options[0]}
          </Button>
          <Button
            color="primary"
            size="small"
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          placement="top-end"
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top-start' : 'center bottom'
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu">
                    {options.map((option, index) => (
                      <MenuItem
                        style={{
                          maxWidth: '200px',
                          minWidth: '200px'
                        }}
                        key={option}
                        onClick={(event) => handleOther(event, index)}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
    </Grid>
  );
}
