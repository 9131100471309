import {
  GET_SINGLE_OUTLETS_REQUEST,
  GET_SINGLE_OUTLETS_SUCCESS,
  GET_SINGLE_OUTLETS_FAILED,
  GET_ALL_OUTLETS_REQUEST,
  GET_ALL_OUTLETS_SUCCESS,
  ADD_OUTLETS_REQUEST,
  ADD_OUTLETS_FAILED,
  REMOVE_OUTLETS_REQUEST,
  GET_ALL_OUTLETS_FAILED,
  UPDATE_OUTLETS_REQUEST
} from 'redux/constants/StoreSetupConstants/outlets.registers.constants';

/*----------------------------------
    OUTLET > GET SINGLE OUTLET         
==================================*/

export const getSingleOutlet = (outlet_id) => {
  return { type: GET_SINGLE_OUTLETS_REQUEST, payload: outlet_id };
};
export const populateSingleOutlet = (entry) => {
  return { type: GET_SINGLE_OUTLETS_SUCCESS, payload: entry };
};
export const populateSingleOutletFailed = (error) => {
  return { type: GET_SINGLE_OUTLETS_FAILED, payload: error };
};

/*----------------------------------
   OUTLET > GET ALL OUTLETS       
==================================*/

export const getAllOutlets = (searchValue, page, size, order, orderBy) => {
  return {
    type: GET_ALL_OUTLETS_REQUEST,
    searchValue,
    page,
    size,
    order,
    orderBy
  };
};
export const populateOutlets = (entries) => {
  return { type: GET_ALL_OUTLETS_SUCCESS, payload: entries };
};
export const populateOutletsFailed = (error) => {
  return { type: GET_ALL_OUTLETS_FAILED, payload: error };
};

/*----------------------------------
   OUTLET > ADD OUTLETS       
==================================*/

export const addOutlet = (payload) => {
  console.log('action', payload);
  return { type: ADD_OUTLETS_REQUEST, payload };
};
export const addOutletFailed = (error) => {
  console.log('action', error);
  return { type: ADD_OUTLETS_FAILED, payload: error };
};

/*----------------------------------
   OUTLET > UPDATE OUTLETS       
==================================*/

export const updateOutlet = (outlet_id, entry) => {
  return { type: UPDATE_OUTLETS_REQUEST, payload: { outlet_id, entry } };
};
/*----------------------------------
   OUTLET > REMOVE OUTLETS       
==================================*/

export const removeOutlet = (outlet_id) => {
  return { type: REMOVE_OUTLETS_REQUEST, payload: { outlet_id } };
};
