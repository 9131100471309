export const GET_PRODUCT_TAGS_REQUEST = 'GET_PRODUCT_TAGS_REQUEST';
export const GET_PRODUCT_TAGS_SUCCESS = 'GET_PRODUCT_TAGS_SUCCESS';
export const GET_PRODUCT_TAGS_FAILED = 'GET_PRODUCT_TAGS_FAILED';
export const REMOVE_PRODUCT_TAGS_REQUEST = 'REMOVE_PRODUCT_TAGS_REQUEST';
export const REMOVE_PRODUCT_TAGS_SUCCESS = 'REMOVE_PRODUCT_TAGS_SUCCESS';
export const REMOVE_PRODUCT_TAGS_FAILED = 'REMOVE_PRODUCT_TAGS_FAILED';
export const REMOVE_MULTIPLE_PRODUCT_TAGS_REQUEST =
  'REMOVE_MULTIPLE_PRODUCT_TAGS_REQUEST';
export const REMOVE_MULTIPLE_PRODUCT_TAGS_SUCCESS =
  'REMOVE_MULTIPLE_PRODUCT_TAGS_SUCCESS';
export const REMOVE_MULTIPLE_PRODUCT_TAGS_FAILED =
  'REMOVE_MULTIPLE_PRODUCT_TAGS_FAILED';
export const ADD_PRODUCT_TAGS_REQUEST = 'ADD_PRODUCT_TAGS_REQUEST';
export const ADD_PRODUCT_TAGS_SUCCESS = 'ADD_PRODUCT_TAGS_SUCCESS';
export const ADD_PRODUCT_TAGS_FAILED = 'ADD_PRODUCT_TAGS_FAILED';
export const UPDATE_PRODUCT_TAGS_REQUEST = 'UPDATE_PRODUCT_TAGS_REQUEST';
export const UPDATE_PRODUCT_TAGS_SUCCESS = 'UPDATE_PRODUCT_TAGS_SUCCESS';
export const UPDATE_PRODUCT_TAGS_FAILED = 'UPDATE_PRODUCT_TAGS_FAILED';
