import {
  GET_INVENTORY_DASHBOARD_REQUEST,
  GET_INVENTORY_DASHBOARD_SUCCESS,
  GET_INVENTORY_DASHBOARD_FAILED
} from 'redux/constants/InventoryConstants/inventory.dashboard.constants';

const InventoryDashboardReducer = (
  state = {
    loading: false,
    dashboard: {},
    error: false
  },
  action
) => {
  const { dashboard } = state;

  switch (action.type) {
    case GET_INVENTORY_DASHBOARD_REQUEST:
      return {
        ...state,
        dashboard: {},
        loading: true
      };
    case GET_INVENTORY_DASHBOARD_SUCCESS:
      return {
        ...state,
        dashboard: { ...action.payload },
        loading: false
      };
    case GET_INVENTORY_DASHBOARD_FAILED:
      return {
        ...state,
        error: action.payload,
        dashboard: { ...dashboard },
        loading: false
      };

    default:
      return state;
  }
};
export default InventoryDashboardReducer;
