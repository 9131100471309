import { call, put, take, takeLatest, takeEvery } from 'redux-saga/effects';

import {
  ADD_SUPPLIERS_REQUEST,
  ADD_SUPPLIERS_SUCCESS,
  GET_ALL_SUPPLIERS_REQUEST,
  GET_SINGLE_SUPPLIERS_REQUEST,
  REMOVE_SUPPLIERS_FAILED,
  REMOVE_SUPPLIERS_REQUEST,
  REMOVE_SUPPLIERS_SUCCESS,
  UPDATE_SUPPLIERS_REQUEST,
  UPDATE_SUPPLIERS_SUCCESS
} from 'redux/constants/InventoryConstants/inventory.suppliers.constants';
import {
  addSupplierFailed,
  populateSuppliers,
  populateSuppliersFailed,
  populateSingleSupplier,
  populateSingleSupplierFailed
} from 'redux/actions/InventoryActions/inventory.supplier.action';
import { setSnackbar } from 'redux/actions/UIComponentActions/snackbar.action';
import api from 'utils/api';

// /*----------------------------------
//        GET SINGLE USER
// ==================================*/
export function* onFetchProductStart() {
  yield takeLatest(GET_SINGLE_SUPPLIERS_REQUEST, fetchProduct);
}
function* fetchProduct({ payload }) {
  console.log(payload, 'single ');
  try {
    if (payload) {
      const { data } = yield api.get(`/get-supplier/${payload}`);
      yield put(populateSingleSupplier(data));
    }
  } catch (err) {
    yield put(populateSingleSupplierFailed(err.message));
  }
}

/*----------------------------------
    SYSTEM USER >> GET ALL SUPPLIER          
==================================*/

// export function* getAllSuppliers() {
//   yield take(GET_ALL_SUPPLIERS_REQUEST);
//   try {
//     const { data } = yield call(api, `/getAll-suppliers`);
//     // const { data } = yield call(api, `http://localhost:3000/taxRules`);
//     yield put(populateSuppliers(data));
//   } catch (error) {
//     yield put(populateSuppliersFailed(error));
//     if (error.response.status !== 404)
//       yield put(setSnackbar(true, 'error', 'Something Went Wrong !!'));
//   }
// }
// export function* getAllSuppliers() {
//   yield takeLatest(GET_ALL_SUPPLIERS_REQUEST, fetchProducts);
// }
// function* fetchProducts() {
//   try {
//     const { data } = yield call(api, `/getAll-suppliers`);

//     yield put(populateSuppliers(data));
//   } catch (error) {
//     yield put(populateSuppliersFailed(error));
//     if (error.response.status !== 404) {
//       yield put(setSnackbar(true, 'error', error.message));
//     }
//   }
// }

export function* getAllSuppliers() {
  yield takeLatest(GET_ALL_SUPPLIERS_REQUEST, fetchProducts);
}
function* fetchProducts(payload) {
  const {
    page: currentPage,
    size,
    orderBy: sortField,
    order: sortDir,
    searchValue: query
  } = payload;
  try {
    const { data } = yield api.get('/getAll-suppliers', {
      params: {
        currentPage: currentPage + 1 || 1,
        size: size || 8,
        sortField: sortField || 'supplier_name',
        sortDir: sortDir || 'asc',
        query: query || ''
      }
    });
    yield put(populateSuppliers(data));
  } catch (err) {
    yield put(populateSuppliersFailed(err));
    if (err.response.status !== 404) {
      yield put(setSnackbar(true, 'error', err.message));
    }
  }
}
/*----------------------------------
    SYSTEM USER >> ADD USER          
==================================*/
export function* addSystemUserSaga() {
  yield takeLatest(ADD_SUPPLIERS_REQUEST, systemUser);
}

function* systemUser({ payload }) {
  try {
    const result = yield call(sysUserAsync, payload);
    yield put({ type: ADD_SUPPLIERS_SUCCESS, payload: result });
    console.log(result, 'result');

    yield put(setSnackbar(true, 'success', 'Cash Register Added Successfully'));
    yield put({ type: GET_ALL_SUPPLIERS_REQUEST });
    yield call(getAllSuppliers);
  } catch (error) {
    yield put(addSupplierFailed(error));
    if (error.response.status !== 404)
      yield put(setSnackbar(true, 'error', 'Something Went Wrong !!'));
  }
}

async function sysUserAsync(payload) {
  const {
    supplier_id,
    supplier_name,
    supplier_code,
    company_name,
    supplier_phone,
    supplier_website,
    note,
    default_markup,
    fax,
    first_name,
    last_name,
    primary_mobile,
    email,
    invoices_dueDay,
    invoices_dueType,
    active,
    default_tax_id,
    mail_addr_id,
    phys_addr_id,
    created_date_time,
    last_modified_time,
    mailing_address,
    physical_address,
    same_as_mailing,
    pos_rid
  } = payload;

  const { data } = await api.post(`/save-supplier`, {
    supplier_id,
    supplier_name,
    supplier_code,
    company_name,
    supplier_phone,
    supplier_website,
    note,
    default_markup,
    fax,
    first_name,
    last_name,
    primary_mobile,
    email,
    invoices_dueDay,
    invoices_dueType,
    active,
    default_tax_id,
    mail_addr_id,
    phys_addr_id,
    created_date_time,
    last_modified_time,
    mailing_address,
    physical_address,
    same_as_mailing,
    pos_rid
  });

  return data;
}

/*----------------------------------
       SYSTEM USER >> UPDATE USER        
==================================*/

export function* updateSystemUserSaga() {
  yield takeEvery(UPDATE_SUPPLIERS_REQUEST, updateSystemUser);
}

function* updateSystemUser({ payload }) {
  try {
    const result = yield call(updateAsyncSuppliers, payload);
    yield put({ type: UPDATE_SUPPLIERS_SUCCESS, payload: result });

    yield put(setSnackbar(true, 'success', 'Supplier Updated Successfully'));
    yield put({ type: GET_ALL_SUPPLIERS_REQUEST });
    yield call(getAllSuppliers);
  } catch (error) {
    if (error.response.status !== 404)
      yield put(setSnackbar(true, 'error', 'Something Went Wrong !!'));
  }
}

async function updateAsyncSuppliers(payload) {
  const { supplier_id, entry } = payload;
  const {
    supplier_name,
    supplier_code,
    company_name,
    supplier_phone,
    supplier_website,
    note,
    default_markup,
    fax,
    first_name,
    last_name,
    primary_mobile,
    email,
    invoices_dueDay,
    invoices_dueType,
    active,
    default_tax_id,
    mail_addr_id,
    phys_addr_id,
    created_date_time,
    last_modified_time,
    mailing_address,
    physical_address,
    same_as_mailing,
    pos_rid
  } = entry;
  const { data } = await api.put(`/update-supplier/${supplier_id}`, {
    supplier_id,
    supplier_name,
    supplier_code,
    company_name,
    supplier_phone,
    supplier_website,
    note,
    default_markup,
    fax,
    first_name,
    last_name,
    primary_mobile,
    email,
    invoices_dueDay,
    invoices_dueType,
    active,
    default_tax_id,
    mail_addr_id,
    phys_addr_id,
    created_date_time,
    last_modified_time,
    mailing_address,
    physical_address,
    same_as_mailing,
    pos_rid
  });
  console.log(data, 'data sagaMiddleware');
  return data;
}

/*-----------------------------------------
    TAX RATE >> DELETE SINGLE TAX RATE       
=========================================*/

export function* deleteSystemUserSaga() {
  while (true) {
    try {
      const { payload } = yield take(REMOVE_SUPPLIERS_REQUEST);
      yield call(deleteSingleSystemUsers, payload.supplier_id);
      yield put({
        type: REMOVE_SUPPLIERS_SUCCESS,
        payload: { supplier_id: payload.supplier_id }
      });
      yield put(setSnackbar(true, 'error', 'Supplier Delete Successfully'));
      yield put({ type: GET_ALL_SUPPLIERS_REQUEST });
      yield call(getAllSuppliers);
    } catch (error) {
      yield put({ type: REMOVE_SUPPLIERS_FAILED, payload: error });
      yield put(setSnackbar(true, 'error', 'Something Went Wrong'));
    }
  }
}

async function deleteSingleSystemUsers(supplier_id) {
  await api.delete(`/delete-supplier/${supplier_id}`);
}
