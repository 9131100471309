import {
  GET_AWAITING_DELIVERY_REQUEST,
  GET_AWAITING_DELIVERY_SUCCESS,
  GET_AWAITING_DELIVERY_FAILED,
  GET_DRAFT_PO_NOT_YET_SENT_REQUEST,
  GET_DRAFT_PO_NOT_YET_SENT_SUCCESS,
  GET_DRAFT_PO_NOT_YET_SENT_FAILED,
  GET_LOW_STOCK_REQUEST,
  GET_LOW_STOCK_SUCCESS,
  GET_LOW_STOCK_FAILED,
  GET_INVENTORY_DASHBOARD_REQUEST,
  GET_INVENTORY_DASHBOARD_SUCCESS,
  GET_INVENTORY_DASHBOARD_FAILED
} from 'redux/constants/InventoryConstants/inventory.dashboard.constants';

/*------------------------
    INVENTORY DASHBOARD     
=========================*/

export const getAllInventoryDashboard = (outlet_id) => {
  return { type: GET_INVENTORY_DASHBOARD_REQUEST, payload: outlet_id };
};
export const populateInventoryDashboard = (entries) => {
  return { type: GET_INVENTORY_DASHBOARD_SUCCESS, payload: entries };
};
export const populateInventoryDashboardFailed = (error) => {
  return { type: GET_INVENTORY_DASHBOARD_FAILED, payload: error };
};
/*------------------------
   AWAITING DELIVERY     
=========================*/

export const getAllAwaitingDelivery = (
  searchValue,
  page,
  size,
  order,
  orderBy,
  outletId
) => {
  return {
    type: GET_AWAITING_DELIVERY_REQUEST,
    searchValue,
    page,
    size,
    order,
    orderBy,
    outletId
  };
};
export const populateAwaitingDelivery = (entries) => {
  return { type: GET_AWAITING_DELIVERY_SUCCESS, payload: entries };
};
export const populateAwaitingDeliveryFailed = (error) => {
  return { type: GET_AWAITING_DELIVERY_FAILED, payload: error };
};
/*------------------------
    INVENTORY DRAFT PO
=========================*/

export const getAllDraftPOs = (searchValue, page, size, order, orderBy) => {
  return {
    type: GET_DRAFT_PO_NOT_YET_SENT_REQUEST,
    searchValue,
    page,
    size,
    order,
    orderBy
  };
};
export const populateDraftPOs = (entries) => {
  return { type: GET_DRAFT_PO_NOT_YET_SENT_SUCCESS, payload: entries };
};
export const populateDraftPOsFailed = (error) => {
  return { type: GET_DRAFT_PO_NOT_YET_SENT_FAILED, payload: error };
};
// /*------------------------
//     INVENTORY LOW STOCK
// =========================*/

export const getAllLowStockItems = (
  searchValue,
  page,
  size,
  order,
  orderBy,
  outletId
) => {
  return {
    type: GET_LOW_STOCK_REQUEST,
    searchValue,
    page,
    size,
    order,
    orderBy,
    outletId
  };
};
export const populateLowStockItems = (entries) => {
  return { type: GET_LOW_STOCK_SUCCESS, payload: entries };
};
export const populateLowStockItemsFailed = (error) => {
  return { type: GET_LOW_STOCK_FAILED, payload: error };
};
