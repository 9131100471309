/*----------------------------------
          POS USER        
==================================*/

export const GET_SINGLE_USER_REQUEST = 'GET_SINGLE_USER_REQUEST';
export const GET_SINGLE_USER_SUCCESS = 'GET_SINGLE_USER_SUCCESS';
export const GET_SINGLE_USER_FAILED = 'GET_SINGLE_USER_FAILED';

export const GET_ALL_USERS_REQUEST = 'GET_ALL_USERS_REQUEST';
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS';
export const GET_ALL_USERS_FAILED = 'GET_ALL_USERS_FAILED';

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILED = 'UPDATE_USER_FAILED';

/*----------------------------------
          LOGIN USER        
==================================*/

export const LOGIN_USER_REQUEST = 'LOGIN_USER_REQUEST';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILED = 'LOGIN_USER_FAILED';
export const USER_LOGOUT = 'USER_LOGOUT';

/*----------------------------------
          FORGOT PASSWORD        
==================================*/

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILED = 'FORGOT_PASSWORD_FAILED';

/*----------------------------------
          RESET FORGOT PASSWORD        
==================================*/

export const RESET_FORGOT_PASSWORD_REQUEST = 'RESET_FORGOT_PASSWORD_REQUEST';
export const RESET_FORGOT_PASSWORD_SUCCESS = 'RESET_FORGOT_PASSWORD_SUCCESS';
export const RESET_FORGOT_PASSWORD_FAILED = 'RESET_FORGOT_PASSWORD_FAILED';

/*----------------------------------
          REFRESH TOKEN        
==================================*/

export const REFRESH_TOKEN_REQUEST = 'REFRESH_TOKEN_REQUEST';
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_FAILED = 'REFRESH_TOKEN_FAILED';

/*----------------------------------
          VALIDATION TOKEN        
==================================*/

export const VALIDATE_TOKEN_REQUEST = 'VALIDATE_TOKEN_REQUEST';
export const VALIDATE_TOKEN_SUCCESS = 'VALIDATE_TOKEN_SUCCESS';
export const VALIDATE_TOKEN_FAILED = 'VALIDATE_TOKEN_FAILED';

/*----------------------------------
          REGISTER USER        
==================================*/

export const REGISTER_USER_REQUEST = 'REGISTER_USER_REQUEST';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAILED = 'REGISTER_USER_FAILED';

/*----------------------------------
        CHANGE PASSWORD USER        
==================================*/

export const CHANGE_USER_PASS_REQUEST = 'CHANGE_USER_PASS_REQUEST';
export const CHANGE_USER_PASS_SUCCESS = 'CHANGE_USER_PASS_SUCCESS';
export const CHANGE_USER_PASS_FAILED = 'CHANGE_USER_PASS_FAILED';

/*----------------------------------
        MY PROFILE        
==================================*/

export const GET_MY_PROFILE_REQUEST = 'GET_MY_PROFILE_REQUEST';
export const GET_MY_PROFILE_SUCCESS = 'GET_MY_PROFILE_SUCCESS';
export const GET_MY_PROFILE_FAILED = 'GET_MY_PROFILE_FAILED';

export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAILED = 'UPDATE_PROFILE_FAILED';

/*----------------------------------
          SYSTEM USER        
==================================*/

export const GET_SINGLE_SYSTEM_USER_REQUEST = 'GET_SINGLE_SYSTEM_USER_REQUEST';
export const GET_SINGLE_SYSTEM_USER_SUCCESS = 'GET_SINGLE_SYSTEM_USER_SUCCESS';
export const GET_SINGLE_SYSTEM_USER_FAILED = 'GET_SINGLE_SYSTEM_USER_FAILED';

export const GET_ALL_SYSTEM_USERS_REQUEST = 'GET_ALL_SYSTEM_USERS_REQUEST';
export const GET_ALL_SYSTEM_USERS_SUCCESS = 'GET_ALL_SYSTEM_USERS_SUCCESS';
export const GET_ALL_SYSTEM_USERS_FAILED = 'GET_ALL_SYSTEM_USERS_FAILED';

export const ADD_SYSTEM_USERS_REQUEST = 'ADD_SYSTEM_USERS_REQUEST';
export const ADD_SYSTEM_USERS_SUCCESS = 'ADD_SYSTEM_USERS_SUCCESS';
export const ADD_SYSTEM_USERS_FAILED = 'ADD_SYSTEM_USERS_FAILED';

export const UPDATE_SYSTEM_USER_REQUEST = 'UPDATE_SYSTEM_USER_REQUEST';
export const UPDATE_SYSTEM_USER_SUCCESS = 'UPDATE_SYSTEM_USER_SUCCESS';
export const UPDATE_SYSTEM_USER_FAILED = 'UPDATE_SYSTEM_USER_FAILED';

export const REMOVE_SYSTEM_USER_REQUEST = 'REMOVE_SYSTEM_USER_REQUEST';
export const REMOVE_SYSTEM_USER_SUCCESS = 'REMOVE_SYSTEM_USER_SUCCESS';
export const REMOVE_SYSTEM_USER_FAILED = 'REMOVE_SYSTEM_USER_FAILED';

export const REMOVE_MULTIPLE_SYSTEM_USER_REQUEST =
  'REMOVE_MULTIPLE_SYSTEM_USER_REQUEST';
export const REMOVE_MULTIPLE_SYSTEM_USER_SUCCESS =
  'REMOVE_MULTIPLE_SYSTEM_USER_SUCCESS';
export const REMOVE_MULTIPLE_SYSTEM_USER_FAILED =
  'REMOVE_MULTIPLE_SYSTEM_USER_FAILED';
