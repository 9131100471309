import { call, put, takeLatest } from 'redux-saga/effects';
import axios from 'axios';
import { GET_MY_INVENTORY_REQUEST } from 'redux/constants/InventoryConstants/inventory.my.constants';
import {
  populateInventoryItems,
  populateInventoryItemsFailed
} from 'redux/actions/InventoryActions/inventory.my.action';
import { setSnackbar } from 'redux/actions/UIComponentActions/snackbar.action';
import api from 'utils/api';

export function* getAllMyInventory() {
  yield takeLatest(GET_MY_INVENTORY_REQUEST, fetchProducts);
}
/* const response = yield call(getTweets, buttonStatus);

 */
function* fetchProducts(payload) {
  const {
    page: currentPage,
    size,
    orderBy: sortField,
    order: sortDir,
    searchValue: query,
    outletId
  } = payload;
  try {
    const { data } = yield api.get('/getAll-Inventory', {
      params: {
        currentPage: currentPage + 1 || 1,
        size: size || 8,
        sortField: sortField ?? 'name',
        sortDir: sortDir || 'asc',
        query: query || '',
        outletId: outletId || ''
      }
    });
    yield put(populateInventoryItems(data));
  } catch (error) {
    yield put(populateInventoryItemsFailed(error));
    if (error?.response?.status !== 404) {
      yield put(setSnackbar(true, 'error', error.response.data.errorMessage));
    }
  }
}

/* function* fetchProducts(payload) {
  const {
    page: currentPage,
    size,
    orderBy: sortField,
    order: sortDir,
    searchValue: query,
    oultetId
  } = payload;
  try {
    const { data } = yield api.get('/getAwaiting-Delivery', {
      params: {
        currentPage: currentPage + 1 || 1,
        size: size || 8,
        sortField: sortField ?? 'po_number',
        sortDir: sortDir || 'asc',
        query: query || '',
        outletId: oultetId || ''
      }
    });
    yield put(populateAwaitingDelivery(data));
  } catch (error) {
    yield put(populateAwaitingDeliveryFailed(error));
    if (error?.response?.status !== 404) {
      yield put(setSnackbar(true, 'error', error.message));
    }
  }
} */
