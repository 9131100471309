import {
  GET_MY_INVENTORY_SUCCESS,
  GET_MY_INVENTORY_FAILED,
  GET_MY_INVENTORY_REQUEST
} from 'redux/constants/InventoryConstants/inventory.my.constants';

const MyInventoryReducer = (
  state = {
    loading: false,
    inventory_items: [],
    error: false,
    page: 1,
    first: true,
    last: true,
    outletId: ''
  },
  action
) => {
  const { inventory_items } = state;

  switch (action.type) {
    case GET_MY_INVENTORY_REQUEST:
      return {
        ...state,
        inventory_items: [],
        loading: true
      };
    case GET_MY_INVENTORY_SUCCESS:
      console.log(action.payload, 'my');
      return {
        ...state,
        inventory_items: [...action.payload.content],
        page: action.payload.page,
        pages: action.payload.totalElements,
        first: action.payload.first,
        last: action.payload.last,
        size: action.payload.size,
        searchValue: action.payload.searchValue,
        loading: false,
        outletId: action.payload.outletId
      };
    case GET_MY_INVENTORY_FAILED:
      return {
        ...state,
        error: action.payload,
        inventory_items: [...inventory_items],
        loading: false
      };

    default:
      return state;
  }
};
export default MyInventoryReducer;
