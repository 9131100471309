import {
  GET_ALL_TAX_RATES_SUCCESS,
  GET_ALL_TAX_RATES_FAILED,
  REMOVE_TAX_RATES_SUCCESS,
  ADD_TAX_RATES_SUCCESS,
  ADD_TAX_RATES_FAILED,
  UPDATE_TAX_RATES_SUCCESS,
  GET_SINGLE_TAX_RATES_FAILED,
  GET_SINGLE_TAX_RATES_SUCCESS,
  UPDATE_TAX_RATES_FAILED,
  GET_ALL_TAX_GROUPS_SUCCESS,
  GET_ALL_TAX_GROUPS_FAILED,
  GET_SINGLE_TAX_GROUP_SUCCESS,
  GET_SINGLE_TAX_GROUP_FAILED,
  GET_SINGLE_TAX_GROUP_REQUEST,
  ADD_TAX_GROUPS_SUCCESS,
  ADD_TAX_GROUPS_FAILED,
  REMOVE_TAX_GROUPS_SUCCESS,
  UPDATE_TAX_GROUPS_SUCCESS,
  UPDATE_TAX_GROUPS_FAILED,
  GET_ALL_TAX_RATES_REQUEST,
  GET_SINGLE_TAX_RATES_REQUEST
} from 'redux/constants/StoreSetupConstants/tax.rules.constants';
export const taxRulesReducer = (
  state = { loading: true, tax_rules: [], error: false, tax_rate: {} },
  action
) => {
  const { tax_rules } = state;
  let newTaxRates;
  switch (action.type) {
    case GET_ALL_TAX_RATES_REQUEST:
      return {
        ...state,
        tax_rules: [],
        loading: true
      };
    case GET_ALL_TAX_RATES_SUCCESS:
      return {
        ...state,
        tax_rules: [...action.payload],
        loading: false
      };
    case GET_ALL_TAX_RATES_FAILED:
      return {
        ...state,
        error: action.payload,
        tax_rules: [...state.tax_rules],
        loading: false
      };
    case GET_SINGLE_TAX_RATES_REQUEST:
      return {
        ...state,
        tax_rate: {},
        loading: true
      };
    case GET_SINGLE_TAX_RATES_SUCCESS:
      return {
        ...state,
        tax_rate: action.payload,
        loading: false
      };
    case GET_SINGLE_TAX_RATES_FAILED:
      return {
        ...state,
        error: action.payload,
        tax_rate: {},
        loading: false
      };
    case GET_SINGLE_TAX_GROUP_REQUEST:
      return {
        ...state,
        tax_group: {},
        loading: true
      };
    case GET_SINGLE_TAX_GROUP_SUCCESS:
      return {
        ...state,
        tax_group: action.payload,
        loading: false
      };
    case GET_SINGLE_TAX_GROUP_FAILED:
      return {
        ...state,
        error: action.payload,
        tax_group: {},
        loading: false
      };
    case ADD_TAX_RATES_SUCCESS:
      newTaxRates = {
        ...state,
        tax_rate: {},
        tax_rules: [...tax_rules, action.payload]
      };
      return newTaxRates;
    case ADD_TAX_RATES_FAILED:
      return { error: action.payload, tax_rules: [...state.tax_rules] };
    case REMOVE_TAX_RATES_SUCCESS:
      newTaxRates = tax_rules.filter(
        (tax_rate) => tax_rate.id !== action.payload.id
      );
      return { loading: false, tax_rules: newTaxRates, error: false };

    case UPDATE_TAX_RATES_SUCCESS:
      const { id, tax_name, tax_rate, about, group } = action.payload;

      newTaxRates = [...tax_rules];
      const existingProduct = state.tax_rules.find(
        (tax_rate) => tax_rate.id === id
      );
      if (existingProduct) {
        existingProduct.tax_name = tax_name;
        existingProduct.tax_rate = tax_rate;
        existingProduct.about = about;
        existingProduct.group = group;
        existingProduct.default = action.payload.default;
      }
      return { tax_rules: newTaxRates };
    case UPDATE_TAX_RATES_FAILED: {
      return { error: action.payload, tax_rules: [...state.tax_rules] };
    }
    /*----------------------------------
              TAX GROUP          
    ==================================*/

    default:
      return state;
  }
};

export const taxGroupsReducer = (
  state = { loading: true, tax_groups: [], error: false, tax_group: {} },
  action
) => {
  let newTaxGroups;
  const { tax_groups } = state;
  switch (action.type) {
    case GET_ALL_TAX_GROUPS_SUCCESS:
      return {
        ...state,
        tax_groups: [...action.payload],
        loading: false
      };
    case GET_ALL_TAX_GROUPS_FAILED:
      return {
        ...state,
        error: action.payload,
        tax_groups: [...state.tax_groups],
        loading: false
      };
    case ADD_TAX_GROUPS_SUCCESS:
      newTaxGroups = {
        ...state,
        tax_groups: [...tax_groups, action.payload],
        tax_rate: {}
      };
      return newTaxGroups;
    case ADD_TAX_GROUPS_FAILED:
      return { error: action.payload, tax_groups: [...state.tax_groups] };
    case REMOVE_TAX_GROUPS_SUCCESS:
      newTaxGroups = tax_groups.filter(
        (tax_rate) => tax_rate.id !== action.payload.id
      );
      return { loading: false, tax_groups: newTaxGroups, error: false };

    case UPDATE_TAX_GROUPS_SUCCESS:
      // const {
      //   id: tax_id_group,
      //   tax_name:ta,
      //   tax_rate,
      //   about,
      //   group
      // } = action.payload;
      console.log(action.payload, 'reducer group');
      newTaxGroups = [...tax_groups];
      const existingGroup = state.tax_groups.find(
        (tax_rate) => tax_rate.tax_id === action.payload.tax_id
      );

      if (existingGroup) {
        existingGroup.tax_name = action.payload.tax_name;
        existingGroup.tax_rate = action.payload.tax_rate;
        existingGroup.about = action.payload.about;
        existingGroup.group = action.payload.group;
        existingGroup.default = action.payload.default;
        // existingGroup.sub_taxes = action.payload.sub_taxes;
        existingGroup.sub_taxIds = action.payload.sub_taxIds;
        existingGroup.systemDefault = action.payload.systemDefault;
        existingGroup.active = action.payload.active;
      }
      return { tax_groups: newTaxGroups };
    case UPDATE_TAX_GROUPS_FAILED: {
      return { error: action.payload, tax_rules: [...state.tax_rules] };
    }
    case GET_SINGLE_TAX_GROUP_REQUEST:
      return {
        ...state,
        tax_group: {},
        loading: true
      };
    case GET_SINGLE_TAX_GROUP_SUCCESS:
      return {
        ...state,
        tax_group: action.payload,
        loading: false
      };
    case GET_SINGLE_TAX_GROUP_FAILED:
      return {
        ...state,
        error: action.payload,
        tax_group: {},
        loading: false
      };
    default:
      return state;
  }
};
