import {
  GET_PRODUCT_SEASONS_REQUEST,
  GET_PRODUCT_SEASONS_SUCCESS,
  GET_PRODUCT_SEASONS_FAILED,
  REMOVE_MULTIPLE_PRODUCT_SEASONS_REQUEST,
  REMOVE_PRODUCT_SEASONS_REQUEST,
  ADD_PRODUCT_SEASONS_REQUEST,
  UPDATE_PRODUCT_SEASONS_REQUEST,
  ADD_PRODUCT_SEASONS_FAILED,
  GET_SINGLE_PRODUCT_SEASON_REQUEST,
  GET_SINGLE_PRODUCT_SEASON_SUCCESS,
  GET_SINGLE_PRODUCT_SEASON_FAILED
} from 'redux/constants/ProductConstants/product.seasons.constants';

export const getSingleSeason = (id) => {
  return { type: GET_SINGLE_PRODUCT_SEASON_REQUEST, payload: id };
};
export const populateSingleSeason = (user) => {
  return { type: GET_SINGLE_PRODUCT_SEASON_SUCCESS, payload: user };
};
export const populateSingleSeasonFailed = (error) => {
  return { type: GET_SINGLE_PRODUCT_SEASON_FAILED, payload: error };
};
export const addProductSeason = (payload) => {
  return { type: ADD_PRODUCT_SEASONS_REQUEST, payload };
};
export const addProductSeasonError = (error) => {
  return { type: ADD_PRODUCT_SEASONS_FAILED, payload: error };
};
export const removeProductSeason = (id) => {
  return { type: REMOVE_PRODUCT_SEASONS_REQUEST, payload: { id } };
};
export const removeMultipleProductSeason = (id) => {
  return { type: REMOVE_MULTIPLE_PRODUCT_SEASONS_REQUEST, payload: { id } };
};

export const updateProductSeason = (id, entry) => {
  return { type: UPDATE_PRODUCT_SEASONS_REQUEST, payload: { id, entry } };
};

export const getAllProductSeasons = (
  searchValue,
  page,
  size,
  order,
  orderBy
) => {
  return {
    type: GET_PRODUCT_SEASONS_REQUEST,
    searchValue,
    page,
    size,
    order,
    orderBy
  };
};
export const populateProductSeasons = (entries) => {
  return { type: GET_PRODUCT_SEASONS_SUCCESS, payload: entries };
};
export const populateProductSeasonsError = (error) => {
  return { type: GET_PRODUCT_SEASONS_FAILED, payload: error };
};
