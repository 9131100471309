import {
  GET_SINGLE_CASH_REGISTERS_REQUEST,
  GET_SINGLE_CASH_REGISTERS_SUCCESS,
  GET_SINGLE_CASH_REGISTERS_FAILED,
  GET_ALL_CASH_REGISTERS_REQUEST,
  GET_ALL_CASH_REGISTERS_SUCCESS,
  GET_ALL_CASH_REGISTERS_FAILED,
  ADD_CASH_REGISTERS_REQUEST,
  ADD_CASH_REGISTERS_FAILED,
  UPDATE_CASH_REGISTERS_REQUEST,
  REMOVE_CASH_REGISTERS_REQUEST
} from 'redux/constants/StoreSetupConstants/cash.registers.constants';
/*----------------------------------
    CASH_REGISTER > GET SINGLE CASH_REGISTER         
==================================*/

export const getSingleCashRegister = (cash_regid) => {
  return { type: GET_SINGLE_CASH_REGISTERS_REQUEST, payload: cash_regid };
};
export const populateSingleCashRegister = (tax_rate) => {
  return { type: GET_SINGLE_CASH_REGISTERS_SUCCESS, payload: tax_rate };
};
export const populateSingleCashRegisterFailed = (error) => {
  return { type: GET_SINGLE_CASH_REGISTERS_FAILED, payload: error };
};

/*------------------------------------------
     CASH_REGISTER > GET ALL CASH_REGISTERS       
  ==========================================*/

export const getAllCashRegisters = () => {
  return { type: GET_ALL_CASH_REGISTERS_REQUEST };
};
export const populateCashRegisters = (entries) => {
  return { type: GET_ALL_CASH_REGISTERS_SUCCESS, payload: entries };
};
export const populateCashRegistersFailed = (error) => {
  return { type: GET_ALL_CASH_REGISTERS_FAILED, payload: error };
};

/*-------------------------------------
     CASH_REGISTER > ADD CASH_REGISTERS       
  =====================================*/

export const addCashRegister = (payload) => {
  return { type: ADD_CASH_REGISTERS_REQUEST, payload };
};
export const addCashRegisterFailed = (error) => {
  return { type: ADD_CASH_REGISTERS_FAILED, payload: error };
};

/*------------------------------------------
     CASH_REGISTER > UPDATE CASH_REGISTERS       
  ==========================================*/

export const updateCashRegister = (cash_regid, entry) => {
  return {
    type: UPDATE_CASH_REGISTERS_REQUEST,
    payload: { cash_regid, entry }
  };
};
/*----------------------------------------
     CASH_REGISTER > REMOVE CASH_REGISTERS       
  =========================================*/

export const removeCashRegister = (cash_regid) => {
  return { type: REMOVE_CASH_REGISTERS_REQUEST, payload: { cash_regid } };
};
