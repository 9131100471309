import {
  GET_GENERAL_STORE_SETUP_SUCCESS,
  GET_GENERAL_STORE_SETUP_FAILED,
  GET_GENERAL_STORE_SETUP_REQUEST
} from 'redux/constants/StoreSetupConstants/general.store.setup.constants';

const GeneralStoreSetupReducer = (
  state = { loading: true, error: false, general_store: {} },
  action
) => {
  switch (action.type) {
    case GET_GENERAL_STORE_SETUP_REQUEST:
      return {
        ...state,
        general_store: {},
        loading: true
      };
    case GET_GENERAL_STORE_SETUP_SUCCESS:
      return {
        ...state,
        general_store: { ...action.payload },
        loading: false
      };
    case GET_GENERAL_STORE_SETUP_FAILED:
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    default:
      return state;
  }
};

export default GeneralStoreSetupReducer;
