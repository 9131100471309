import {
  GET_SINGLE_SUPPLIERS_REQUEST,
  GET_SINGLE_SUPPLIERS_SUCCESS,
  GET_SINGLE_SUPPLIERS_FAILED,
  GET_ALL_SUPPLIERS_REQUEST,
  GET_ALL_SUPPLIERS_SUCCESS,
  GET_ALL_SUPPLIERS_FAILED,
  ADD_SUPPLIERS_REQUEST,
  ADD_SUPPLIERS_FAILED,
  UPDATE_SUPPLIERS_REQUEST,
  REMOVE_SUPPLIERS_REQUEST
} from 'redux/constants/InventoryConstants/inventory.suppliers.constants';
/*----------------------------------
    SUPPLIER > GET SINGLE SUPPLIER         
==================================*/

export const getSingleSupplier = (supplier_id) => {
  return { type: GET_SINGLE_SUPPLIERS_REQUEST, payload: supplier_id };
};
export const populateSingleSupplier = (supplier) => {
  return { type: GET_SINGLE_SUPPLIERS_SUCCESS, payload: supplier };
};
export const populateSingleSupplierFailed = (error) => {
  return { type: GET_SINGLE_SUPPLIERS_FAILED, payload: error };
};

/*------------------------------------------
     SUPPLIER > GET ALL SUPPLIERS       
  ==========================================*/

export const getAllSuppliers = (searchValue, page, size, order, orderBy) => {
  return {
    type: GET_ALL_SUPPLIERS_REQUEST,
    searchValue,
    page,
    size,
    order,
    orderBy
  };
};

export const populateSuppliers = (entries) => {
  return { type: GET_ALL_SUPPLIERS_SUCCESS, payload: entries };
};
export const populateSuppliersFailed = (error) => {
  return { type: GET_ALL_SUPPLIERS_FAILED, payload: error };
};

/*-------------------------------------
     SUPPLIER > ADD SUPPLIERS       
  =====================================*/

export const addSupplier = (payload) => {
  return { type: ADD_SUPPLIERS_REQUEST, payload };
};
export const addSupplierFailed = (error) => {
  return { type: ADD_SUPPLIERS_FAILED, payload: error };
};

/*------------------------------------------
     SUPPLIER > UPDATE SUPPLIERS       
  ==========================================*/

export const updateSupplier = (supplier_id, entry) => {
  return {
    type: UPDATE_SUPPLIERS_REQUEST,
    payload: { supplier_id, entry }
  };
};
/*----------------------------------------
     SUPPLIER > REMOVE SUPPLIERS       
  =========================================*/

export const removeSupplier = (supplier_id) => {
  return { type: REMOVE_SUPPLIERS_REQUEST, payload: { supplier_id } };
};
