import React from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import Switch from '@material-ui/core/Switch';
const SwitchToggle = (props) => {
  const { name, onChange, value, ...other } = props;
  const convertToDefaultEventParameter = (name, value) => ({
    target: {
      name,
      value
    }
  });
  return (
    <>
      <FormGroup>
        <Switch
          checked={value}
          disableRipple
          onChange={(e) =>
            onChange(convertToDefaultEventParameter(name, e.target.checked))
          }
          name={name}
          color="primary"
          {...other}
        />
      </FormGroup>
    </>
  );
};
export default SwitchToggle;
