import { TextField } from '@material-ui/core';
import React from 'react';
const Input = (props) => {
  const {
    error = null,
    name,
    label,
    value,
    onChange,
    id,
    size,
    fullWidth,
    style,
    margin,
    variant,
    ...other
  } = props;
  return (
    <TextField
      inputProps={{
        autoComplete: 'new-password',
        form: {
          autoComplete: 'no'
        }
      }}
      fullWidth={fullWidth}
      label={label}
      style={style}
      variant={variant || 'outlined'}
      id={id}
      name={name}
      margin={margin}
      value={value}
      size={size || 'small'}
      {...(error && { error: true, helperText: error })}
      onChange={onChange}
      {...other}
    />
  );
};

export default Input;
