import {
  GET_SINGLE_CUSTOMER_REQUEST,
  GET_SINGLE_CUSTOMER_SUCCESS,
  GET_SINGLE_CUSTOMER_FAILED,
  UPDATE_CUSTOMER_REQUEST,
  GET_ALL_CUSTOMERS_REQUEST,
  GET_ALL_CUSTOMERS_SUCCESS,
  GET_ALL_CUSTOMERS_FAILED,
  ADD_CUSTOMERS_REQUEST,
  ADD_CUSTOMERS_FAILED,
  REMOVE_CUSTOMER_REQUEST,
  REMOVE_MULTIPLE_CUSTOMER_REQUEST,
  SET_CUSTOMER,
  RESET_CUSTOMER_SEARCH,
  HIDE_POPUP
} from 'redux/constants/CustomersConstants/customers.constants';

/*----------------------------------
          GET SINGLE POS CUSTOMER         
==================================*/

export const getSingleCustomer = (id) => {
  return { type: GET_SINGLE_CUSTOMER_REQUEST, payload: id };
};
export const populateSingleCustomer = (user) => {
  return { type: GET_SINGLE_CUSTOMER_SUCCESS, payload: user };
};
export const populateSingleCustomerFailed = (error) => {
  return { type: GET_SINGLE_CUSTOMER_FAILED, payload: error };
};
/*----------------------------------
          UPDATE POS CUSTOMER         
==================================*/

export const updateCustomer = (id, entry) => {
  return {
    type: UPDATE_CUSTOMER_REQUEST,
    payload: { id, entry }
  };
};

/*----------------------------------
   CUSTOMER > GET ALL CUSTOMERS       
==================================*/

export const getAllCustomers = (searchValue, page, size, order, orderBy) => {
  return {
    type: GET_ALL_CUSTOMERS_REQUEST,
    searchValue,
    page,
    size,
    order,
    orderBy
  };
};
export const populateCustomers = (entries) => {
  return { type: GET_ALL_CUSTOMERS_SUCCESS, payload: entries };
};
export const populateCustomersFailed = (error) => {
  return { type: GET_ALL_CUSTOMERS_FAILED, payload: error };
};

/*----------------------------------
   CUSTOMER > ADD CUSTOMERS       
==================================*/

export const addCustomer = (payload) => {
  return { type: ADD_CUSTOMERS_REQUEST, payload };
};
export const addCustomerFailed = (error) => {
  return { type: ADD_CUSTOMERS_FAILED, payload: error };
};

/*----------------------------------
   CUSTOMER > REMOVE CUSTOMERS       
==================================*/

export const removeCustomer = (id) => {
  return { type: REMOVE_CUSTOMER_REQUEST, payload: { id } };
};
export const removeMultipleCustomer = (id) => {
  return {
    type: REMOVE_MULTIPLE_CUSTOMER_REQUEST,
    payload: { id }
  };
};

/*----------------------------------
          SET CUSTOMER          
==================================*/
export const setCustomer = (customer) => {
  return { type: SET_CUSTOMER, payload: customer };
};

export const resetCustomer = () => {
  return { type: RESET_CUSTOMER_SEARCH };
};

// export const hidePopup = () => {
//   return { type: HIDE_POPUP };
// };
