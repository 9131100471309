import { put, takeLatest, delay } from '@redux-saga/core/effects';
import {
  populateGeneralStoreSetup,
  populateGeneralStoreSetupFailed
} from 'redux/actions/StoreSetupActions/general.store.setup.action';
import { GET_GENERAL_STORE_SETUP_REQUEST } from 'redux/constants/StoreSetupConstants/general.store.setup.constants';
import api from 'utils/api';

export function* onFetchMyProfile() {
  yield takeLatest(GET_GENERAL_STORE_SETUP_REQUEST, fetchProfile);
}
function* fetchProfile() {
  try {
    const { data } = yield api.get(`/store-setUp`);
    yield delay(400);
    yield put(populateGeneralStoreSetup(data));
  } catch (err) {
    yield put(populateGeneralStoreSetupFailed(err.message));
  }
}
