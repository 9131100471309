import React from 'react';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete'; // createFilterOptions,
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
// const filterOptions = createFilterOptions({
//   matchFrom: "start",
//   stringify: (option) => option.text,
// });
const AutoCompleteSearch = (props) => {
  const {
    error = null,
    label,
    inputValue,
    onChange,
    options,
    disabled,
    onInputChange,
    defaultValue,
    multiple,
    disableClearable
  } = props;
  return (
    <Autocomplete
      multiple={multiple}
      disableClearable={disableClearable}
      // id="highlights-demo"
      // style={{ width: 300 }}
      options={options}
      // value={value}
      onInputChange={onInputChange}
      getOptionLabel={(option) => option.title}
      inputValue={inputValue}
      disabled={disabled}
      defaultValue={defaultValue}
      onChange={onChange}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          label={label}
          variant="outlined"
          margin="normal"
          disabled={disabled}
          {...(error && { error: true, helperText: error })}
        />
      )}
      renderOption={(option, { inputValue }) => {
        const matches = match(option.title, inputValue);
        const parts = parse(option.title, matches);

        return (
          <div>
            {parts.map((part, index) => (
              <span
                key={index}
                style={{ fontWeight: part.highlight ? 700 : 400 }}
              >
                {part.text}
              </span>
            ))}
          </div>
        );
      }}
    />
  );
};

export default AutoCompleteSearch;
