/*----------------------------------
    CASH REGISTERS CONSTANTS         
==================================*/

export const GET_ALL_SUPPLIERS_REQUEST = 'GET_ALL_SUPPLIERS_REQUEST';
export const GET_ALL_SUPPLIERS_SUCCESS = 'GET_ALL_SUPPLIERS_SUCCESS';
export const GET_ALL_SUPPLIERS_FAILED = 'GET_ALL_SUPPLIERS_FAILED';

export const REMOVE_SUPPLIERS_REQUEST = 'REMOVE_SUPPLIERS_REQUEST';
export const REMOVE_SUPPLIERS_SUCCESS = 'REMOVE_SUPPLIERS_SUCCESS';
export const REMOVE_SUPPLIERS_FAILED = 'REMOVE_SUPPLIERS_FAILED';

export const ADD_SUPPLIERS_REQUEST = 'ADD_SUPPLIERS_REQUEST';
export const ADD_SUPPLIERS_SUCCESS = 'ADD_SUPPLIERS_SUCCESS';
export const ADD_SUPPLIERS_FAILED = 'ADD_SUPPLIERS_FAILED';

export const UPDATE_SUPPLIERS_REQUEST = 'UPDATE_SUPPLIERS_REQUEST';
export const UPDATE_SUPPLIERS_SUCCESS = 'UPDATE_SUPPLIERS_SUCCESS';
export const UPDATE_SUPPLIERS_FAILED = 'UPDATE_SUPPLIERS_FAILED';

export const GET_SINGLE_SUPPLIERS_REQUEST = 'GET_SINGLE_SUPPLIERS_REQUEST';
export const GET_SINGLE_SUPPLIERS_SUCCESS = 'GET_SINGLE_SUPPLIERS_SUCCESS';
export const GET_SINGLE_SUPPLIERS_FAILED = 'GET_SINGLE_SUPPLIERS_FAILED';
