import { makeStyles, createMuiTheme } from '@material-ui/core';
export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#1a2f54',
      light: '#EFF3FA'
    },
    secondary: {
      main: '#EB3732',
      light: '#f8324526'
    },
    background: {
      default: '#f4f5fd'
    }
  },
  overrides: {
    MuiAppBar: {
      root: {
        transform: 'translateZ(0)'
      }
    }
  },
  props: {
    MuiIconButton: {
      disableRipple: true
    }
  }
});

export const useStyles = makeStyles({
  '@global': {
    '*::-webkit-scrollbar': {
      width: 12,
      backgroundColor: '#F5F5F5'
    },
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 8px rgba(0,0,0,0.3)',
      borderRadius: 10,
      // backgroundColor: "#F5F5F5",
      backgroundColor: theme.palette.primary.light
    },
    '*::-webkit-scrollbar-thumb': {
      borderRadius: 10,
      '-webkit-box-shadow': 'inset 0 0 8px rgba(0,0,0,0.3)',
      backgroundColor: theme.palette.primary.light
    }
  }
});
