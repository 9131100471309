import {
  GET_PRODUCTS_REQUEST,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_FAILED,
  GET_SINGLE_PRODUCTS_REQUEST,
  REMOVE_MULTIPLE_PRODUCTS_REQUEST,
  REMOVE_PRODUCTS_REQUEST,
  ADD_PRODUCTS_REQUEST,
  UPDATE_PRODUCTS_REQUEST,
  ADD_PRODUCTS_FAILED,
  GET_SINGLE_PRODUCTS_SUCCESS,
  GET_SINGLE_PRODUCTS_FAILED,
  RESET_SINGLE_PRODUCT_SUCCESS
} from 'redux/constants/ProductConstants/products.constants';

export const addProduct = (payload) => {
  return { type: ADD_PRODUCTS_REQUEST, payload };
};
export const addProductFailed = (error) => {
  return { type: ADD_PRODUCTS_FAILED, payload: error };
};
export const removeProduct = (id) => {
  return { type: REMOVE_PRODUCTS_REQUEST, payload: { id } };
};
export const removeMultipleProduct = (id) => {
  return { type: REMOVE_MULTIPLE_PRODUCTS_REQUEST, payload: { id } };
};

export const updateProduct = (id, entry) => {
  return { type: UPDATE_PRODUCTS_REQUEST, payload: { id, entry } };
};

export const getAllProducts = (searchValue, page, size, order, orderBy) => {
  return {
    type: GET_PRODUCTS_REQUEST,
    searchValue,
    page,
    size,
    order,
    orderBy
  };
};
export const populateProducts = (entries) => {
  return { type: GET_PRODUCTS_SUCCESS, payload: entries };
};
export const getSingleProduct = (id) => {
  return { type: GET_SINGLE_PRODUCTS_REQUEST, payload: id };
};
export const populateSingleProduct = (product) => {
  return { type: GET_SINGLE_PRODUCTS_SUCCESS, payload: product };
};

export const populateProductsFailed = (error) => {
  return { type: GET_PRODUCTS_FAILED, payload: error };
};
export const resetSingleProduct = () => {
  return { type: RESET_SINGLE_PRODUCT_SUCCESS };
};
export const populateSingleProductFailed = (error) => {
  return { type: GET_SINGLE_PRODUCTS_FAILED, payload: error };
};
