import {
  GET_ALL_CASH_REGISTERS_SUCCESS,
  GET_ALL_CASH_REGISTERS_FAILED,
  REMOVE_CASH_REGISTERS_SUCCESS,
  ADD_CASH_REGISTERS_SUCCESS,
  ADD_CASH_REGISTERS_FAILED,
  UPDATE_CASH_REGISTERS_SUCCESS,
  GET_SINGLE_CASH_REGISTERS_FAILED,
  GET_SINGLE_CASH_REGISTERS_SUCCESS,
  UPDATE_CASH_REGISTERS_FAILED,
  GET_ALL_CASH_REGISTERS_REQUEST,
  GET_SINGLE_CASH_REGISTERS_REQUEST,
  ADD_CASH_REGISTERS_REQUEST
} from 'redux/constants/StoreSetupConstants/cash.registers.constants';
const initialStateValues = {
  cash_registers: [],
  cash_register: {},
  loading: true,
  error: false
};
const CashRegistersReducer = (state = initialStateValues, action) => {
  const { cash_registers } = state;
  let newCashRegisters;
  switch (action.type) {
    case GET_ALL_CASH_REGISTERS_REQUEST:
      return {
        ...state,
        loading: true,
        cash_registers: []
      };
    case GET_ALL_CASH_REGISTERS_SUCCESS:
      return {
        cash_registers: [...action.payload],
        loading: false,
        error: false
      };
    case GET_ALL_CASH_REGISTERS_FAILED:
      return {
        error: action.payload,
        cash_registers: [...cash_registers],
        loading: false
      };
    case GET_SINGLE_CASH_REGISTERS_REQUEST:
      return {
        ...state,
        cash_register: {},
        loading: true
      };
    case GET_SINGLE_CASH_REGISTERS_SUCCESS:
      return {
        ...state,
        cash_register: action.payload,
        loading: false
      };
    case GET_SINGLE_CASH_REGISTERS_FAILED:
      return {
        ...state,
        error: action.payload,
        cash_register: {},
        loading: false
      };
    case ADD_CASH_REGISTERS_REQUEST:
      newCashRegisters = {
        ...state,
        cash_registers: [...cash_registers],
        loading: true
      };
      return newCashRegisters;
    case ADD_CASH_REGISTERS_SUCCESS:
      newCashRegisters = {
        ...state,
        cash_registers: [...cash_registers, action.payload],
        loading: true
      };
      return newCashRegisters;
    case ADD_CASH_REGISTERS_FAILED:
      return {
        error: action.payload,
        cash_registers: [...state.cash_registers],
        loading: false
      };
    case REMOVE_CASH_REGISTERS_SUCCESS:
      newCashRegisters = cash_registers.filter(
        (cash_register) =>
          cash_register.cash_regid !== action.payload.cash_regid
      );
      return { loading: false, cash_registers: newCashRegisters, error: false };

    case UPDATE_CASH_REGISTERS_SUCCESS:
      const {
        cash_regid,
        cash_reg_name,
        receipt_no,
        receipt_no_prefix,
        receipt_no_suffix,
        receipt_template,
        gift_receipt_template,
        delivery_docket,
        parkedsale_rec_temp,
        laybysale_rec_temp,
        outlet_id,
        paymentTypes,
        pos_rid
      } = action.payload;

      newCashRegisters = [...state.cash_registers];
      const existingProduct = state.cash_registers.find(
        (cash_register) => cash_register.cash_regid === cash_regid
      );

      if (existingProduct) {
        existingProduct.cash_reg_name = cash_reg_name;
        existingProduct.receipt_no = receipt_no;
        existingProduct.receipt_no_prefix = receipt_no_prefix;
        existingProduct.receipt_no_suffix = receipt_no_suffix;
        existingProduct.receipt_template = receipt_template;
        existingProduct.gift_receipt_template = gift_receipt_template;
        existingProduct.delivery_docket = delivery_docket;
        existingProduct.parkedsale_rec_temp = parkedsale_rec_temp;
        existingProduct.laybysale_rec_temp = laybysale_rec_temp;
        existingProduct.outlet_id = outlet_id;
        existingProduct.paymentTypes = paymentTypes;
        existingProduct.pos_rid = pos_rid;
      }
      return { cash_registers: newCashRegisters };
    case UPDATE_CASH_REGISTERS_FAILED: {
      return {
        error: action.payload,
        cash_registers: [...state.cash_registers]
      };
    }

    default:
      return state;
  }
};
export default CashRegistersReducer;
