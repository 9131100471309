import {
  call,
  put,
  take,
  takeLatest,
  takeEvery,
  delay
} from 'redux-saga/effects';

import {
  ADD_CUSTOMERS_REQUEST,
  ADD_CUSTOMERS_SUCCESS,
  GET_ALL_CUSTOMERS_REQUEST,
  GET_SINGLE_CUSTOMER_REQUEST,
  REMOVE_MULTIPLE_CUSTOMER_REQUEST,
  REMOVE_MULTIPLE_CUSTOMER_SUCCESS,
  REMOVE_CUSTOMER_REQUEST,
  REMOVE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_REQUEST,
  UPDATE_CUSTOMER_SUCCESS
} from 'redux/constants/CustomersConstants/customers.constants';
import {
  addCustomerFailed,
  populateSingleCustomer,
  populateSingleCustomerFailed,
  populateCustomers,
  populateCustomersFailed
} from 'redux/actions/CustomersActions/customers.action';
import { setSnackbar } from 'redux/actions/UIComponentActions/snackbar.action';
import { push } from 'connected-react-router';
import api from 'utils/api';
import { getAllCustomerGroups } from './customer.groups.saga';
import { closeDrawer } from 'redux/actions/UIComponentActions/drawer.actions';

/* {{URL}}/getAll-Customers?currentPage=1&size=&sortField=first_name&sortDir=asc&query=usuf */
// export function* getAllCustomers() {
//   yield takeLatest(GET_ALL_CUSTOMERS_REQUEST, fetchCustomers);
// }
// /* const response = yield call(getTweets, buttonStatus);

//  */
// function* fetchCustomers(payload) {
//   console.log(payload);
//   try {
//     const { data } = yield api.get(
//       `getAll-Customers?currentPage=${payload.page + 1}&query=${
//         payload.searchValue
//       }&size=${payload.size}&sortField=${payload.orderBy}&sortDir=${
//         payload.order
//       }`
//     );
//     /* getAwaiting-Delivery?currentPage=1&size=3&sortField=po_number&sortDir=asc */
//     yield put(populateCustomers(data));
//   } catch (error) {
//     yield put(populateCustomersFailed(error));
//     if (error?.response?.status !== 404) {
//       yield put(setSnackbar(true, 'error', error.message));
//     }
//   }
// }
export function* getAllCustomers() {
  yield takeLatest(GET_ALL_CUSTOMERS_REQUEST, fetchCustomers);
}
function* fetchCustomers(payload) {
  const {
    page: currentPage,
    size,
    orderBy: sortField,
    order: sortDir,
    searchValue: query
  } = payload;
  try {
    const { data } = yield api.get('/getAll-Customers', {
      params: {
        currentPage: currentPage + 1 || 1,
        size: size || 8,
        sortField: sortField || 'first_name',
        sortDir: sortDir || 'asc',
        query: query || ''
      }
    });
    yield delay(400);
    yield put(populateCustomers(data));
  } catch (err) {
    yield put(populateCustomersFailed(err));
    if (err.response.status !== 404) {
      yield put(setSnackbar(true, 'error', err.message));
    }
  }
}
/*----------------------------------
       GET SINGLE CUSTOMER
==================================*/
export function* onFetchProductStart() {
  yield takeLatest(GET_SINGLE_CUSTOMER_REQUEST, fetchProduct);
}
function* fetchProduct({ payload }) {
  try {
    if (payload) {
      const { data } = yield api.get(`/get-Customer/${payload}`);
      yield put(populateSingleCustomer(data));
    }
  } catch (err) {
    yield put(populateSingleCustomerFailed(err.message));
  }
}

/*----------------------------------
       UPDATE CUSTOMER
==================================*/
// export function* updateEntrySaga() {
//   yield takeEvery(UPDATE_CUSTOMER_REQUEST, updateEntryToDb);
// }
// function* updateEntryToDb({ payload }) {
//   try {
//     // console.log();
//     const result = yield call(updateEntry, payload);
//     yield put({ type: UPDATE_CUSTOMER_SUCCESS, payload: result });

//     yield put(setSnackbar(true, 'success', 'Updated Successfully'));

//     setTimeout(() => {
//       window.location.reload();
//     }, 2000);
//   } catch (error) {
//     yield put(setSnackbar(true, 'error', 'Something Went Wrong'));
//   }
// }
// async function updateEntry(payload) {
//   const { id, entry } = payload;
//   const {
//     first_name,
//     last_name,
//     country,
//     city,
//     phone,
//     email,
//     role,
//     // accept_terms,
//     created_date_time,
//     last_modified_time,
//     store_setup,
//     store_feature_setup,
//     store_general_rules,
//     store_inven_notif,
//     store_web_addresses
//   } = entry;

//   const { data } = await api.put(`/update-Customer/`, {
//     id,
//     first_name,
//     last_name,
//     country,
//     city,
//     email,
//     // accept_terms,
//     created_date_time,
//     last_modified_time,
//     phone,
//     role,
//     store_setup,
//     store_feature_setup,
//     store_general_rules,
//     store_inven_notif,
//     store_web_addresses
//   });

//   return data;
// }

/*----------------------------------
   CUSTOMER >> ADD CUSTOMER          
==================================*/
export function* addCustomersSaga() {
  yield takeLatest(ADD_CUSTOMERS_REQUEST, systemCustomer);
}

function* systemCustomer({ payload }) {
  try {
    const result = yield call(sysCustomerAsync, payload);
    yield put({ type: ADD_CUSTOMERS_SUCCESS, payload: result });
    yield put(
      closeDrawer({
        modalProps: { open: false }
      })
    );
    yield put(setSnackbar(true, 'success', 'Customer Added Successfully'));

    yield put({ type: GET_ALL_CUSTOMERS_REQUEST });
    yield call(getAllCustomers);
  } catch (error) {
    yield put(addCustomerFailed(error));
    yield put(setSnackbar(true, 'error', 'Something Went Wrong !!'));
  }
}

async function sysCustomerAsync({
  id,
  first_name,
  last_name,
  gender,
  birth_date,
  company_name,
  email,
  phone,
  primary_address,
  customer_group,
  customer_from,
  customer_fromName,
  custome_field1,
  custome_field2,
  custome_field3,
  custome_field4,
  customer_code,
  credit_limit,
  howDidYou_hearAboutusId,
  howDidYou_hearAboutus_name,
  note,
  toAllowFor_marketing,
  toAllowFor_taxExempt,
  active,
  created_date_time,
  last_modified_time,
  allow_loyalty,
  customer_outlets,
  credit_balance,
  current_loyalty_balance,
  isDefault,
  isLoyalty_welcome_emailSent,
  isOffline_customer,
  outStanding_balance,
  password,
  profile_image,
  sync_reference,
  total_loyaltySpent
}) {
  const { data } = await api.post(`/save-Customer`, {
    id,
    first_name,
    last_name,
    gender,
    birth_date,
    company_name,
    email,
    phone,
    primary_address,
    customer_group,
    customer_from,
    customer_fromName,
    custome_field1,
    custome_field2,
    custome_field3,
    custome_field4,
    customer_code,
    credit_limit,
    howDidYou_hearAboutusId,
    howDidYou_hearAboutus_name,
    note,
    toAllowFor_marketing,
    toAllowFor_taxExempt,
    active,
    created_date_time,
    last_modified_time,
    allow_loyalty,
    customer_outlets,
    credit_balance,
    current_loyalty_balance,
    isDefault,
    isLoyalty_welcome_emailSent,
    isOffline_customer,
    outStanding_balance,
    password,
    profile_image,
    sync_reference,
    total_loyaltySpent
  });
  return data;
}

/*----------------------------------
       CUSTOMER >> UPDATE CUSTOMER        
==================================*/

export function* updateCustomersSaga() {
  yield takeEvery(UPDATE_CUSTOMER_REQUEST, updateCustomers);
}

function* updateCustomers({ payload }) {
  try {
    const result = yield call(updateAsyncCustomers, payload);

    yield put({ type: UPDATE_CUSTOMER_SUCCESS, payload: result });
    yield put(
      closeDrawer({
        modalProps: { open: false }
      })
    );
    yield put(setSnackbar(true, 'success', 'Customer Updated Successfully'));

    yield put({ type: GET_ALL_CUSTOMERS_REQUEST });
    yield call(getAllCustomers);
  } catch (error) {
    yield put(setSnackbar(true, 'error', 'Something Went Wrong !!'));
  }
}

async function updateAsyncCustomers(payload) {
  const { id, entry } = payload;
  const {
    first_name,
    last_name,
    gender,
    birth_date,
    company_name,
    email,
    phone,
    primary_address,
    customer_group,
    customer_from,
    customer_fromName,
    custome_field1,
    custome_field2,
    custome_field3,
    custome_field4,
    customer_code,
    credit_limit,
    howDidYou_hearAboutusId,
    howDidYou_hearAboutus_name,
    note,
    toAllowFor_marketing,
    toAllowFor_taxExempt,
    active,
    created_date_time,
    last_modified_time,
    allow_loyalty,
    customer_outlets,
    credit_balance,
    current_loyalty_balance,
    isDefault,
    isLoyalty_welcome_emailSent,
    isOffline_customer,
    outStanding_balance,
    password,
    profile_image,
    sync_reference,
    total_loyaltySpent
  } = entry;
  const { data } = await api.put(`/update-Customer/${id}`, {
    id,
    first_name,
    last_name,
    gender,
    birth_date,
    company_name,
    email,
    phone,
    primary_address,
    customer_group,
    customer_from,
    customer_fromName,
    custome_field1,
    custome_field2,
    custome_field3,
    custome_field4,
    customer_code,
    credit_limit,
    howDidYou_hearAboutusId,
    howDidYou_hearAboutus_name,
    note,
    toAllowFor_marketing,
    toAllowFor_taxExempt,
    active,
    created_date_time,
    last_modified_time,
    allow_loyalty,
    customer_outlets,
    credit_balance,
    current_loyalty_balance,
    isDefault,
    isLoyalty_welcome_emailSent,
    isOffline_customer,
    outStanding_balance,
    password,
    profile_image,
    sync_reference,
    total_loyaltySpent
  });
  return data;
}

/*-------------------------------------
   CUSTOMER >> DELETE SINGLE CUSTOMER       
=====================================*/

export function* deleteCustomersSaga() {
  while (true) {
    const { payload } = yield take(REMOVE_CUSTOMER_REQUEST);
    yield call(deleteSingleCustomer, payload.id);
    yield put({
      type: REMOVE_CUSTOMER_SUCCESS,
      payload: { id: payload.id }
    });
  }
}

async function deleteSingleCustomer(id) {
  await api.delete(`/delete-Customer/${id}`);
}
/*---------------------------------------
 CUSTOMER >> DELETE MULTIPLE CUSTOMER       
========================================*/

export function* deleteMultipleCustomersSaga() {
  while (true) {
    const { payload } = yield take(REMOVE_MULTIPLE_CUSTOMER_REQUEST);
    yield call(deleteMultipleCustomerss, payload.id);
    yield put({
      type: REMOVE_MULTIPLE_CUSTOMER_SUCCESS,
      payload: { id: payload.id }
    });
  }
}

async function deleteMultipleCustomerss(ids) {
  await api.all(ids.map((id) => api.delete(`/delete-systemCustomer/${id}`)));
}
