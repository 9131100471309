import {
  GET_SINGLE_TAX_RATES_REQUEST,
  GET_SINGLE_TAX_RATES_SUCCESS,
  GET_SINGLE_TAX_RATES_FAILED,
  GET_SINGLE_TAX_GROUP_REQUEST,
  GET_SINGLE_TAX_GROUP_SUCCESS,
  GET_SINGLE_TAX_GROUP_FAILED,
  GET_ALL_TAX_RATES_REQUEST,
  GET_ALL_TAX_RATES_SUCCESS,
  ADD_TAX_RATES_REQUEST,
  ADD_TAX_RATES_FAILED,
  REMOVE_TAX_RATES_REQUEST,
  GET_ALL_TAX_RATES_FAILED,
  UPDATE_TAX_RATES_REQUEST,
  REMOVE_TAX_GROUPS_REQUEST,
  UPDATE_TAX_GROUPS_REQUEST,
  ADD_TAX_GROUPS_FAILED,
  ADD_TAX_GROUPS_REQUEST,
  GET_ALL_TAX_GROUPS_REQUEST,
  GET_ALL_TAX_GROUPS_SUCCESS,
  GET_ALL_TAX_GROUPS_FAILED
} from 'redux/constants/StoreSetupConstants/tax.rules.constants';

/*----------------------------------
    TAX RATE > GET SINGLE TAX RATE         
==================================*/

export const getSingleTaxRate = (id) => {
  return { type: GET_SINGLE_TAX_RATES_REQUEST, payload: id };
};
export const populateSingleTaxRate = (tax_rate) => {
  return { type: GET_SINGLE_TAX_RATES_SUCCESS, payload: tax_rate };
};
export const populateSingleTaxRateFailed = (error) => {
  return { type: GET_SINGLE_TAX_RATES_FAILED, payload: error };
};

/*----------------------------------
    TAX GROUP > GET SINGLE TAX GROUP         
==================================*/

export const getSingleTaxGroup = (tax_id) => {
  return { type: GET_SINGLE_TAX_GROUP_REQUEST, payload: tax_id };
};
export const populateSingleTaxGroup = (tax_rate) => {
  return { type: GET_SINGLE_TAX_GROUP_SUCCESS, payload: tax_rate };
};
export const populateSingleTaxGroupFailed = (error) => {
  return { type: GET_SINGLE_TAX_GROUP_FAILED, payload: error };
};

/*----------------------------------
   TAX RATE > GET ALL TAX RATES       
==================================*/

export const getAllTaxRates = (searchValue, page, size, order, orderBy) => {
  return {
    type: GET_ALL_TAX_RATES_REQUEST,
    searchValue,
    page,
    size,
    order,
    orderBy
  };
};
export const populateTaxRates = (entries) => {
  return { type: GET_ALL_TAX_RATES_SUCCESS, payload: entries };
};
export const populateTaxRatesFailed = (error) => {
  return { type: GET_ALL_TAX_RATES_FAILED, payload: error };
};

/*----------------------------------
   TAX RATE > ADD TAX RATES       
==================================*/

export const addTaxRate = (payload) => {
  return { type: ADD_TAX_RATES_REQUEST, payload };
};
export const addTaxRateFailed = (error) => {
  return { type: ADD_TAX_RATES_FAILED, payload: error };
};

/*----------------------------------
   TAX RATE > UPDATE TAX RATES       
==================================*/

export const updateTaxRate = (id, entry) => {
  return { type: UPDATE_TAX_RATES_REQUEST, payload: { id, entry } };
};

/*----------------------------------
   TAX RATE > REMOVE TAX RATES       
==================================*/

export const removeTaxRate = (id) => {
  return { type: REMOVE_TAX_RATES_REQUEST, payload: { id } };
};

/*----------------------------------
   TAX GROUP > GET ALL TAX GROUPS       
==================================*/

export const getAllTaxGroups = () => {
  return { type: GET_ALL_TAX_GROUPS_REQUEST };
};
export const populateTaxGroups = (entries) => {
  return { type: GET_ALL_TAX_GROUPS_SUCCESS, payload: entries };
};
export const populateTaxGroupsFailed = (error) => {
  return { type: GET_ALL_TAX_GROUPS_FAILED, payload: error };
};

/*----------------------------------
   TAX GROUP > ADD TAX GROUPS       
==================================*/

export const addTaxGroup = (payload) => {
  return { type: ADD_TAX_GROUPS_REQUEST, payload };
};
export const addTaxGroupFailed = (error) => {
  return { type: ADD_TAX_GROUPS_FAILED, payload: error };
};

/*----------------------------------
   TAX GROUP > UPDATE TAX GROUPS       
==================================*/

export const updateTaxGroup = (tax_id, entry) => {
  console.log(tax_id, entry, 'action group');
  return { type: UPDATE_TAX_GROUPS_REQUEST, payload: { tax_id, entry } };
};
/*----------------------------------
   TAX GROUP > REMOVE TAX GROUPS       
==================================*/

export const removeTaxGroup = (id) => {
  return { type: REMOVE_TAX_GROUPS_REQUEST, payload: { id } };
};
