/* eslint-disable no-use-before-define */
import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

const AutoCompleteHighlight = ({
  id,
  value,
  options,
  getOptionLabel,
  getOptionSelected,
  label,
  variant,
  onChange,
  border,
  ...rest
}) => {
  return (
    <Autocomplete
      id={id}
      value={value}
      onChange={onChange}
      options={options}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant={variant || 'outlined'}
          margin="normal"
        />
      )}
      renderOption={(option, { inputValue }) => {
        const matches = match(
          option.supplier_name || option.outlet_name || option.name || option,
          inputValue
        );
        const parts = parse(
          option.supplier_name || option.outlet_name || option.name || option,
          matches
        );
        return (
          <div>
            {parts.map((part, index) => (
              <span
                key={index}
                style={{ fontWeight: part.highlight ? 700 : 400 }}
              >
                {part.text}
              </span>
            ))}
          </div>
        );
      }}
      {...rest}
    />
  );
};
AutoCompleteHighlight.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  getOptionSelected: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  getOptionLabel: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any,
  variant: PropTypes.string
};

export default AutoCompleteHighlight;
