import { Button, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 0,
    margin: theme.spacing(0.5),
    fontSize: 13,
    textTransform: 'capitalize'
  },
  secondary: {
    backgroundColor: theme.palette.secondary.light,
    '& .MuiButton-label': { color: theme.palette.secondary.main }
  },
  primary: {
    backgroundColor: theme.palette.primary.light,
    '& .MuiButton-label': { color: theme.palette.primary.main }
  }
}));
const ActionButton = (props) => {
  const classes = useStyles();
  const { color, children, onClick, recordsForEdit, ...others } = props;

  return (
    <Button
      {...others}
      className={`${classes.root} ${classes[color]}`}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

export default ActionButton;
