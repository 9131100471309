import {
  GET_SINGLE_PURCHASE_ORDER_REQUEST,
  GET_SINGLE_PURCHASE_ORDER_SUCCESS,
  GET_SINGLE_PURCHASE_ORDER_FAILED,
  GET_ALL_PURCHASE_ORDER_REQUEST,
  GET_ALL_PURCHASE_ORDER_SUCCESS,
  GET_ALL_PURCHASE_ORDER_FAILED,
  ADD_PURCHASE_ORDER_REQUEST,
  ADD_PURCHASE_ORDER_FAILED,
  RECEIVE_PURCHASE_ORDER_REQUEST,
  REMOVE_PURCHASE_ORDER_REQUEST,
  RECEIVE_ALL_PURCHASE_ORDER_REQUEST
} from 'redux/constants/InventoryConstants/inventory.receive.purchorders.constants';
/*----------------------------------
      PURCHASE_ORDER> GET SINGLE PURCHASE_ORDER        
  ==================================*/

export const getSinglePurchaseOrder = (purchase_id) => {
  return { type: GET_SINGLE_PURCHASE_ORDER_REQUEST, payload: purchase_id };
};
export const populateSinglePurchaseOrder = (purchase) => {
  return { type: GET_SINGLE_PURCHASE_ORDER_SUCCESS, payload: purchase };
};
export const populateSinglePurchaseOrderFailed = (error) => {
  return { type: GET_SINGLE_PURCHASE_ORDER_FAILED, payload: error };
};

/*------------------------------------------
       PURCHASE_ORDER> GET ALL PURCHASE_ORDER       
    ==========================================*/

export const getAllPurchaseOrders = (
  searchValue,
  page,
  size,
  order,
  orderBy
) => {
  return {
    type: GET_ALL_PURCHASE_ORDER_REQUEST,
    searchValue,
    page,
    size,
    order,
    orderBy
  };
};
export const populatePurchaseOrders = (entries) => {
  return { type: GET_ALL_PURCHASE_ORDER_SUCCESS, payload: entries };
};
export const populatePurchaseOrdersFailed = (error) => {
  return { type: GET_ALL_PURCHASE_ORDER_FAILED, payload: error };
};

/*-------------------------------------
       PURCHASE_ORDER> ADD PURCHASE_ORDER       
    =====================================*/

export const addPurchaseOrder = (payload) => {
  return { type: ADD_PURCHASE_ORDER_REQUEST, payload };
};
export const addPurchaseOrderFailed = (error) => {
  return { type: ADD_PURCHASE_ORDER_FAILED, payload: error };
};

/*------------------------------------------
       PURCHASE_ORDER> UPDATE PURCHASE_ORDER       
    ==========================================*/

export const receivePurchaseOrder = (purch_orderlineitem_id, entry) => {
  return {
    type: RECEIVE_PURCHASE_ORDER_REQUEST,
    payload: { purch_orderlineitem_id, entry }
  };
};
export const receiveAllPurchaseOrder = (purchase_id) => {
  return {
    type: RECEIVE_ALL_PURCHASE_ORDER_REQUEST,
    payload: { purchase_id }
  };
};
/*----------------------------------------
       PURCHASE_ORDER> REMOVE PURCHASE_ORDER       
    =========================================*/

export const removePurchaseOrder = (purch_orderlineitem_id) => {
  return {
    type: REMOVE_PURCHASE_ORDER_REQUEST,
    payload: { purch_orderlineitem_id }
  };
};
