import React from 'react';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete'; // createFilterOptions,
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
// const filterOptions = createFilterOptions({
//   matchFrom: "start",
//   stringify: (option) => option.text,
// });
const CustomAutoComplete = (props) => {
  const {
    error = null,
    name,
    label,
    inputValue,
    options,
    disabled,
    onInputChange,
    defaultValue
  } = props;
  return (
    <Autocomplete
      options={options}
      onInputChange={onInputChange}
      getOptionLabel={(option) => option.text ?? option.title}
      inputValue={inputValue}
      disabled={disabled}
      defaultValue={defaultValue}
      name={name}
      renderInput={(params) => (
        <>
          <TextField
            {...params}
            size="small"
            label={label}
            variant="outlined"
            margin="normal"
            disabled={disabled}
            {...(error && { error: true, helperText: error })}
          />
        </>
      )}
      renderOption={(option, { inputValue }) => {
        const matches = match(option.text ?? option.title, inputValue);
        const parts = parse(option.text ?? option.title, matches);

        return (
          <div>
            {parts.map((part, index) => (
              <span
                key={index}
                style={{ fontWeight: part.highlight ? 700 : 400 }}
              >
                {part.text}
              </span>
            ))}
          </div>
        );
      }}
    />
  );
};

export default CustomAutoComplete;
