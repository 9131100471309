import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

const MultiSelectInput = (props) => {
  const { onChange, options, name, label, getOptionLabel, placeholder } = props;

  return (
    <Autocomplete
      multiple
      name={name}
      id="tags-outlined"
      options={options}
      getOptionLabel={getOptionLabel}
      defaultValue={[]}
      onChange={onChange}
      filterSelectedOptions
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={label}
          placeholder={placeholder}
        />
      )}
    />
  );
};

export default MultiSelectInput;
