import axios from 'axios';
import React from 'react';
import { TextField, CircularProgress, InputAdornment } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { Search as SearchIcon } from '@material-ui/icons';

const AsyncAutoComplete = (props) => {
  const {
    id,
    multiple,
    name,
    value,
    onChange,
    open,
    onOpen,
    onClose,
    options,
    loading,
    onKeyDown,
    renderOption,
    label,
    placeholder,
    getOptionSelected,
    getOptionLabel
  } = props;

  return (
    <React.Fragment>
      <Autocomplete
        id={id}
        name={name}
        fullWidth
        size="small"
        multiple={multiple}
        value={value}
        onChange={onChange}
        disableClearable={true}
        onKeyDown={onKeyDown}
        open={open}
        onOpen={onOpen}
        onClose={onClose}
        // style={{ backgroundColor: 'blue' }}
        renderOption={renderOption}
        placeholder={placeholder}
        getOptionSelected={getOptionSelected}
        getOptionLabel={getOptionLabel}
        options={options}
        loading={loading}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            style={{}}
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  <InputAdornment position="end">
                    <SearchIcon size={20} />
                  </InputAdornment>
                </React.Fragment>
              )
            }}
          />
        )}
      />
    </React.Fragment>
  );
};

export default AsyncAutoComplete;
