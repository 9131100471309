import { SET_SNACKBAR } from 'redux/constants/UIComponentsConstants/snackbar.constants';

export const setSnackbar = (
  snackbarOpen,
  snackbarType = 'success',
  snackbarMessage = ''
) => ({
  type: SET_SNACKBAR,
  snackbarOpen,
  snackbarType,
  snackbarMessage
});
