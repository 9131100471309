/*----------------------------------
          PAYMENT_TYPES CONSTANTS         
==================================*/
export const GET_ALL_PAYMENT_TYPES_REQUEST = 'GET_ALL_PAYMENT_TYPES_REQUEST';
export const GET_ALL_PAYMENT_TYPES_SUCCESS = 'GET_ALL_PAYMENT_TYPES_SUCCESS';
export const GET_ALL_PAYMENT_TYPES_FAILED = 'GET_ALL_PAYMENT_TYPES_FAILED';

export const REMOVE_PAYMENT_TYPES_REQUEST = 'REMOVE_PAYMENT_TYPES_REQUEST';
export const REMOVE_PAYMENT_TYPES_SUCCESS = 'REMOVE_PAYMENT_TYPES_SUCCESS';
export const REMOVE_PAYMENT_TYPES_FAILED = 'REMOVE_PAYMENT_TYPES_FAILED';

export const ADD_PAYMENT_TYPES_REQUEST = 'ADD_PAYMENT_TYPES_REQUEST';
export const ADD_PAYMENT_TYPES_SUCCESS = 'ADD_PAYMENT_TYPES_SUCCESS';
export const ADD_PAYMENT_TYPES_FAILED = 'ADD_PAYMENT_TYPES_FAILED';

export const UPDATE_PAYMENT_TYPES_REQUEST = 'UPDATE_PAYMENT_TYPES_REQUEST';
export const UPDATE_PAYMENT_TYPES_SUCCESS = 'UPDATE_PAYMENT_TYPES_SUCCESS';
export const UPDATE_PAYMENT_TYPES_FAILED = 'UPDATE_PAYMENT_TYPES_FAILED';

export const GET_SINGLE_PAYMENT_TYPES_REQUEST =
  'GET_SINGLE_PAYMENT_TYPES_REQUEST';
export const GET_SINGLE_PAYMENT_TYPES_SUCCESS =
  'GET_SINGLE_PAYMENT_TYPES_SUCCESS';
export const GET_SINGLE_PAYMENT_TYPES_FAILED =
  'GET_SINGLE_PAYMENT_TYPES_FAILED';
