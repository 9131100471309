/*----------------------------------
           CUSTOMER        
==================================*/

export const GET_SINGLE_CUSTOMER_REQUEST = 'GET_SINGLE_CUSTOMER_REQUEST';
export const GET_SINGLE_CUSTOMER_SUCCESS = 'GET_SINGLE_CUSTOMER_SUCCESS';
export const GET_SINGLE_CUSTOMER_FAILED = 'GET_SINGLE_CUSTOMER_FAILED';
export const RESET_CUSTOMER = 'RESET_CUSTOMER';
export const SET_CUSTOMER = 'SET_CUSTOMER';
export const RESET_CUSTOMER_SEARCH = 'RESET_CUSTOMER_SEARCH';
export const HIDE_POPUP = 'HIDE_POPUP';

export const GET_ALL_CUSTOMERS_REQUEST = 'GET_ALL_CUSTOMERS_REQUEST';
export const GET_ALL_CUSTOMERS_SUCCESS = 'GET_ALL_CUSTOMERS_SUCCESS';
export const GET_ALL_CUSTOMERS_FAILED = 'GET_ALL_CUSTOMERS_FAILED';

export const ADD_CUSTOMERS_REQUEST = 'ADD_CUSTOMERS_REQUEST';
export const ADD_CUSTOMERS_SUCCESS = 'ADD_CUSTOMERS_SUCCESS';
export const ADD_CUSTOMERS_FAILED = 'ADD_CUSTOMERS_FAILED';

export const UPDATE_CUSTOMER_REQUEST = 'UPDATE_CUSTOMER_REQUEST';
export const UPDATE_CUSTOMER_SUCCESS = 'UPDATE_CUSTOMER_SUCCESS';
export const UPDATE_CUSTOMER_FAILED = 'UPDATE_CUSTOMER_FAILED';

export const REMOVE_CUSTOMER_REQUEST = 'REMOVE_CUSTOMER_REQUEST';
export const REMOVE_CUSTOMER_SUCCESS = 'REMOVE_CUSTOMER_SUCCESS';
export const REMOVE_CUSTOMER_FAILED = 'REMOVE_CUSTOMER_FAILED';

export const REMOVE_MULTIPLE_CUSTOMER_REQUEST =
  'REMOVE_MULTIPLE_CUSTOMER_REQUEST';
export const REMOVE_MULTIPLE_CUSTOMER_SUCCESS =
  'REMOVE_MULTIPLE_CUSTOMER_SUCCESS';
export const REMOVE_MULTIPLE_CUSTOMER_FAILED =
  'REMOVE_MULTIPLE_CUSTOMER_FAILED';
