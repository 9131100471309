import {
  take,
  call,
  put,
  takeLatest,
  takeEvery,
  delay
} from '@redux-saga/core/effects';

import {
  GET_PRODUCT_TAGS_REQUEST,
  REMOVE_MULTIPLE_PRODUCT_TAGS_REQUEST,
  REMOVE_MULTIPLE_PRODUCT_TAGS_SUCCESS,
  REMOVE_PRODUCT_TAGS_REQUEST,
  REMOVE_PRODUCT_TAGS_SUCCESS,
  ADD_PRODUCT_TAGS_REQUEST,
  ADD_PRODUCT_TAGS_SUCCESS,
  UPDATE_PRODUCT_TAGS_REQUEST,
  UPDATE_PRODUCT_TAGS_SUCCESS,
  REMOVE_PRODUCT_TAGS_FAILED,
  REMOVE_MULTIPLE_PRODUCT_TAGS_FAILED
} from 'redux/constants/ProductConstants/product.tags.constants';
import {
  populateProductTags,
  populateProductTagsError,
  addProductTagError
} from 'redux/actions/ProductActions/product.tags.actions';
import { setSnackbar } from 'redux/actions/UIComponentActions/snackbar.action';
import api from 'utils/api';

/*----------------------------------
      GET ALL PRODUCT TAGS        
==================================*/
// export function* getAllProductTags() {
//   yield take(GET_PRODUCT_TAGS_REQUEST);
//   try {
//     // const { data } = yield call(api, url);
//     const { data } = yield call(api, `/getAll-tags`);
//     yield put(populateProductTags(data));
//   } catch (error) {
//     yield put(populateProductTagsError(error));
//   }
// }

export function* getAllProductTags() {
  yield takeLatest(GET_PRODUCT_TAGS_REQUEST, fetchProducts);
}
function* fetchProducts(payload) {
  const {
    page: currentPage,
    size,
    orderBy: sortField,
    order: sortDir,
    searchValue: query
  } = payload;
  try {
    const { data } = yield api.get('/getAll-tags', {
      params: {
        currentPage: currentPage + 1 || 1,
        size: size || 8,
        sortField: sortField || 'tag_name',
        sortDir: sortDir || 'asc',
        query: query || ''
      }
    });
    yield delay(400);
    yield put(populateProductTags(data));
  } catch (err) {
    yield put(populateProductTagsError(err));
    if (err.response.status !== 404) {
      yield put(setSnackbar(true, 'error', err.message));
    }
  }
}
/*----------------------------------
         DELETE SINGLE ENTRY       
==================================*/
// export function* deleteEntrySaga() {
//   while (true) {
//     const { payload } = yield take(REMOVE_PRODUCT_TAGS_REQUEST);
//     yield call(deleteEntries, payload.id);
//     yield put({
//       type: REMOVE_PRODUCT_TAGS_SUCCESS,
//       payload: { id: payload.id }
//     });
//     yield put({ type: GET_PRODUCT_TAGS_REQUEST });
//     yield call(getAllProductTags);
//   }
// }

// async function deleteEntries(id) {
//   await api.delete(`/delete-tags/${id}`);
// }
function* deleteProduct(action) {
  const { id } = action.payload;
  try {
    yield call(deleteProductDB, id);
    yield put({
      type: REMOVE_PRODUCT_TAGS_SUCCESS,
      payload: { id: id }
    });
    yield put(setSnackbar(true, 'error', 'Product Brand Deleted successfully'));
    yield put({ type: GET_PRODUCT_TAGS_REQUEST });
    yield call(getAllProductTags);
  } catch (error) {
    yield put({
      type: REMOVE_PRODUCT_TAGS_FAILED,
      payload: error?.response?.data?.errorMessage
    });
    yield put(setSnackbar(true, 'error', error?.response?.data?.errorMessage));
    yield put({ type: GET_PRODUCT_TAGS_REQUEST });
    yield call(getAllProductTags);
  }
}
const deleteProductDB = async (id) => {
  return await api.delete(`/delete-tags/${id}`);
};
// watcher saga
export function* deleteProductSaga() {
  yield takeEvery(REMOVE_PRODUCT_TAGS_REQUEST, deleteProduct);
}
/*----------------------------------
         DELETE MULTIPLE ENTRY       
==================================*/
// export function* deleteMultipleEntrySaga() {
//   while (true) {
//     const { payload } = yield take(REMOVE_MULTIPLE_PRODUCT_TAGS_REQUEST);
//     yield call(deleteMultipleEntries, payload.id);
//     yield put({
//       type: REMOVE_MULTIPLE_PRODUCT_TAGS_SUCCESS,
//       payload: { id: payload.id }
//     });
//     yield put({ type: GET_PRODUCT_TAGS_REQUEST });
//     yield call(getAllProductTags);
//   }
// }

// async function deleteMultipleEntries(ids) {
//   await Promise.all(ids.map((id) => api.delete(`/delete-tags/${id}`)));
// }
function* deleteProductTagMultiple(action) {
  const { id } = action.payload;
  try {
    yield call(deleteProductTagMultiDB, id);
    yield put({
      type: REMOVE_MULTIPLE_PRODUCT_TAGS_SUCCESS,
      payload: { id: id }
    });
    yield put(setSnackbar(true, 'error', 'Product Tags Deleted successfully'));
    yield put({ type: REMOVE_MULTIPLE_PRODUCT_TAGS_REQUEST });
    yield call(getAllProductTags);
  } catch (error) {
    yield put({
      type: REMOVE_MULTIPLE_PRODUCT_TAGS_FAILED,
      payload: error?.response?.data?.errorMessage
    });
    yield put(setSnackbar(true, 'error', error?.response?.data?.errorMessage));
    yield put({ type: GET_PRODUCT_TAGS_REQUEST });
    yield call(getAllProductTags);
  }
}
const deleteProductTagMultiDB = async (ids) => {
  return await Promise.all(ids.map((id) => api.delete(`/delete-tags/${id}`)));
};
// watcher saga
export function* deleteProductTagSaga() {
  yield takeEvery(
    REMOVE_MULTIPLE_PRODUCT_TAGS_REQUEST,
    deleteProductTagMultiple
  );
}

/*----------------------------------
         ADD PRODUCT TAG       
==================================*/
export function* addEntrySaga() {
  yield takeLatest(ADD_PRODUCT_TAGS_REQUEST, addEntryToDb);
}

function* addEntryToDb({ payload }) {
  try {
    yield call(addEntry, payload);
    yield put({ type: ADD_PRODUCT_TAGS_SUCCESS, payload });
    yield put(setSnackbar(true, 'success', 'Product Tag added Successfully'));
    yield put({ type: GET_PRODUCT_TAGS_REQUEST });
    yield call(getAllProductTags);
  } catch (error) {
    yield put(addProductTagError(error));
  }
}

async function addEntry({
  id,
  tag_name,
  active,
  created_date_time,
  last_modified_time
}) {
  await api.post(`/save-tags`, {
    id,
    tag_name,
    active,
    created_date_time,
    last_modified_time
  });
}

/*----------------------------------
       UPDATE PRODUCT TAG        
==================================*/

export function* updateEntrySaga() {
  yield takeEvery(UPDATE_PRODUCT_TAGS_REQUEST, updateEntryToDb);
}

function* updateEntryToDb({ payload }) {
  try {
    yield call(updateEntry, payload);
    yield put({ type: UPDATE_PRODUCT_TAGS_SUCCESS, payload });
    yield put({ type: GET_PRODUCT_TAGS_REQUEST });
    yield call(getAllProductTags);
  } catch (error) {}
}

async function updateEntry(payload) {
  const { id, entry } = payload;
  const { tag_name, active, created_date_time, last_modified_time } = entry;
  await api.put(`/update-tags/${id}`, {
    id,
    tag_name,
    active,
    created_date_time,
    last_modified_time
  });
}
