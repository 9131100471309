import {
  GET_DRAFT_PO_NOT_YET_SENT_REQUEST,
  GET_DRAFT_PO_NOT_YET_SENT_SUCCESS,
  GET_DRAFT_PO_NOT_YET_SENT_FAILED
} from 'redux/constants/InventoryConstants/inventory.dashboard.constants';
/* GET_DRAFT_PO_NOT_YET_SENT_REQUEST */
const InventoryDraftPOReducer = (
  state = {
    loading: false,
    draft_pos: [],
    error: false,
    page: 1,
    first: true,
    last: true
  },
  action
) => {
  const { draft_pos } = state;

  switch (action.type) {
    case GET_DRAFT_PO_NOT_YET_SENT_REQUEST:
      return {
        ...state,
        draft_pos: [],
        loading: true
      };
    case GET_DRAFT_PO_NOT_YET_SENT_SUCCESS:
      return {
        ...state,
        draft_pos: [...action.payload.content],
        page: action.payload.page,
        pages: action.payload.totalElements,
        first: action.payload.first,
        last: action.payload.last,
        size: action.payload.size,
        searchValue: action.payload.searchValue,
        loading: false
      };
    case GET_DRAFT_PO_NOT_YET_SENT_FAILED:
      return {
        ...state,
        error: action.payload,
        draft_pos: [...draft_pos],
        loading: false
      };

    default:
      return state;
  }
};
export default InventoryDraftPOReducer;
