import { call, put, take, takeLatest, takeEvery } from 'redux-saga/effects';
import api from 'utils/api';
import {
  ADD_CASH_REGISTERS_REQUEST,
  ADD_CASH_REGISTERS_SUCCESS,
  GET_ALL_CASH_REGISTERS_REQUEST,
  GET_SINGLE_CASH_REGISTERS_REQUEST,
  REMOVE_CASH_REGISTERS_FAILED,
  REMOVE_CASH_REGISTERS_REQUEST,
  REMOVE_CASH_REGISTERS_SUCCESS,
  UPDATE_CASH_REGISTERS_REQUEST,
  UPDATE_CASH_REGISTERS_SUCCESS
} from 'redux/constants/StoreSetupConstants/cash.registers.constants';
import {
  addCashRegisterFailed,
  populateCashRegisters,
  populateCashRegistersFailed,
  populateSingleCashRegister,
  populateSingleCashRegisterFailed
} from 'redux/actions/StoreSetupActions/cash.register.action';
import { setSnackbar } from 'redux/actions/UIComponentActions/snackbar.action';
import { getAllOutlets } from './outlets.registers.saga';
import { GET_ALL_OUTLETS_REQUEST } from 'redux/constants/StoreSetupConstants/outlets.registers.constants';

// /*----------------------------------
//        GET SINGLE cash register
// ==================================*/
export function* onFetchProductStart() {
  yield takeLatest(GET_SINGLE_CASH_REGISTERS_REQUEST, fetchProduct);
}
function* fetchProduct({ payload }) {
  try {
    if (payload) {
      const { data } = yield api.get(`/get-cashReg/${payload}`);
      yield put(populateSingleCashRegister(data));
    }
  } catch (err) {
    yield put(populateSingleCashRegisterFailed(err.message));
  }
}

/*----------------------------------
    SYSTEM USER >> GET ALL CASH_REGISTER          
==================================*/
export function* getAllCashRegisters() {
  yield takeLatest(GET_ALL_CASH_REGISTERS_REQUEST, fetchProducts);
}
function* fetchProducts() {
  try {
    const { data } = yield api.get(`/getAll-cashRegs`);
    yield put(populateCashRegisters(data));
  } catch (error) {
    yield put(populateCashRegistersFailed(error));
    if (error?.response?.status !== 404) {
      yield put(setSnackbar(true, 'error', error.message));
    }
  }
}

/*----------------------------------
    SYSTEM USER >> ADD USER          
==================================*/
export function* addSystemUserSaga() {
  yield takeLatest(ADD_CASH_REGISTERS_REQUEST, systemUser);
}

function* systemUser({ payload }) {
  try {
    const result = yield call(sysUserAsync, payload);
    yield put({ type: ADD_CASH_REGISTERS_SUCCESS, payload: result });
    yield put({ type: GET_ALL_OUTLETS_REQUEST });
    yield call(getAllOutlets);
    yield put(setSnackbar(true, 'success', 'Cash Register Added Successfully'));
  } catch (error) {
    yield put(addCashRegisterFailed(error));
    if (error.response.status !== 404)
      yield put(setSnackbar(true, 'error', 'Something Went Wrong !!'));
  }
}

async function sysUserAsync(payload) {
  const {
    cash_regid,
    cash_reg_name,
    receipt_no,
    receipt_no_prefix,
    receipt_no_suffix,
    receipt_template,
    gift_receipt_template,
    delivery_docket,
    parkedsale_rec_temp,
    laybysale_rec_temp,
    outlet_id
  } = payload;

  const { data } = await api.post(`/save-cashReg`, {
    cash_regid,
    cash_reg_name,
    receipt_no,
    receipt_no_prefix,
    receipt_no_suffix,
    receipt_template,
    gift_receipt_template,
    delivery_docket,
    parkedsale_rec_temp,
    laybysale_rec_temp,
    outlet_id
  });
  console.log(data, 'data saga');
  return data;
}
/* {
    "outlet_id": 29,
	"cash_reg_name": "cash by atul",
	"receipt_no": 800,
	"receipt_no_prefix": "po-23",
	"receipt_no_suffix": "32",
	"receipt_template": "emial template",
	"gift_receipt_template": "abc",
	"delivery_docket": "xyz",
	"parkedsale_rec_temp": "efg",
	"laybysale_rec_temp": "fdfd"

    
} */
/*----------------------------------
       SYSTEM USER >> UPDATE USER        
==================================*/

export function* updateSystemUserSaga() {
  yield takeEvery(UPDATE_CASH_REGISTERS_REQUEST, updateSystemUser);
}

function* updateSystemUser({ payload }) {
  try {
    const result = yield call(updateAsyncCashRegisters, payload);
    yield put({ type: UPDATE_CASH_REGISTERS_SUCCESS, payload: result });
    console.log(result, 'result sagaMiddleware');
    yield put({ type: GET_ALL_OUTLETS_REQUEST });
    yield call(getAllOutlets);
    yield put(
      setSnackbar(true, 'success', 'CashRegister Updated Successfully')
    );
  } catch (error) {
    if (error.response.status !== 404)
      yield put(setSnackbar(true, 'error', 'Something Went Wrong !!'));
  }
}

async function updateAsyncCashRegisters(payload) {
  const { cash_regid, entry } = payload;
  const {
    cash_reg_name,
    receipt_no,
    receipt_no_prefix,
    receipt_no_suffix,
    receipt_template,
    gift_receipt_template,
    delivery_docket,
    parkedsale_rec_temp,
    laybysale_rec_temp,
    outlet_id,
    paymentTypes,
    pos_rid
  } = entry;
  const { data } = await api.put(`/update-cashReg/${cash_regid}`, {
    cash_regid,
    cash_reg_name,
    receipt_no,
    receipt_no_prefix,
    receipt_no_suffix,
    receipt_template,
    gift_receipt_template,
    delivery_docket,
    parkedsale_rec_temp,
    laybysale_rec_temp,
    outlet_id,
    paymentTypes,
    pos_rid
  });
  return data;
}

/*-----------------------------------------
    TAX RATE >> DELETE SINGLE TAX RATE       
=========================================*/

export function* deleteSystemUserSaga() {
  while (true) {
    try {
      const { payload } = yield take(REMOVE_CASH_REGISTERS_REQUEST);
      yield call(deleteSingleSystemUsers, payload.cash_regid);
      yield put({
        type: REMOVE_CASH_REGISTERS_SUCCESS,
        payload: { cash_regid: payload.cash_regid }
      });
      yield put({ type: GET_ALL_OUTLETS_REQUEST });
      yield call(getAllOutlets);
      yield put(setSnackbar(true, 'error', 'CashRegister Delete Successfully'));
    } catch (error) {
      yield put({ type: REMOVE_CASH_REGISTERS_FAILED, payload: error });
      yield put(setSnackbar(true, 'error', 'Something Went Wrong'));
    }
  }
}

async function deleteSingleSystemUsers(cash_regid) {
  await api.delete(`/delete-cashReg/${cash_regid}`);
}
