import React from 'react';
import './NotFound.css';
import { ArrowBack, Home as HomeIcon } from '@material-ui/icons';
// import { useHistory } from 'react-router-dom';
import { history } from 'redux/store/configureStore';
import { Grid, makeStyles, Paper } from '@material-ui/core';
import Controls from 'components/controls/Controls';
const useStyles = makeStyles((theme) => ({
  paperContent: {
    margin: theme.spacing(0),
    padding: theme.spacing(3)
  },
  image: {
    width: '75%',
    overflow: 'hidden'
  }
}));
const NotFound = (props) => {
  const classes = useStyles();
  // const history = useHistory();
  return (
    <Paper className={classes.paperContent}>
      <div className="section section-404 p-0 m-0 ">
        <div>
          <Grid className="col s12 center-align white">
            <img src="not-found2.png" className="bg-image-404" alt="" />
            <h1 className="error-code m-0">404</h1>
            <h2 className="m-5">BAD REQUEST</h2>

            <div className="position-bottom-fixed">
              <Controls.Button
                text="Back"
                className="nf-button"
                startIcon={<ArrowBack />}
                onClick={() => {
                  history.goBack();
                }}
              />
              <Controls.Button
                className="nf-button"
                text="Back To Home"
                onClick={() => history.push('/')}
                startIcon={<HomeIcon />}
              />
            </div>
          </Grid>
        </div>
      </div>
    </Paper>
  );
};

export default NotFound;
