export const GET_PRODUCT_TYPES_REQUEST = 'GET_PRODUCT_TYPES_REQUEST';
export const GET_PRODUCT_TYPES_SUCCESS = 'GET_PRODUCT_TYPES_SUCCESS';
export const GET_PRODUCT_TYPES_FAILED = 'GET_PRODUCT_TYPES_FAILED';
export const GET_SINGLE_PRODUCT_TYPES_REQUEST =
  'GET_SINGLE_PRODUCT_TYPES_REQUEST';
export const GET_SINGLE_PRODUCT_TYPES_SUCCESS =
  'GET_SINGLE_PRODUCT_TYPES_SUCCESS';
export const GET_SINGLE_PRODUCT_TYPES_FAILED =
  'GET_SINGLE_PRODUCT_TYPES_FAILED';
export const REMOVE_PRODUCT_TYPES_REQUEST = 'REMOVE_PRODUCT_TYPES_REQUEST';
export const REMOVE_PRODUCT_TYPES_SUCCESS = 'REMOVE_PRODUCT_TYPES_SUCCESS';
export const REMOVE_PRODUCT_TYPES_FAILED = 'REMOVE_PRODUCT_TYPES_FAILED';
export const REMOVE_MULTIPLE_PRODUCT_TYPES_REQUEST =
  'REMOVE_MULTIPLE_PRODUCT_TYPES_REQUEST';
export const REMOVE_MULTIPLE_PRODUCT_TYPES_SUCCESS =
  'REMOVE_MULTIPLE_PRODUCT_TYPES_SUCCESS';
export const REMOVE_MULTIPLE_PRODUCT_TYPES_FAILED =
  'REMOVE_MULTIPLE_PRODUCT_TYPES_FAILED';
export const ADD_PRODUCT_TYPES_REQUEST = 'ADD_PRODUCT_TYPES_REQUEST';
export const ADD_PRODUCT_TYPES_SUCCESS = 'ADD_PRODUCT_TYPES_SUCCESS';
export const ADD_PRODUCT_TYPES_FAILED = 'ADD_PRODUCT_TYPES_FAILED';
export const UPDATE_PRODUCT_TYPES_REQUEST = 'UPDATE_PRODUCT_TYPES_REQUEST';
export const UPDATE_PRODUCT_TYPES_SUCCESS = 'UPDATE_PRODUCT_TYPES_SUCCESS';
export const UPDATE_PRODUCT_TYPES_FAILED = 'UPDATE_PRODUCT_TYPES_FAILED';
