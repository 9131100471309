import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const AutoCompleteSearchSingle = (props) => {
  const convertToDefaultEventParameter = (name, value) => ({
    target: {
      name,
      value
    }
  });
  const {
    disableClearable,
    getOptionLabel,
    value,
    inputValue,
    onInputChange,
    onChange,
    id,
    label,
    options,
    disabled,
    name,
    onKeyDown,
    classes,
    required,
    getOptionSelected,
    renderOption
  } = props;
  // 'MuiAutocomplete-option'
  // /* const me = event.target.className;

  return (
    <Autocomplete
      required={required}
      fullWidth
      classes={classes}
      size="small"
      value={value}
      getOptionSelected={getOptionSelected}
      getOptionLabel={getOptionLabel}
      renderOption={renderOption}
      // onChange={onChange}
      onChange={(ev) => {
        return onChange(
          convertToDefaultEventParameter(name, ev.target.innerHTML)
        );
      }}
      inputValue={inputValue}
      onInputChange={onInputChange}
      id={id}
      name={name}
      options={options}
      disabled={disabled}
      disableClearable={disableClearable}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          autoComplete="off"
          onKeyDown={onKeyDown}
        />
      )}
    />
  );
};

export default AutoCompleteSearchSingle;
