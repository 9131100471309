/*----------------------------------
    INVENTORY PURCHASE CONSTANTS         
==================================*/

export const GET_ALL_PURCHASES_REQUEST = 'GET_ALL_PURCHASES_REQUEST';
export const GET_ALL_PURCHASES_SUCCESS = 'GET_ALL_PURCHASES_SUCCESS';
export const GET_ALL_PURCHASES_FAILED = 'GET_ALL_PURCHASES_FAILED';

export const REMOVE_PURCHASES_REQUEST = 'REMOVE_PURCHASES_REQUEST';
export const REMOVE_PURCHASES_SUCCESS = 'REMOVE_PURCHASES_SUCCESS';
export const REMOVE_PURCHASES_FAILED = 'REMOVE_PURCHASES_FAILED';

export const ADD_PURCHASES_REQUEST = 'ADD_PURCHASES_REQUEST';
export const ADD_PURCHASES_SUCCESS = 'ADD_PURCHASES_SUCCESS';
export const ADD_PURCHASES_FAILED = 'ADD_PURCHASES_FAILED';

export const UPDATE_PURCHASES_REQUEST = 'UPDATE_PURCHASES_REQUEST';
export const UPDATE_PURCHASES_SUCCESS = 'UPDATE_PURCHASES_SUCCESS';
export const UPDATE_PURCHASES_FAILED = 'UPDATE_PURCHASES_FAILED';

export const GET_SINGLE_PURCHASES_REQUEST = 'GET_SINGLE_PURCHASES_REQUEST';
export const GET_SINGLE_PURCHASES_SUCCESS = 'GET_SINGLE_PURCHASES_SUCCESS';
export const GET_SINGLE_PURCHASES_FAILED = 'GET_SINGLE_PURCHASES_FAILED';

export const PURCHASE_RESET = 'PURCHASE_RESET';
