export const GET_ALL_TAX_RATES_REQUEST = 'GET_ALL_TAX_RATES_REQUEST';
export const GET_ALL_TAX_RATES_SUCCESS = 'GET_ALL_TAX_RATES_SUCCESS';
export const GET_ALL_TAX_RATES_FAILED = 'GET_ALL_TAX_RATES_FAILED';

export const REMOVE_TAX_RATES_REQUEST = 'REMOVE_TAX_RATES_REQUEST';
export const REMOVE_TAX_RATES_SUCCESS = 'REMOVE_TAX_RATES_SUCCESS';
export const REMOVE_TAX_RATES_FAILED = 'REMOVE_TAX_RATES_FAILED';

export const ADD_TAX_RATES_REQUEST = 'ADD_TAX_RATES_REQUEST';
export const ADD_TAX_RATES_SUCCESS = 'ADD_TAX_RATES_SUCCESS';
export const ADD_TAX_RATES_FAILED = 'ADD_TAX_RATES_FAILED';

export const UPDATE_TAX_RATES_REQUEST = 'UPDATE_TAX_RATES_REQUEST';
export const UPDATE_TAX_RATES_SUCCESS = 'UPDATE_TAX_RATES_SUCCESS';
export const UPDATE_TAX_RATES_FAILED = 'UPDATE_TAX_RATES_FAILED';

export const GET_SINGLE_TAX_RATES_REQUEST = 'GET_SINGLE_TAX_RATES_REQUEST';
export const GET_SINGLE_TAX_RATES_SUCCESS = 'GET_SINGLE_TAX_RATES_SUCCESS';
export const GET_SINGLE_TAX_RATES_FAILED = 'GET_SINGLE_TAX_RATES_FAILED';

export const GET_SINGLE_TAX_GROUP_REQUEST = 'GET_SINGLE_TAX_GROUP_REQUEST';
export const GET_SINGLE_TAX_GROUP_SUCCESS = 'GET_SINGLE_TAX_GROUP_SUCCESS';
export const GET_SINGLE_TAX_GROUP_FAILED = 'GET_SINGLE_TAX_GROUP_FAILED';

export const GET_ALL_TAX_GROUPS_REQUEST = 'GET_ALL_TAX_GROUPS_REQUEST';
export const GET_ALL_TAX_GROUPS_SUCCESS = 'GET_ALL_TAX_GROUPS_SUCCESS';
export const GET_ALL_TAX_GROUPS_FAILED = 'GET_ALL_TAX_GROUPS_FAILED';

export const REMOVE_TAX_GROUPS_REQUEST = 'REMOVE_TAX_GROUPS_REQUEST';
export const REMOVE_TAX_GROUPS_SUCCESS = 'REMOVE_TAX_GROUPS_SUCCESS';
export const REMOVE_TAX_GROUPS_FAILED = 'REMOVE_TAX_GROUPS_FAILED';

export const ADD_TAX_GROUPS_REQUEST = 'ADD_TAX_GROUPS_REQUEST';
export const ADD_TAX_GROUPS_SUCCESS = 'ADD_TAX_GROUPS_SUCCESS';
export const ADD_TAX_GROUPS_FAILED = 'ADD_TAX_GROUPS_FAILED';

export const UPDATE_TAX_GROUPS_REQUEST = 'UPDATE_TAX_GROUPS_REQUEST';
export const UPDATE_TAX_GROUPS_SUCCESS = 'UPDATE_TAX_GROUPS_SUCCESS';
export const UPDATE_TAX_GROUPS_FAILED = 'UPDATE_TAX_GROUPS_FAILED';
